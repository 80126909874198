import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) =>
  state.teamPersonality || initialState;

export const selectTeamPersonality = createSelector(
  [selectDomain],
  (teamPersonalityState) => teamPersonalityState
);

export const selectTeamPersonalityReports = createSelector(
  [selectDomain],
  (teamPersonalityState) => teamPersonalityState.teamPersonalityReports
);

export const selectTeam = createSelector(
  [selectDomain],
  (teamPersonalityState) => teamPersonalityState.team
);

export const selectNotableTraits = createSelector(
  [selectDomain],
  (teamPersonalityState) => {
    const teamPersonalityReports = teamPersonalityState.teamPersonalityReports;
    const notableTraits: string[] = [];
    if (teamPersonalityReports) {
      Object.keys(teamPersonalityReports).map((trait) => {
        if (teamPersonalityReports[trait].isNotableTrait) {
          notableTraits.push(trait);
        }
        return true;
      });
    }
    return notableTraits;
  }
);

export const selectMemberPersonalityReports = createSelector(
  [selectDomain],
  (teamPersonalityState) => teamPersonalityState.memberPersonalityReports
);

export const selectTeamPersonalityPDF = createSelector(
  [selectDomain],
  (teamPersonalityState) => {
    return {
      teamPersonalityPDF: teamPersonalityState.teamPersonalityPDF,
      loadingPersonalityPDF: teamPersonalityState.loadingPersonalityPDF,
    };
  }
);
