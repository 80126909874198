/**
 *
 * NotableTraitCard
 *
 */
import React from "react";
import { Col, Card } from "react-bootstrap";

interface Props {
  title: string;
  text: string;
  color: string;
}

export function NotableTraitCard({ title, text, color }: Props) {
  return (
    <Col lg={6} md={6} sm={12} key={title} className="mb-3">
      <Card style={{ borderLeft: `3px solid ${color}` }} className="h-100">
        <Card.Body>
          <p className="notable-trait-title mb-0">{title}</p>
          <p className="notable-trait-text mt-0">{text}</p>
        </Card.Body>
      </Card>
    </Col>
  );
}
