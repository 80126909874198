import React from "react";
import Chart from "chart.js";
import { Row, Col, Badge } from "react-bootstrap";

export function TeamWorkplaceInsights({ scoreData, numberOfMembers }) {
  Chart.defaults.global.defaultFontColor = "#001f4e";

  const ctx = document.getElementById("teamWIRChart");
  if (ctx) {
    // eslint-disable-next-line no-unused-vars
    const teamChart = new Chart(ctx, {
      type: "radar",
      data: {
        labels: [
          "Achievement",
          "Motivation",
          "Conscientiousness",
          "Assertiveness",
          "Extroversion",
          "Cooperativeness",
          "Competitiveness",
          "Patience",
          "Self-Confidence",
          "Openness",
        ],
        datasets: scoreData,
      },
      options: {
        animation: false,
        legend: {
          display: false,
          position: "bottom",
          align: "center",
          labels: {
            filter: function (legendItem, data) {
              if (legendItem.text === "") return false;
              else return true;
            },
          },
        },
        scale: {
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 125,
            stepSize: 25,
            display: false,
          },
          pointLabels: {
            fontColor: [
              "#425CC7",
              "#425CC7",
              "#425CC7",
              "#ffa300",
              "#ffa300",
              "#ffa300",
              "#FF5C39",
              "#FF5C39",
              "#2dccd3",
              "#2dccd3",
            ],
            fontStyle: "bold",
            fontSize: window.innerWidth < 500 ? 9 : 13,
          },
        },
        layout: {
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        tooltips: {
          enabled: false,
        },
      },
    });
  }

  return (
    <Row className="mb-4">
      <Col lg={12} md={12} sm={12} xs={12}>
        <Badge pill id="workHabitsPill">
          <span className="badge-title">Work Habits</span>
        </Badge>
        <Badge pill id="attitudesOutlookPill">
          <span className="badge-title">Attitudes &amp; Outlook</span>
        </Badge>
        <canvas id="teamWIRChart" />
        <Badge pill id="interactionStylePill">
          <span className="badge-title">Interaction Style</span>
        </Badge>
        <Badge pill id="temperamentPill">
          <span className="badge-title">Temperament</span>
        </Badge>
        <Row className="my-3">
          <Col className="text-center">
            <div className="insightsChartLegendObject">&nbsp;</div>
            <div className="insightsChartLegendLabel mr-2">Team Average</div>
            <div className="insightsChartLegendObject insightsChartLegendObject-general">
              &nbsp;
            </div>
            <div className="insightsChartLegendLabel mr-4">
              General Population
            </div>
          </Col>
        </Row>
        {numberOfMembers > 1 ? (
          <p className="small-text">
            * Includes {numberOfMembers} team members who have completed a
            personality test
          </p>
        ) : null}
      </Col>
    </Row>
  );
}
