import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";

// The initial state of the Global container
export const initialState: ContainerState = {
  user: null,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    getUser(state, action: PayloadAction<any>) {},
    getUserSuccess(state, action: PayloadAction<any>) {
      state.user = action.payload;
    },
    getUserError(state, action: PayloadAction<any>) {
      console.error(action.payload);
    },
  },
});

export const { actions, reducer, name: sliceKey } = globalSlice;
