import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => state.teamList || initialState;

export const selectTeamList = createSelector(
  [selectDomain],
  (teamListState) => teamListState
);

export const selectTeamMembers = createSelector(
  [selectDomain],
  (teamListState) => teamListState.team?.teamMembers
);

export const selectTeamName = createSelector(
  [selectDomain],
  (teamListState) => teamListState.team?.teamName
);

export const selectViewAllWorkplaceInsights = createSelector(
  [selectDomain],
  (teamListState) => teamListState.team?.viewAllWorkplaceInsights
);

export const selectAllowedDomains = createSelector(
  [selectDomain],
  (teamListState) => teamListState.allowedDomains
);

export const selectMembersInvited = createSelector(
  [selectDomain],
  (teamListState) => teamListState.membersInvited
);

export const selectUsersList = createSelector(
  [selectDomain],
  (teamListState) => teamListState.usersList
);

export const selectInvitingMembers = createSelector(
  [selectDomain],
  (teamListState) => teamListState.invitingMembers
);

export const selectAutomatic = createSelector(
  [selectDomain],
  (teamListState) => teamListState.team?.automatic
);
