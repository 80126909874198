import achievementIcon from "resources/images/trait_icons/trait-icons-achievement.svg";
import assertivenessIcon from "resources/images/trait_icons/trait-icons-assertiveness.svg";
import competitivenessIcon from "resources/images/trait_icons/trait-icons-competitiveness.svg";
import conscientiousnessIcon from "resources/images/trait_icons/trait-icons-conscientiousness.svg";
import cooperativenessIcon from "resources/images/trait_icons/trait-icons-cooperativeness.svg";
import extroversionIcon from "resources/images/trait_icons/trait-icons-extroversion.svg";
import motivationIcon from "resources/images/trait_icons/trait-icons-motivation.svg";
import opennessIcon from "resources/images/trait_icons/trait-icons-openness.svg";
import patienceIcon from "resources/images/trait_icons/trait-icons-patience.svg";
import selfConfidenceIcon from "resources/images/trait_icons/trait-icons-self-confidence.svg";
import teamPersonalityHighIcon from "resources/images/team_icons/icon-team-personality-high.svg";
import teamPersonalityMediumIcon from "resources/images/team_icons/icon-team-personality-medium.svg";
import teamPersonalityLowIcon from "resources/images/team_icons/icon-team-personality-low.svg";
import { TeamScores } from "app/containers/TeamPage/types";

export const getTraitNameAndIcon = (
  trait: string,
  teamScores: TeamScores | null
) => {
  const teamIconObject = {
    HIGH: teamPersonalityHighIcon,
    MEDIUM: teamPersonalityMediumIcon,
    LOW: teamPersonalityLowIcon,
  };

  const traitObject = {
    ACH: {
      traitName: "Achievement",
      traitIcon: achievementIcon,
      teamIcon: teamScores ? teamIconObject[teamScores["ACH"].level] : null,
      traitColor: "#a0ade3",
      lowLabel: "Impulsive",
      highLabel: "Goal-Oriented",
    },
    MOT: {
      traitName: "Motivation",
      traitIcon: motivationIcon,
      teamIcon: teamScores ? teamIconObject[teamScores["MOT"].level] : null,
      traitColor: "#a0ade3",
      lowLabel: "Easy-going",
      highLabel: "Driven, Committed",
    },
    CMP: {
      traitName: "Competitiveness",
      traitIcon: competitivenessIcon,
      teamIcon: teamScores ? teamIconObject[teamScores["CMP"].level] : null,
      traitColor: "#ffad9c",
      lowLabel: "Relaxed",
      highLabel: "Competitive",
    },
    AST: {
      traitName: "Assertiveness",
      traitIcon: assertivenessIcon,
      teamIcon: teamScores ? teamIconObject[teamScores["AST"].level] : null,
      traitColor: "#ffd17f",
      lowLabel: "Deferential",
      highLabel: "Forceful, Dominant",
    },
    EXT: {
      traitName: "Extroversion",
      traitIcon: extroversionIcon,
      teamIcon: teamScores ? teamIconObject[teamScores["EXT"].level] : null,
      traitColor: "#ffd17f",
      lowLabel: "Introverted",
      highLabel: "Extroverted",
    },
    COP: {
      traitName: "Cooperativeness",
      traitIcon: cooperativenessIcon,
      teamIcon: teamScores ? teamIconObject[teamScores["COP"].level] : null,
      traitColor: "#ffd17f",
      lowLabel: "Aggressive, Independent",
      highLabel: "Accommodating",
    },
    PAT: {
      traitName: "Patience",
      traitIcon: patienceIcon,
      teamIcon: teamScores ? teamIconObject[teamScores["PAT"].level] : null,
      traitColor: "#ffad9c",
      lowLabel: "Impatient",
      highLabel: "Patient",
    },
    SCN: {
      traitName: "Self-Confidence",
      traitIcon: selfConfidenceIcon,
      teamIcon: teamScores ? teamIconObject[teamScores["SCN"].level] : null,
      traitColor: "#96e5e9",
      lowLabel: "Modest",
      highLabel: "Self-Confident",
    },
    CON: {
      traitName: "Conscientiousness",
      traitIcon: conscientiousnessIcon,
      teamIcon: teamScores ? teamIconObject[teamScores["CON"].level] : null,
      traitColor: "#a0ade3",
      lowLabel: "Spontaneous, Laid-Back",
      highLabel: "Dependable, Self-Disciplined",
    },
    OPEN: {
      traitName: "Openness",
      traitIcon: opennessIcon,
      teamIcon: teamScores ? teamIconObject[teamScores["OPEN"].level] : null,
      traitColor: "#96e5e9",
      lowLabel: "Conventional, Traditional",
      highLabel: "Experimental, Creative",
    },
  };
  return traitObject[trait];
};
