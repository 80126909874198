/**
 *
 * LabelRow
 *
 */
import React from "react";
import { Col } from "react-bootstrap";

interface Props {
  lowLabel: string;
  highLabel: string;
  fromSummaryPage?: boolean;
}

export function LabelRow({ lowLabel, highLabel, fromSummaryPage }: Props) {
  return (
    <>
      <Col
        lg={6}
        md={6}
        sm={6}
        xs={6}
        className="text-left label-text mt-3"
        style={{ paddingLeft: fromSummaryPage ? "0px" : "" }}
      >
        {lowLabel}
      </Col>
      <Col lg={6} md={6} sm={6} xs={6} className="text-right label-text mt-3">
        {highLabel}
      </Col>
    </>
  );
}
