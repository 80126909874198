import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";

// The initial state of the InviteLink container
export const initialState: ContainerState = {
  loadingCompanyInfo: false,
  allowableDomains: [],
  companyName: "",
  sendingEmail: false,
  emailSent: false,
  errorMessage: "",
  expiredLink: false,
};

const inviteLinkSlice = createSlice({
  name: "inviteLink",
  initialState,
  reducers: {
    getCompanyInfo(state, action: PayloadAction<any>) {
      state.loadingCompanyInfo = true;
    },
    getCompanyInfoSuccess(state, action: PayloadAction<any>) {
      state.loadingCompanyInfo = false;
      state.allowableDomains = action.payload.allowedDomains;
      state.companyName = action.payload.companyName;
    },
    getCompanyInfoError(state, action: PayloadAction<any>) {
      state.loadingCompanyInfo = false;
      if (action.payload === 400) {
        state.expiredLink = true;
      }
      // state.errorMessage = action.payload;
    },
    sendEmailAddress(state, action: PayloadAction<any>) {
      state.sendingEmail = true;
    },
    sendEmailAddressSuccess(state, action: PayloadAction<any>) {
      state.sendingEmail = false;
      state.emailSent = true;
    },
    sendEmailAddressError(state, action: PayloadAction<any>) {
      state.sendingEmail = false;
      state.errorMessage = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = inviteLinkSlice;
