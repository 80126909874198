import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => state?.importCSV || initialState;

export const selectImportCSV = createSelector(
  [selectDomain],
  (importCSVState) => importCSVState
);

export const selectLoadingPreview = createSelector(
  [selectDomain],
  (importCSVState) => importCSVState.loadingPreview
);

export const selectPreviewCSVResponse = createSelector(
  [selectDomain],
  (importCSVState) => importCSVState.previewCSVResponse
);

export const selectProcessingCSV = createSelector(
  [selectDomain],
  (importCSVState) => importCSVState.processingCSV
);

export const selectProcessCSVResponse = createSelector(
  [selectDomain],
  (importCSVState) => importCSVState.processCSVResponse
);

export const selectSafeToRedirect = createSelector(
  [selectDomain],
  (importCSVState) => importCSVState.safeToRedirect
);
