import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { TraitsList } from "./TraitsList";
import {
  MemberScores,
  TeamScores,
  MemberPersonalityReport,
  TeamMembersData,
} from "../types";
import { useEventListener } from "utils/custom_hooks/useEventListener";
import { TeamPersonalityHeader } from "./TeamPersonalityHeader";
import EmptyTraits from "./EmptyTraits";

interface Props {
  memberScores: MemberScores[] | null;
  teamScores: TeamScores | null;
  loadingPersonalityScores: boolean;
  memberPersonalityReports: MemberPersonalityReport[] | null;
  teamId: string | null;
  teamMembers: TeamMembersData[] | undefined;
  team: any;
}

export function TeamPersonalityCard({
  memberScores,
  teamScores,
  loadingPersonalityScores,
  memberPersonalityReports,
  teamId,
  teamMembers,
  team,
}: Props) {
  /* State Variables */
  const [showTooltip, setShowTooltip] = useState(false);

  /****************************************************************************
   * Adding Event Listeners for Explore button to show on hover               *
   ****************************************************************************/
  const el = document.getElementById("explore-button");

  /* Handlers for the two event listeners we are adding */
  const mouseEnterHandler = () => {
    if (!(memberScores && memberScores.length > 2)) {
      setShowTooltip(true);
    }
  };
  const mouseOutHandler = () => {
    setShowTooltip(false);
  };

  /* adds and removes event listeners on mount and unmount */
  useEventListener("mouseenter", mouseEnterHandler, el);
  useEventListener("mouseleave", mouseOutHandler, el);

  // variables to indicate team member counts
  const totalTeamMembers = team?.teamMembers ? team.teamMembers.length : 0;
  const numberOfDisplayedMembers = memberScores ? memberScores.length : 0;

  return (
    <Container fluid="lg">
      <Row>
        <Col>
          <Card>
            <Card.Body className="personality-card-body">
              <TeamPersonalityHeader
                showTooltip={showTooltip}
                memberScores={memberScores}
                team={team}
                teamId={teamId}
                totalTeamMembers={totalTeamMembers}
                numberOfDisplayedMembers={numberOfDisplayedMembers}
              />
              {memberScores ? (
                <TraitsList
                  memberScores={memberScores}
                  teamScores={teamScores}
                  memberPersonalityReports={memberPersonalityReports}
                  teamMembers={teamMembers}
                  team={team}
                />
              ) : (
                <EmptyTraits />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
