import { Avatar } from "app/components/Avatar";
import React from "react";
import { Row, Col, ButtonGroup, Dropdown } from "react-bootstrap";
import { TeamMembers } from "./types";
import overflowImg from "resources/images/overflow.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectTeamMembers } from "./selectors";
import { selectIsAdmin } from "app/containers/Global/selectors";

interface Props {
  member: TeamMembers | undefined;
  isLastMember: boolean;
  showRemoveMemberConfirmation: (teamMemberId: number) => void;
  isTeamLead: boolean;
  showResendInviteConfirmation: (teamMemberId: number) => void;
  teamName: string | undefined;
  teamId: string;
  automatic: boolean;
}

export function MemberRow({
  member,
  isLastMember,
  showRemoveMemberConfirmation,
  isTeamLead,
  showResendInviteConfirmation,
  teamName,
  teamId,
  automatic,
}: Props) {
  const history = useHistory();

  /****************************************************************************
   * Selectors                                                                *
   ****************************************************************************/
  // const viewAllWorkplaceInsights = useSelector(selectViewAllWorkplaceInsights);
  const teamMembers = useSelector(selectTeamMembers);
  const loggedInUserIsAdmin = useSelector(selectIsAdmin);

  /****************************************************************************
   * Handler                                                                  *
   ****************************************************************************/

  const handleOverflowSelection = (event: any) => {
    switch (event) {
      case "view-insights":
        history.push(`/profile/${member?.userAccountId}`);
        break;
      case "remove-member":
        if (member?.teamMemberId) {
          showRemoveMemberConfirmation(member.teamMemberId);
        }
        break;
      case "resend-invite":
        if (member?.teamMemberId) {
          showResendInviteConfirmation(member.teamMemberId);
        }
        break;
      default:
        return true;
    }
  };

  const handleCandidateClick = (userAccountId: number | undefined) => {
    const selectedMember = teamMembers?.filter(
      (member) => member.userAccountId === userAccountId
    )[0];

    const location = {
      pathname: `/profile/${selectedMember?.userAccountId}`,
      state: {
        rootName: teamName,
        rootPath: `/team/${teamId}`,
        previousName: "Team List",
        previousPath: `/team_list/${teamId}`,
      },
    };
    history.push(location);
  };

  return (
    <>
      <Row>
        <Col lg={1} md={1} sm={2} xs={2}>
          <span
            className="cursor-pointer"
            onClick={(event: any) =>
              handleCandidateClick(member?.userAccountId)
            }
          >
            <Avatar
              height={48}
              width={48}
              fontSize={20}
              userName={`${member?.firstName} ${member?.lastName}`}
              emailAddress={member?.emailAddress}
              image={member?.profilePicture}
              initials={
                member?.firstName
                  ? `${member?.firstName.charAt(0)}${member?.lastName.charAt(
                      0
                    )}`
                  : `${member?.emailAddress.slice(0, 2)}`
              }
              isLoading={false}
              backgroundColor={
                member?.dateAccepted === null
                  ? `#b1b3b3`
                  : `#${member?.avatarColor}`
              }
              showInGrayscale={member?.dateAccepted === null}
            />
          </span>
        </Col>
        <Col lg={6} md={6} sm={5} xs={10}>
          <p
            onClick={(event: any) =>
              handleCandidateClick(member?.userAccountId)
            }
            className="mb-0 team-list-member-name"
            style={{ color: member?.dateAccepted ? "#000000" : "#b1b3b3" }}
          >
            {member?.firstName} {member?.lastName}{" "}
            {member?.isTeamLead ? "(Team Lead)" : null}
          </p>
          <p
            onClick={(event: any) =>
              handleCandidateClick(member?.userAccountId)
            }
            className={
              member?.firstName
                ? `mt-0 team-list-member-email`
                : `mt-2 team-list-member-name`
            }
            style={{ color: member?.dateAccepted ? "#000000" : "#b1b3b3" }}
          >
            {member?.emailAddress}
          </p>
        </Col>
        <Col lg={5} md={5} sm={5} xs={12} className="text-right">
          {member?.dateAccepted === null ? (
            <span className="mr-5 pending-text">Invitation Pending</span>
          ) : null}
          <ButtonGroup>
            {member &&
            member.pendingTests?.length > 0 &&
            member.dateAccepted !== null ? (
              <p className="mt-3 mr-5 pending-text text-center">
                1 Assessment Pending
              </p>
            ) : null}
            {(!member?.isTeamLead ||
              member?.dateAccepted === null ||
              loggedInUserIsAdmin) &&
            !automatic ? (
              <Dropdown onSelect={handleOverflowSelection}>
                <div className="no-caret">
                  <Dropdown.Toggle size="sm" variant="outline-secondary">
                    <img
                      src={overflowImg}
                      alt="menu"
                      className="mt-2 overflow-button"
                      width="24"
                      height="24"
                    />
                    <Dropdown.Menu>
                      {member &&
                      member.pendingTests?.length === 0 &&
                      member.dateAccepted !== null ? (
                        <Dropdown.Item
                          eventKey="view-insights"
                          style={{ color: "#425cc7" }}
                        >
                          View Insights
                        </Dropdown.Item>
                      ) : null}
                      {!member?.isTeamLead &&
                      (isTeamLead || loggedInUserIsAdmin) ? (
                        <Dropdown.Item eventKey="remove-member">
                          Remove Team Member
                        </Dropdown.Item>
                      ) : null}
                      {member?.dateAccepted === null &&
                      (isTeamLead || loggedInUserIsAdmin) ? (
                        <Dropdown.Item eventKey="resend-invite">
                          Resend Invitation
                        </Dropdown.Item>
                      ) : null}
                    </Dropdown.Menu>
                  </Dropdown.Toggle>
                </div>
              </Dropdown>
            ) : null}
          </ButtonGroup>
        </Col>
      </Row>
      <hr className="mt-1 mb-3" />
    </>
  );
}
