import React from "react";
import { Avatar } from "app/components/Avatar";
import anonymousUserIcon from "resources/images/user-anonymous.svg";
import averageTeamScoreIcon from "resources/images/average-team-score-icon.svg";

export const getAvatars = (
  memberScores: any,
  width: number | null,
  trait: string,
  forTraitSpectrum: boolean,
  handleAvatarClick: any,
  forPersonalitySummary: boolean = false,
  // viewAllWorkplaceInsights: number | undefined,
  // isTeamLead: boolean,
  loggedInUserIsAdmin: boolean,
  showAverageScore?: boolean,
  averageTeamScore?: number | null,
  fromTeamSummary?: boolean,
  loggedInUserAccountId?: number | null
) => {
  // we need to determine the current logged in user's userAccountId (from the token)
  // so that we can use it while mapping over the members to determine whether to show
  // that members initial or image (logged in user's avatars always appear normal).

  const avatarArray = memberScores?.map(
    (member): JSX.Element => {
      const isLoggedInMember = member.userAccountId === loggedInUserAccountId;
      const baselineTraitScore =
        member && trait && !forPersonalitySummary
          ? member[trait]
          : member[trait]?.score;
      const collidingAvatars: any = [];
      memberScores?.map((member) => {
        const memberScore = !forPersonalitySummary
          ? member[trait]
          : member[trait]?.score;
        if (
          width &&
          (width / 100) * memberScore >
            (width / 100) * baselineTraitScore - 32 &&
          (width / 100) * memberScore < (width / 100) * baselineTraitScore + 32
        ) {
          collidingAvatars.push(member.teamMemberId);
        }
        if (
          width &&
          averageTeamScore &&
          (width / 100) * averageTeamScore >
            (width / 100) * baselineTraitScore - 32 &&
          (width / 100) * averageTeamScore <
            (width / 100) * baselineTraitScore + 32
        ) {
          collidingAvatars.push(member.teamMemberId);
        }
      });
      const leftPlacement =
        width && forTraitSpectrum
          ? (width / 100) * member[trait] + 32
          : width
          ? (width / 100) * member[trait].score + 8
          : null;

      return (
        <span
          data-id={member.teamMemberId}
          data-trait={trait}
          onClick={
            isLoggedInMember || loggedInUserIsAdmin || member.firstName
              ? () => handleAvatarClick(member.teamMemberId)
              : undefined
          }
          key={member.teamMemberId}
          className="cursor-pointer"
        >
          {isLoggedInMember || loggedInUserIsAdmin || member.firstName ? (
            <Avatar
              height={32}
              width={32}
              image={member.profilePicture}
              userName={
                member.firstName ? `${member.firstName} ${member.lastName}` : ""
              }
              initials={
                member.firstName
                  ? `${member.firstName.charAt(0)} ${member.lastName.charAt(0)}`
                  : member.emailAddress.slice(0, 2)
              }
              isTeamLead={0}
              fontSize={12}
              shouldOverlap={true}
              key={member.teamMemberId}
              backgroundColor={`#${member.avatarColor}`}
              forTraitSpectrum
              leftPlacement={leftPlacement}
              dataFor={`avatar-${trait}`}
              dataTip={`${trait}-${member.teamMemberId}`}
              doNotShowTooltip={
                collidingAvatars.length > 1 &&
                collidingAvatars.includes(member.teamMemberId) &&
                showAverageScore
              }
              doNotShowBorder={false}
            />
          ) : (
            <Avatar
              height={32}
              width={32}
              image={anonymousUserIcon}
              userName={""}
              initials={""}
              isTeamLead={0}
              fontSize={12}
              shouldOverlap={true}
              key={member.teamMemberId}
              backgroundColor={`#${member.avatarColor}`}
              forTraitSpectrum
              leftPlacement={leftPlacement}
              dataFor={`avatar-${trait}`}
              dataTip={`${trait}-${member.teamMemberId}`}
              doNotShowTooltip={true}
              doNotShowBorder={true}
            />
          )}
        </span>
      );
    }
  );
  if (showAverageScore && fromTeamSummary) {
    let showTeamAverageTooltip = false;
    memberScores.map((member) => {
      const baselineTraitScore =
        member && trait && !forPersonalitySummary
          ? member[trait]
          : member[trait]?.score;
      if (
        width &&
        averageTeamScore &&
        (width / 100) * averageTeamScore >
          (width / 100) * baselineTraitScore - 32 &&
        (width / 100) * averageTeamScore <
          (width / 100) * baselineTraitScore + 32
      ) {
        showTeamAverageTooltip = true;
      }
      return null;
    });
    const leftPlacement =
      width && forTraitSpectrum && averageTeamScore
        ? (width / 100) * averageTeamScore + 32
        : width && averageTeamScore
        ? (width / 100) * averageTeamScore + 8
        : null;
    const teamAverageAvatar = (
      <span
        data-id={0}
        data-trait={trait}
        onClick={undefined}
        key={0}
        className="cursor-pointer"
      >
        <Avatar
          height={32}
          width={32}
          image={averageTeamScoreIcon}
          userName={""}
          initials={""}
          isTeamLead={0}
          fontSize={12}
          shouldOverlap={true}
          key={`teamAverage`}
          backgroundColor={`#425cc7`}
          forTraitSpectrum
          leftPlacement={leftPlacement}
          dataFor={`avatar-${trait}`}
          dataTip={`${trait}-teamAverage`}
          doNotShowBorder={true}
          doNotShowTooltip={showTeamAverageTooltip}
          isTeamAverage={true}
        />
      </span>
    );
    avatarArray.push(teamAverageAvatar);
  }
  return avatarArray;
};
