import React from "react";
import everyoneSettingIcon from "resources/images/everyone-setting-icon.svg";
import onlyMeSettingIcon from "resources/images/only-me-settings-icon.svg";
import teamLeadSettingIcon from "resources/images/team-leads-icon.svg";

export const getSettingsIcon = (
  value: number | undefined,
  permissionToUpdate: string | undefined = ""
) => {
  if (value) {
    switch (value) {
      case 1:
        return (
          <>
            <span className="setting-icon-text mr-2 d-flex">
              {" "}
              <img
                src={onlyMeSettingIcon}
                alt="only me icon"
                height="16px"
                className="mr-2"
              />
              {permissionToUpdate === "showPersonalitySummary"
                ? "Not Visible"
                : "Only me"}
            </span>
          </>
        );
      case 2:
        return (
          <>
            <span className="setting-icon-text mr-2 d-flex">
              {" "}
              <img
                src={teamLeadSettingIcon}
                alt="team lead icon"
                height="16px"
                className="mr-2"
              />
              Team Lead
            </span>
          </>
        );
      case 3:
        return (
          <>
            <span className="setting-icon-text mr-2 d-flex">
              {" "}
              <img
                src={everyoneSettingIcon}
                alt="everyone icon"
                height="16px"
                className="mr-2"
              />
              Everyone on my teams
            </span>
          </>
        );
    }
  }
};
