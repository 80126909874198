import { all, call, put, takeLatest } from "redux-saga/effects";
import { request } from "utils/request";
import { actions } from "./slice";
import {
  MemberScores,
  GetTeamPersonalityReportResponse,
  TeamData,
} from "./types";

export function* getTeams(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/teams/${action.payload}`;
    const getTeamsResponse: TeamData = yield call(request, requestUrl);
    yield put(actions.getTeamsSuccess(getTeamsResponse));
  } catch (error) {
    yield put(actions.getTeamsError(error.response));
  }
}

export function* getPersonalityScores(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/teams/${action.payload}/personalityScores`;
    const getPersonalityScoresResponse: MemberScores[] = yield call(
      request,
      requestUrl
    );
    yield put(
      actions.getPersonalityScoresSuccess(getPersonalityScoresResponse)
    );
  } catch (error) {
    yield put(
      actions.getPersonalityScoresError(
        "There was an error in retrieving the members scores."
      )
    );
  }
}

export function* getPersonalityReports(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/teams/${action.payload}/personalityReport`;
    const getPersonalityReportResponse: GetTeamPersonalityReportResponse = yield call(
      request,
      requestUrl
    );
    yield put(
      actions.getPersonalityReportsSuccess(getPersonalityReportResponse)
    );
  } catch (error) {
    yield put(
      actions.getPersonalityReportsError(
        "There was an error in retrieving the members scores."
      )
    );
  }
}

export function* getAllowedDomains(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/company/${action.payload}`;
    const getAllowedDomainsResponse = yield call(request, requestUrl);
    yield put(
      actions.getAllowedDomainsSuccess(getAllowedDomainsResponse.allowedDomains)
    );
  } catch (error) {
    yield put(actions.getAllowedDomainsError(error.response));
  }
}

export function* inviteMembers(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/teams/${action.payload.teamId}/teamMembers`;
    yield call(request, requestUrl, {
      method: "POST",
      body: JSON.stringify(action.payload.formData),
    });
    yield put(actions.inviteMembersSuccess());
  } catch (error) {
    yield put(actions.inviteMembersError(error.response));
  }
}

export function* removeMember(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/teamMembers/${action.payload}`;
    yield call(request, requestUrl, {
      method: "DELETE",
    });

    yield put(actions.removeMemberSuccess());
    window.location.href = `${process.env.REACT_APP_URL}`;
  } catch (error) {
    yield put(actions.removeMemberError(error.response));
  }
}

export function* getUsersList(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/list`;
    const getUsersListResponse = yield call(request, requestUrl);
    yield put(actions.getUsersListSuccess(getUsersListResponse));
  } catch (error) {
    yield put(
      actions.getUsersListError("There was an error in retrieving your users.")
    );
  }
}

export function* teamPageSaga() {
  yield all([
    takeLatest(actions.getTeams.type, getTeams),
    takeLatest(actions.getPersonalityScores.type, getPersonalityScores),
    takeLatest(actions.getPersonalityReports.type, getPersonalityReports),
    takeLatest(actions.getAllowedDomains.type, getAllowedDomains),
    takeLatest(actions.inviteMembers.type, inviteMembers),
    takeLatest(actions.removeMember.type, removeMember),
    takeLatest(actions.getUsersList.type, getUsersList),
  ]);
}
