import React from "react";
import { Nav } from "react-bootstrap";
import Scrollspy from "react-scrollspy";
import { ManagerGuideNavTitles, getNavItems } from "../helpers";

export function NavigationBar() {
  const navItemsArray: string[] = ManagerGuideNavTitles.map(
    navItem => navItem.key
  );

  const spyScrollItems = navItemsArray.map(item =>
    item.replace(/ /g, "").replace(",", "").replace("&", "")
  );

  const navItems = getNavItems(ManagerGuideNavTitles);

  return (
    <Nav
      id="team-personality-nav"
      className="flex-column section-nav mr-1 mb-3"
      defaultActiveKey="Communication"
    >
      <Scrollspy
        items={spyScrollItems}
        currentClassName="is-current"
        style={{ paddingLeft: 0 }}
      >
        {navItems}
      </Scrollspy>
    </Nav>
  );
}
