/**
 *
 * LinkCandidate
 *
 */

import React, { useState, useEffect } from "react";
import useEffectOnMount from "utils/custom_hooks/useEffectOnMount";
import { actions } from "./slice";
import {
  selectMatches,
  selectLoadingMatches,
  selectMatchesLoaded,
  selectCurrentlyLinkedCandidate,
  selectCurrentlyLinkedLoaded,
} from "./selectors";
import { useSelector, useDispatch } from "react-redux";
import { Form, Row, InputGroup, Container, Card } from "react-bootstrap";
import searchIcon from "../../../resources/images/light-search.svg";
import "./styles.css";
import { CandidateCard } from "./CandidateCard";
import { LinkedCandidate, LinkData } from "./types";

export function LinkCandidate({ setLinkData, currentEmployeeId }) {
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [numDisplayed, setNumDisplayed] = useState<number>(10);
  const [linkedCandidate, setLinkedCandidate] = useState<any>(null);
  const [matchedCandidates, setMatchedCandidates] = useState<
    LinkedCandidate[] | null
  >(null);
  const [searchStarted, setSearchStarted] = useState<boolean>(false);
  const [candidateUnlinked, setCandidateUnlinked] = useState<boolean>(false);
  const [candidateLinked, setCandidateLinked] = useState<boolean>(false);
  const [userFinishedEpp, setUserFinishedEpp] = useState<boolean>(false);

  const dispatch = useDispatch();

  const matches = useSelector(selectMatches);
  const loadingMatches = useSelector(selectLoadingMatches);
  const matchesLoaded = useSelector(selectMatchesLoaded);
  const currentlyLinkedCandidate = useSelector(selectCurrentlyLinkedCandidate);
  const currentlyLinkedLoaded = useSelector(selectCurrentlyLinkedLoaded);

  let linkData: LinkData = {
    unlinkTestTaker: 0,
    linkTestTaker: 0,
    importEpp: 0,
    testTakerId: 0,
    removeCurrentEpp: 0,
  };
  let candidateCards;

  /****************************************************************************
   * Hooks                                                                    *
   ****************************************************************************/

  useEffectOnMount(() => {
    setLinkedCandidate(null);
    setMatchedCandidates(null);
    dispatch(actions.getLinkedCandidate(currentEmployeeId));
  });

  // logic checking for a currentlyLinkedCandidate being set when the page loads
  useEffect(() => {
    if (
      currentlyLinkedLoaded &&
      currentlyLinkedCandidate &&
      !candidateUnlinked &&
      !candidateLinked
    ) {
      setLinkedCandidate(currentlyLinkedCandidate?.linkedTestTaker ?? null);
      setUserFinishedEpp(
        currentlyLinkedCandidate.userFinishedEpp &&
          currentlyLinkedCandidate.linkedTestTaker?.eppCopied !== 1
      );
    }
  }, [
    currentlyLinkedCandidate,
    candidateUnlinked,
    currentlyLinkedLoaded,
    candidateLinked,
  ]);

  // add currentlyLinkedCandidate to search to that the currentlyLinkedCandidate can be searched and linked
  useEffect(() => {
    if (matchesLoaded && searchStarted) {
      let allCandidates = matches;
      if (currentlyLinkedCandidate?.linkedTestTaker) {
        allCandidates = [currentlyLinkedCandidate.linkedTestTaker, ...matches];
      }
      setMatchedCandidates(allCandidates);
    }
  }, [currentlyLinkedCandidate, matches, matchesLoaded, searchStarted]);

  /****************************************************************************
   * Handlers                                                                    *
   ****************************************************************************/

  const handleUnlinkCandidate = () => {
    linkData.unlinkTestTaker = 1;
    linkData.linkTestTaker = 0;
    linkData.importEpp = 0;
    linkData.removeCurrentEpp = currentlyLinkedCandidate?.linkedTestTaker
      ?.eppAvailable
      ? 1
      : 0;
    linkData.testTakerId = 0;
    setLinkData(linkData);
    setCandidateUnlinked(true);
    setCandidateLinked(false);
    setLinkedCandidate(null);
  };

  const handleLinkCandidate = (e) => {
    if (matchedCandidates) {
      linkData.linkTestTaker = 1;
      linkData.testTakerId = matchedCandidates[e.target.value].testTakerId;
      linkData.unlinkTestTaker = 0;
      linkData.removeCurrentEpp = currentlyLinkedCandidate?.linkedTestTaker
        ?.eppAvailable
        ? 1
        : 0;
      if (linkedCandidate) {
        linkData.importEpp = matchedCandidates[e.target.value].eppAvailable
          ? 1
          : 0;
      } else {
        linkData.importEpp = matchedCandidates[e.target.value].eppAvailable
          ? 1
          : 0;
      }
      setLinkedCandidate(matchedCandidates[e.target.value]);
      setLinkData(linkData);
      setCandidateLinked(true);
    }
  };

  const allCandidateCards = () => {
    if (matchedCandidates && matchedCandidates.length > 0) {
      candidateCards = matchedCandidates
        .slice(0, numDisplayed)
        .map((candidate, i) => {
          return (
            <CandidateCard
              candidateInfo={candidate}
              formattedDate={formattedDate(candidate.date)}
              handleUnlinkCandidate={handleUnlinkCandidate}
              handleLinkCandidate={handleLinkCandidate}
              linked={false}
              index={i}
            />
          );
        });
    }

    return <>{candidateCards}</>;
  };

  const handleSearch = () => {
    candidateCards = [];
    setNumDisplayed(10);
    if (searchValue) {
      const searchString = {
        searchText: searchValue,
      };
      dispatch(actions.getMatches(searchString));
    }
    setSearchStarted(true);
    setMatchedCandidates(matches);
  };

  const displayMore = () => {
    let newDisplayNum = numDisplayed;
    setNumDisplayed((newDisplayNum += 10));
  };

  const getLoadingNum = () => {
    if (matchedCandidates && matchedCandidates.length - numDisplayed >= 10) {
      return 10;
    } else if (
      matchedCandidates &&
      matchedCandidates.length - numDisplayed < 10
    ) {
      return matchedCandidates.length - numDisplayed;
    }
  };

  const formattedDate = (date) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const showLinkedCandidateCard =
    linkedCandidate &&
    linkedCandidate?.testTakerFirstName &&
    !userFinishedEpp &&
    currentlyLinkedLoaded;

  const showSearchContainer =
    !linkedCandidate &&
    !loadingMatches &&
    searchStarted &&
    matchedCandidates &&
    !userFinishedEpp;

  return (
    <>
      {!userFinishedEpp && (
        <Row style={{ margin: "0px" }}>
          <Form.Label className="form-label-text">Link Candidate</Form.Label>
          {!linkedCandidate && !userFinishedEpp && (
            <InputGroup style={{ width: "96%" }}>
              <InputGroup.Prepend>
                <InputGroup.Text style={{ backgroundColor: "white" }}>
                  <span>
                    <img
                      src={searchIcon}
                      alt="search"
                      style={{ height: "16px" }}
                    />
                  </span>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder={searchValue ?? "Search by candidate name or email"}
                style={{
                  height: "34px",
                  borderLeft: "none",
                  paddingLeft: "0px",
                  borderRadius: "0 4px 4px 0",
                }}
              />
              <button className="search-button" onClick={() => handleSearch()}>
                Search
              </button>
            </InputGroup>
          )}
        </Row>
      )}
      <Row style={{ margin: "0px" }}>
        {showLinkedCandidateCard && (
          <CandidateCard
            candidateInfo={linkedCandidate}
            formattedDate={formattedDate(linkedCandidate.date)}
            handleUnlinkCandidate={handleUnlinkCandidate}
            handleLinkCandidate={handleLinkCandidate}
            linked={true}
            index={null}
          />
        )}
        {showSearchContainer ? (
          <Container fluid="lg" className="candidates-container">
            <p style={{ color: "#53565a" }}>
              {matchedCandidates?.length === 0
                ? `No matching candidates`
                : matchedCandidates?.length === 1
                ? `1 candidate profiles found`
                : `${matchedCandidates?.length} candidate profiles found`}
            </p>
            {allCandidateCards()}
            {matchedCandidates && numDisplayed < matchedCandidates.length && (
              <Row style={{ justifyContent: "center", marginTop: "10px" }}>
                <button onClick={displayMore} className="load-more-button">
                  {`Load ${getLoadingNum()} more`}
                </button>
              </Row>
            )}
          </Container>
        ) : (
          <p className="sub-text">
            Any personality results from the linked candidate profile will be
            displayed on the employee profile.
          </p>
        )}
        {userFinishedEpp && linkedCandidate && (
          <div style={{ flexDirection: "column", width: "100%" }}>
            <p style={{ fontWeight: "bold", marginBottom: ".5rem" }}>
              Completed Tests
            </p>
            <Card className="employee-test-card">
              <p
                className="candidate-card-title"
                style={{ margin: "13px 0 0 0" }}
              >
                Personality Test
              </p>
              <p style={{ margin: "13px 10px 0 0", color: "#53565a" }}>
                {`Completed ${formattedDate(linkedCandidate.dateEventTaken)}`}
              </p>
            </Card>
          </div>
        )}
      </Row>
    </>
  );
}
