import { call, put, all, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { CompanyInfo, CreateTeamResponse } from "./types";
import { request } from "utils/request";

export function* getAllowedDomains(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/company/${action.payload}`;
    const getCompanyInfoResponse: CompanyInfo = yield call(request, requestUrl);
    yield put(
      actions.getAllowedDomainsSuccess(getCompanyInfoResponse.allowedDomains)
    );
  } catch (error) {
    yield put(actions.getAllowedDomainsError(error.response));
  }
}

export function* getUsersList(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/list`;
    const getUsersListResponse = yield call(request, requestUrl);
    yield put(actions.getUsersListSuccess(getUsersListResponse));
  } catch (error) {
    yield put(
      actions.getUsersListError("There was an error in retrieving your users.")
    );
  }
}

export function* createNewTeam(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/teams`;
    const createNewTeamsResponse: CreateTeamResponse = yield call(
      request,
      requestUrl,
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );
    yield put(actions.createNewTeamSuccess(createNewTeamsResponse));
  } catch (error) {
    yield put(actions.createNewTeamError(error.response));
  }
}

export function* createTeamSaga() {
  yield all([
    takeLatest(actions.getAllowedDomains.type, getAllowedDomains),
    takeLatest(actions.getUsersList.type, getUsersList),
    takeLatest(actions.createNewTeam.type, createNewTeam),
  ]);
}
