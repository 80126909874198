import React from "react";
import { Nav } from "react-bootstrap";
import Scrollspy from "react-scrollspy";
import {
  getNavItems,
  getTraitsData,
  getSortedTraitData,
} from "./helper-functions";

interface Props {
  notableTraits: string[];
}

export function NavigationBar({ notableTraits }: Props) {
  const navItems = getNavItems(notableTraits);

  /* get the trait data so that we sort the traits by notable trait */
  const traitData = getTraitsData();

  /*
   * sort the trait data by notable traits being first, then create the array used in the
   * ScrollSpy component to track the trait boxes
   */
  const sortedTraitsData = getSortedTraitData(traitData, notableTraits);
  const spyScrollItems: string[] = sortedTraitsData.map((trait) => trait.name);

  return (
    <Nav
      id="team-personality-nav"
      className="flex-column section-nav mr-1 mb-3"
      defaultActiveKey="Self-Confidence"
    >
      <Scrollspy
        items={spyScrollItems}
        currentClassName="is-current"
        offset={300}
        style={{ paddingLeft: 0 }}
      >
        {navItems}
      </Scrollspy>
    </Nav>
  );
}
