import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import { Avatar } from "app/components/Avatar";
import { User } from "../types";

interface Props {
  user: User | null;
  handleViewWorkInsights: (newValue: number) => void;
}
export const ManagerGuideHeader = ({ user, handleViewWorkInsights }: Props) => {
  return (
    <Col className="collaboration-guide-header-container">
      <BrowserView>
        <Row>
          <Col lg={10} md={9} sm={8} xs={8}>
            <p className="collaboration-guide-header">Manager Guide</p>
            <p className="collaboration-guide-header-text mb-0">
              This guide provides tips for managing
              <strong>{` ${user?.firstName} ${user?.lastName} `}</strong>
              based on their personality results.
            </p>
            <p>
              {`For more in-depth information about ${user?.firstName}'s personality, view
                their `}
              <Button
                variant="outline-none"
                className="manager-guide-link-text"
                onClick={() => handleViewWorkInsights(2)}
              >
                <span style={{ textDecoration: "underline" }}>
                  Work Insights report
                </span>
                .
              </Button>
            </p>
          </Col>
          <Col lg={2} md={2} sm={3} xs={3} className="m-auto">
            <Row className="manager-guide-header-avatar-desktop">
              <Avatar
                height={60}
                width={60}
                fontSize={24}
                userName={user?.firstName}
                image={user?.profilePicture}
                initials={
                  user?.firstName
                    ? `${user?.firstName.charAt(0)}${user?.lastName.charAt(0)}`
                    : `${user?.emailAddress.slice(0, 2)}`
                }
                backgroundColor={`#${user?.avatarColor}`}
                shouldOverlap={false}
              />
            </Row>
          </Col>
        </Row>
      </BrowserView>
      <MobileView>
        <Row>
          <Col xs={9}>
            <p className="collaboration-guide-header" style={{ lineHeight: 2 }}>
              Manager Guide
            </p>
          </Col>
          <Col xs={3}>
            <Row>
              <Avatar
                height={42}
                width={42}
                fontSize={18}
                userName={user?.firstName}
                image={user?.profilePicture}
                initials={
                  user?.firstName
                    ? `${user?.firstName.charAt(0)}${user?.lastName.charAt(0)}`
                    : `${user?.emailAddress.slice(0, 2)}`
                }
                backgroundColor={`#${user?.avatarColor}`}
                shouldOverlap={false}
              />
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p className="collaboration-guide-header-text mb-0">
              This guide provides tips for managing
              <strong>{` ${user?.firstName} ${user?.lastName} `}</strong>
              based on their personality results.
            </p>
            <p>
              {`For more in-depth information about ${user?.firstName}'s personality, view
                their `}
              <Button
                variant="outline-none"
                className="manager-guide-link-text"
                onClick={() => handleViewWorkInsights(2)}
              >
                <span>Work Insights report</span>.
              </Button>
            </p>
          </Col>
        </Row>
      </MobileView>
    </Col>
  );
};
