import Cookies from "js-cookie";
import { isValidCritToken } from "./helper_functions/isValidCritToken";
import { getCookieConfigObject } from "./helper_functions/getConfigObj";

export class ResponseError extends Error {
  public response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response: Response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  if (response.status === 401) {
    // if the token was not valid, we need to remove the login jwt token, set a
    // cookie to be retrieved by the login center in order to display the appropriate
    // error message, and then redirect the user to the login center.
    localStorage.removeItem("tmg-tkn");
    Cookies.set("tmg-uat", 1, getCookieConfigObject());
    window.location.href = `${process.env.REACT_APP_LOGOUT_URL}`;
  }
  const error = new ResponseError(response);
  error.response = response;
  throw error;
}

/**
 * Attaches JWT Token to all requests
 *
 * @param  {object} [options] The original options we receive from the redux-saga
 *
 * @return {object}           the new options containing the Authorization header
 */
function authenticateRequest(options) {
  const token =
    localStorage.getItem("tmg-tkn") ||
    isValidCritToken(Cookies.get("critToken"));
  const newOptions = { ...options };
  newOptions.headers = {
    Authorization: token,
  };
  return newOptions;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export async function request(
  url: string,
  options?: RequestInit
): Promise<{} | { err: ResponseError }> {
  const authenticatedOptions = authenticateRequest(options);
  const fetchResponse = await fetch(url, authenticatedOptions);
  const response = checkStatus(fetchResponse);
  return parseJSON(response);
}
