export const isTeamLead = (teamMembers, currentUserId: number | null) => {
  let isTeamLead = false;
  /* determine if the current user is the Team Lead (to determine if they have access to Team Settings) */
  if (currentUserId && teamMembers) {
    teamMembers.map((member) => {
      if (member.userAccountId === currentUserId) {
        if (member.isTeamLead) isTeamLead = true;
      }
      return false;
    });
  }
  return isTeamLead;
};

export const isTeamMember = (teamMembers, user: User | null) => {
  let isTeamMember = false;
  if (teamMembers && user) {
    const currentUserId = user.userAccountId;
    teamMembers.map((member) => {
      if (member.userAccountId === currentUserId) {
        isTeamMember = true;
      }
      return false;
    });
  }
  return isTeamMember;
};
