import React from "react";
import { Nav } from "react-bootstrap";
import { ProfileTypes, ProfileCardObjects } from "./types";
import communicationIcon from "resources/images/icon-communicationstyle.svg";
import workStyleIcon from "resources/images/icon-workstyle.svg";
import thinkingStyleIcon from "resources/images/icon-decisionmakingstyle.svg";
import communicateIcon from "resources/images/manager_guide_icons/manager-guide-icon-communicate.svg";
import supportIcon from "resources/images/manager_guide_icons/manager-guide-icon-support.svg";
import motivateIcon from "resources/images/manager_guide_icons/manager-guide-icon-motivate.svg";

export const getDescriptions = (profile: ProfileTypes[]) => {
  const descriptionsArray = profile.map(profileObj => {
    return profileObj.description;
  });
  return descriptionsArray;
};

export const getTips = (profile: ProfileTypes[] | undefined) => {
  if (profile) {
    const tipsArray = profile.map(profileObj => {
      return profileObj.tip;
    });
    return tipsArray;
  }
  return [];
};

export const showTips = (tips: any[]) => {
  const validTips = tips.filter(tip => tip !== undefined);
  return validTips.length > 0;
};

export const profileCardTitles: ProfileCardObjects[] = [
  {
    title: "Communication Style",
    key: "Communication",
    icon: communicationIcon
  },
  {
    title: "Work Style",
    key: "WorkStyle",
    icon: workStyleIcon
  },
  {
    title: "Thinking Style",
    key: "Decision-Making",
    icon: thinkingStyleIcon
  }
];

export const ManagerGuideNavTitles = [
  {
    title: "How to Communicate",
    key: "Communication",
    icon: communicateIcon
  },
  {
    title: "How to Support",
    key: "Support",
    icon: supportIcon
  },
  {
    title: "How to Motivate",
    key: "Motivation",
    icon: motivateIcon
  }
];

export const getNavItems = navItems => {
  const navItemsArray = navItems.map(item => {
    return (
      <Nav.Link
        key={item.key}
        eventKey={item.key}
        href={`#${item.key}`}
        className="trait-nav"
      >
        <img src={item.icon} alt={item.title} className="mr-3 nav-image" />
        <span className="nav-text">{item.title}</span>
      </Nav.Link>
    );
  });
  return navItemsArray;
};
