import React from "react";
import { Row, Col } from "react-bootstrap";
import { LinkButton } from "app/components/LinkButton";

interface Props {
  teamId: number;
  teamMemberId: number;
  teamName: string;
  acceptInvitations: (teamMemberId: number) => void;
}

export function PendingInvitationsBox({
  acceptInvitation,
  teamId,
  teamMemberId,
  teamName,
}) {
  const handleClick = () => {
    acceptInvitation(teamMemberId, teamId, teamName);
  };
  return (
    <>
      <Row className="pending-box">
        <Col lg={8} md={7} sm={8} className="text-left">
          {`You've been invited to join the ${teamName}!`}
        </Col>
        <Col lg={4} md={5} sm={4} className="text-sm-left text-md-right">
          <LinkButton
            text={`Accept Invitation`}
            showArrow=""
            handler={handleClick}
            isLoading={false}
          />
        </Col>
      </Row>
    </>
  );
}
