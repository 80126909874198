import React from "react";
import { getTraitCards } from "./helper-functions";
import { PersonalityReports, MemberPersonalityReport } from "./types";

interface Props {
  teamPersonalityReports: PersonalityReports | null;
  memberPersonalityReports: MemberPersonalityReport[] | null;
  notableTraits: string[];
  viewAllWorkplaceInsights: number | undefined;
  loggedInUserIsTeamLead: boolean;
  teamId: number | undefined;
  teamName: string | undefined;
}

export default function TraitSummaries({
  teamPersonalityReports,
  memberPersonalityReports,
  notableTraits,
  viewAllWorkplaceInsights,
  loggedInUserIsTeamLead,
  teamId,
  teamName,
}: Props) {
  const traitCards = getTraitCards(
    teamPersonalityReports,
    memberPersonalityReports,
    notableTraits,
    viewAllWorkplaceInsights,
    loggedInUserIsTeamLead,
    teamId,
    teamName
  );
  return <div>{traitCards}</div>;
}
