import React from "react";
import { Card } from "react-bootstrap";
import notableTraitIcon from "resources/images/notable-trait.svg";
import "./styles.css";

export function Header() {
  return (
    <Card className="mt-3 team-personality-header-card">
      <Card.Body>
        <p className="headline-text">Team Personality</p>
        <p className="team-personality-text">
          This section shows how your team members work according to 10
          different behavioral traits. Notable traits are marked with a
          <img src={notableTraitIcon} alt="notable trait" />
          and most strongly characterize your team.
        </p>
      </Card.Body>
    </Card>
  );
}
