import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";

// The initial state of the TeamPersonality container
export const initialState: ContainerState = {
  team: null,
  loadingTeams: false,
  teamPersonalityReports: null,
  memberPersonalityReports: null,
  loadingPersonalityReports: false,
  teamPersonalityPDF: null,
  loadingPersonalityPDF: false,
  errorMessage: null,
};

const teamPersonalitySlice = createSlice({
  name: "teamPersonality",
  initialState,
  reducers: {
    getPersonalityReports(state, action: PayloadAction<any>) {
      state.loadingPersonalityReports = true;
    },
    getPersonalityReportsSuccess(state, action: PayloadAction<any>) {
      state.teamPersonalityReports = action.payload.team;
      state.memberPersonalityReports = action.payload.teamMembers;
      state.loadingPersonalityReports = false;
    },
    getPersonalityReportsError(state, action: PayloadAction<any>) {
      state.errorMessage = action.payload;
      state.loadingPersonalityReports = false;
    },
    getPersonalityPDF(state, action: PayloadAction<any>) {
      state.loadingPersonalityPDF = true;
    },
    getPersonalityPDFSuccess(state, action: PayloadAction<any>) {
      state.teamPersonalityPDF = action.payload;
      state.loadingPersonalityPDF = false;
    },
    getPersonalityPDFError(state, action: PayloadAction<any>) {
      state.errorMessage = action.payload;
      state.loadingPersonalityPDF = false;
    },
    getTeams(state, action: PayloadAction<any>) {
      state.loadingTeams = true;
    },
    getTeamsSuccess(state, action: PayloadAction<any>) {
      state.loadingTeams = false;
      state.team = action.payload;
    },
    getTeamsError(state, action: PayloadAction<any>) {
      state.loadingTeams = false;
      state.errorMessage = "There was a problem retrieving your team data.";
    },
    resetTeamPersonalityPDF(state) {
      state.teamPersonalityPDF = initialState.teamPersonalityPDF;
    },
  },
});

export const { actions, reducer, name: sliceKey } = teamPersonalitySlice;
