import { all, call, put, takeLatest } from "redux-saga/effects";
import { request } from "utils/request";
import { actions } from "./slice";

export function* acceptInvitation(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/teamMembers/accept`;
    const acceptInvitationResponse = yield call(request, requestUrl, {
      method: "POST",
      body: JSON.stringify({ token: action.payload.token }),
    });
    yield put(actions.acceptInvitationSuccess(acceptInvitationResponse));
  } catch (error) {
    let errorMessage = "";
    if (error.response.status === 403) {
      errorMessage = "This link has expired";
    }
    yield put(actions.acceptInvitationError(errorMessage));
  }
}

export function* inviteConfirmationSaga() {
  yield all([takeLatest(actions.acceptInvitation.type, acceptInvitation)]);
}
