import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";

// The initial state of the AccountSettings container
export const initialState: ContainerState = {
  loadingUserSettings: false,
  userSettings: null,
  savingSetting: false,
};

const accountSettingsSlice = createSlice({
  name: "accountSettings",
  initialState,
  reducers: {
    getUserSettings(state, action: PayloadAction<any>) {
      state.loadingUserSettings = true;
    },
    getUserSettingsSuccess(state, action: PayloadAction<any>) {
      state.loadingUserSettings = false;
      state.userSettings = { ...action.payload };
    },
    getUserSettingsError(state) {
      state.loadingUserSettings = false;
    },
    saveSetting: {
      reducer(state, action: PayloadAction<any>) {
        state.savingSetting = true;
      },
      prepare(userAccountId, formData) {
        return {
          payload: {
            userAccountId: userAccountId,
            formData: formData,
          },
        };
      },
    },
    saveSettingSuccess(state, action: PayloadAction<any>) {
      state.savingSetting = false;
    },
    saveSettingError(state) {
      state.savingSetting = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = accountSettingsSlice;
