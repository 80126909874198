import React, { useState, useRef, useLayoutEffect } from "react";
import { Col, Card, Row } from "react-bootstrap";
import { useWindowResize } from "beautiful-react-hooks";
import { useHistory } from "react-router-dom";
import { TeamData } from "../types";
import { getMemberList } from "utils/helper_functions/getMemberList";

interface Props {
  team: TeamData;
  handleViewTeam: (teamId: number) => void;
  teamId: number;
  sampleTeam?: boolean;
  automatic: boolean;
}

export function TeamBox({
  team,
  handleViewTeam,
  teamId,
  sampleTeam,
  automatic
}: Props): JSX.Element {
  const membersArray = team?.teamMembers;
  const rowRef: any = useRef(null);

  /****************************************************************************
   * State Variables                                                          *
   ****************************************************************************/
  const [membersWidth, setMembersWidth] = useState<number | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [windowWidth, setWindowWidth] = useState(0);
  // const [windowDimensions] = useWindowDimensions();

  /****************************************************************************
   * Hooks                                                                    *
   ****************************************************************************/

  /*
   * the layout effect hook will determine the width of the element that contains
   * the members list.  it is responsive, so it'll get called anytime there is a
   * change to the element size
   */
  useLayoutEffect(() => {
    if (rowRef.current) {
      setMembersWidth(
        Math.round(rowRef?.current?.getBoundingClientRect().width)
      );
    }
  }, [rowRef]);

  useWindowResize(() => {
    if (rowRef.current) {
      setMembersWidth(
        Math.round(rowRef?.current?.getBoundingClientRect().width)
      );
    }
    setWindowWidth(window.innerWidth);
  });

  const history = useHistory();

  const handleAvatarClick = (event: any): void => {
    const member = membersArray?.filter(
      // eslint-disable-next-line
      member => member.teamMemberId == event
    );

    history.push(`/profile/${member[0].userAccountId}`);
  };

  /* get an array of team member avatars */
  const memberList = membersWidth
    ? getMemberList(membersArray, membersWidth, true, handleAvatarClick)
    : null;

  const sampleTeamBadge = (
    <span
      className={"sample-team-badge"}
      style={{ whiteSpace: "nowrap", maxWidth: "fit-content" }}
    >
      SAMPLE TEAM
    </span>
  );

  const automaticTeamBadge = (
    <span
      className={"automatic-team-badge"}
      style={{ whiteSpace: "nowrap", maxWidth: "fit-content" }}
    >
      AUTOMATIC TEAM
    </span>
  );

  return (
    <Col lg={6} md={12} sm={12}>
      <Card className="team-card">
        <Card.Title
          className="team-name"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <a
            href={`${process.env.REACT_APP_URL}/team/${teamId}`}
            style={{ color: "#032876", textDecoration: "none" }}
          >
            {team?.teamName.length < 30
              ? team?.teamName
              : `${team?.teamName.slice(0, 29)}...`}
          </a>
          <div>
            {sampleTeam && sampleTeamBadge}
            {automatic && automaticTeamBadge}
          </div>
        </Card.Title>
        <Card.Body className="horizontal-avatar-row" ref={rowRef}>
          {memberList}
        </Card.Body>
        <Row style={{ padding: "23px 0 0 17px" }}>
          <button
            className="teal-button"
            onClick={event => handleViewTeam(teamId)}
          >
            View Team
          </button>
        </Row>
      </Card>
    </Col>
  );
}
