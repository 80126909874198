import React from "react";
import { Col, Modal } from "react-bootstrap";
import { LinkButton } from "app/components/LinkButton";

interface Props {
  show: boolean;
  teamMemberToDelete: {
    firstName: string;
    lastName: string;
    teamMemberId: number;
  };
  teamName: string | undefined;
  onHide: () => void;
  removeTeamMember: (teamMemberId: number) => void;
}

export const DeleteMemberConfirmationModal = ({
  show,
  teamMemberToDelete,
  teamName,
  onHide,
  removeTeamMember,
}: Props) => {
  const handleRemoveMemberButton = () => {
    removeTeamMember(teamMemberToDelete.teamMemberId);
  };
  return (
    <Modal show={show} size="lg" onHide={onHide}>
      <Modal.Header closeButton className="modal-header">
        Remove Team Member
      </Modal.Header>
      <Modal.Body className="modal-text">
        {`Are you sure you would like to remove ${teamMemberToDelete?.firstName} ${teamMemberToDelete?.lastName} from the ${teamName}?`}
      </Modal.Body>
      <Modal.Footer>
        <Col className="text-right">
          <LinkButton
            text="Cancel"
            showArrow=""
            handler={onHide}
            isLoading={false}
            style={{
              fontFamily: "Lato",
              fontSize: "14px",
              lineHeight: "1.43",
              color: "#425cc7",
            }}
          />
          <button
            className="modal-button ml-5 pl-3 pr-3"
            onClick={handleRemoveMemberButton}
          >
            Remove Team Member
          </button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};
