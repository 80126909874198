import React, { useEffect, useState } from "react";
import { LinkButton } from "app/components/LinkButton";
import { Button, Col, Modal, Row } from "react-bootstrap";
import uploadPhotoIcon from "resources/images/photo-upload.png";
import Avatar from "react-avatar-edit";

interface Props {
  show: boolean;
  onHide: () => void;
  profilePicture: string | null;
  profilePictureFileType: string | null;
  profilePictureFileName: string | null;
  handlePhotoUpload: (formData: any) => void;
  handlePhotoDeletion: () => void;
}

export default function UploadPhotoModal({
  show,
  onHide,
  profilePicture,
  handlePhotoUpload,
  profilePictureFileType,
  profilePictureFileName,
  handlePhotoDeletion,
}: Props) {
  /****************************************************************************
   * State Variables                                                          *
   ****************************************************************************/
  const [showAvatarEditComponent, setShowAvatarEditComponent] = useState(false);
  const [preview, setPreview] = useState("");
  const [profileSrc, setProfileSrc] = useState<any>("");
  const [imageName, setImageName] = useState<string | null>(null);

  /****************************************************************************
   * Hooks                                                                    *
   ****************************************************************************/
  useEffect(() => {
    // since the modal mounts when the parent component does, we need to check
    // here for when the user's profile picture is returned from the API (in
    // the user object), and then set the modal's state values appropriately.
    if (profilePicture) {
      setShowAvatarEditComponent(true);
      setProfileSrc(
        `data:image/${profilePictureFileType};base64, ${profilePicture}`
      );
      setImageName(profilePictureFileName);
    }
  }, [profilePicture, profilePictureFileType, profilePictureFileName]);

  /****************************************************************************
   * React-Avatar-Edit Methods                                                *
   ****************************************************************************/
  const closeModal = () => {
    setPreview("");
    setShowAvatarEditComponent(false);
    onHide();
  };

  const onClose = () => {
    setPreview("");
    setProfileSrc("");
    setShowAvatarEditComponent(false);
  };

  const onCrop = (preview) => {
    setPreview(preview);
    setProfileSrc(preview);
  };

  const onFileLoad = (file) => {
    setImageName(file.name);
    const fileReader = new FileReader();

    fileReader.onload = function (fileLoadedEvent) {
      var srcData: any = fileLoadedEvent?.target?.result; // <--- srcData: base64
      setProfileSrc(srcData);
    };
    fileReader.readAsDataURL(file);
  };

  /****************************************************************************
   * Handlers                                                                 *
   ****************************************************************************/
  const handleSave = () => {
    const profileImgStringArray = profileSrc.split(",");
    const formData = {
      imageName,
      profilePicture: profileImgStringArray[1],
    };
    handlePhotoUpload(formData);
    closeModal();
  };

  const handleRemoveImage = () => {
    handlePhotoDeletion();
    closeModal();
  };

  return (
    <Modal size="lg" show={show} onHide={closeModal}>
      <Modal.Header closeButton className="modal-header">
        Upload Profile Photo
      </Modal.Header>
      <Modal.Body>
        {!showAvatarEditComponent ? (
          <>
            <Row>
              <img
                className="m-auto"
                src={uploadPhotoIcon}
                alt="camera icon"
                height="160px"
                width="160px"
              />
            </Row>
            <Row className="mt-3 mb-3">
              <Button
                className="m-auto"
                variant="outline-primary"
                onClick={() => {
                  setShowAvatarEditComponent(true);
                }}
              >
                Upload a Photo
              </Button>
            </Row>
          </>
        ) : (
          <Row>
            <Col lg={6} md={6} sm={12} className="text-center">
              <Avatar
                width={325}
                height={246}
                onCrop={onCrop}
                onClose={onClose}
                onFileLoad={onFileLoad}
                src={profileSrc}
              />
              <div className="text-center mt-3 mb-3">
                <LinkButton
                  text="Remove Profile Picture"
                  showArrow=""
                  handler={handleRemoveImage}
                  style={{ color: "red" }}
                />
              </div>
            </Col>
            {preview ? (
              <Col className="text-center m-auto" lg={6} md={6} sm={12}>
                <img src={preview} alt="Preview" />
                <div className="mt-3">Preview</div>
              </Col>
            ) : null}
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Col className="text-right">
          <LinkButton
            text="Cancel"
            showArrow=""
            handler={closeModal}
            isLoading={false}
            style={{
              fontFamily: "Lato",
              fontSize: "14px",
              lineHeight: "1.43",
              color: "#425cc7",
            }}
          />
          <button className="modal-button ml-5 pl-3 pr-3" onClick={handleSave}>
            Save Photo
          </button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}
