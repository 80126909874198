import { call, put, all, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { request } from "utils/request";
import { PayloadAction } from "@reduxjs/toolkit";

export function* getUserSettings(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/${action.payload}/settings`;
    const getUserSettingsResponse = yield call(request, requestUrl);
    yield put(actions.getUserSettingsSuccess(getUserSettingsResponse));
  } catch (error) {
    yield put(actions.getUserSettingsError());
  }
}

export function* saveSetting(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/${action.payload.userAccountId}/settings`;
    const saveSettingResponse = yield call(request, requestUrl, {
      method: "PUT",
      body: JSON.stringify(action.payload.formData),
    });
    yield put(actions.saveSettingSuccess(saveSettingResponse));
  } catch (error) {
    yield put(actions.saveSettingError());
  }
}

export function* accountSettingsSaga() {
  yield all([
    takeLatest(actions.getUserSettings.type, getUserSettings),
    takeLatest(actions.saveSetting.type, saveSetting),
  ]);
}
