import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Form,
  Container,
  Row,
  Card,
  Button,
  FormControl
} from "react-bootstrap";
import { LinkButton } from "../../components/LinkButton";
import { Helmet } from "react-helmet-async";
import useEffectOnMount from "utils/custom_hooks/useEffectOnMount";
import { useHistory, Redirect } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./slice";
import { addEmployeeSaga } from "./saga";
import {
  selectUsers,
  selectAllowedDomains,
  selectSafeToRedirect,
  selectExistingUsers,
  selectManager,
  selectHsVersion,
  selectHasAutomaticTeamsOn,
  selectEmailErrorMessage
} from "./selectors";
import { selectUser } from "app/containers/Global/selectors";
import "./styles.css";
import { FormData, LinkData } from "./types";
import { LinkCandidate } from "./LinkCandidate";
import { ManagerDropdown } from "./ManagerDropdown";
import ReportsConfirmationModal from "./ReportsConfirmationModal";
export function AddEmployee() {
  useInjectReducer({
    key: sliceKey,
    reducer: reducer
  });
  useInjectSaga({
    key: sliceKey,
    saga: addEmployeeSaga
  });

  /* local state variables */
  const dispatch = useDispatch();
  const history = useHistory();
  const editId = window.location.search?.split("editId=")[1];

  const [firstName, setFirstName] = useState<string>("");
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastName, setLastName] = useState<string>("");
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [jobTitle, setJobTitle] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [linkData, setLinkData] = useState<LinkData>({
    unlinkTestTaker: 0,
    linkTestTaker: 0,
    importEpp: 0,
    testTakerId: 0,
    removeCurrentEpp: 0
  });
  const [accessRole, setAccessRole] = useState<string>("user");
  const [talentInsightsInvite, setTalentInsightsInvite] = useState<boolean>(
    true
  );
  const [emailError, setEmailError] = useState<string>("");
  const [existingMemberEmails, setExistingMemberEmails] = useState<string[]>(
    []
  );
  const [userEmails, setUserEmails] = useState<string[]>([]);
  const [userInvited, setUserInvited] = useState<boolean>(false);
  const [reportsTo, setReportsTo] = useState<any>(null);
  const [
    showReportsConfirmationModal,
    setShowReportsConfirmationModal
  ] = useState<boolean>(false);
  const [managerInvited, setManagerInvited] = useState<boolean>(false);

  const user = useSelector(selectUser);
  const users = useSelector(selectUsers);
  const safeToRedirect = useSelector(selectSafeToRedirect);
  const allowedDomains: string[] = useSelector(selectAllowedDomains);
  const existingUsers = useSelector(selectExistingUsers);
  const manager = useSelector(selectManager);
  const hsVersion = useSelector(selectHsVersion);
  const hasAutomaticTeamsOn = useSelector(selectHasAutomaticTeamsOn);
  const emailErrorMessage = useSelector(selectEmailErrorMessage);

  // check if reporting confirmation modal has been shown and user chose to continue
  let reportingConfirmed = false;

  /****************************************************************************
   * Hooks
   ****************************************************************************/

  useEffectOnMount(() => {
    dispatch(actions.getUsers());
    dispatch(actions.getExistingUsers());
  });

  useEffect(() => {
    if (user) {
      if (user.tmgRoleId !== 1) history.push("/");
      if (user.companyAccountId) {
        const companyAccountId: number = user.companyAccountId;
        dispatch(actions.getCompanyData(companyAccountId));
      }
    }
  }, [dispatch, history, user]);

  useEffect(() => {
    if (existingUsers) {
      const existingEmails = Object.keys(existingUsers).map(key => {
        if (existingUsers[key].emailAddress !== emailAddress) {
          return existingUsers[key].emailAddress;
        }
        return true;
      });
      setExistingMemberEmails(existingEmails);
    }
  }, [emailAddress, existingUsers]);

  useEffect(() => {
    if (users) {
      let allUserEmails = Object.keys(users).map(key => {
        return users[key].emailAddress;
      });
      if (editId) {
        allUserEmails = allUserEmails.filter(
          userEmail => userEmail !== emailAddress
        );
      }
      setUserEmails(allUserEmails);
    }
  }, [emailAddress, users, editId]);

  useEffect(() => {
    if (editId && users && users[editId]) {
      let userAccessRole = users[editId].tmgRoleId === 1 ? "admin" : "user";
      setFirstName(users[editId].firstName);
      setLastName(users[editId].lastName);
      setAccessRole(userAccessRole);
      setEmailAddress(users[editId].emailAddress);
      setJobTitle(users[editId].jobTitle);
      setUserInvited(users[editId].tmgInvited === 1);
    }
  }, [editId, users]);

  useEffect(() => {
    if (reportsTo && users && users[reportsTo.userAccountId]) {
      setManagerInvited(
        users[reportsTo.userAccountId].tmgInvited === 1 ? true : false
      );
    }
  }, [reportsTo, users]);

  useEffect(() => {
    if (editId) {
      dispatch(actions.getManager(editId));
    }
  }, [editId, dispatch]);

  useEffect(() => {
    if (firstName !== "") {
      setFirstNameError(false);
    }
  }, [firstName]);

  useEffect(() => {
    if (lastName !== "") {
      setLastNameError(false);
    }
  }, [lastName]);

  useEffect(() => {
    if (emailAddress !== "") {
      setEmailError("");
    }
  }, [emailAddress]);

  useEffect(() => {
    if (emailErrorMessage) {
      setEmailError(emailErrorMessage);
    }
  }, [emailErrorMessage]);

  /****************************************************************************
   *  Handlers
   ****************************************************************************/

  const handleAccessRoleInput = e => {
    setAccessRole(e.target.value);
  };

  const handleTalentInsightsInvite = e => {
    setTalentInsightsInvite(!talentInsightsInvite);
  };

  const isValidEmail = emailAddress =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress);

  const checkConfirmationModalLogic = () => {
    if (
      editId &&
      !reportingConfirmed &&
      reportsTo?.userAccountId &&
      manager?.userAccountId !== reportsTo.userAccountId &&
      hasAutomaticTeamsOn
    ) {
      return true;
    }
    return false;
  };

  const submitForm = () => {
    if (firstName === "") {
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
    if (lastName === "") {
      setLastNameError(true);
    } else {
      setLastNameError(false);
    }
    let formData: FormData = {
      firstName,
      lastName,
      jobTitle: jobTitle ? jobTitle : "",
      emailAddress,
      tmgRoleId: accessRole === "user" ? "2" : "1",
      manager: +reportsTo?.userAccountId ?? null
    };
    if (handleCheckEmail() && firstName !== "" && lastName !== "") {
      if (checkConfirmationModalLogic()) {
        setShowReportsConfirmationModal(true);
        return;
      }
      if (editId) {
        formData["tmgSuspendedbyUserAccountId"] = 0;
        formData.linkData = linkData;
        dispatch(actions.editUser(+editId, formData));
      } else {
        formData.tmgRoleId = Number(formData.tmgRoleId);
        formData.testTakerId =
          linkData.testTakerId === 0 ? null : linkData.testTakerId;
        formData.sendInvitation = talentInsightsInvite ? 1 : 0;
        dispatch(actions.addEmployee(formData));
      }
    }
  };

  const handleCheckEmail = () => {
    if (emailAddress && isValidEmail(emailAddress)) {
      if (!allowedDomains.includes(emailAddress.split("@")[1].toLowerCase())) {
        setEmailError(
          "You must sign up with a company domain email address in order to get access to Criteria."
        );
        return false;
      }
      if (existingMemberEmails.includes(emailAddress)) {
        setEmailError(
          `This email address is already associated with another user on your account.`
        );
        return false;
      }
      if (userEmails.includes(emailAddress)) {
        setEmailError(
          `This email address is already associated with another Criteria account.`
        );
        return false;
      }
    } else {
      setEmailError(`Please enter a valid email address.`);
      return false;
    }
    setEmailError("");
    return true;
  };

  const handleBack = () => {
    dispatch(actions.resetState());
    setFirstName("");
    setLastName("");
    setJobTitle("");
    setEmailAddress("");
    setAccessRole("user");
    setTalentInsightsInvite(true);
    setEmailError("");
    history.push("/admin");
  };

  const handleReportingConfirmed = () => {
    reportingConfirmed = true;
    setShowReportsConfirmationModal(false);
    submitForm();
  };

  const handleReportsTo = manager => {
    setReportsTo(manager);
  };

  const linkButtonStyle = {
    fontFamily: "Lato",
    fontSize: "14px",
    lineHeight: "32px",
    color: "#425cc7",
    margin: "24px 0 0 0"
  };

  return (
    <>
      <Helmet>
        <title>{editId ? "Edit Employee" : "Add New Employee"}</title>
        <meta name="description" content="Add New Employee Form" />
      </Helmet>
      <Container fluid="lg" className="remove-padding">
        <LinkButton
          text="Back to Employees"
          showArrow="left"
          handler={handleBack}
          style={linkButtonStyle}
        />
      </Container>
      <Container fluid="lg" className="create-team-container">
        <Row className="mb-3">
          <Col>
            <span className="add-new-employee-header">
              {editId ? "Edit Employee" : "Add New Employee"}
            </span>
          </Col>
        </Row>
        <Row className="mb-3 mt-4">
          <Col>
            <span className="required-fields">* Required Fields</span>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={6} style={{ paddingRight: "40px" }}>
            <Row>
              <Col>
                <Form.Group controlId="firstName">
                  <Form.Label className="account-setup-label">
                    First Name
                    <span className="required-fields"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    onChange={event => setFirstName(event.target.value)}
                    isInvalid={firstNameError}
                    className="account-setup-label"
                    value={firstName}
                  />
                  <FormControl.Feedback type="invalid">
                    Please enter your first name.
                  </FormControl.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} md={6} sm={6}>
                <Form.Group controlId="lastName">
                  <Form.Label className="form-label-text">
                    Last Name
                    <span className="required-fields"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    onChange={event => setLastName(event.target.value)}
                    isInvalid={lastNameError}
                    className="account-setup-label"
                    value={lastName}
                  />
                  <FormControl.Feedback type="invalid">
                    Please enter your last name.
                  </FormControl.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col lg={12} md={12} sm={12}>
                <Form.Group controlId="email">
                  <Form.Label className="form-label-text">
                    Email Address
                    <span className="required-fields"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    onChange={event => setEmailAddress(event.target.value)}
                    isInvalid={emailError !== ""}
                    className="account-setup-label"
                    value={emailAddress}
                  />
                  <FormControl.Feedback type="invalid">
                    {emailError}
                  </FormControl.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <Form.Group controlId="teamName">
                  <Form.Label className="form-label-text">Job Title</Form.Label>
                  <Form.Control
                    type="text"
                    value={jobTitle}
                    onChange={event => setJobTitle(event.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            {hsVersion > 1 && (
              <Row>
                <ManagerDropdown
                  users={users}
                  editId={editId}
                  handleReportsTo={manager => handleReportsTo(manager)}
                />
              </Row>
            )}
            <Row className="mb-3">
              <Col lg={12} md={12} sm={12}>
                <Form.Label className="form-label-text">
                  Access Role
                  <span className="required-fields"> *</span>
                </Form.Label>
                <Card
                  className={`user-card ${
                    accessRole === "user" ? "highlighted-card" : ""
                  }`}
                >
                  <Card.Body
                    style={{
                      maxHeight: "125px",
                      overflowY: "scroll",
                      padding: "16px"
                    }}
                  >
                    <Form.Group controlId="userAccessRole">
                      <Form.Check
                        type="radio"
                        name="userAccessRole"
                        data-test="thumbs_radio"
                      >
                        <Form.Check.Input
                          type="radio"
                          onChange={handleAccessRoleInput}
                          value={"user"}
                          checked={accessRole === "user"}
                        />
                        <Form.Check.Label bsPrefix="noValidate ml-2">
                          <strong>User </strong>(Default)
                          <p
                            style={{
                              margin: "5px 0 0 0",
                              fontSize: "14px",
                              color: "#53565a!important"
                            }}
                          >
                            Users can view their own results and any results
                            that others have chosen to share. If enabled in
                            company settings, users can create teams and invite
                            users.
                          </p>
                        </Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                  </Card.Body>
                </Card>
                <Card
                  className={accessRole === "admin" ? "highlighted-card" : ""}
                >
                  <Card.Body
                    style={{
                      padding: "16px 16px 0 16px"
                    }}
                  >
                    <Form.Group style={{ paddingBottom: "5px" }}>
                      <Form.Check
                        type="radio"
                        name="radioGroup"
                        data-test="thumbs_radio"
                      >
                        <Form.Check.Input
                          type="radio"
                          onChange={handleAccessRoleInput}
                          checked={accessRole === "admin"}
                          value={"admin"}
                        />
                        <Form.Check.Label bsPrefix="noValidate ml-2">
                          <strong>Admin</strong>
                          <p
                            style={{
                              margin: "5px 0 0 0",
                              fontSize: "14px",
                              color: "#53565a!important"
                            }}
                          >
                            Admins can add, view, update, and remove users and
                            teams. Admins can make changes to company settings.
                          </p>
                        </Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {!editId && (
              <Row>
                <Col>
                  <Form.Label className="form-label-text">
                    Invite to Talent Insights
                  </Form.Label>
                  <Card className="bg-light">
                    <Card.Body>
                      <Form.Group>
                        <Row>
                          <Col lg={12} md={12} sm={12}>
                            <Form.Check
                              type="checkbox"
                              // key={teamId}
                              id={"inviteToTalentInsights"}
                            >
                              <Form.Check.Input
                                type="checkbox"
                                checked={talentInsightsInvite}
                                onChange={handleTalentInsightsInvite}
                                id={"inviteToTalentInsights"}
                              />
                              <Form.Check.Label
                                bsPrefix="notValidatedCheckbox"
                                htmlFor={"inviteToTalentInsights"}
                              >
                                <p style={{ fontSize: "14px" }}>
                                  Invite this employee to Talent Insights via
                                  email
                                </p>
                                <span
                                  style={{
                                    fontSize: "14px",
                                    color: "#53565a"
                                  }}
                                >
                                  You can also do this later.
                                </span>
                              </Form.Check.Label>
                            </Form.Check>
                          </Col>
                        </Row>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}
          </Col>
          <Col lg={6} md={6} sm={6}>
            <LinkCandidate
              setLinkData={setLinkData}
              currentEmployeeId={editId}
            />
          </Col>
          <Row className="add-cancel-row">
            <Button
              onClick={handleBack}
              style={{
                color: "#425cc7",
                backgroundColor: "white",
                border: "none",
                fontSize: "16px",
                fontWeight: "bold"
              }}
            >
              Cancel
            </Button>
            <Button
              className="modal-button ml-2 pl-3 pr-3"
              type="submit"
              id="createNewUser"
              onClick={submitForm}
            >
              {editId ? "Save" : "Add"}
            </Button>
          </Row>
        </Row>
      </Container>
      <ReportsConfirmationModal
        show={showReportsConfirmationModal}
        onHide={() => setShowReportsConfirmationModal(false)}
        reportsTo={reportsTo}
        employeeName={`${firstName} ${lastName}`}
        userInvited={userInvited}
        reportingConfirmed={handleReportingConfirmed}
        managerInvited={managerInvited}
      />
      {safeToRedirect && firstName ? (
        <Redirect
          to={{
            pathname: "/admin",
            state: {
              from: "addEmployee",
              userFirstName: firstName,
              userLastName: lastName,
              invitedToInsights: talentInsightsInvite,
              edited: editId ? true : false,
              newEmployeeCount: null,
              updatedEmployeeCount: null,
              deactivatedEmployeeCount: null
            }
          }}
        />
      ) : null}
    </>
  );
}
