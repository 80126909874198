import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";

// The initial state of the AdminConsole container
export const initialState: ContainerState = {
  teams: null,
  users: null,
  existingUsers: null,
  loadingTeams: false,
  loadingUser: false,
  loadingUsers: false,
  loadingExistingUsers: false,
  resendingInvites: false,
  inviteSentSuccessfully: false,
  savingUser: false,
  userSavedSuccessfully: false,
  changingUserStatus: false,
  errorMessage: null,
  savedSettings: false,
  loadingCompanyData: false,
  allowedDomains: [],
  tmgAllowUsersCreateTeam: 1,
  allowUsersShareInviteLink: 1,
  allowLinkResults: 1,
  usersInvited: false,
  allUsersInvited: false,
  hasSSOHardEnabled: false,
  inviteLink: "",
  hsVersion: 0,
  deactivatingLink: false,
  newLinkGenerated: false,
  savingSetting: false,
  updatingVisitedAdminConsole: false,
  searchTerm: "",
  accessRole: "all",
  userStatus: "active",
  hasAutomaticTeamsOn: 1
};

const adminConsoleSlice = createSlice({
  name: "adminConsole",
  initialState,
  reducers: {
    getTeams(state) {
      state.loadingTeams = true;
    },
    getTeamsSuccess(state, action: PayloadAction<any>) {
      state.loadingTeams = false;
      state.teams = action.payload;
    },
    getTeamsError(state, action: PayloadAction<any>) {
      state.loadingTeams = false;
      state.errorMessage = action.payload;
    },
    getUsers(state) {
      state.loadingUsers = true;
    },
    getUsersSuccess(state, action: PayloadAction<any>) {
      state.loadingUsers = false;
      state.users = action.payload;
    },
    getUsersError(state, action: PayloadAction<any>) {
      state.loadingUsers = false;
      state.errorMessage = action.payload;
    },
    getExistingUsers(state) {
      state.loadingExistingUsers = true;
    },
    getExistingUsersSuccess(state, action: PayloadAction<any>) {
      state.loadingExistingUsers = false;
      state.existingUsers = action.payload;
    },
    getExistingUsersError(state, action: PayloadAction<any>) {
      state.loadingExistingUsers = false;
      state.errorMessage = action.payload;
    },
    getCompanyData(state, action: PayloadAction<any>) {
      state.loadingCompanyData = true;
    },
    getCompanyDataSuccess(state, action: PayloadAction<any>) {
      state.loadingCompanyData = false;
      state.allowedDomains = action.payload.allowedDomains;
      state.tmgAllowUsersCreateTeam = action.payload.tmgAllowUsersCreateTeam;
      state.allowUsersShareInviteLink =
        action.payload.allowUsersShareInviteLink;
      state.allowLinkResults = action.payload.allowLinkResults;
      state.hasSSOHardEnabled = action.payload.identityProviderMode === 2;
      state.inviteLink = action.payload.inviteLink;
      state.hsVersion = action.payload.hsVersion;
      state.hasAutomaticTeamsOn = action.payload.hasAutomaticTeamsOn;
    },
    getCompanyDataError(state) {
      state.loadingCompanyData = false;
      state.errorMessage =
        "There was an issue with loading your company's data";
    },
    resetInvitesSentSuccessfully(state) {
      state.inviteSentSuccessfully = false;
    },
    deactivateUser(state, action: PayloadAction<any>) {
      state.changingUserStatus = true;
    },
    deactivateUserSuccess(state) {
      state.changingUserStatus = false;
    },
    deactivateUserError(state, action: PayloadAction<any>) {
      state.changingUserStatus = false;
      state.errorMessage = action.payload;
    },
    deactivateLink(state, action: PayloadAction<any>) {
      state.deactivatingLink = true;
    },
    deactivateLinkSuccess(state, action: PayloadAction<any>) {
      state.deactivatingLink = false;
      state.newLinkGenerated = true;
      state.inviteLink = action.payload.inviteLink;
    },
    deactivateLinkError(state, action: PayloadAction<any>) {
      state.deactivatingLink = false;
      state.errorMessage = action.payload;
    },
    updateNewLinkGenerated(state, action: PayloadAction<any>) {
      state.newLinkGenerated = action.payload;
    },
    editUser: {
      reducer(state, action: PayloadAction<any>) {
        state.savingUser = true;
      },
      prepare(userAccountId, formData) {
        return {
          payload: {
            userAccountId: userAccountId,
            formData: { ...formData }
          }
        };
      }
    },
    editUserSuccess(state) {
      state.savingUser = false;
      state.userSavedSuccessfully = true;
    },
    editUserError(state, action: PayloadAction<any>) {
      state.resendingInvites = false;
      state.errorMessage = action.payload;
    },
    resetUserSavedSuccessfully(state) {
      state.userSavedSuccessfully = false;
    },
    saveSettings: {
      reducer(state, action: PayloadAction<any>) {
        state.savedSettings = false;
      },
      prepare(formData, companyAccountId) {
        return {
          payload: {
            companyAccountId: companyAccountId,
            formData: { ...formData }
          }
        };
      }
    },
    saveSettingsSuccess(state) {
      state.savedSettings = true;
    },
    saveSettingsError(state) {
      state.savedSettings = false;
      state.errorMessage = "There was a problem saving your settings.";
    },
    saveSetting: {
      reducer(state, action: PayloadAction<any>) {
        state.savingSetting = true;
      },
      prepare(userAccountId, formData) {
        return {
          payload: {
            userAccountId: userAccountId,
            formData: formData
          }
        };
      }
    },
    saveSettingSuccess(state, action: PayloadAction<any>) {
      state.savingSetting = false;
    },
    saveSettingError(state) {
      state.savingSetting = false;
    },
    addUsers(state, action: PayloadAction<any>) {
      state.usersInvited = false;
    },
    addUsersSuccess(state) {
      state.usersInvited = true;
    },
    addUsersError(state) {
      state.usersInvited = false;
      state.errorMessage = "There was a problem adding these users.";
    },
    inviteUsers(state, action: PayloadAction<any>) {
      state.allUsersInvited = false;
    },
    inviteUsersSuccess(state) {
      state.inviteSentSuccessfully = true;
      state.allUsersInvited = true;
    },
    inviteUsersError(state) {
      state.allUsersInvited = false;
      state.errorMessage = "There was a problem adding these users.";
    },
    resetUsersInvited(state) {
      state.usersInvited = false;
    },
    updateUserTracking(state, action: PayloadAction<any>) {
      state.updatingVisitedAdminConsole = true;
    },
    updateUserTrackingSuccess(state) {
      state.updatingVisitedAdminConsole = false;
    },
    updateUserTrackingError(state, action: PayloadAction<any>) {
      state.updatingVisitedAdminConsole = false;
      // state.errorMessage = action.payload;
    },
    updateSearchTerm(state, action: PayloadAction<any>) {
      state.searchTerm = action.payload;
    },
    updateAccessRole(state, action: PayloadAction<any>) {
      state.accessRole = action.payload;
    },
    updateUserStatus(state, action: PayloadAction<any>) {
      state.userStatus = action.payload;
    }
  }
});

export const { actions, reducer, name: sliceKey } = adminConsoleSlice;
