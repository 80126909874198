import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { TeamData } from "../types";
import { Container, Row, Col } from "react-bootstrap";
import { CreateTeamBox } from "./CreateTeamBox";
import { getTeamsArray } from "../helper-functions";
import { useHistory } from "react-router-dom";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { selectAccountSettings } from "../selectors";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../slice";
import useWindowDimensions from "utils/custom_hooks/useWindowDimensions";
import { selectUser, selectIsAdmin } from "app/containers/Global/selectors";

interface Props {
  teams: TeamData[] | null;
  isLoading: boolean;
  tmgAllowUsersCreateTeam: number | undefined;
}

export function Teams({ teams, isLoading, tmgAllowUsersCreateTeam }: Props) {
  /****************************************************************************
   * States                                                                   *
   ****************************************************************************/
  const accountSettings = useSelector(selectAccountSettings);
  const [showSampleTeams, setShowSampleTeams] = useState(false);
  const [toggleChanged, setToggleChanged] = useState(false);
  const [windowDimensions] = useWindowDimensions();

  /****************************************************************************
   * Selectors                                                                *
   ****************************************************************************/
  const user = useSelector(selectUser);
  const isAdmin = useSelector(selectIsAdmin);

  /****************************************************************************
   * Handlers                                                                 *
   ****************************************************************************/
  const history = useHistory();
  const dispatch = useDispatch();
  const handleViewTeam = (teamId: number) => {
    history.push(`/team/${teamId}`);
  };
  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formData = { showTeamingSampleData: e.target.checked ? 1 : 0 };

    dispatch(actions.saveSetting(user?.userAccountId, formData));
    setShowSampleTeams(e.target.checked);
    setToggleChanged(true);
  };

  // get an array of Team Boxes for the teams the user is a member of
  const teamBoxArray = getTeamsArray(teams, handleViewTeam, showSampleTeams);

  // Initializes the value for sample data toggle
  useEffect(() => {
    if (!toggleChanged)
      setShowSampleTeams(
        accountSettings?.userSettings?.showTeamingSampleData === 1
      );
  }, [accountSettings, toggleChanged]);

  return (
    <Container fluid="lg">
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "15px",
          /* at 993px, the cards wrap on top of each other */
          maxWidth: `${windowDimensions.width < 993 ? "570px" : "100%"} `,
        }}
      >
        <Col>
          <p className="dashboard-section-text" style={{ marginBottom: "0px" }}>
            My Teams
          </p>
        </Col>
        <Col className="show-sample-toggle-container">
          <Toggle
            id="show-sample-teams"
            icons={false}
            onChange={(e) => handleToggle(e)}
            checked={showSampleTeams}
          />
          <span style={{ whiteSpace: "nowrap" }}>Show Sample Teams</span>
        </Col>
      </Row>
      <Row>
        {isLoading ? (
          <>
            <Col lg={6}>
              <div className="skeleton-team-card card">
                <Skeleton height={24} width={100} duration={2} />
                <Skeleton height={14} width={50} duration={2} />
                <Skeleton height={48} width={150} duration={2} />
                <Skeleton height={40} width={75} duration={2} />
              </div>
            </Col>
            <Col lg={6}>
              <div className="skeleton-team-card card">
                <Skeleton height={24} width={100} duration={2} />
                <Skeleton height={14} width={50} duration={2} />
                <Skeleton height={48} width={150} duration={2} />
                <Skeleton height={40} width={75} duration={2} />
              </div>
            </Col>
          </>
        ) : (
          teamBoxArray
        )}
        {(!isLoading && tmgAllowUsersCreateTeam) || isAdmin ? (
          <CreateTeamBox />
        ) : null}
      </Row>
    </Container>
  );
}
