import React from "react";
import { Row, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

interface Props {
  isLoading: boolean;
}

export function Header({ isLoading }: Props) {
  // isLoading = true;
  return (
    <>
      <Row>
        <Col className="text-left">
          {isLoading ? (
            <div className="personality-header mb-3">
              <Skeleton height={30} width={300} duration={2} />
            </div>
          ) : (
            <p className="personality-header">Team Personality Average</p>
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          {isLoading ? (
            <div className="skeleton-personality-text">
              <Skeleton height={15} duration={2} />
            </div>
          ) : (
            <p className="personality-text">
              See the overall shape of your team dynamic.
            </p>
          )}
        </Col>
      </Row>
    </>
  );
}
