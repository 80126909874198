/**
 *
 * IndividualPersonality
 *
 */

import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./slice";
import { individualPersonalitySaga } from "./saga";
import { Row, Col, Container } from "react-bootstrap";
import { Header } from "./Header";
import { WorkplaceInsightsGraph } from "./WorkplaceInsightsGraph";
import {
  selectIndividualPersonality,
  selectUser,
  selectWorkplaceInsightsPdf,
  selectNoPermission,
  selectNoEPP
} from "./selectors";
import { WorkplaceInsightsReport } from "./WorkplaceInsightsReport";
import downloadIcon from "resources/images/download.svg";
import { PendingTestsData } from "./types";
import { NotAvailable } from "../MyProfile/NotAvailable";
import { PendingTests } from "./PendingTest";
import { selectUserAccountId as selectLoggedInUserAccountId } from "app/containers/Global/selectors";
import useSegmentTracking from "utils/custom_hooks/useSegmentTracking";
import useDownloadPDF from "utils/custom_hooks/useDownloadPDF";
interface Props {
  userAccountId: string;
  loggedInUserPendingTests?: PendingTestsData[] | null;
}

export function IndividualPersonality({
  userAccountId,
  loggedInUserPendingTests
}: Props) {
  useInjectReducer({
    key: sliceKey,
    reducer: reducer
  });
  useInjectSaga({
    key: sliceKey,
    saga: individualPersonalitySaga
  });

  const individualPersonality = useSelector(selectIndividualPersonality);
  const user = useSelector(selectUser);
  const pdf = useSelector(selectWorkplaceInsightsPdf);
  const noPermission = useSelector(selectNoPermission);
  const noEPP = useSelector(selectNoEPP);

  /* get the logged in userAccountId for comparison (to take assessments, etc.) */
  const loggedInUserAccountId: string = String(
    useSelector(selectLoggedInUserAccountId)
  );

  const dispatch = useDispatch();

  useSegmentTracking(
    "user profile", // page name
    individualPersonality.workplaceInsightsLoaded, // isDataLoaded
    individualPersonality.workplaceInsightsData, // pageHasContentToShow
    userAccountId,
    loggedInUserAccountId
  );

  useEffect(() => {
    dispatch(actions.getUser(userAccountId));
    return () => {
      dispatch(actions.cleanUp());
    };
  }, [userAccountId, dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(actions.getWorkplaceInsights(user?.userAccountId, "json"));
    }
  }, [user, dispatch]);

  useDownloadPDF(
    pdf,
    "individualPersonality",
    actions.resetWorkplaceInsightsPdf
  );

  const downloadReport = () => {
    dispatch(actions.getWorkplaceInsights(user?.userAccountId, "pdf"));
  };

  return (
    <>
      <Helmet>
        <title>{user ? `${user?.firstName} ${user?.lastName}` : ""}</title>
        <meta
          name="description"
          content="Display of the Workplace Insights Report"
        />
      </Helmet>
      <Container fluid="lg">
        <div className="my-3 pb-3">
          {individualPersonality.workplaceInsightsLoaded ? (
            individualPersonality.workplaceInsightsData ? (
              <>
                <Row>
                  <Col className="text-right">
                    <button
                      className="download-button"
                      onClick={downloadReport}
                      style={{
                        color: "#001F4E"
                      }}
                    >
                      <img
                        className="mr-2 mb-1"
                        src={downloadIcon}
                        alt="download report"
                      />
                      Download
                    </button>
                  </Col>
                </Row>
                <Header user={user} />
                <WorkplaceInsightsGraph
                  user={user}
                  loaded={individualPersonality.workplaceInsightsLoaded}
                />
                <WorkplaceInsightsReport user={user} />
              </>
            ) : loggedInUserAccountId !== userAccountId ? (
              <NotAvailable
                text={"Workplace Insights"}
                noPermission={noPermission}
                noEPP={noEPP}
                firstName={user?.firstName}
              />
            ) : (
              <PendingTests pendingTests={loggedInUserPendingTests} />
            )
          ) : null}
        </div>
      </Container>
    </>
  );
}
