/**
 *
 * Avatar
 *
 */
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { OverlayTrigger, Popover, Image } from "react-bootstrap";
import teamLeadStar from "resources/images/team-lead-star.png";
import photoUploadIcon from "resources/images/edit-photo.png";
import { TeamMembersData } from "app/containers/Dashboard/types";
import "./styles.css";

interface Props {
  height: number;
  width: number;
  fontSize: number;
  userName: string | undefined | null;
  emailAddress?: string;
  image?: string | null;
  overflowNames?: TeamMembersData[] | null;
  initials?: string | null;
  isTeamLead?: number;
  isLoading?: boolean;
  shouldOverlap?: boolean;
  backgroundColor?: string;
  forTraitSpectrum?: boolean;
  leftPlacement?: number | null;
  dataFor?: string;
  dataTip?: string;
  doNotShowTooltip?: boolean;
  color?: string;
  showPhotoUpload?: boolean;
  uploadPhotoHandler?: () => void;
  doNotShowBorder?: boolean;
  fromDashboard?: boolean;
  showInGrayscale?: boolean;
  isTeamAverage?: boolean;
  opacity?: number;
  padding?: string;
}

export function Avatar({
  height,
  width,
  fontSize,
  image,
  userName,
  emailAddress,
  initials,
  overflowNames,
  isTeamLead,
  isLoading,
  shouldOverlap,
  backgroundColor,
  forTraitSpectrum,
  leftPlacement,
  dataFor,
  dataTip,
  doNotShowTooltip,
  color,
  showPhotoUpload,
  uploadPhotoHandler,
  doNotShowBorder,
  fromDashboard,
  showInGrayscale,
  isTeamAverage,
  opacity,
  padding
}: Props) {
  const [name, setName] = useState<any>("");

  useEffect(() => {
    const nameDisplay = overflowNames
      ? overflowNames.map((member) => {
          return (
            <li style={{ listStyleType: "none" }} key={member.emailAddress}>{`${
              member.firstName
                ? member.firstName
                : member.emailAddress.slice(0, 2).toUpperCase()
            } ${member.lastName}`}</li>
          );
        })
      : userName
      ? userName
      : emailAddress
      ? emailAddress
      : initials?.toUpperCase();
    setName(nameDisplay);
  }, [userName, emailAddress, overflowNames, initials]);

  const [showPopover, setShowPopover] = useState(false);
  let avatarStyles: any = {
    height: `${height}px`,
    width: `${width}px`,
    fontSize: `${fontSize}px`,
    lineHeight: `${height - 6}px`,
    borderRadius: "50%",
    border: doNotShowBorder && !isTeamAverage ? "none" : "2px solid #FFF",
    backgroundColor: `${
      !isTeamAverage && image
        ? image?.includes("average-team-score-icon")
          ? "#425cc7"
          : "transparent"
        : backgroundColor
    }`,
    color: `${color ? color : "#FFF"}`,
    fontFamily: "Lato",
    marginLeft: `${shouldOverlap ? "-8px" : ""}`,
    position: `${forTraitSpectrum ? "absolute" : ""}`,
    top: `${forTraitSpectrum ? "-12px" : null}`,
    left: `${leftPlacement ? `${String(Math.floor(leftPlacement))}px` : null}`,
    cursor: `${fromDashboard ? "default" : "pointer"}`,
    filter: `${showInGrayscale ? "grayscale(1)" : "grayscale(0)"}`,
    opacity: `${opacity ? `${opacity}` : "100"}%`,
    padding: `${padding && fromDashboard ? `${padding}` : null}`
  };

  const popover = (
    <Popover id="name-popover" className="avatar-popover">
      {isTeamAverage ? "Team Average" : name}
    </Popover>
  );

  const handleOnToggle = (show: boolean) => {
    if (doNotShowTooltip) setShowPopover(false);
    else setShowPopover(show);
  };

  return (
    <>
      {isLoading ? (
        <Skeleton height={height} width={width} circle={true} duration={2} />
      ) : !image ? (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={popover}
          trigger={["hover", "focus"]}
          onToggle={handleOnToggle}
          show={showPopover}
        >
          <div
            style={avatarStyles}
            className="text-center avatar-container"
            id="avatar"
            data-for={dataFor ? dataFor : ""}
            data-tip={dataTip ? dataTip : ""}
          >
            {initials?.toUpperCase() || emailAddress?.slice(0, 2).toUpperCase()}
            {isTeamLead ? (
              <img
                src={showPhotoUpload ? photoUploadIcon : teamLeadStar}
                height={showPhotoUpload ? "32px" : "20px"}
                width={showPhotoUpload ? "32px" : "20px"}
                className="team-lead-star"
                alt="Team Lead star"
                onClick={showPhotoUpload ? uploadPhotoHandler : () => {}}
              />
            ) : null}
            {!isTeamLead && showPhotoUpload ? (
              <img
                src={photoUploadIcon}
                height={showPhotoUpload ? "32px" : "20px"}
                width={showPhotoUpload ? "32px" : "20px"}
                className="team-lead-star"
                alt="Team Lead star"
                onClick={uploadPhotoHandler}
              />
            ) : null}
          </div>
        </OverlayTrigger>
      ) : (
        <OverlayTrigger
          placement={overflowNames ? "bottom" : "top"}
          delay={{ show: 250, hide: 350 }}
          overlay={popover}
          trigger={["hover", "focus"]}
          onToggle={handleOnToggle}
          show={showPopover}
        >
          <div
            style={avatarStyles}
            className=" text-center avatar-container"
            id="avatar"
            data-for={dataFor ? dataFor : ""}
            data-tip={dataTip ? dataTip : ""}
          >
            <Image
              fluid
              src={image}
              alt="user avatar"
              id="avatar"
              style={{
                height: isTeamAverage ? `20px` : `${height}px`,
                width: isTeamAverage ? `20px` : `${width}px`,
                maxHeight: image.includes("average-team-score-icon")
                  ? `70%`
                  : "100%",
              }}
            />
            {isTeamLead ? (
              <img
                src={showPhotoUpload ? photoUploadIcon : teamLeadStar}
                height={showPhotoUpload ? "32px" : "20px"}
                width={showPhotoUpload ? "32px" : "20px"}
                className="team-lead-star"
                alt="Team Lead star"
                onClick={showPhotoUpload ? uploadPhotoHandler : () => {}}
              />
            ) : null}

            {!isTeamLead && showPhotoUpload ? (
              <img
                src={photoUploadIcon}
                height={showPhotoUpload ? "32px" : "20px"}
                width={showPhotoUpload ? "32px" : "20px"}
                className="team-lead-star"
                alt="Team Lead star"
                onClick={uploadPhotoHandler}
              />
            ) : null}
          </div>
        </OverlayTrigger>
      )}
    </>
  );
}
