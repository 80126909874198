import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import emailInvites from "resources/images/email-invites.jpg";
import rightArrow from "resources/images/arrow-right-white.svg";
import { LinkButton } from "app/components/LinkButton";
import { useHistory } from "react-router-dom";

interface Props {
  showNewTeamModal: boolean;
  teamName: string | null;
  teamId: number | null;
  isOnTeam: boolean;
  onHide: () => void;
  fromDashboard: boolean;
}
export function NewTeamModal({
  showNewTeamModal,
  teamName,
  teamId,
  isOnTeam,
  onHide,
  fromDashboard
}: Props) {
  const history = useHistory();

  const handleViewTeam = () => {
    history.push(`/team/${teamId}`);
  };

  return (
    <Modal show={showNewTeamModal} onHide={onHide} centered>
      <Modal.Header className="create-team-modal-header" closeButton>
        {isOnTeam
          ? `Your ${teamName} invitations have been sent!`
          : `${teamName} has been created and invitations have been sent.`}
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <Col className="text-center">
            <img
              src={emailInvites}
              alt="email invitations"
              style={{ height: "160px", width: "160px" }}
            />
          </Col>
        </Row>
        {isOnTeam && (
          <Row>
            <Col>
              <p className="dashboard-modal-body">
                {teamName} will now be visible on your Dashboard. Once your team
                members have taken their personality assessment, you’ll be able
                to explore your team dynamic.
              </p>
            </Col>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Col xl={12} lg={12} className="text-center">
          <button className="inverse-arrow-button" onClick={handleViewTeam}>
            View{" "}
            {teamName && teamName.length > 40
              ? `${teamName?.slice(0, 39)}...`
              : teamName}
            <img
              src={rightArrow}
              alt="right arrow"
              className="link-button-arrow"
            />
          </button>
        </Col>
        <Col className="text-center mt-2">
          <LinkButton
            text={`Back to ${fromDashboard ? "My Dashboard" : "Admin Console"}`}
            showArrow=""
            handler={onHide}
            isLoading={false}
          />
        </Col>
      </Modal.Footer>
    </Modal>
  );
}
