import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => state.teamPage || initialState;

export const selectTeamPage = createSelector(
  [selectDomain],
  (teamPageState) => teamPageState
);

export const selectTeam = createSelector(
  [selectDomain],
  (teamPageState) => teamPageState.team
);

export const selectTeamMembers = createSelector(
  [selectDomain],
  (teamPageState) => teamPageState.team?.teamMembers
);

export const selectMemberScores = createSelector(
  [selectDomain],
  (teamPageState) => teamPageState.memberScores
);

export const selectTeamScores = createSelector(
  [selectDomain],
  (teamPageState) => teamPageState.teamScores
);

export const selectMembersPersonalityReports = createSelector(
  [selectDomain],
  (teamPageState) => teamPageState.memberPersonalityReports
);

export const selectLoadingPersonalityScores = createSelector(
  [selectDomain],
  (teamPageState) => teamPageState.loadingPersonalityScores
);

export const selectPersonalityReportsLoaded = createSelector(
  [selectDomain],
  (teamPageState) => teamPageState.personalityReportsLoaded
);

export const selectAllowedDomains = createSelector(
  [selectDomain],
  (teamPageState) => teamPageState.allowedDomains
);

export const selectMembersInvited = createSelector(
  [selectDomain],
  (teamPageState) => teamPageState.membersInvited
);

export const selectUsersList = createSelector(
  [selectDomain],
  (teamPageState) => teamPageState.usersList
);

export const selectInvitingMembers = createSelector(
  [selectDomain],
  (teamPageState) => teamPageState.invitingMembers
);

export const selectIsLoading = createSelector(
  [selectDomain],
  (teamPageState) => {
    return (
      teamPageState.loadingTeams ||
      teamPageState.loadingPersonalityScores ||
      teamPageState.loadingPersonalityReports ||
      teamPageState.loadingAllowedDomains ||
      teamPageState.loadingUsers
    );
  }
);
