import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";

// The initial state of the MyProfile container
export const initialState: ContainerState = {
  user: null,
  pendingTests: null,
  userLoaded: false,
  personalityProfileLoaded: false,
  showPersonalityProfile: true,
  noPermission: false,
  noEPP: false,
  personalityProfile: null,
  uploadingImage: false,
  deletingImage: false,
  errorMessage: "",
  loggedInUserPendingTests: null,
  loadingPendingTests: false,
  collaborationGuidePDF: null,
  loadingCollaborationGuidePDF: false,
  updatingViewManagerGuide: false,
  managerGuideLoaded: false,
  showManagerGuide: true,
  managerGuide: null,
  loadingManagerGuidePDF: false,
  managerGuidePDF: null,
  companyDataLoaded: false,
  hsVersion: 0
};

const myProfileSlice = createSlice({
  name: "myProfile",
  initialState,
  reducers: {
    getUser(state, action: PayloadAction<any>) {
      state.userLoaded = false;
    },
    getUserSuccess(state, action: PayloadAction<any>) {
      state.userLoaded = true;
      state.user = action.payload;
    },
    getUserError(state, action: PayloadAction<any>) {
      state.userLoaded = true;
    },
    getPersonalityProfile(state, action: PayloadAction<any>) {
      state.personalityProfileLoaded = false;
    },
    getPersonalityProfileSuccess(state, action: PayloadAction<any>) {
      state.personalityProfileLoaded = true;
      if (action.payload) {
        state.personalityProfile = action.payload;
      } else {
        state.showPersonalityProfile = false;
        state.noEPP = true;
      }
    },
    getPersonalityProfileError(state, action: PayloadAction<any>) {
      state.personalityProfileLoaded = true;
      state.showPersonalityProfile = false;
      state.noPermission = true;
    },
    getPendingTests(state) {
      state.loadingPendingTests = true;
    },
    getPendingTestsSuccess(state, action: PayloadAction<any>) {
      state.loadingPendingTests = false;
      state.loggedInUserPendingTests = action?.payload.pendingTests;
    },
    getPendingTestsError(state, action: PayloadAction<any>) {
      state.loadingPendingTests = false;
    },
    uploadImage: {
      reducer(state, action: PayloadAction<any>) {
        state.uploadingImage = true;
      },
      prepare(userAccountId, formData) {
        return {
          payload: {
            userAccountId: userAccountId,
            formData: { ...formData }
          }
        };
      }
    },
    uploadImageSuccess(state) {
      state.uploadingImage = false;
    },
    uploadImageError(state) {
      state.uploadingImage = false;
      state.errorMessage = "There was a problem uploading your image.";
    },
    deleteImage(state, action: PayloadAction<any>) {
      state.deletingImage = true;
    },
    deleteImageSuccess(state) {
      state.deletingImage = false;
    },
    deleteImageError(state) {
      state.deletingImage = false;
    },
    getCollaborationGuidePDF(state, action: PayloadAction<any>) {
      state.loadingCollaborationGuidePDF = true;
    },
    getCollaborationGuidePDFSuccess(state, action: PayloadAction<any>) {
      state.collaborationGuidePDF = action.payload;
      state.loadingCollaborationGuidePDF = false;
    },
    getCollaborationGuidePDFError(state, action: PayloadAction<any>) {
      state.errorMessage = action.payload;
      state.loadingCollaborationGuidePDF = false;
    },
    resetcollaborationGuidePDF(state) {
      state.collaborationGuidePDF = initialState.collaborationGuidePDF;
    },
    cleanUp(state) {
      state.user = initialState.user;
      state.pendingTests = initialState.pendingTests;
      state.userLoaded = initialState.userLoaded;
      state.uploadingImage = initialState.uploadingImage;
      state.errorMessage = initialState.errorMessage;
      state.deletingImage = initialState.deletingImage;
      state.noEPP = initialState.noEPP;
      state.noPermission = initialState.noPermission;
    },
    updateViewManagerGuide(state, action: PayloadAction<any>) {
      state.updatingViewManagerGuide = true;
    },
    updateViewManagerGuideSuccess(state) {
      state.updatingViewManagerGuide = false;
    },
    updateViewManagerGuideError(state, action: PayloadAction<any>) {
      state.updatingViewManagerGuide = false;
    },
    getManagerGuide(state, action: PayloadAction<any>) {
      state.managerGuideLoaded = false;
    },
    getManagerGuideSuccess(state, action: PayloadAction<any>) {
      state.managerGuideLoaded = true;
      if (action.payload) {
        state.managerGuide = action.payload;
      } else {
        state.showManagerGuide = false;
        state.noEPP = true;
      }
    },
    getManagerGuideError(state, action: PayloadAction<any>) {
      state.managerGuideLoaded = true;
      state.showManagerGuide = false;
    },
    getManagerGuidePDF(state, action: PayloadAction<any>) {
      state.loadingManagerGuidePDF = true;
    },
    getManagerGuidePDFSuccess(state, action: PayloadAction<any>) {
      state.managerGuidePDF = action.payload;
      state.loadingManagerGuidePDF = false;
    },
    getManagerGuidePDFError(state, action: PayloadAction<any>) {
      state.errorMessage = action.payload;
      state.loadingManagerGuidePDF = false;
    },
    resetManagerGuidePDF(state) {
      state.managerGuidePDF = initialState.managerGuidePDF;
    },
    getCompanyData(state, action: PayloadAction<any>) {
      state.companyDataLoaded = false;
    },
    getCompanyDataSuccess(state, action: PayloadAction<any>) {
      state.companyDataLoaded = true;
      state.hsVersion = action.payload.hsVersion;
    },
    getCompanyDataError(state) {
      state.companyDataLoaded = true;
      state.errorMessage =
        "There was an issue with loading your company's data";
    }
  }
});

export const { actions, reducer, name: sliceKey } = myProfileSlice;
