import React from "react";
import { TeamMembersData } from "app/containers/Dashboard/types";
import { Avatar } from "app/components/Avatar";

export const getMemberList = (
  members: TeamMembersData[],
  width: number,
  fromDashboard: boolean,
  handleAvatarClick: any
) => {
  let numberOfAvatarsToShow;
  if (!fromDashboard) {
    width > 360
      ? (numberOfAvatarsToShow = Math.floor((width - 220) / 40))
      : (numberOfAvatarsToShow = Math.floor((width - 80) / 40));
  } else {
    width >= 400
      ? (numberOfAvatarsToShow = Math.floor(width / 40))
      : (numberOfAvatarsToShow = Math.floor(width / 45));
  }

  const sizeAdjustedMembersList = members.slice(0, numberOfAvatarsToShow - 1);
  const overflowMembers = members.slice(numberOfAvatarsToShow - 1);

  let membersList = sizeAdjustedMembersList
    .slice()
    /* sort by first name, or, if one or both members are lacking that, email address */
    .sort((a: any, b: any) => {
      if (a.firstName && b.firstName) {
        return a.firstName
          .trim()
          .toLowerCase()
          .localeCompare(b.firstName.trim().toLowerCase());
      } else if (a.firstName && !b.firstName) {
        return a.firstName
          .trim()
          .toLowerCase()
          .localeCompare(b.emailAddress.trim().toLowerCase());
      } else if (!a.firstName && b.firstName) {
        return a.emailAddress
          .trim()
          .toLowerCase()
          .localeCompare(b.firstName.trim().toLowerCase());
      } else {
        return a.emailAddress
          .trim()
          .toLowerCase()
          .localeCompare(b.emailAddress.trim().toLowerCase());
      }
    })
    .sort((a: any, b: any) => {
      if (fromDashboard) {
        return a.isTeamLead > b.isTeamLead ? 1 : -1;
      }
      return a.isTeamLead > b.isTeamLead ? -1 : 1;
    })
    .map((member) => {
      return (
        <span
          onClick={
            fromDashboard
              ? () => {}
              : () => handleAvatarClick(member.teamMemberId)
          }
          className="cursor-pointer"
          key={member.teamMemberId}
        >
          <Avatar
            height={48}
            width={48}
            image={member.profilePicture ? member.profilePicture : null}
            userName={
              member.firstName ? `${member.firstName} ${member.lastName}` : null
            }
            emailAddress={member.emailAddress}
            initials={`${
              member.firstName
                ? member.firstName.charAt(0)
                : member.emailAddress.slice(0, 2)
            }${member.lastName.charAt(0)}`}
            isTeamLead={member.isTeamLead}
            fontSize={20}
            shouldOverlap={true}
            key={member.teamMemberId}
            backgroundColor={
              member?.dateAccepted === null
                ? `#b1b3b3`
                : `#${member?.avatarColor}`
            }
            fromDashboard={fromDashboard}
            showInGrayscale={member.dateAccepted === null}
          />
        </span>
      );
    });
  const overflowAvatar =
    overflowMembers.length > 0 ? (
      <Avatar
        height={48}
        width={48}
        userName={null}
        initials={`+${overflowMembers.length}`}
        overflowNames={overflowMembers}
        isTeamLead={0}
        fontSize={overflowMembers.length > 99 ? 15 : 20}
        shouldOverlap={true}
        key="additional"
        backgroundColor="#d9def4"
        color="#425cc7"
        fromDashboard={fromDashboard}
        padding="0 1px 0 2px"
      />
    ) : null;
  if (overflowAvatar) {
    if (fromDashboard) {
      membersList = [overflowAvatar, ...membersList];
    } else membersList = [...membersList, overflowAvatar];
  }
  return membersList;
};
