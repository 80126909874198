import React from "react";
import { Nav } from "react-bootstrap";
import Scrollspy from "react-scrollspy";
import { getNavItems } from "../helper-functions";

export function NavigationBar() {
  const navItemsArray: string[] = [
    "Notable Traits",
    "Work, Communication & Interaction Style",
    "Temperament, Attitudes & Outlook",
    "Strength & Potential Challenges",
    "Development Suggestions",
    "Workplace Challenges & Motivators",
  ];

  const spyScrollItems = navItemsArray.map((item) =>
    item.replace(/ /g, "").replace(",", "").replace("&", "")
  );

  const navItems = getNavItems(navItemsArray);

  return (
    <Nav
      id="team-personality-nav"
      className="flex-column section-nav mr-1 mb-3"
      defaultActiveKey="Notable Traits"
    >
      <Scrollspy
        items={spyScrollItems}
        currentClassName="is-current"
        style={{ paddingLeft: 0 }}
      >
        {navItems}
      </Scrollspy>
    </Nav>
  );
}
