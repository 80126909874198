import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import newTeamImage from "resources/images/team-invite-success.jpg";
import rightArrow from "resources/images/arrow-right-white.svg";
import { LinkButton } from "app/components/LinkButton";
import { useHistory } from "react-router-dom";

interface Props {
  showInvitationAcceptedModal: boolean;
  invitationAcceptedTeamName: string | null;
  invitationAcceptedTeamId: number | null;
  onHide: () => void;
}
export function AcceptedInvitationModal({
  showInvitationAcceptedModal,
  invitationAcceptedTeamName,
  invitationAcceptedTeamId,
  onHide,
}: Props) {
  const history = useHistory();
  const handleViewTeamButton = () => {
    // if values are in local storage from accepting an invitation via
    // email, we want to remove them now.
    localStorage.removeItem("teamName");
    localStorage.removeItem("tid");
    history.push(`/team/${invitationAcceptedTeamId}`);
  };
  return (
    <Modal show={showInvitationAcceptedModal} onHide={onHide} centered>
      <Modal.Header closeButton style={{ border: "none" }} />
      <Modal.Body>
        <Row>
          <Col lg={6} md={6} sm={6} className="text-center">
            <img
              src={newTeamImage}
              alt="new team"
              style={{ height: "200px", width: "200px", margin: "auto" }}
            />
          </Col>
          <Col lg={6} md={6} sm={6}>
            <p className="accepted-invitation-header">
              You've joined the {invitationAcceptedTeamName}!
            </p>
            <p className="dashboard-modal-body mb-4">
              Learn more about yourself and your teammates by visiting your Team
              page.
            </p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="d-block">
        <Row>
          <Col className="text-center">
            <button
              className="teal-button"
              style={{ width: "100%" }}
              onClick={handleViewTeamButton}
            >
              View Team
              <img
                src={rightArrow}
                alt="right arrow"
                className="link-button-arrow"
              />
            </button>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-2">
            <LinkButton
              text="Back to My Dashboard"
              showArrow=""
              handler={onHide}
              isLoading={false}
            />
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}
