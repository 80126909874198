import { call, put, all, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { request } from "utils/request";

export function* getUser(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/${action.payload}`;
    const getUserResponse = yield call(request, requestUrl);
    yield put(actions.getUserSuccess(getUserResponse));
  } catch (error) {
    yield put(
      actions.getUserError("There was a problem retrieving your user data.")
    );
  }
}

export function* globalSaga() {
  yield all([takeLatest(actions.getUser.type, getUser)]);
}
