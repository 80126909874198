import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";

// The initial state of the TeamList container
export const initialState: ContainerState = {
  team: null,
  allowedDomains: [],
  loadingAllowedDomains: false,
  errorMessage: null,
  loadingTeamMembers: false,
  removingTeamMember: false,
  resendingInvitation: false,
  membersInvited: false,
  invitingMembers: false,
  usersList: null,
  loadingUsers: false,
};

const teamListSlice = createSlice({
  name: "teamList",
  initialState,
  reducers: {
    getTeamMembers(state, action: PayloadAction<any>) {
      state.loadingTeamMembers = true;
    },
    getTeamMembersSuccess(state, action: PayloadAction<any>) {
      state.loadingTeamMembers = false;
      state.team = action.payload;
    },
    getTeamMembersError(state, action: PayloadAction<any>) {
      state.loadingTeamMembers = false;
      state.errorMessage = action.payload;
    },
    removeTeamMember: {
      reducer(state, action: PayloadAction<any>) {
        state.removingTeamMember = true;
      },
      prepare(teamMemberId, teamId) {
        return {
          payload: {
            teamMemberId: teamMemberId,
            teamId: teamId,
          },
        };
      },
    },
    removeTeamMemberSuccess(state, action: PayloadAction<any>) {
      state.removingTeamMember = false;
    },
    removeTeamMemberError(state, action: PayloadAction<any>) {
      state.removingTeamMember = false;
      state.errorMessage = action.payload;
    },
    getAllowedDomains(state, action: PayloadAction<any>) {
      state.loadingAllowedDomains = true;
    },
    getAllowedDomainsSuccess(state, action: PayloadAction<any>) {
      state.loadingAllowedDomains = false;
      state.allowedDomains = action.payload;
    },
    getAllowedDomainsError(state, action: PayloadAction<any>) {
      state.loadingAllowedDomains = false;
      state.errorMessage = action.payload;
    },
    inviteMembers: {
      reducer(state, action: PayloadAction<any>) {
        state.membersInvited = false;
        state.invitingMembers = true;
      },
      prepare(formData, teamId) {
        return {
          payload: {
            formData: formData,
            teamId: teamId,
          },
        };
      },
    },
    inviteMembersSuccess(state) {
      state.membersInvited = true;
      state.invitingMembers = false;
    },
    inviteMembersError(state, action: PayloadAction<any>) {
      state.errorMessage = action.payload;
      state.invitingMembers = false;
    },
    resetMembersInvited(state) {
      state.membersInvited = false;
    },
    resendInvitation(state, action: PayloadAction<any>) {
      state.resendingInvitation = true;
    },
    resendInviationSuccess(state) {
      state.resendingInvitation = false;
    },
    resendInvitationError(state, action: PayloadAction<any>) {
      state.resendingInvitation = false;

      state.errorMessage = action.payload;
    },
    getUsersList(state) {
      state.loadingUsers = true;
    },
    getUsersListSuccess(state, action: PayloadAction<any>) {
      state.loadingUsers = false;
      state.usersList = action.payload;
    },
    getUsersListError(state, action: PayloadAction<any>) {
      state.loadingUsers = false;
      state.errorMessage = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = teamListSlice;
