/**
 *
 * InviteCandidatesModal
 *
 */
import React, { useEffect, useState } from "react";
import { Modal, Col } from "react-bootstrap";
import { InviteFormData, TeamMembersData } from "app/containers/TeamPage/types";
import rightArrow from "resources/images/arrow-right-white.svg";
import invitationsSentIcon from "resources/images/email-invites.jpg";
import { AddUserInput } from "../AddUserInput";
import { LinkButton } from "../LinkButton";
import { Row } from "react-bootstrap";
import { isValidEmail } from "utils/helper_functions/isValidEmail";

interface Props {
  show: boolean;
  handleCancel: () => void;
  teamId?: number;
  allowedDomains: string[];
  handleInviteMembers: (formData: InviteFormData) => void;
  membersInvited: boolean;
  usersList: User[] | null;
  teamMembers: TeamMembersData[] | undefined;
  invitingMembers: boolean;
  teamName?: string;
}

interface User {
  emailAddress: string;
  firstName: string;
  lastName: string;
}

export function InviteCandidatesModal({
  show,
  handleCancel,
  teamId,
  allowedDomains,
  handleInviteMembers,
  membersInvited,
  usersList,
  teamMembers,
  invitingMembers,
  teamName,
}: Props) {
  /****************************************************************************
   * State Variables                                                          *
   ****************************************************************************/
  const [emailAddresses, setEmailAddresses] = useState<string[]>([]);
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [emptyEmailError, setEmptyEmailError] = useState<string | null>(null);
  const [tempInputValue, setTempInputValue] = useState("");
  const [tempInputValidationError, setTempInputValidationError] = useState("");
  const [existingMemberEmails, setExistingMemberEmails] = useState<string[]>(
    []
  );

  /****************************************************************************
   * Hooks                                                                    *
   ****************************************************************************/
  // in order to validate the entered objects based on whether or not the entered
  // email is already a Criteria user, we need to create an array of existing user
  // email addresses that we can then validate against.
  useEffect(() => {
    if (teamMembers) {
      const existingEmailAddresses = teamMembers.map((user) => {
        return user.emailAddress;
      });
      setExistingMemberEmails(existingEmailAddresses);
    }
  }, [teamMembers]);

  // remove temp validation error if the user has removed all temp input values
  useEffect(() => {
    if (tempInputValue === "" && tempInputValidationError !== "") {
      setTempInputValidationError("");
    }
  }, [tempInputValue, tempInputValidationError]);

  /****************************************************************************
   * Handlers                                                                 *
   ****************************************************************************/

  const handleCancelButton = () => {
    setEmailAddresses([]);
    setValidationErrors({});
    setTempInputValidationError("");
    handleCancel();
  };

  const handleInviteMembersButton = () => {
    // the following conditional in enacted if the user has typed in an entry and
    // clicked the Invite button without typing a delimiter
    if (tempInputValue) {
      if (isValidEmail(tempInputValue)) {
        if (
          !allowedDomains.includes(tempInputValue.split("@")[1].toLowerCase())
        ) {
          setTempInputValidationError(
            `${tempInputValue} is not included in your company domain.`
          );
          return false;
        }
        if (existingMemberEmails.includes(tempInputValue)) {
          setTempInputValidationError(
            `${tempInputValue} is already on the team.`
          );
          return false;
        }
      } else {
        setTempInputValidationError(`${tempInputValue} is not a valid email.`);
        return false;
      }
      const emailAddressesClone = emailAddresses;
      emailAddressesClone.push(tempInputValue);
      setEmailAddresses(emailAddressesClone);
    }

    if (emailAddresses.length > 0) {
      const formData = {
        teamName: teamName || "",
        subTestIds: [43],
        emails: emailAddresses,
      };

      handleInviteMembers(formData);
    } else {
      setEmptyEmailError("Please enter in at least one email address or user.");
    }
  };

  /* Style constants */
  const linkButtonStyle = {
    fontFamily: "Lato",
    fontSize: "14px",
    lineHeight: "1.43",
    color: "#425cc7",
  };

  return (
    <Modal show={show} onHide={handleCancel}>
      {!membersInvited ? (
        <>
          <Modal.Header closeButton className="invite-members-header">
            Add Team Members
          </Modal.Header>
          <Modal.Body>
            <AddUserInput
              usersList={usersList}
              allowedDomains={allowedDomains}
              emailAddresses={emailAddresses}
              setEmailAddresses={setEmailAddresses}
              validationErrors={validationErrors}
              setValidationErrors={setValidationErrors}
              existingTeamMembers={teamMembers}
              emptyEmailError={emptyEmailError}
              setEmptyEmailError={setEmptyEmailError}
              tempInputValue={tempInputValue}
              setTempInputValue={setTempInputValue}
              tempInputValidationError={tempInputValidationError}
            />
            {emptyEmailError ? (
              <p className="mt-0 ml-1 error-message">{emptyEmailError}</p>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="text-center m-auto order-12 order-md-1"
              >
                <LinkButton
                  text="Cancel"
                  showArrow=""
                  handler={handleCancelButton}
                  isLoading={false}
                  style={linkButtonStyle}
                />

                <button
                  className="send-invites-button ml-5"
                  disabled={Object.keys(validationErrors).length > 0}
                  onClick={handleInviteMembersButton}
                >
                  Send Invitations
                  <img
                    src={rightArrow}
                    alt="right arrow"
                    className="create-team-arrow"
                  />
                </button>
              </Col>
            </Row>
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Header closeButton className="invite-members-header">
            Invitations Sent
          </Modal.Header>
          <Modal.Body>
            <img
              src={invitationsSentIcon}
              alt="invitationsSent"
              style={{
                float: "left",
                width: "120px",
                height: "120px",
                marginRight: "32px",
              }}
            />
            <Col className="invite-members-text pt-2">
              Once invited team members have completed their personality
              assessment, you’ll be able to view their results, as well as your
              updated Team Personality.
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Col>
              <button
                className="invite-members-close-button"
                onClick={handleCancelButton}
              >
                Close
              </button>
            </Col>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}
