import React from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectSuggestions } from "../selectors";

export default function Suggestions() {
  const suggestions = useSelector(selectSuggestions);
  return (
    <div className="trait-summary-card">
      {/* eslint-disable-next-line */}
      <a className="anchor" id="DevelopmentSuggestions"></a>
      <Row>
        <Col className="d-flex">
          <p className="trait-name mb-4">Development Suggestions</p>
        </Col>
      </Row>
      <Row>
        {suggestions?.map((item) => (
          <Col lg={6} md={6} sm={6} className="mb-3" key={item.title}>
            <div
              style={{
                height: "32px",
                width: "148px",
                display: "flex",
                borderRadius: "16px",
                backgroundColor: `${item.color}`,
                color: "#FFF",
              }}
              className="mb-3"
            >
              <span style={{ margin: "auto" }}>{item.title}</span>
            </div>
            {item.suggestions.map((suggest, index) => (
              <p key={index} className="individual-personality-text">
                {suggest}
              </p>
            ))}
          </Col>
        ))}
      </Row>
    </div>
  );
}
