import React from "react";
import { Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import tipsIcon from "resources/images/icon-tips.svg";

interface Props {
  anchorId: string;
  icon: string;
  lastSection: boolean;
  questions: string[] | undefined;
  tips: string[];
  title: string;
}

export const ManagerGuideSectionCard = ({
  anchorId,
  icon,
  lastSection,
  questions,
  tips,
  title
}: Props) => {
  return (
    <Col
      lg={12}
      md={12}
      sm={12}
      className={
        isMobile
          ? "pt-2"
          : "d-flex align-items-stretch p-0 mt-3 manager-guide-section-card-container"
      }
      style={
        isMobile
          ? {
              borderBottom: lastSection ? "none" : "1px solid #d8d8d8",
              paddingBottom: lastSection ? "5px" : "25px"
            }
          : {}
      }
    >
      {/* eslint-disable-next-line */}
      <a className="anchor" id={anchorId}></a>
      <Col className="p-0">
        {isMobile ? (
          <div className="mb-3">
            <img src={icon} alt={title} className="mr-3 nav-image" />
            <span className="nav-text">{title}</span>
          </div>
        ) : (
          <div className="mb-2 manager-guide-section-card-title">{title}</div>
        )}
        <div className={isMobile ? "" : "manager-guide-section-card-content"}>
          <div>
            <p className="tips-title">
              <img
                src={tipsIcon}
                alt="tips icon"
                height="19px"
                width="19px"
                className="mr-1 "
              />
              <span>Tip{tips.length > 1 ? `s` : ``}</span>
            </p>
            <div>
              {tips ? tips.map((tip, index) => <p key={index}>{tip}</p>) : null}
            </div>
          </div>
          <div className="manager-guide-conversation">
            <p className="manager-guide-conversation-title">
              Conversation Starters
            </p>
            <ul className="manager-guide-conversation-content">
              {questions
                ? questions.map((question, index) => (
                    <li key={index}>{question}</li>
                  ))
                : null}
            </ul>
          </div>
        </div>
      </Col>
    </Col>
  );
};
