import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";

// The initial state of the IndividualPersonality container
export const initialState: ContainerState = {
  user: null,
  workplaceInsightsPdf: null,
  loadingUser: false,
  workplaceInsightsLoaded: false,
  downloadingWorkplaceInsights: false,
  workplaceInsightsData: null,
  noPermission: false,
  noEPP: false,
};

const individualPersonalitySlice = createSlice({
  name: "individualPersonality",
  initialState,
  reducers: {
    getUser(state, action: PayloadAction<any>) {
      state.loadingUser = true;
    },
    getUserSuccess(state, action: PayloadAction<any>) {
      state.loadingUser = false;
      state.user = action.payload;
    },
    getUserError(state, action: PayloadAction<any>) {
      state.loadingUser = false;
    },

    getWorkplaceInsights: {
      reducer(state, action: PayloadAction<any>) {
        state.workplaceInsightsLoaded = false;
      },
      prepare(userAccountId, format) {
        return {
          payload: {
            userAccountId: userAccountId,
            format: format,
          },
        };
      },
    },
    getWorkplaceInsightsSuccess: {
      reducer(state, action: PayloadAction<any>) {
        state.workplaceInsightsLoaded = true;
        if (action.payload) {
          if (action.payload.response === null) {
            state.noEPP = true;
          } else {
            if (action.payload.format === "json") {
              state.workplaceInsightsData = action.payload.response;
            } else {
              state.workplaceInsightsPdf = action.payload.response;
            }
          }
        }
      },
      prepare(response, format) {
        return {
          payload: {
            response: response,
            format: format,
          },
        };
      },
    },
    getWorkplaceInsightsError(state, action: PayloadAction<any>) {
      if (action.payload === 403) {
        state.workplaceInsightsLoaded = true;
        state.noPermission = true;
      } else {
        state.workplaceInsightsLoaded = false;
      }
    },
    resetWorkplaceInsightsPdf(state) {
      state.workplaceInsightsPdf = initialState.workplaceInsightsPdf;
    },
    cleanUp(state) {
      state.user = initialState.user;
      state.loadingUser = initialState.loadingUser;
      state.workplaceInsightsLoaded = initialState.workplaceInsightsLoaded;
      state.workplaceInsightsData = initialState.workplaceInsightsData;
      state.workplaceInsightsPdf = initialState.workplaceInsightsPdf;
    },
  },
});

export const { actions, reducer, name: sliceKey } = individualPersonalitySlice;
