import { all, call, put, takeLatest } from "redux-saga/effects";
import { request } from "utils/request";
import { actions } from "./slice";
import { PayloadAction } from "@reduxjs/toolkit";
import { redirectUserFromAccountSetup } from "./helperFunctions";

export function* createAccount(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/setupAccount`;
    const createAccountResponse = yield call(request, requestUrl, {
      method: "PUT",
      body: JSON.stringify(action.payload)
    });
    localStorage.setItem("tmg-tkn", createAccountResponse.jwt);
    yield put(actions.createAccountSuccess(createAccountResponse.jwt));
  } catch (error) {
    let errorMessage;
    // if (error.response?.status === 400) errorMessage = "This link has expired";
    // if (error.response?.status === 403)
    errorMessage = "This account requires SSO";
    yield put(actions.createAccountError(errorMessage));
  }
}

export function* acceptInvitation(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/teamMembers/accept`;
    yield call(request, requestUrl, {
      method: "POST",
      body: JSON.stringify({
        token: action.payload.teamToken
      })
    });

    const { authenticated, teamName, teamId } = action.payload;
    // if `redirectUserFromAccountSetup` returns false, it means this is a new user who has to set up account
    if (!redirectUserFromAccountSetup(authenticated, teamName, teamId, false)) {
      yield put(actions.acceptInvitationSuccess());
    }
  } catch (error) {
    yield put(actions.acceptInvitationError(error.response.status));
  }
}

export function* accountSetupSaga() {
  yield all([
    takeLatest(actions.createAccount.type, createAccount),
    takeLatest(actions.acceptInvitation.type, acceptInvitation)
  ]);
}
