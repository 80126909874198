import { call, put, all, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { request } from "utils/request";
import { PreviewCSVResponse, ProcessCSVResponse } from "./types";

export function* previewCSV(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/previewCSV`;
    const previewCSVResponse: PreviewCSVResponse = yield call(
      request,
      requestUrl,
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );
    yield put(actions.previewCSVSuccess(previewCSVResponse));
  } catch (error) {
    yield put(actions.previewCSVError(error));
  }
}

export function* processCSV(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/processCSV`;
    const processCSVResponse: ProcessCSVResponse = yield call(
      request,
      requestUrl,
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );
    yield put(actions.processCSVSuccess(processCSVResponse));
  } catch (error) {
    yield put(actions.processCSVError(error));
  }
}

export function* importCSVSaga() {
  yield all([
    takeLatest(actions.previewCSV.type, previewCSV),
    takeLatest(actions.processCSV.type, processCSV),
  ]);
}
