import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => state.myProfile || initialState;

export const selectMyProfile = createSelector(
  [selectDomain],
  myProfileState => myProfileState
);

export const selectUser = createSelector(
  [selectDomain],
  myProfileState => myProfileState.user
);

export const selectPendingTests = createSelector(
  [selectDomain],
  myProfileState => myProfileState.pendingTests
);

export const selectPersonalityProfile = createSelector(
  [selectDomain],
  myProfileState => myProfileState.personalityProfile
);

export const selectPersonalityProfileLoaded = createSelector(
  [selectDomain],
  myProfileState => myProfileState.personalityProfileLoaded
);

export const selectShowPersonalityProfile = createSelector(
  [selectDomain],
  myProfileState => myProfileState.showPersonalityProfile
);

export const selectNoEPP = createSelector(
  [selectDomain],
  myProfileState => myProfileState.noEPP
);

export const selectNoPermission = createSelector(
  [selectDomain],
  myProfileState => myProfileState.noPermission
);

export const selectLoggedInUserPendingTests = createSelector(
  [selectDomain],
  myProfileState => myProfileState.loggedInUserPendingTests
);

export const selectCollaborationGuidePDF = createSelector(
  [selectDomain],
  myProfileState => {
    return {
      collaborationGuidePDF: myProfileState.collaborationGuidePDF,
      loadingCollaborationGuidePDF: myProfileState.loadingCollaborationGuidePDF
    };
  }
);

export const selectManagerGuide = createSelector(
  [selectDomain],
  myProfileState => myProfileState.managerGuide
);

export const selectManagerGuideLoaded = createSelector(
  [selectDomain],
  myProfileState => {
    if (myProfileState.user?.companyAccountId === 0) {
      return true;
    }
    return myProfileState.managerGuideLoaded;
  }
);

export const selectShowManagerGuide = createSelector(
  [selectDomain],
  myProfileState => {
    return myProfileState.showManagerGuide;
  }
);

export const selectManagerGuidePDF = createSelector(
  [selectDomain],
  myProfileState => {
    return {
      managerGuidePDF: myProfileState.managerGuidePDF,
      loadingManagerGuidePDF: myProfileState.loadingManagerGuidePDF
    };
  }
);

export const selectHsVersion = createSelector(
  [selectDomain],
  myProfileState => myProfileState.hsVersion
);

export const selectUserCompanyAccountId = createSelector(
  [selectDomain],
  myProfileState => myProfileState.user?.companyAccountId
);
