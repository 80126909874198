import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getTraitDisplay } from "../helper-functions";
import { selectWorkStyle } from "../selectors";
import { WorkStylesType } from "../types";

interface Props {}

export default function WorkStyles() {
  const workStyle: WorkStylesType[] = useSelector(selectWorkStyle);
  const workStylesArray = workStyle ? getTraitDisplay(workStyle) : null;
  return (
    <div className="trait-summary-card">
      {/* eslint-disable-next-line */}
      <a className="anchor" id="WorkCommunicationInteractionStyle"></a>
      <Row>
        <Col className="d-flex">
          <p className="trait-name mb-4">
            Work, Communication & Interaction Style
          </p>
        </Col>
      </Row>
      <Row>{workStylesArray}</Row>
    </div>
  );
}
