import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";

export const initialState: ContainerState = {
  loadingPreview: false,
  previewCSVResponse: null,
  processingCSV: false,
  processCSVResponse: null,
  safeToRedirect: false,
};

const importCSVSlice = createSlice({
  name: "importCSV",
  initialState,
  reducers: {
    previewCSV(state, action: PayloadAction<any>) {
      state.loadingPreview = true;
    },
    previewCSVSuccess(state, action: PayloadAction<any>) {
      state.loadingPreview = false;
      state.previewCSVResponse = action.payload;
    },
    previewCSVError(state, action: PayloadAction<any>) {
      state.loadingPreview = false;
    },
    processCSV(state, action: PayloadAction<any>) {
      state.processingCSV = true;
    },
    processCSVSuccess(state, action: PayloadAction<any>) {
      state.processingCSV = false;
      state.processCSVResponse = action.payload;
      state.safeToRedirect = true;
    },
    processCSVError(state, action: PayloadAction<any>) {
      state.processingCSV = false;
    },
    resetState(state) {
      state.previewCSVResponse = null;
      state.safeToRedirect = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = importCSVSlice;
