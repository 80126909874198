import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => state.adminConsole || initialState;

export const selectAdminConsole = createSelector(
  [selectDomain],
  adminConsoleState => adminConsoleState
);

export const selectTeams = createSelector(
  [selectDomain],
  adminConsoleState => adminConsoleState.teams
);

export const selectUsers = createSelector(
  [selectDomain],
  adminConsoleState => adminConsoleState.users
);

export const selectExistingUsers = createSelector(
  [selectDomain],
  adminConsoleState => adminConsoleState.existingUsers
);

export const selectInviteSentSuccessfully = createSelector(
  [selectDomain],
  adminConsoleState => adminConsoleState.inviteSentSuccessfully
);

export const selectTmgAllowUsersCreateTeam = createSelector(
  [selectDomain],
  adminConsoleState => adminConsoleState.tmgAllowUsersCreateTeam
);

export const selectAllowUsersShareInviteLink = createSelector(
  [selectDomain],
  adminConsoleState => adminConsoleState.allowUsersShareInviteLink
);

export const selectAllowLinkResults = createSelector(
  [selectDomain],
  adminConsoleState => adminConsoleState.allowLinkResults
);

export const selectHasSSOHardEnabled = createSelector(
  [selectDomain],
  adminConsoleState => adminConsoleState.hasSSOHardEnabled
);

export const selectAllowedDomains = createSelector(
  [selectDomain],
  adminConsoleState => adminConsoleState.allowedDomains
);

export const selectAllUsersInvited = createSelector(
  [selectDomain],
  adminConsoleState => adminConsoleState.allUsersInvited
);

export const selectInviteLink = createSelector(
  [selectDomain],
  adminConsoleState => adminConsoleState.inviteLink
);

export const selectNewLinkGenerated = createSelector(
  [selectDomain],
  adminConsoleState => adminConsoleState.newLinkGenerated
);

export const selectHsVersion = createSelector(
  [selectDomain],
  adminConsoleState => adminConsoleState.hsVersion
);

export const selectSearchTerm = createSelector(
  [selectDomain],
  adminConsoleState => adminConsoleState.searchTerm
);

export const selectAccessRole = createSelector(
  [selectDomain],
  adminConsoleState => adminConsoleState.accessRole
);

export const selectUserStatus = createSelector(
  [selectDomain],
  adminConsoleState => adminConsoleState.userStatus
);

export const selectHasAutomaticTeamsOn = createSelector(
  [selectDomain],
  adminConsoleState => adminConsoleState.hasAutomaticTeamsOn
);
