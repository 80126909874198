/**
 *
 * MyResults
 *
 */

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./slice";
import {
  selectMyProfile,
  selectPendingTests,
  selectPersonalityProfile,
  selectUser,
  selectShowPersonalityProfile,
  selectPersonalityProfileLoaded,
  selectNoPermission,
  selectNoEPP,
  selectLoggedInUserPendingTests,
  selectCollaborationGuidePDF,
  selectManagerGuideLoaded,
  selectManagerGuide,
  selectShowManagerGuide,
  selectManagerGuidePDF,
  selectHsVersion,
  selectUserCompanyAccountId
} from "./selectors";
import {
  selectUserAccountId,
  selectDirectReports,
  selectIsAdmin,
  selectCompanyAccountId
} from "app/containers/Global/selectors";
import { myProfileSaga } from "./saga";
import { useParams, useLocation } from "react-router-dom";
import { HeadlinePanel } from "./HeadlinePanel";
import { Col, Container, Row } from "react-bootstrap";
import { Tabs, Tab } from "@material-ui/core";
import "./styles.css";
import TabPanel from "./TabPanel";
import { IndividualPersonality } from "../IndividualPersonality";
import { PendingTests, PersonalityProfile, ManagerGuidesData } from "./types";
import UploadPhotoModal from "./UploadPhotoModal";
import { CollaborationGuide } from "./CollaborationGuide";
import { SampleDataBanner } from "app/components/SampleDataBanner";
import { ManagerGuide } from "./ManagerGuide/ManagerGuide";
import useDownloadPDF from "utils/custom_hooks/useDownloadPDF";

interface Props {}

export function MyProfile(props: Props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: myProfileSaga });

  const location = useLocation();

  // state variables
  const [showUploadPhotoModal, setShowUploadPhotoModal] = useState(false);

  const myProfile = useSelector(selectMyProfile);
  const { collaborationGuidePDF } = useSelector(selectCollaborationGuidePDF);
  const user: any = useSelector(selectUser);
  const pendingTests: PendingTests[] | null = useSelector(selectPendingTests);
  const personalityProfile: PersonalityProfile | null = useSelector(
    selectPersonalityProfile
  );
  const personalityProfileLoaded = useSelector(selectPersonalityProfileLoaded);
  const showPersonalityProfile: boolean = useSelector(
    selectShowPersonalityProfile
  );
  const noPermission: boolean = useSelector(selectNoPermission);
  const noEPP: boolean = useSelector(selectNoEPP);
  const loggedInUserPendingTests = useSelector(selectLoggedInUserPendingTests);
  /* get the logged in userAccountId for comparison (to take assessments, etc.) */
  const loggedInUserAccountId = useSelector(selectUserAccountId);
  const loggedInUserCompanyAccountId = useSelector(selectCompanyAccountId);
  const hsVersion = useSelector(selectHsVersion);
  const directReports = useSelector(selectDirectReports);
  const managerGuide: ManagerGuidesData | null = useSelector(
    selectManagerGuide
  );
  const managerGuideLoaded: boolean = useSelector(selectManagerGuideLoaded);
  const showManagerGuide: boolean = useSelector(selectShowManagerGuide);
  const isAdmin: boolean = useSelector(selectIsAdmin);
  const { managerGuidePDF } = useSelector(selectManagerGuidePDF);

  const dispatch = useDispatch();
  const { userAccountId }: any = useParams();
  const userCompanyAccountId = useSelector(selectUserCompanyAccountId);

  /****************************************************************************
   * State                                                                    *
   ****************************************************************************/

  // if the logged-in user is a manager to the profile user, the initial value 0 will land on the manager guide
  // if the logged-in is not a manager to the profile user, the initial value 0 will land on the collaboration guide
  const [value, setValue] = useState(
    user?.manager === loggedInUserAccountId ||
      userAccountId !== loggedInUserAccountId
      ? 0
      : 1
  );

  /****************************************************************************
   * Hooks                                                                    *
   ****************************************************************************/
  useEffect(() => {
    dispatch(actions.getUser(userAccountId));
    // if the viewer is viewing their own profile, we only show the Workplace
    // Insights tab, so this API call is not needed.
    if (Number(userAccountId) !== loggedInUserAccountId) {
      dispatch(actions.getPersonalityProfile(userAccountId));
    } else {
      dispatch(actions.getPendingTests());
    }

    return () => {
      dispatch(actions.cleanUp());
    };
  }, [userAccountId, loggedInUserAccountId, dispatch]);

  useEffect(() => {
    if (loggedInUserCompanyAccountId) {
      dispatch(actions.getCompanyData(loggedInUserCompanyAccountId));
    }
  }, [dispatch, loggedInUserCompanyAccountId]);

  useEffect(() => {
    if (
      Number(userAccountId) !== loggedInUserAccountId &&
      (isAdmin || directReports) &&
      hsVersion > 1 &&
      userCompanyAccountId !== undefined
    ) {
      const directReport =
        directReports &&
        directReports.find(
          directReport => directReport.userAccountId === Number(userAccountId)
        );

      if (directReport && directReport.viewedManagerGuide === 0) {
        const payload = {
          reportUserAccountId: Number(userAccountId)
        };
        dispatch(actions.updateViewManagerGuide({ formData: payload }));
      }
      if (directReport || isAdmin) {
        dispatch(actions.getManagerGuide(userAccountId));
      }
    }
  }, [
    userAccountId,
    loggedInUserAccountId,
    directReports,
    isAdmin,
    dispatch,
    hsVersion,
    userCompanyAccountId
  ]);

  const onManagerGuideTabAndHasPermission =
    value === 0 && (loggedInUserAccountId === user?.manager || isAdmin);

  useDownloadPDF(
    onManagerGuideTabAndHasPermission ? managerGuidePDF : collaborationGuidePDF,
    onManagerGuideTabAndHasPermission ? "managerGuide" : "collaborationGuide",
    onManagerGuideTabAndHasPermission
      ? actions.resetManagerGuidePDF
      : actions.resetcollaborationGuidePDF
  );

  /****************************************************************************
   * Handlers                                                                 *
   ****************************************************************************/
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  /****************************************************************************
   * Upload Modal Handlers                                                    *
   ****************************************************************************/
  const handleUploadClick = () => {
    setShowUploadPhotoModal(true);
  };

  const uploadModalOnHide = () => {
    setShowUploadPhotoModal(false);
  };

  const handlePhotoUpload = (formData: any): void => {
    dispatch(actions.uploadImage(userAccountId, formData));
  };

  const handlePhotoDeletion = () => {
    dispatch(actions.deleteImage(userAccountId));
  };

  const downloadReport = (reportToDownload: string) => {
    switch (reportToDownload) {
      case "collaborationGuide":
        dispatch(actions.getCollaborationGuidePDF(userAccountId));
        break;
      case "managerGuide":
        dispatch(actions.getManagerGuidePDF(userAccountId));
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Helmet>
        <title>{user ? `${user?.firstName} ${user?.lastName}` : ""}</title>
        <meta name="description" content="My Profile" />
      </Helmet>
      {user?.companyAccountId === 0 && <SampleDataBanner />}
      <div>
        {!pendingTests &&
        myProfile.userLoaded &&
        myProfile.companyDataLoaded ? (
          <>
            <Container fluid="lg">
              <Row>
                <Col>
                  <HeadlinePanel
                    user={user}
                    handleUploadClick={handleUploadClick}
                    location={location}
                  />
                </Col>
              </Row>
            </Container>
            <Container className="mt-3">
              <Row>
                <Col>
                  {loggedInUserAccountId !== Number(userAccountId) ? (
                    (loggedInUserAccountId === user?.manager || isAdmin) &&
                    hsVersion > 1 ? (
                      <Tabs
                        value={value}
                        indicatorColor="primary"
                        onChange={handleChange}
                        aria-label="profile options"
                        className="tabs-override"
                        variant="scrollable"
                        scrollButtons="on"
                      >
                        <Tab
                          label="Manager Guide"
                          disableRipple={true}
                          style={{ width: "200px" }}
                        />
                        <Tab
                          label="Collaboration Guide"
                          disableRipple={true}
                          style={{ width: "200px" }}
                        />
                        <Tab
                          label="Workplace Insights"
                          disableRipple={true}
                          style={{ width: "200px" }}
                        />
                      </Tabs>
                    ) : (
                      <Tabs
                        value={value}
                        indicatorColor="primary"
                        onChange={handleChange}
                        aria-label="profile options"
                        className="tabs-override"
                        variant="scrollable"
                        scrollButtons="on"
                      >
                        <Tab
                          label="Collaboration Guide"
                          disableRipple={true}
                          style={{ width: "200px" }}
                        />
                        <Tab
                          label="Workplace Insights"
                          disableRipple={true}
                          style={{ width: "200px" }}
                        />
                      </Tabs>
                    )
                  ) : null}
                  {/* Saving this in case we want to have it returned later:
                      <NotableTraitsContainer
                      notableTraits={notableTraits}
                      user={user}
                   /> */}
                </Col>
              </Row>
              <Row>
                <Col>
                  {loggedInUserAccountId !== Number(userAccountId) ? (
                    (loggedInUserAccountId === user?.manager || isAdmin) &&
                    hsVersion > 1 ? (
                      <>
                        <TabPanel value={value} index={0}>
                          <ManagerGuide
                            managerGuide={managerGuide}
                            managerGuideLoaded={managerGuideLoaded}
                            noEPP={noEPP}
                            showManagerGuide={showManagerGuide}
                            user={user}
                            handleViewWorkInsights={setValue}
                            downloadReport={downloadReport}
                          />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <CollaborationGuide
                            personalityProfile={personalityProfile}
                            showPersonalityProfile={showPersonalityProfile}
                            noPermission={noPermission}
                            noEPP={noEPP}
                            personalityProfileLoaded={personalityProfileLoaded}
                            user={user}
                            downloadReport={downloadReport}
                            isSample={user?.companyAccountId === 0}
                          />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                          <IndividualPersonality
                            userAccountId={userAccountId}
                            loggedInUserPendingTests={loggedInUserPendingTests}
                          />
                        </TabPanel>
                      </>
                    ) : (
                      <>
                        <TabPanel value={value} index={0}>
                          <CollaborationGuide
                            personalityProfile={personalityProfile}
                            showPersonalityProfile={showPersonalityProfile}
                            noPermission={noPermission}
                            noEPP={noEPP}
                            personalityProfileLoaded={personalityProfileLoaded}
                            user={user}
                            downloadReport={downloadReport}
                            isSample={user?.companyAccountId === 0}
                          />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <IndividualPersonality
                            userAccountId={userAccountId}
                            loggedInUserPendingTests={loggedInUserPendingTests}
                          />
                        </TabPanel>
                      </>
                    )
                  ) : (
                    <IndividualPersonality
                      userAccountId={userAccountId}
                      loggedInUserPendingTests={loggedInUserPendingTests}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          </>
        ) : null}
        <UploadPhotoModal
          show={showUploadPhotoModal}
          onHide={uploadModalOnHide}
          profilePicture={user?.profilePictureBase64}
          profilePictureFileType={user?.profilePictureFileType}
          profilePictureFileName={user?.profilePictureFileName}
          handlePhotoUpload={handlePhotoUpload}
          handlePhotoDeletion={handlePhotoDeletion}
        />
      </div>
    </div>
  );
}
