import React, { useState } from "react";
import { useSelector } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { selectTeams } from "app/containers/AdminConsole/selectors";
import { getComparator, getTeamLead, stableSort } from "../helper-functions";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from "@material-ui/core";
import TablePagination from "@mui/material/TablePagination";
import TeamsTableDropdown from "./TeamsTableDropdown";

interface Props {
  searchValue: string;
  teamType: string;
}

interface Data {
  teamName: string;
  teamLead: string | null;
  numberOfMembers: number;
  teamId: string;
  teamMembers: TeamMembers[] | null;
  automatic: number;
}

interface HeadCell {
  id: keyof Data;
  label: string;
}

interface TeamMembers {
  dateAccepted: string;
  emailAddress: string;
  firstName: string;
  isTeamLead: number;
  jobTitle: string | null;
  lastName: string;
  teamId: number;
  teamMemberId: number;
  userAccountId: number;
}

type Order = "asc" | "desc";

const headCells: HeadCell[] = [
  { id: "teamName", label: "Team Name" },
  { id: "teamLead", label: "Team Lead" },
  { id: "numberOfMembers", label: "# of Team Members" }
];

function createData(
  teamName: string,
  teamLead: string | null,
  numberOfMembers: number,
  teamId: string,
  teamMembers: TeamMembers[] | null,
  automatic: number
): Data {
  return {
    teamName,
    teamLead,
    numberOfMembers,
    teamId,
    teamMembers,
    automatic
  };
}

const TeamsTable = ({ searchValue, teamType }: Props) => {
  // state variables
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("teamName");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);

  // get access to the history hook
  const history = useHistory();

  // retrieve the object of teams in the redux store
  const teams = useSelector(selectTeams);

  // create the array of rows to be displayed in the table
  let rows;
  if (teams) {
    rows = Object.keys(teams).map(teamId => {
      const teamLead = getTeamLead(teams[teamId].teamMembers) || "";
      return createData(
        teams[teamId].teamName,
        teamLead,
        teams[teamId].teamMembers.length,
        teamId,
        teams[teamId].teamMembers,
        teams[teamId].automatic
      );
    });
  }

  // filter the rows based on the search value and team type
  const filteredRows = rows?.filter(row => {
    if (
      String(row.teamName).toLowerCase().includes(searchValue.toLowerCase()) ||
      String(row.teamLead).toLowerCase().includes(searchValue.toLowerCase())
    ) {
      if (
        (teamType === "automatic" && row.automatic === 1) ||
        teamType === "all"
      ) {
        return true;
      }
    }
    return false;
  });

  // handlers
  const handleNumberOfMembersClick = (teamId: string) => {
    history.push(`/team_list/${teamId}`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const teamMembersList = teamMembers => {
    let teamMembersCopy = [...teamMembers];
    const allTeamMembers = teamMembersCopy
      .sort((a, b) => {
        if (
          a.firstName.trim().toLowerCase() === b.firstName.trim().toLowerCase()
        ) {
          return a.lastName
            .trim()
            .toLowerCase()
            .localeCompare(b.lastName.trim().toLowerCase());
        } else {
          return a.firstName
            .trim()
            .toLowerCase()
            .localeCompare(b.firstName.trim().toLowerCase());
        }
      })
      .map((member, index) => {
        return (
          <p
            key={member.userAccountId}
            style={{
              margin: "0",
              lineHeight: "1.79",
              textDecoration: "none",
              color: "black"
            }}
            className="popover-text"
          >
            {`${member.firstName} ${member.lastName}`}
          </p>
        );
      });

    return (
      <ul style={{ padding: "10px 0 0 17px", fontSize: "14px" }}>
        {allTeamMembers}
      </ul>
    );
  };

  /****************************************************************************
   * Sorting Functions                                                        *
   ****************************************************************************/
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    handleRequestSort(event, property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer className="table-container">
        <Table
          aria-labelledby="Teams Table"
          size="medium"
          aria-label="teams table"
          stickyHeader={true}
        >
          <colgroup>
            <col style={{ width: "30%" }} />
            <col style={{ width: "30%" }} />
            <col style={{ width: "30%" }} />
            <col style={{ width: "10%" }} />
          </colgroup>
          <TableHead className="table-header">
            <TableRow>
              {headCells.map(headCell => (
                <TableCell key={headCell.id} align="left">
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell key={"teamId"} align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              ? stableSort(filteredRows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index} style={{ height: "75px" }}>
                      <TableCell component="th" scope="row" align="left">
                        <a
                          href={`/team/${row.teamId}`}
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            height: "20px",
                            color: "black"
                          }}
                        >
                          {row.teamName}
                        </a>
                        {row.automatic === 1 && (
                          <span
                            className={"badge automatic-badge"}
                            style={{ width: "75px" }}
                          >
                            Automatic
                          </span>
                        )}
                      </TableCell>
                      <TableCell align="left">{row.teamLead}</TableCell>
                      <TableCell align="left">
                        {row.numberOfMembers > 0 ? (
                          <div>
                            <OverlayTrigger
                              placement="right-start"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Popover
                                  id="teams-popover"
                                  className="teams-popover"
                                >
                                  {teamMembersList(row.teamMembers)}
                                </Popover>
                              }
                              trigger={["hover", "focus"]}
                            >
                              <button
                                onClick={() =>
                                  handleNumberOfMembersClick(String(row.teamId))
                                }
                                className="team-member-button"
                              >
                                {row.numberOfMembers}
                              </button>
                            </OverlayTrigger>
                          </div>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TeamsTableDropdown
                        automatic={row.automatic === 1}
                        teamId={row.teamId}
                      />
                    </TableRow>
                  ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={filteredRows ? filteredRows.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={"table-pagination"}
      />
    </>
  );
};

export default TeamsTable;
