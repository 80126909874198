import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => state.teamSettings || initialState;

export const selectTeamSettings = createSelector(
  [selectDomain],
  (teamSettingsState) => teamSettingsState
);
export const selectTeamName = createSelector(
  [selectDomain],
  (teamSettingsState) => teamSettingsState.teamName
);

export const selectTeamMembers = createSelector(
  [selectDomain],
  (teamSettingsState) => teamSettingsState.teamMembers
);

// export const selectViewAllWorkplaceInsights = createSelector(
//   [selectDomain],
//   (teamSettingsState) => teamSettingsState.viewAllWorkplaceInsights
// );

export const selectTeamLeadChangeSuccessful = createSelector(
  [selectDomain],
  (teamSettingsState) => teamSettingsState.teamLeadChangeSuccessful
);

export const selectAllowedDomains = createSelector(
  [selectDomain],
  (teamSettingsState) => teamSettingsState.allowedDomains
);

export const selectTeamLeadErrorMessage = createSelector(
  [selectDomain],
  (teamSettingsState) => teamSettingsState.teamLeadErrorMessage
);

export const selectUsersList = createSelector(
  [selectDomain],
  (createTeamState) => createTeamState.usersList
);
