import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { NavigationBar } from "./NavigationBar";
import { User, ManagerGuidesData } from "../types";
import { NotAvailable } from "../NotAvailable";
import { ManagerGuideNavTitles } from "../helpers";
import { ManagerGuideSectionCard } from "./ManagerGuideSectionCard";
import { ManagerGuideHeader } from "./ManagerGuideHeader";
import downloadIcon from "resources/images/download.svg";
import { useSelector } from "react-redux";
import { selectManagerGuidePDF } from "../selectors";

interface Props {
  managerGuide: ManagerGuidesData | null;
  managerGuideLoaded: boolean;
  noEPP: boolean;
  showManagerGuide: boolean;
  user: User | null;
  handleViewWorkInsights: (newValue: number) => void;
  downloadReport: (reportToDownload: string) => void;
}

export function ManagerGuide({
  managerGuide,
  managerGuideLoaded,
  noEPP,
  showManagerGuide,
  user,
  handleViewWorkInsights,
  downloadReport
}: Props) {
  const { loadingManagerGuidePDF } = useSelector(selectManagerGuidePDF);
  return (
    <Container className="mt-3 mb-5">
      {managerGuideLoaded ? (
        showManagerGuide ? (
          <>
            {!isMobile ? (
              <Row>
                <Col className="text-right mb-3">
                  <button
                    className="download-button"
                    onClick={() => downloadReport("managerGuide")}
                    style={{
                      color: "#001F4E"
                    }}
                    disabled={loadingManagerGuidePDF}
                  >
                    <img
                      className="mr-2 mb-1"
                      src={downloadIcon}
                      alt="download report"
                    />
                    {loadingManagerGuidePDF ? "Loading..." : "Download"}
                  </button>
                </Col>
              </Row>
            ) : null}
            <Card className="profile-card">
              <Card.Body>
                <Row>
                  <ManagerGuideHeader
                    user={user}
                    handleViewWorkInsights={handleViewWorkInsights}
                  />
                </Row>
                <Row className="mt-4">
                  {!isMobile ? (
                    <Col lg={4} md={4}>
                      <NavigationBar />
                    </Col>
                  ) : null}
                  <Col lg={8} md={8}>
                    {ManagerGuideNavTitles.map(
                      (ManagerGuideSectionObject, index) => {
                        const ManagerGuideSectionKey =
                          ManagerGuideSectionObject.key;
                        const managerTipsArray: string[] = managerGuide
                          ? managerGuide[ManagerGuideSectionKey].tips
                          : [];
                        const managerQuestionsArray: string[] = managerGuide
                          ? managerGuide[ManagerGuideSectionKey].questions
                          : [];

                        return (
                          <ManagerGuideSectionCard
                            anchorId={ManagerGuideSectionKey}
                            icon={ManagerGuideSectionObject.icon}
                            key={ManagerGuideSectionKey}
                            lastSection={
                              index === ManagerGuideNavTitles.length - 1
                            }
                            questions={managerQuestionsArray}
                            tips={managerTipsArray}
                            title={ManagerGuideSectionObject.title}
                          />
                        );
                      }
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </>
        ) : (
          <div className="mt-4">
            <NotAvailable
              text="Manager Guide"
              noPermission={
                false
              } /*DEV NOTE: Will not address permissions for Manager Guide*/
              noEPP={noEPP}
              firstName={user?.firstName}
              isSample
              isManagerGuide
            />
          </div>
        )
      ) : null}
    </Container>
  );
}
