/**
 *
 * UserWelcome
 *
 */
import React from "react";
import { Col, Media } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import { Avatar } from "app/components/Avatar";
import { LinkButton } from "app/components/LinkButton";
import inviteCoworkerIcon from "resources/images/invite-coworker-icon.svg";
import "../styles.css";
import { selectIsAdmin } from "app/containers/Global/selectors";
import { useSelector } from "react-redux";

interface Props {
  user: User | null;
  numberOfActions: number;
  isLoading: boolean;
  setShowInviteLinkModal: any;
  hasSSOHardEnabled: boolean;
  allowUsersShareInviteLink: number | undefined;
}

export function UserWelcome({
  user,
  numberOfActions,
  isLoading,
  setShowInviteLinkModal,
  hasSSOHardEnabled,
  allowUsersShareInviteLink,
}: Props) {
  /****************************************************************************
   * State Variables                                                          *
   ****************************************************************************/

  // update the state based on if the user is an admin or not.  we do this so that the
  // button does not flash on initial load for users that are not suppose to see it.

  const isAdmin = useSelector(selectIsAdmin);

  const history = useHistory();
  const viewMyProfileHandler = () => {
    history.push(`/profile/${user?.userAccountId}`);
  };
  const styleForButton = {
    marginBottom: "16px",
  };
  return (
    <Col lg={{ span: 6 }} md={{ span: 6 }} sm={{ span: 12 }} xs={12}>
      <Media>
        <div className="align-self-start mr-3">
          <Avatar
            height={100}
            width={100}
            fontSize={48}
            image={user?.profilePicture}
            userName={
              user?.firstName ? `${user?.firstName} ${user?.lastName}` : ""
            }
            initials={
              user?.firstName
                ? `${user?.firstName
                    .charAt(0)
                    .toUpperCase()} ${user?.lastName.charAt(0).toUpperCase()}`
                : null
            }
            emailAddress={user?.emailAddress}
            isLoading={isLoading}
            backgroundColor={`#${user?.avatarColor}`}
          />
        </div>
        <Media.Body>
          <div className="headline-panel-header-text">
            {isLoading ? (
              <Skeleton width={200} duration={2} />
            ) : (
              `Hello${user?.firstName ? `, ${user?.firstName}` : ""}!`
            )}
          </div>

          <div className="action-text">
            {isLoading ? (
              <Skeleton width={300} duration={2} />
            ) : numberOfActions > 0 ? (
              `You have ${numberOfActions} action${
                numberOfActions === 1 ? "" : "s"
              } to complete.`
            ) : null}
          </div>
          <div className="mt-3">
            <LinkButton
              text="View My Profile"
              showArrow="right"
              handler={viewMyProfileHandler}
              isLoading={isLoading}
              style={styleForButton}
            />
          </div>
          {!hasSSOHardEnabled ? (
            allowUsersShareInviteLink || isAdmin ? (
              <div>
                <button
                  id="invite-button"
                  className="pr-3 pl-3"
                  onClick={() => setShowInviteLinkModal(true)}
                >
                  <img
                    src={inviteCoworkerIcon}
                    alt="invite coworkers"
                    height="16px"
                    width="16px"
                    className="mr-2"
                  />
                  Invite
                </button>
              </div>
            ) : null
          ) : null}
        </Media.Body>
      </Media>
    </Col>
  );
}
