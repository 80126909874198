import { call, put, all, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { request } from "utils/request";
import { PayloadAction } from "@reduxjs/toolkit";

export function* getTeams(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/teams`;
    const getTeamsResponse = yield call(request, requestUrl);
    yield put(actions.getTeamsSuccess(getTeamsResponse));
  } catch (error) {
    yield put(
      actions.getTeamsError("There was a problem retrieving your data.")
    );
  }
}

export function* getUsers(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users`;
    const getUsersResponse = yield call(request, requestUrl);
    yield put(actions.getUsersSuccess(getUsersResponse));
  } catch (error) {
    yield put(
      actions.getUsersError("There was a problem retrieving your data.")
    );
  }
}

export function* getExistingUsers(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/users`;
    const getExistingUsersResponse = yield call(request, requestUrl);
    yield put(actions.getExistingUsersSuccess(getExistingUsersResponse.data));
  } catch (error) {
    yield put(
      actions.getExistingUsersError("There was a problem retrieving your data.")
    );
  }
}

export function* editUser(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/${action.payload.userAccountId}`;
    yield call(request, requestUrl, {
      method: "PUT",
      body: JSON.stringify(action.payload.formData),
    });
    yield put(actions.getUsers());
    yield put(actions.editUserSuccess());
  } catch (error) {
    yield put(actions.editUserError("There was a problem saving this user."));
  }
}

export function* addUsers(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/bulkInvite`;
    yield call(request, requestUrl, {
      method: "POST",
      body: JSON.stringify(action.payload),
    });
    yield put(actions.addUsersSuccess());
  } catch (error) {
    yield put(actions.addUsersError());
  }
}

export function* inviteUsers(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/invite`;
    yield call(request, requestUrl, {
      method: "POST",
      body: JSON.stringify(action.payload),
    });
    yield put(actions.inviteUsersSuccess());
  } catch (error) {
    yield put(actions.inviteUsersError());
  }
}

export function* deactivateUser(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/${action.payload}`;
    yield call(request, requestUrl, {
      method: "DELETE",
    });
    yield put(actions.getUsers());
    yield put(actions.deactivateUserSuccess());
  } catch (error) {
    yield put(
      actions.deactivateUserError(
        "There was a problem sending your invitation."
      )
    );
  }
}

export function* getCompanyData(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/company/${action.payload}`;
    const getCompanyDataResponse = yield call(request, requestUrl);
    yield put(actions.getCompanyDataSuccess(getCompanyDataResponse));
  } catch (error) {
    yield put(actions.getCompanyDataError());
  }
}

export function* deactivateLink(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/company/${action.payload}/inviteLink/deactivate`;
    const getDeactivateLinkResponse = yield call(request, requestUrl, {
      method: "PUT",
    });
    yield put(actions.deactivateLinkSuccess(getDeactivateLinkResponse));
  } catch (error) {
    yield put(
      actions.deactivateLinkError(
        "There was an error while deactivating the link."
      )
    );
  }
}

export function* saveSettings(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/company/${action.payload.companyAccountId}`;
    yield call(request, requestUrl, {
      method: "PUT",
      body: JSON.stringify(action.payload.formData),
    });
    yield put(actions.saveSettingsSuccess());
  } catch (error) {
    yield put(actions.saveSettingsError());
  }
}

export function* updateUserTracking(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/${action.payload}/tracking`;
    yield call(request, requestUrl, {
      method: "PUT",
      body: JSON.stringify({ visitedAdminConsole: 1 }),
    });
    yield put(actions.updateUserTrackingSuccess());
  } catch (error) {
    // console.log("error: ", error);
    // yield put(actions.updateUserTrackingError(error));
  }
}

export function* updateSearchTerm(action: PayloadAction<any>) {
  yield actions.updateSearchTerm(action.payload);
}

export function* updateAccessRole(action: PayloadAction<any>) {
  yield actions.updateAccessRole(action.payload);
}

export function* updateUserStatus(action: PayloadAction<any>) {
  yield actions.updateUserStatus(action.payload);
}

export function* adminConsoleSaga() {
  yield all([
    takeLatest(actions.getTeams.type, getTeams),
    takeLatest(actions.getUsers.type, getUsers),
    takeLatest(actions.getExistingUsers.type, getExistingUsers),
    takeLatest(actions.editUser.type, editUser),
    takeLatest(actions.deactivateUser.type, deactivateUser),
    takeLatest(actions.saveSettings.type, saveSettings),
    takeLatest(actions.getCompanyData.type, getCompanyData),
    takeLatest(actions.deactivateLink.type, deactivateLink),
    takeLatest(actions.addUsers.type, addUsers),
    takeLatest(actions.inviteUsers.type, inviteUsers),
    takeLatest(actions.updateSearchTerm.type, updateSearchTerm),
    takeLatest(actions.updateAccessRole.type, updateAccessRole),
    takeLatest(actions.updateUserStatus.type, updateUserStatus),
    takeLatest(actions.updateUserTracking.type, updateUserTracking),
  ]);
}
