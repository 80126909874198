import React from "react";
import useEffectOnMount from "utils/custom_hooks/useEffectOnMount";
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import tipsIcon from "resources/images/icon-tips.svg";
import { showTips } from "./helpers";

interface Props {
  title: string;
  descriptions: string[] | undefined;
  tips: string[];
  icon: any;
  lastCard: boolean;
  setShowDownloadButton: (val: any) => void;
}

export const ProfileCard = ({
  title,
  descriptions,
  tips,
  icon,
  lastCard,
  setShowDownloadButton,
}: Props) => {
  useEffectOnMount(() => {
    setShowDownloadButton(showTips(tips));
  });
  const shouldShowTips = showTips(tips);

  return (
    <Col
      lg={12}
      md={12}
      sm={12}
      className={isMobile ? "" : "d-flex align-items-stretch"}
      style={{
        borderBottom: lastCard ? "none" : "1px solid #d8d8d8",
        paddingBottom: lastCard ? "5px" : "25px",
        paddingTop: "20px",
      }}
    >
      <Col
        lg={!shouldShowTips ? 12 : 6}
        md={!shouldShowTips ? 12 : 6}
        sm={!shouldShowTips || isMobile ? 12 : 6}
        xs={!shouldShowTips || isMobile ? 12 : 6}
        className="pt-3"
      >
        <div className="profile-card-title mb-3">
          <img
            src={icon}
            alt="title icon"
            height="32px"
            width="32px"
            className="mr-2"
          />
          {title}
        </div>
        {!shouldShowTips ? (
          <ul>
            {descriptions?.map((description, index) => {
              return (
                <li
                  className="profile-description-text mb-2"
                  dangerouslySetInnerHTML={{ __html: description }}
                  key={index}
                />
              );
            })}
          </ul>
        ) : (
          descriptions?.map((description, index) => {
            return (
              <p
                className="profile-description-text mb-2"
                dangerouslySetInnerHTML={{ __html: description }}
                key={index}
              />
            );
          })
        )}
      </Col>

      <Col
        lg={isMobile ? 12 : 6}
        md={isMobile ? 12 : 6}
        sm={isMobile ? 12 : 6}
        xs={isMobile ? 12 : 6}
      >
        {shouldShowTips ? (
          <div className="tips-container">
            <Row className="ml-3 mt-0 d-flex tips-title">
              <img
                src={tipsIcon}
                alt="tips icon"
                height="19px"
                width="19px"
                className="mr-1 "
              />
              <p>Tips</p>
            </Row>
            <ul>
              {tips?.map((tip, index) => {
                return (
                  <li className="profile-description-text" key={index}>
                    {tip}
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
      </Col>
    </Col>
  );
};
