import React from "react";
import { Col, Nav } from "react-bootstrap";
import { WorkStylesType } from "./types";
import { getTraitNameAndIcon } from "utils/helper_functions/getTraitNameAndIcon";

export const getNavItems = (navItems: string[]) => {
  const navItemsArray = navItems.map((item) => {
    return (
      <Nav.Link
        key={`${item.replace(/ /g, "").replace(",", "").replace("&", "")}`}
        eventKey={`${item.replace(/ /g, "").replace(",", "").replace("&", "")}`}
        href={`#${item.replace(/ /g, "").replace(",", "").replace("&", "")}`}
        className="trait-nav"
      >
        <span className="nav-text">{item}</span>
      </Nav.Link>
    );
  });
  return navItemsArray;
};

export const getTraitDisplay = (workStyles: WorkStylesType[]) => {
  const workStyleComponent = Object.keys(workStyles)?.map((trait) => {
    const { traitIcon } = getTraitNameAndIcon(trait, null);
    return (
      <Col lg={6} md={6} sm={6} key={trait}>
        <p className="individual-personality-header">
          <img src={traitIcon} alt="trait icon" className="trait-icon mr-2" />
          {workStyles[trait].title}
        </p>
        <p className="individual-personality-text">{workStyles[trait].text}</p>
      </Col>
    );
  });
  return workStyleComponent;
};
