/**
 *
 * DownloadCSV
 *
 */

import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import "./styles.css";

export const DownloadCSV = ({ hsVersion }) => {
  const csvFormattedData = [
    "First Name",
    "Last Name",
    "Email",
    "Job Title",
    "Manager Email",
  ];

  const csvFormattedEssentialsData = [
    "First Name",
    "Last Name",
    "Email",
    "Job Title",
  ];

  const handleDownloadCSV = () => {
    downloadCSV({
      filename: "employee_csv_template.csv",
      data: hsVersion > 1 ? csvFormattedData : csvFormattedEssentialsData,
    });
  };

  const downloadCSV = (args) => {
    const csv = convertArrayOfArraysToCSV({
      data: args.data,
    });
    if (csv == null) {
      return null;
    }

    const filename = args.filename || "export.csv";

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    return null;
  };

  const excelExecutors = ["=", "+", "-", "@"];

  const convertArrayOfArraysToCSV = (args) => {
    let result = "";
    let ctr = 0;

    const data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    const columnDelimiter = args.columnDelimiter || ",";
    const lineDelimiter = args.lineDelimiter || "\n";

    ctr = 0;
    data.forEach((item) => {
      if (ctr > 0) {
        result += columnDelimiter;
      }
      let itemToDisplay = item;
      if (item === null || item === undefined) {
        itemToDisplay = "-";
      }

      itemToDisplay = itemToDisplay.replace(/"/g, "'");

      result += `"${
        excelExecutors.includes(itemToDisplay[0]) && itemToDisplay !== "-"
          ? `'${itemToDisplay}}`
          : itemToDisplay
      }"`;
      ctr += 1;
    });
    result += lineDelimiter;
    return result;
  };

  return (
    <>
      <Row>
        <Col>
          <Button
            className="import-csv-button"
            onClick={handleDownloadCSV}
            id="exportResults"
          >
            CSV template.
          </Button>
        </Col>
      </Row>
    </>
  );
};
