//
import React from "react";
import { Modal, Button } from "react-bootstrap";
import { LinkButton } from "app/components/LinkButton";

interface Props {
  show: boolean;
  onHide: () => void;
  reportsTo: any;
  employeeName: string;
  userInvited: boolean;
  reportingConfirmed: () => void;
  managerInvited: boolean;
}

export default function ReportsConfirmationModal({
  show,
  onHide,
  reportsTo,
  employeeName,
  userInvited,
  reportingConfirmed,
  managerInvited
}: Props) {
  /* Style constants */
  const linkButtonStyle = {
    fontFamily: "Lato",
    fontSize: "14px",
    lineHeight: "1.43",
    color: "#425cc7",
    paddingTop: "2px"
  };

  const handleSubmit = () => {
    reportingConfirmed();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      id="invite-modal"
      className="invite-all-modal"
    >
      <Modal.Header closeButton className="modal-header">
        Update Reporting Relationships
      </Modal.Header>
      <Modal.Body>
        <p style={{ paddingTop: "10px" }}>
          You are updating the reporting relationship for this employee. Do you
          want to continue?
        </p>
        <ul className="confirmation-details-list">
          {userInvited ? (
            <li>{`${employeeName} (employee) will be notified of this change.`}</li>
          ) : (
            <li>
              {`Since ${employeeName} (employee) has not been invited to the
            platform yet, the employee will not be notified of this change.`}
            </li>
          )}
          {managerInvited && reportsTo && (
            <li>{`${reportsTo.firstName} ${reportsTo.lastName} (manager) will be notified of this change.`}</li>
          )}
          {!managerInvited && reportsTo && (
            <li>
              {`Since ${reportsTo.firstName} ${reportsTo.lastName} (manager) has not been invited to the
            platform yet, the manager will not be notified of this change.`}
            </li>
          )}
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <LinkButton
          text="No, Cancel"
          showArrow=""
          handler={onHide}
          isLoading={false}
          style={linkButtonStyle}
        />
        <Button className="invite-created-button" onClick={handleSubmit}>
          Yes, Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
