import { Avatar } from "app/components/Avatar";
import React from "react";
import { Row, Col } from "react-bootstrap";
// import { User } from "./types";
import { useHistory } from "react-router-dom";

interface Props {
  member: User | undefined;
  collaborationGuideUsers: User[] | null;
}

export function CollaborationUserRow({
  member,
  collaborationGuideUsers,
}: Props) {
  const history = useHistory();

  /****************************************************************************
   * Handler                                                                  *
   ****************************************************************************/
  const handleUserClick = (userAccountId: number | undefined) => {
    const selectedMember = collaborationGuideUsers?.filter(
      (member) => member.userAccountId === userAccountId
    )[0];

    const location = {
      pathname: `/profile/${selectedMember?.userAccountId}`,
      state: {
        rootName: "Dashboard",
        rootPath: `/dashboard`,
      },
    };
    history.push(location);
  };

  return (
    <div>
      <Row>
        <Col lg={2} md={2} sm={2} xs={2}>
          <span
            className="cursor-pointer"
            onClick={(event: any) => handleUserClick(member?.userAccountId)}
          >
            <Avatar
              height={56}
              width={56}
              fontSize={20}
              userName={`${member?.firstName} ${member?.lastName}`}
              emailAddress={member?.emailAddress}
              image={member?.profilePicture}
              initials={
                member?.firstName
                  ? `${member?.firstName.charAt(0)}${member?.lastName.charAt(
                      0
                    )}`
                  : `${member?.emailAddress.slice(0, 2)}`
              }
              isLoading={false}
              backgroundColor={`#${member?.avatarColor}`}
            />
          </span>
        </Col>
        <Col lg={10} md={10} sm={10} xs={10}>
          <p
            onClick={(event: any) => handleUserClick(member?.userAccountId)}
            className="mb-0 team-list-member-name"
            style={{ color: "#000000" }}
          >
            {member?.firstName} {member?.lastName}{" "}
          </p>
          <p
            onClick={(event: any) => handleUserClick(member?.userAccountId)}
            className={
              member?.firstName
                ? `mt-0 collab-users-list-member-email`
                : `mt-0 collab-users-list-member-name`
            }
            style={{ color: "#b1b3b3" }}
          >
            {member?.emailAddress}
          </p>
        </Col>
      </Row>
      <hr className="mt-1 mb-2" />
    </div>
  );
}
