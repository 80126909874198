/**
 *
 * AccountSetup
 *
 */

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./slice";
import {
  selectInvitationAccepted,
  selectExpiredLink,
  selectAlreadyAccepted,
  selectUserAccountId
} from "./selectors";
import { accountSetupSaga } from "./saga";
import useEffectOnMount from "utils/custom_hooks/useEffectOnMount";
import { isValidCritToken } from "utils/helper_functions/isValidCritToken";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import JwtDecode from "jwt-decode";
import { Container, Card, Col, Row } from "react-bootstrap";
import { AccountSetupForm } from "./AccountSetupForm";
import addUserIcon from "resources/images/add-user.jpg";
import expiredLinkIcon from "resources/images/expired-link-icon.png";
import "./styles.css";
import { FormData } from "./types";
import { redirectUserFromAccountSetup } from "./helperFunctions";

interface Props {}

export function AccountSetup(props: Props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: accountSetupSaga });

  const invitationAccepted = useSelector(selectInvitationAccepted);
  const expiredLink = useSelector(selectExpiredLink);
  const alreadyAccepted = useSelector(selectAlreadyAccepted);
  const userAccountId = useSelector(selectUserAccountId);

  const dispatch = useDispatch();
  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie] = useCookies(["name"]);

  /****************************************************************************
   * State Variable                                                           *
   ****************************************************************************/
  const [token1, setToken1] = useState<string | null>(null);
  const [token2, setToken2] = useState<string | null>(null);
  const [teamName, setTeamName] = useState<string | null>(null);
  const [teamId, setTeamId] = useState<string | null>(null);
  const [teamToken, setTeamToken] = useState<string | null | undefined>(
    undefined
  );
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [authenticated, setAuthenticated] = useState(false);

  useEffectOnMount(() => {
    // we need to parse the url for the tokens that may be there, and then
    // set those into state.
    const token1 = queryString.parse(location.search).token1
      ? String(queryString.parse(location.search).token1)
      : null;
    const token2 = queryString.parse(location.search).token2
      ? String(queryString.parse(location.search).token2)
      : null;
    const teamToken = queryString.parse(location.search).teamToken
      ? String(queryString.parse(location.search).teamToken)
      : null;
    setToken1(token1);
    setToken2(token2);
    setTeamToken(teamToken);

    // if there is a teamToken value set in the url, then this is coming from a
    // team invitation via email, so we need to accept that invitation, and save
    // the team name into state to use for the header.
    if (teamToken) {
      const decodedTeamToken: any = JwtDecode(teamToken);
      const teamName: string = decodedTeamToken?.teamName;
      const teamId: string = decodedTeamToken?.teamId;
      setTeamName(teamName);
      setTeamId(teamId);

      const token: string | null =
        localStorage.getItem("tmg-tkn") || isValidCritToken(cookies.critToken);

      const decodedToken: DecodedJWT | null = token ? JwtDecode(token) : null;

      // user is authenticated if there is a valid token that has not expired yet
      // saved in localStorage.
      const authenticated =
        decodedToken && Date.now() / 1000 <= decodedToken.exp;

      if (authenticated) {
        setAuthenticated(true);
      }

      // if there is no token 1 and no token 2, it means the user is an existing
      // Criteria user. For this case, we first accept invite
      // then in the saga check the user's authentication status to redirect the user accordingly
      if (!token1 && !token2) {
        dispatch(
          actions.acceptInvitation({
            teamToken,
            teamId,
            teamName,
            authenticated
          })
        );
      } else {
        // if there is token 1 and token 2, it can mean two things:
        // 1. the user has not set up account yet: accept invite and move the user to the account setup flow
        // 2. the user has created account and accepted invite already, but click on the Accept Invite link again:
        // send request to accept invite endpoint anyways and expect an error response. Redirect the user accordingly
        dispatch(
          actions.acceptInvitation({
            teamToken,
            teamId,
            teamName
          })
        );
      }
    }

    // if there is not a teamToken value in the url, then this is coming from an
    // email to join Talent Insights, and so we need to get the first and last
    // name, if they exist, to prefill in the form.
    if (token2) {
      const decodedToken: any = JwtDecode(
        String(queryString.parse(location.search).token2)
      );
      setFirstName(decodedToken.firstName);
      setLastName(decodedToken.lastName);
    }
  });

  // if there is a userAccountId, it means a new user has successfully created an account
  useEffect(() => {
    if (userAccountId) {
      const rawToken: any = userAccountId;
      const decodedToken: any = JwtDecode(rawToken);
      if (decodedToken.sub) {
        window.analytics.identify(
          `${decodedToken.sub}${
            process.env.REACT_APP_INSTANCE_PREFIX === "AU_" ? "au" : ""
          }`
        );
        window.analytics.track(
          "talent insights login",
          {
            "referral source type": "url",
            url: window.location.href
          },
          () => {
            window.location.href = `${process.env.REACT_APP_URL}?team=${teamName}&tid=${teamId}`;
          }
        );
      }
    }
  }, [userAccountId, teamId, teamName]);

  // if `alreadyAccepted` is true, it means an existing user is trying to access TI via the Accept Invite link
  useEffect(() => {
    if (alreadyAccepted) {
      redirectUserFromAccountSetup(authenticated, teamName, teamId, true);
    }
  }, [alreadyAccepted, authenticated, teamName, teamId]);

  const onSubmit = (formData: FormData) => {
    if (token1 && token2) {
      const payload = {
        ...formData,
        token1: decodeURIComponent(token1),
        token2: token2
      };
      dispatch(actions.createAccount(payload));
    }
  };

  const newUserDisplay = teamName ? (
    <>
      <p className="account-setup-header text-center mb-0">
        {`You've joined the ${teamName} Team`}
      </p>
      <p className="text-center mt-0">
        Create a password to finish setting up your Criteria account.
      </p>
    </>
  ) : (
    <p className="account-setup-header text-center mb-4">
      Complete Account Setup
    </p>
  );

  return (
    <>
      <Helmet>
        <title>Account Setup</title>
        <meta name="description" content="Description of AccountSetup" />
      </Helmet>

      <Container className="mt-3">
        {(teamToken !== undefined && !teamToken) ||
        (teamToken && invitationAccepted) ? (
          <Row>
            <Col>
              {expiredLink || !alreadyAccepted ? (
                <Card>
                  <Card.Body>
                    <Row className="text-center mt-4">
                      <Col>
                        {expiredLink ? (
                          <img
                            src={expiredLinkIcon}
                            alt="Add User Logo"
                            height="200px"
                            width="200px"
                          />
                        ) : !alreadyAccepted ? (
                          <img
                            src={addUserIcon}
                            alt="Add User Logo"
                            height="200px"
                            width="200px"
                          />
                        ) : null}
                      </Col>
                    </Row>
                    {expiredLink ? (
                      <>
                        <p className="text-center expired-header">
                          This link is no longer active
                        </p>
                        <p className="text-center mb-5 mt-2">
                          Already have an account?{" "}
                          <a href={`${process.env.REACT_APP_LOGOUT_URL}`}>
                            Go to the Login Page.
                          </a>
                        </p>
                      </>
                    ) : !alreadyAccepted ? (
                      newUserDisplay
                    ) : null}
                    {!expiredLink && !alreadyAccepted ? (
                      <AccountSetupForm
                        firstName={firstName}
                        lastName={lastName}
                        onSubmit={onSubmit}
                      />
                    ) : null}
                  </Card.Body>
                </Card>
              ) : null}
            </Col>
          </Row>
        ) : null}
      </Container>
    </>
  );
}
