import { TeamData, TeamScores } from "./types";
import averageTeamScoreIcon from "resources/images/average-team-score-icon.svg";

export const getNotableTraits = (teamScores: TeamScores) => {
  let notableTraits: string[] = [];
  Object.keys(teamScores).map((trait) => {
    if (teamScores[trait].isNotableTrait) notableTraits.push(trait);
    return true;
  });
  return notableTraits;
};

export const getNonNotableTraits = (teamScores: TeamScores) => {
  let nonNotableTraitsArray: string[] = [];
  Object.keys(teamScores).map((trait) => {
    if (!teamScores[trait].isNotableTrait) nonNotableTraitsArray.push(trait);
    return true;
  });
  return nonNotableTraitsArray;
};

export const hasPendingTest = (
  userAccountId: number | null,
  team: TeamData | null
) => {
  let eventId: string | null = null;
  team?.teamMembers.map((member) => {
    if (
      userAccountId &&
      member.userAccountId === userAccountId &&
      member.pendingTests.length > 0
    ) {
      eventId = member.pendingTests[0].eventId;
    }
  });

  return eventId;
};

export const getIndicators = (memberScores, width, trait, summary) => {
  const indicatorLocationArray: number[] = [];
  memberScores.map((score) => {
    const currentMemberId = score.teamMemberId;
    const baselineScore = summary ? score[trait].score : score[trait];
    memberScores.map((member) => {
      const memberTraitScore = summary ? member[trait].score : member[trait];
      if (
        member.teamMemberId !== currentMemberId &&
        width &&
        (width / 100) * memberTraitScore > (width / 100) * baselineScore - 8 &&
        (width / 100) * memberTraitScore < (width / 100) * baselineScore + 8 &&
        !indicatorLocationArray.includes(
          Math.floor((width / 100) * baselineScore + 32)
        )
      ) {
        const spacingAdjustment = summary ? 11 : 34;
        indicatorLocationArray.push(
          (width / 100) * baselineScore + spacingAdjustment
        );
      }
      return true;
    });
    return true;
  });
  return indicatorLocationArray;
};

export const shouldShowAverageScore = (teamMembers: any): boolean => {
  const totalNumberOfResults = teamMembers?.reduce(
    (total: number, member: any): number => {
      if (member.completedEpp) return total + 1;
      return total;
    },
    0
  );
  return totalNumberOfResults >= 3;
};

export const getAverageTeamMember = () => {
  let averageTeamMember = {
    profilePicture: averageTeamScoreIcon,
    avatarColor: "425cc7",
    emailAddress: "",
    firstName: "Team",
    lastName: "Average",
    showOnTeamReport: 3,
    teamMemberId: "teamAverage",
    userAccountId: 0,
  };
  return averageTeamMember;
};
