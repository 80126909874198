import React, { useRef, useState, useLayoutEffect } from "react";
import { Row, Col, OverlayTrigger, Popover } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { useWindowResize } from "beautiful-react-hooks";
import { TeamScores, MemberScores, MemberPersonalityReport } from "../types";
import { getAvatars } from "utils/helper_functions/getAvatars";
import { SpectrumBars } from "app/components/SpectrumBars";
import { LabelRow } from "app/components/LabelRow";
import { Avatar } from "app/components/Avatar";
import { getTraitNameAndIcon } from "utils/helper_functions/getTraitNameAndIcon";
import { useHistory } from "react-router-dom";
import { TeamMembersData } from "app/containers/Dashboard/types";
import notableTraitIcon from "resources/images/notable-trait.svg";
import anonymousUserIcon from "resources/images/user-anonymous.svg";
import { getIndicators, getAverageTeamMember } from "../helper-functions";
import {
  selectIsAdmin,
  selectUserAccountId,
} from "app/containers/Global/selectors";
import { useSelector } from "react-redux";

interface Props {
  trait: string;
  teamScores: TeamScores | null;
  memberScores: MemberScores[] | null;
  memberPersonalityReports: MemberPersonalityReport[] | null;
  teamMembers: TeamMembersData[] | undefined;
  isNotable: boolean;
  team: any;
  showAverageScore: boolean;
}

export function TraitCard({
  trait,
  teamScores,
  memberScores,
  teamMembers,
  isNotable,
  team,
  showAverageScore,
}: Props) {
  // set variable to whether the logged in user (the one view the page) is a
  // team lead or an admin. for use when determining whether to show the Avatars
  // or not.

  const loggedInUserAccountId = useSelector(selectUserAccountId);
  const loggedInUserIsAdmin = useSelector(selectIsAdmin);

  /****************************************************************************
   * State Variables                                                          *
   ****************************************************************************/
  const [width, setWidth] = useState<number | null>(null);

  /*
   * define the ref we use to get the width of the spectrum line element
   * so we can set the avatar positions correctly
   */
  const rowRef: any = useRef(null);
  const targetRef = useRef(null);
  const history = useHistory();
  const averageTeamScore = teamScores ? teamScores[trait].teamAverage : null;
  /*
   * call the helper function to get all of the trait data necessary to display
   * the card.
   */
  const { traitName, traitIcon, lowLabel, highLabel } = getTraitNameAndIcon(
    trait,
    teamScores
  );

  /*
   * the layout effect hook will determine the width of the element that contains
   * the spectrum line.  it is responsive, so it'll get called anytime there is a
   * change to the element size
   */
  useLayoutEffect(() => {
    if (rowRef.current) {
      setWidth(Math.round(rowRef?.current?.getBoundingClientRect().width) - 65);
    }
  }, [rowRef]);

  useWindowResize(() => {
    if (rowRef.current) {
      setWidth(Math.round(rowRef?.current?.getBoundingClientRect().width) - 65);
    }
  });

  /* Avatar component click handler */
  const handleAvatarClick = (event): void => {
    const member = memberScores?.filter(
      // eslint-disable-next-line eqeqeq
      (member) => member.teamMemberId == event
    );
    if (member && member?.length > 0) {
      if (member[0].firstName) {
        const location = {
          pathname: `/profile/${member[0].userAccountId}`,
          state: {
            rootName: team?.teamName,
            rootPath: `/team/${team?.teamId}`,
          },
        };
        history.push(location);
      }
    }
  };

  /* get the members Avatars and postions to display along the trait spectrum */
  const AvatarArray = getAvatars(
    memberScores,
    width,
    trait,
    true,
    handleAvatarClick,
    false,
    // viewAllWorkplaceInsights,
    // loggedInUserIsTeamLead,
    loggedInUserIsAdmin,
    showAverageScore,
    averageTeamScore,
    true,
    loggedInUserAccountId
  );

  const indicatorLocations = getIndicators(memberScores, width, trait, false);

  /*
   * method used to determine if hovered over avatar collides with any other, and if
   * so, display the avatars correctly
   */
  const getToolTipDisplay = (dataTip: string) => {
    ReactTooltip.rebuild();
    const trait = dataTip?.substring(0, dataTip.indexOf("-"));
    const teamMemberId = dataTip?.substring(dataTip.indexOf("-") + 1);
    let allScores = showAverageScore
      ? memberScores?.map((member) => member)
      : memberScores;
    if (showAverageScore) {
      const averageTeamMember = getAverageTeamMember();
      averageTeamMember[trait] = averageTeamScore;
      allScores?.push(averageTeamMember);
    }
    const chosenMember = allScores?.filter((member) => {
      // eslint-disable-next-line eqeqeq
      if (member.teamMemberId == teamMemberId) return member;
    });
    let baselineTraitScore =
      teamMemberId === "teamAverage"
        ? averageTeamScore
        : chosenMember && chosenMember.length > 0 && trait
        ? chosenMember[0][trait]
        : null;
    const collidingAvatars: MemberScores[] | null = [];
    allScores?.map((member) => {
      if (
        width &&
        baselineTraitScore &&
        (width / 100) * member[trait] >
          (width / 100) * baselineTraitScore - 32 &&
        (width / 100) * member[trait] < (width / 100) * baselineTraitScore + 32
      ) {
        collidingAvatars.push(member);
      }
    });
    const CollidingAvatarsArray = collidingAvatars
      .slice()
      .sort((a, b) => a[trait] - b[trait])
      .map((member) => {
        const isLoggedInMember = member.userAccountId === loggedInUserAccountId;
        return (
          <span
            style={{ marginRight: "3px" }}
            onClick={() => handleAvatarClick(member.teamMemberId)}
            data-id={member.teamMemberId}
            data-trait={trait}
            key={member.teamMemberId}
          >
            {isLoggedInMember || loggedInUserIsAdmin || member.firstName ? (
              <Avatar
                height={32}
                width={32}
                image={member.profilePicture}
                userName={
                  member.firstName
                    ? `${member.firstName} ${member.lastName}`
                    : member.emailAddress
                }
                initials={
                  member.firstName
                    ? `${member.firstName.charAt(0)} ${member.lastName.charAt(
                        0
                      )}`
                    : member.emailAddress.slice(0, 2)
                }
                fontSize={12}
                key={member.teamMemberId}
                backgroundColor={`#${member.avatarColor}`}
                dataFor={`avatar-${trait}`}
                dataTip={`${trait}-${member.teamMemberId}`}
              />
            ) : (
              <Avatar
                height={32}
                width={32}
                image={anonymousUserIcon}
                userName={""}
                initials={""}
                isTeamLead={0}
                fontSize={12}
                shouldOverlap={true}
                key={member.teamMemberId}
                backgroundColor={`#${member.avatarColor}`}
                dataFor={`avatar-${trait}`}
                data-tip={`${trait}-${member.teamMemberId}`}
                doNotShowTooltip={true}
                doNotShowBorder={true}
              />
            )}
          </span>
        );
      });

    // react-tooltip requires null in getContent if we don't want to display a tooltip
    // if (CollidingAvatarsArray.length <= 1) return null;

    return (
      <div>
        <Row>
          {CollidingAvatarsArray.length > 1 ? CollidingAvatarsArray : null}
        </Row>
      </div>
    );
  };
  /* end getTooltipDisplay method */

  const notablePopover = (
    <Popover id="notable-popover" className="notable-popover">
      Notable traits are defined as those that are furthest from the global
      average.
    </Popover>
  );

  return (
    <Col lg={6}>
      <div className="trait-card">
        <Row>
          <Col lg={11} md={11} sm={10} xs={10}>
            <img src={traitIcon} alt="trait icon" className="trait-icon mr-2" />
            <span className="trait-name-header ml-0">{traitName}</span>
          </Col>
          {isNotable ? (
            <Col lg={1} md={1} sm={2} xs={2} className="text-right">
              <OverlayTrigger overlay={notablePopover}>
                <img src={notableTraitIcon} alt="rank of trait score" />
              </OverlayTrigger>
            </Col>
          ) : null}
        </Row>
        <div className="px-3">
          <Row className="spectrum-container" ref={targetRef}>
            <Col>
              <Row className="spectrum-bar" ref={rowRef}>
                <Col className="d-flex">
                  <div>
                    {AvatarArray}
                    <ReactTooltip
                      id={`avatar-${trait}`}
                      getContent={(dataTip) => getToolTipDisplay(dataTip)}
                      backgroundColor="#FFF"
                      borderColor="#e4e4e4"
                      border={true}
                      clickable
                      effect="solid"
                      delayHide={500}
                      key={traitName}
                    />
                  </div>
                  <SpectrumBars
                    traitColor={`#CCD2DC`}
                    indicatorLocations={indicatorLocations}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="label-row">
            <LabelRow lowLabel={lowLabel} highLabel={highLabel} />
          </Row>
        </div>
      </div>
    </Col>
  );
}
