import React from "react";
import { Col, Row } from "react-bootstrap";

interface Props {
  handleSendEmail: () => void;
}

export default function EmailConfirmation({ handleSendEmail }: Props) {
  return (
    <>
      <Row className="text-center mt-3 mb-3">
        <Col>
          <p className="mb-0" id="invite-link-header">
            Please verify your email address to continue
          </p>
        </Col>
      </Row>
      <Row className="text-center mt-3">
        <Col lg={{ span: 6, offset: 3 }}>
          <p className="invite-form-text">
            To get started with Criteria, please verify your email through the
            email we sent to your email address.
          </p>
        </Col>
      </Row>
      <Row className="text-center mt-2">
        <Col>
          <p className="invite-form-text">
            Didn't receive an email from Criteria?
          </p>
        </Col>
      </Row>
      <Row className="text-center mt-2 mb-2">
        <Col
          lg={{
            span: 4,
            offset: 4,
          }}
          md={{
            span: 6,
            offset: 3,
          }}
          sm={{
            span: 8,
            offset: 2,
          }}
          xs={12}
        >
          <button id="resend-email-button" onClick={handleSendEmail}>
            Resend Email
          </button>
        </Col>
      </Row>
    </>
  );
}
