import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => state.addEmployee || initialState;
const adminConsoleDomain = (state: RootState) =>
  state.adminConsole || initialState;

export const selectUsers = createSelector(
  [selectDomain],
  addEmployeeState => addEmployeeState.users
);

export const selectExistingUsers = createSelector(
  [selectDomain],
  addEmployeeState => addEmployeeState.existingUsers
);

export const selectAllowedDomains = createSelector(
  [selectDomain],
  addEmployeeState => addEmployeeState.allowedDomains
);

export const selectSafeToRedirect = createSelector(
  [selectDomain],
  addEmployeeState => addEmployeeState.safeToRedirect
);

export const selectMatches = createSelector(
  [selectDomain],
  addEmployeeState => addEmployeeState.matches
);

export const selectLoadingMatches = createSelector(
  [selectDomain],
  addEmployeeState => addEmployeeState.loadingMatches
);

export const selectMatchesLoaded = createSelector(
  [selectDomain],
  addEmployeeState => addEmployeeState.matchesLoaded
);

export const selectCurrentlyLinkedCandidate = createSelector(
  [selectDomain],
  addEmployeeState => addEmployeeState.currentlyLinkedCandidate
);

export const selectCurrentlyLinkedLoaded = createSelector(
  [selectDomain],
  addEmployeeState => addEmployeeState.currentlyLinkedLoaded
);

export const selectManager = createSelector(
  [selectDomain],
  addEmployeeState => addEmployeeState.manager
);

export const selectHsVersion = createSelector(
  [selectDomain],
  addEmployeeState => addEmployeeState.hsVersion
);

// Probably not the best to pull state here from another container.  It works because if this
// value is undefined nothing is broken.  But, potentially a good refactor target.
export const selectHasAutomaticTeamsOn = createSelector(
  [adminConsoleDomain],
  (adminConsoleState: any) => adminConsoleState.hasAutomaticTeamsOn
);

export const selectEmailErrorMessage = createSelector(
  [selectDomain],
  addEmployeeState => addEmployeeState.emailErrorMessage
);
