import jwtDecode from "jwt-decode";

// Determines if the critToken cookie is valid, i.e. comes from the same instance/issuer
// in case the user has previously logged into another Criteria instance in another tab/window.
// If it is not valid, we should not consider the user authenticated for this instance.
// Note: critTokenIssuer will be the HireSelect url for whichever instance it was issued from
// ex. 'https://hireselect.criteriacorp.com'
export const isValidCritToken = (critToken: string | null) => {
  if (critToken) {
    const decodedToken: DecodedJWT = jwtDecode(critToken);
    const critTokenIssuer = decodedToken.iss;

    // if the hostname is localhost, the available critToken can only be a cookie set when
    // hireselect2 is running in localhost, so we can make an exception here
    if (
      new URL(`${process.env.REACT_APP_URL}`).hostname === "localhost" ||
      process.env.REACT_APP_HIRESELECT_URL === critTokenIssuer
    ) {
      return critToken;
    }
  }

  return null;
};
