import React from "react";
import { Col, Card, Row } from "react-bootstrap";
import rightArrow from "resources/images/arrow-right-blue.svg";
import { useHistory } from "react-router-dom";

interface Props {}

export const CreateTeamBox = (props: Props) => {
  /* button handler */
  const history = useHistory();
  const handleClick = () => {
    history.push("/create", { from: "dashboard" });
  };

  return (
    <Col lg={6}>
      <Card className="create-team-card">
        <Card.Title className="create-team-name">Create a Team</Card.Title>
        <Card.Subtitle className="create-member-count">
          Invite your colleagues to Criteria to start learning how to work
          better together.
        </Card.Subtitle>
        <Row style={{ padding: "23px 0 0 17px" }}>
          <button className="arrow-button" onClick={handleClick}>
            Create Team{" "}
            <img
              src={rightArrow}
              alt="right arrow"
              className="link-button-arrow"
            />
          </button>
        </Row>
      </Card>
    </Col>
  );
};
