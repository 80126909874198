import React from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  selectStressorsMotivatorsDescription,
  selectWorkplaceStressorsMotivators,
} from "../selectors";
import { StressorsMotivatorsType } from "../types";
import leftWeb from "resources/images/left-web.svg";
import rightWeb from "resources/images/right-web.svg";
import centerWeb from "resources/images/center-web.svg";
import checkIcon from "resources/images/icon-circle-check-web.svg";

export default function StressorsMotivators() {
  const workplaceStressorsMotivators:
    | StressorsMotivatorsType[]
    | undefined = useSelector(selectWorkplaceStressorsMotivators);
  const description = useSelector(selectStressorsMotivatorsDescription);
  return (
    <div className="trait-summary-card">
      {/* eslint-disable-next-line */}
      <a className="anchor" id="WorkplaceChallengesMotivators"></a>
      <Row>
        <Col className="d-flex">
          <p className="trait-name mb-4">Workplace Challenges & Motivators</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>{description}</p>
        </Col>
      </Row>
      <table id="motivators" className="table insightsReport d-none d-md-table">
        <thead>
          <tr>
            <th></th>
            <th className="text-center">
              {" "}
              Less
              <br />
              comfortable
            </th>
            <th></th>
            <th className="text-center">
              Somewhat
              <br />
              comfortable
            </th>
            <th></th>
            <th className="text-center">
              Extremely
              <br />
              comfortable
            </th>
          </tr>
        </thead>
        <tbody>
          {workplaceStressorsMotivators?.map((stressor, index) => (
            <tr className={index % 2 === 0 ? "dark-row" : ""} key={index}>
              <td>
                <h4 className="mt-2 mb-0 pl-2 insightsReport stressor-title">
                  {stressor.title}
                </h4>
              </td>
              {[0, 1, 2, 3, 4].map((likert) => (
                <td
                  key={likert}
                  className={`text-center ${
                    likert === 1
                      ? "first-cell"
                      : likert === 4
                      ? "last-cell"
                      : "middle-cell"
                  }`}
                  style={{
                    background: `url(${
                      likert === 0
                        ? leftWeb
                        : likert === 4
                        ? rightWeb
                        : centerWeb
                    }) center center no-repeat`,
                  }}
                >
                  {likert === stressor.likertValue ? (
                    <img src={checkIcon} alt="check" />
                  ) : null}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <table id="motivators" className="table insightsReport d-md-none">
        {workplaceStressorsMotivators?.map((stressor, index) => (
          <tbody key={index}>
            <tr className={index % 2 === 0 ? "dark-row" : ""}>
              <td colSpan={5} style={{ paddingBottom: "0" }}>
                <h4 className="mt-2 mb-0 ml-3 insightsReport">
                  {stressor.title}
                </h4>
                <div className="row">
                  <div className="col text-center mobileLabel">
                    Not at all
                    <br />
                    comfortable
                  </div>
                  <div className="col text-center mobileLabel">
                    Somewhat
                    <br />
                    comfortable
                  </div>
                  <div className="col text-center mobileLabel">
                    Extremely
                    <br />
                    comfortable
                  </div>
                </div>
              </td>
            </tr>
            <tr className={index % 2 === 0 ? "dark-row" : ""}>
              {[0, 1, 2, 3, 4].map((likert) => (
                <td
                  key={likert}
                  className={`text-center ${
                    likert === 0
                      ? "first-cell"
                      : likert === 4
                      ? "last-cell"
                      : "middle-cell"
                  }`}
                  style={{
                    background: `url(${
                      likert === 0
                        ? leftWeb
                        : likert === 4
                        ? rightWeb
                        : centerWeb
                    }) center center no-repeat`,
                  }}
                >
                  {likert === stressor.likertValue ? (
                    <img src={checkIcon} alt="check" />
                  ) : null}
                </td>
              ))}
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  );
}
