/**
 *
 * PendingActions
 *
 */
import React, { ReactNode } from "react";
import Skeleton from "react-loading-skeleton";
import { Col } from "react-bootstrap";
import { getPendingActions } from "../helper-functions";
import { PendingInvitationsData, DirectReport } from "../types";

interface Props {
  isLoading?: boolean;
  acceptInvitation: (
    teamMemberId: number,
    teamId: number,
    teamName: string
  ) => void;
  pendingActions: Array<PendingInvitationsData | DirectReport> | null;
}

export function PendingActions({
  isLoading,
  acceptInvitation,
  pendingActions
}: Props) {
  const pendingActionsArray: ReactNode = getPendingActions(
    pendingActions,
    acceptInvitation
  );

  /*
   * onScroll handler that removes the faded out affects when user has reached the bottom of the
   * element.  it reapplies it if they scroll up from there.
   */
  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    const target = event.currentTarget;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      const element = document.getElementById("pending-container");
      element?.classList.remove("fade-out");
    }
    if (!(target.scrollHeight - target.scrollTop === target.clientHeight)) {
      const element = document.getElementById("pending-container");
      element?.classList.add("fade-out");
    }
  };

  return (
    <Col
      lg={{ span: 6 }}
      md={{ span: 6 }}
      sm={{ span: 12 }}
      xs={12}
      className={`pending-actions-container ${
        pendingActions && pendingActions?.length > 2 ? "fade-out" : ""
      }`}
      onScroll={handleScroll}
      id="pending-container"
    >
      {isLoading ? (
        <>
          <div className="skeleton-pending-box">
            <Skeleton height={40} duration={2} />
          </div>
          <div className="skeleton-pending-box">
            <Skeleton height={40} duration={2} />
          </div>
        </>
      ) : (
        <>{pendingActionsArray}</>
      )}
    </Col>
  );
}
