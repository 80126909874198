import React from "react";
import overlapIndicator from "resources/images/overlap.svg";
import "./styles.css";

interface Props {
  traitColor: string;
  indicatorLocations?: number[];
}

export function SpectrumBars({ traitColor, indicatorLocations }: Props) {
  return (
    <>
      {indicatorLocations && indicatorLocations.length > 1
        ? indicatorLocations.map((location, index) => {
            return (
              <span
                id="spectrum-container"
                style={{
                  position: "absolute",
                  top: "-30px",
                  left: `${location}px`,
                }}
                key={index}
              >
                <img src={overlapIndicator} alt="overlapping avatars" />
              </span>
            );
          })
        : null}
      <div
        className="spectrum-bar-segment"
        style={{
          backgroundColor: traitColor,
        }}
      />
      <div
        className="spectrum-bar-segment"
        style={{
          backgroundColor: traitColor,
        }}
      />
      <div
        className="spectrum-bar-segment"
        style={{
          backgroundColor: traitColor,
        }}
      />
      <div
        className="spectrum-bar-segment"
        style={{
          backgroundColor: traitColor,
        }}
      />
    </>
  );
}
