/**
 *
 * Breadcrumb
 *
 */
import React from "react";
import { Breadcrumb } from "react-bootstrap";

interface Props {
  location: any;
  user?: User | null;
}

export function BreadcrumbNavigation({ location, user }: Props) {
  return (
    <Breadcrumb className="breadcrumb">
      {location?.state?.rootName ? (
        <>
          <Breadcrumb.Item href={location.state.rootPath}>
            {location.state.rootName}
          </Breadcrumb.Item>

          {location?.state?.previousName ? (
            <Breadcrumb.Item href={location.state.previousPath}>
              {location.state.previousName}
            </Breadcrumb.Item>
          ) : null}
          {user ? (
            <Breadcrumb.Item active>
              {user?.firstName} {user?.lastName}
            </Breadcrumb.Item>
          ) : null}
        </>
      ) : null}
    </Breadcrumb>
  );
}
