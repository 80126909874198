import React from "react";
import { Row, Col, Breadcrumb } from "react-bootstrap";
import addUser from "resources/images/add-user.svg";
import { selectIsAdmin } from "app/containers/Global/selectors";
import { useSelector } from "react-redux";

interface Props {
  teamName: string | undefined;
  numberOfMembers: number | undefined;
  teamId: number | null;
  InviteMembersButtonHandler: () => void;
  currentUserIsTeamLead: boolean;
  automatic: boolean;
}

export function Header({
  teamName,
  numberOfMembers,
  teamId,
  InviteMembersButtonHandler,
  currentUserIsTeamLead,
  automatic,
}: Props) {
  /****************************************************************************
   * Selectors                                                                *
   ****************************************************************************/
  const loggedInUserIsAdmin = useSelector(selectIsAdmin);

  return (
    <>
      <div className="my-3 pb-2">
        <div className="pt-2 pb-2">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item
              href={`${process.env.REACT_APP_URL}/team/${teamId}`}
            >
              {teamName}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Team List</Breadcrumb.Item>
          </Breadcrumb>{" "}
        </div>
      </div>
      <Row className="mb-2">
        <Col lg={6} md={6} sm={6} className="text-left">
          <p className="team-list-team-name mb-0">{teamName}</p>
          <p className="member-count mt-0">{`${numberOfMembers} Members`}</p>
        </Col>
        {(currentUserIsTeamLead || loggedInUserIsAdmin) && !automatic ? (
          <Col lg={6} md={6} sm={6} className="text-right">
            <button
              className="invite-members-button mt-3"
              onClick={InviteMembersButtonHandler}
            >
              <img src={addUser} alt="add user" className="pb-1 mr-2" />
              Invite Team Members
            </button>
          </Col>
        ) : null}
      </Row>
    </>
  );
}
