import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectDescription } from "./selectors";
import "./styles.css";
import { User } from "./types";

interface Props {
  user: User | null;
}

export function Header({ user }: Props) {
  const description = useSelector(selectDescription);
  return (
    <Card className="mt-3 individual-personality-header-card">
      <Card.Body>
        <Row>
          <Col>
            <p className="headline-text">{`${user?.firstName}'s Workplace Insights`}</p>
            <p className="individual-personality-text">{description}</p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
