import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import { PendingTestsData } from "./types";
import circleProgressIcon from "resources/images/circle-progress.svg";
import pendingTestImg from "resources/images/pending-test-img.png";
import rightArrow from "resources/images/pending-tests-right-arrow.svg";
import buttonRightArrow from "resources/images/arrow-right-white.svg";

interface Props {
  pendingTests: PendingTestsData[] | null | undefined;
}

export const PendingTests = ({ pendingTests }: Props) => {
  /****************************************************************************
   * State Variables                                                          *
   ****************************************************************************/
  const [percentComplete, setPercentComplete] = useState(0);

  /****************************************************************************
   * Hooks                                                                    *
   ****************************************************************************/
  useEffect(() => {
    if (pendingTests && pendingTests[0].questionsAnswered > 0) {
      const percent = Math.floor(
        (pendingTests[0].questionsAnswered / pendingTests[0].totalQuestions) *
          100
      );
      setPercentComplete(percent);
    }
  }, [pendingTests]);

  /****************************************************************************
   * Handlers                                                                 *
   ****************************************************************************/
  const handleClick = () => {
    if (pendingTests) {
      window.open(
        `${process.env.REACT_APP_ODA_URL}/verify/index/${pendingTests[0].eventId}`,
        "_blank"
      );
    }
  };

  return (
    <Container fluid="lg" className="mt-4">
      <Row>
        <Col>
          <Card className="dashboard-pending-test-box">
            <Row>
              <Row className="vertical-align">
                <Col lg={4}>
                  <img src={pendingTestImg} alt="pending test" height="159" />
                </Col>
                <Col lg={8}>
                  <div className="d-flex m-auto">
                    <img
                      src={circleProgressIcon}
                      alt="progress"
                      className="progress-icon"
                    />
                    <span className="pending-test-header-text ml-2">
                      {percentComplete === 0
                        ? `You’re almost done! Complete your profile`
                        : `You're ${percentComplete}% done with your personality test`}
                    </span>
                  </div>
                  <p className="mt-2 pending-tests-text">
                    Once you complete your profile by taking the personality
                    test, you’ll have access to:
                  </p>
                  <p className="pending-tests-text">
                    <img
                      src={rightArrow}
                      height="12"
                      width="12"
                      alt="right arrow"
                      className="mr-2 mb-1"
                    />
                    Your personalized Workplace Insights report
                  </p>
                  <p className="pending-tests-text">
                    <img
                      src={rightArrow}
                      height="12"
                      width="12"
                      alt="right arrow"
                      className="mr-2 mb-1"
                    />
                    Collaboration Guides with tips for working with specific
                    teammates
                  </p>
                  <p className="pending-tests-text">
                    <img
                      src={rightArrow}
                      height="12"
                      width="12"
                      alt="right arrow"
                      className="mr-2 mb-1"
                    />
                    Team Personality Reports to see how you compare with your
                    teammates
                  </p>
                </Col>
              </Row>
              <Col className="text-right">
                <button
                  className="pending-tests-teal-button"
                  onClick={handleClick}
                >
                  {percentComplete === 0
                    ? `Begin Personality Test`
                    : `Resume Personality Test`}
                  <img
                    src={buttonRightArrow}
                    height="16"
                    width="16"
                    alt="right arrow"
                    className="ml-2"
                  />
                </button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
