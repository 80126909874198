import React, { useState, useRef, useLayoutEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useWindowResize } from "beautiful-react-hooks";
import { getMemberList } from "utils/helper_functions/getMemberList";
import { TeamMembersData } from "../types";
import rightArrow from "resources/images/arrow-right-blue.svg";
import addUser from "resources/images/add-user.svg";
import { useHistory } from "react-router-dom";
import { isTeamLead } from "utils/helper_functions/isTeamLead";
import {
  selectIsAdmin,
  selectUserAccountId
} from "app/containers/Global/selectors";
import { useSelector } from "react-redux";

interface Props {
  teamMembers: TeamMembersData[] | undefined;
  handleShowInviteModal: () => void;
  handleShowSampleDataModal?: () => void;
  teamId: number | null;
  teamName: string | undefined;
  isLoading: boolean;
  isSample?: boolean;
  automatic?: boolean;
}

export function MembersList({
  teamMembers,
  handleShowInviteModal,
  handleShowSampleDataModal,
  teamId,
  teamName,
  isSample,
  automatic
}: Props) {
  /****************************************************************************
   * Hooks                                                                    *
   ****************************************************************************/
  const rowRef: any = useRef(null);
  const history = useHistory();

  /****************************************************************************
   * Selectors                                                                *
   ****************************************************************************/
  const userAccountId = useSelector(selectUserAccountId);
  const loggedInUserIsAdmin = useSelector(selectIsAdmin);

  /****************************************************************************
   * User variables                                                           *
   ****************************************************************************/
  const currentUserIsTeamLead = isTeamLead(teamMembers, userAccountId);

  /*
   * the layout effect hook will determine the width of the element that contains
   * the members list.  it is responsive, so it'll get called anytime there is a
   * change to the element size
   */
  useLayoutEffect(() => {
    if (rowRef.current) {
      setMembersWidth(
        Math.round(rowRef?.current?.getBoundingClientRect().width)
      );
    }
  }, [rowRef]);

  useWindowResize(() => {
    if (rowRef.current) {
      setMembersWidth(
        Math.round(rowRef?.current?.getBoundingClientRect().width)
      );
    }
  });

  const handleAvatarClick = (event: string) => {
    const member = teamMembers?.filter(
      // eslint-disable-next-line eqeqeq
      member => member.teamMemberId == event
    );
    if (member) {
      const location = {
        pathname: `/profile/${member[0].userAccountId}`,
        state: {
          rootName: teamName,
          rootPath: `/team/${teamId}`
        }
      };
      history.push(location);
    }
  };

  /****************************************************************************
   * State Variables                                                          *
   ****************************************************************************/
  const [membersWidth, setMembersWidth] = useState<number | null>(null);

  /* get an array of team members avatars to display */
  const membersArray =
    membersWidth && teamMembers
      ? getMemberList(teamMembers, membersWidth, false, handleAvatarClick)
      : null;

  const handleNavivationToTeamList = () => {
    const location = {
      pathname: `/team_list/${teamId}`,
      state: {
        rootName: teamName,
        rootPath: `/team/${teamId}`
      }
    };
    history.push(location);
  };

  return (
    <Row>
      <Col lg={9} md={9} sm={9} ref={rowRef}>
        {membersArray}
        <button
          className="rounded-outline-button float-left"
          style={{
            marginLeft: "-10px",
            width: membersWidth && membersWidth > 360 ? "150px" : "50px"
          }}
          onClick={handleNavivationToTeamList}
        >
          {membersWidth && membersWidth > 360 ? `View Members` : null}
          <img src={rightArrow} alt="right arrow" className="ml-2" />
        </button>
      </Col>
      <Col lg={3} md={3} sm={3}>
        <div>
          {(currentUserIsTeamLead || loggedInUserIsAdmin) && !automatic ? (
            <div style={{ width: "20%" }}>
              <button
                className="invite-members-button mt-1"
                onClick={
                  isSample ? handleShowSampleDataModal : handleShowInviteModal
                }
              >
                <img src={addUser} alt="add user" className="pb-1 mr-2" />
                Invite Team Members
              </button>
            </div>
          ) : null}
        </div>
      </Col>
    </Row>
  );
}
