import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { MemberScores, TeamScores, MemberPersonalityReport } from "../types";
import {
  getNotableTraits,
  getNonNotableTraits,
  shouldShowAverageScore,
} from "../helper-functions";
import { TraitCard } from "./TraitCards";
import { LinkButton } from "app/components/LinkButton";
import { TeamMembersData } from "app/containers/Dashboard/types";

interface Props {
  memberScores: MemberScores[] | null;
  teamScores: TeamScores | null;
  memberPersonalityReports: MemberPersonalityReport[] | null;
  teamMembers: TeamMembersData[] | undefined;
  team: any;
}

export function TraitsList({
  memberScores,
  teamScores,
  memberPersonalityReports,
  teamMembers,
  team,
}: Props) {
  /****************************************************************************
   * State                                                                    *
   ****************************************************************************/
  const [showNonNotableTraits, setShowNonNotableTraits] = useState(false);

  // determine if there are enough team members with results to display the
  // team average score avatar
  const showAverageScore = shouldShowAverageScore(team?.teamMembers);

  /* create the array of notable traits cards */
  const NOTABLE_TRAIT_ARRAY = teamScores ? getNotableTraits(teamScores) : [];

  const NotableTraitCardArray = NOTABLE_TRAIT_ARRAY.map((trait) => {
    return (
      <TraitCard
        trait={trait}
        memberScores={memberScores}
        teamScores={teamScores}
        key={trait}
        memberPersonalityReports={memberPersonalityReports}
        teamMembers={teamMembers}
        isNotable
        team={team}
        showAverageScore={showAverageScore}
      />
    );
  });

  /* create the array of non-notable traits cards */
  const NON_NOTABLE_TRAIT_ARRAY = teamScores
    ? getNonNotableTraits(teamScores)
    : [];

  const NonNotableTraitCardArray = NON_NOTABLE_TRAIT_ARRAY.map((trait) => {
    return (
      <TraitCard
        trait={trait}
        memberScores={memberScores}
        teamScores={teamScores}
        key={trait}
        memberPersonalityReports={memberPersonalityReports}
        teamMembers={teamMembers}
        isNotable={false}
        team={team}
        showAverageScore={showAverageScore}
      />
    );
  });

  /* Handlers */

  const handleShowAllTraitsButton = () => {
    setShowNonNotableTraits(!showNonNotableTraits);
  };

  return (
    <>
      <Row>{NotableTraitCardArray}</Row>
      {showNonNotableTraits ? <Row>{NonNotableTraitCardArray}</Row> : null}
      <div className="trait-expand-button text-center">
        <LinkButton
          text={
            showNonNotableTraits ? "Show notable traits" : "Show all 10 traits"
          }
          showArrow={""}
          showChevron={!showNonNotableTraits}
          showUpChevron={showNonNotableTraits}
          handler={handleShowAllTraitsButton}
          style={{ margin: "auto" }}
        />
      </div>
    </>
  );
}
