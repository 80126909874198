import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) =>
  state.individualPersonality || initialState;

export const selectIndividualPersonality = createSelector(
  [selectDomain],
  (individualPersonalityState) => individualPersonalityState
);

export const selectUser = createSelector(
  [selectDomain],
  (individualPersonalityState) => individualPersonalityState.user
);

export const selectDescription = createSelector(
  [selectDomain],
  (individualPersonalityState) =>
    individualPersonalityState.workplaceInsightsData?.description
);

export const selectStressorsMotivatorsDescription = createSelector(
  [selectDomain],
  (individualPersonalityState) =>
    individualPersonalityState.workplaceInsightsData
      ?.workplaceStressorsMotivatorsDescription
);

export const selectWorkStyle = createSelector(
  [selectDomain],
  (individualPersonalityState) =>
    individualPersonalityState.workplaceInsightsData?.workStyle
);

export const selectTemperament = createSelector(
  [selectDomain],
  (individualPersonalityState) =>
    individualPersonalityState.workplaceInsightsData?.temperament
);

export const selectStrengths = createSelector(
  [selectDomain],
  (individualPersonalityState) =>
    individualPersonalityState.workplaceInsightsData?.strengths
);

export const selectWeaknesses = createSelector(
  [selectDomain],
  (individualPersonalityState) =>
    individualPersonalityState.workplaceInsightsData?.weaknesses
);

export const selectSuggestions = createSelector(
  [selectDomain],
  (individualPersonalityState) =>
    individualPersonalityState.workplaceInsightsData?.suggestions
);

export const selectTraitScores = createSelector(
  [selectDomain],
  (individualPersonalityState) =>
    individualPersonalityState.workplaceInsightsData?.traitScores
);

export const selectNotableTraits = createSelector(
  [selectDomain],
  (individualPersonalityState) =>
    individualPersonalityState.workplaceInsightsData?.notableTraits
);

export const selectWorkplaceStressorsMotivators = createSelector(
  [selectDomain],
  (individualPersonalityState) =>
    individualPersonalityState.workplaceInsightsData
      ?.workplaceStressorsMotivators
);

export const selectWorkplaceInsightsPdf = createSelector(
  [selectDomain],
  (individualPersonalityState) =>
    individualPersonalityState.workplaceInsightsPdf
);

export const selectNoPermission = createSelector(
  [selectDomain],
  (individualPersonalityState) => individualPersonalityState.noPermission
);

export const selectNoEPP = createSelector(
  [selectDomain],
  (individualPersonalityState) => individualPersonalityState.noEPP
);
