import { call, put, all, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { request } from "utils/request";
import { PayloadAction } from "@reduxjs/toolkit";

export function* getUsers(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users`;
    const getUsersResponse = yield call(request, requestUrl);
    yield put(actions.getUsersSuccess(getUsersResponse));
  } catch (error) {
    yield put(
      actions.getUsersError("There was a problem retrieving your data.")
    );
  }
}

export function* getExistingUsers(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/users`;
    const getExistingUsersResponse = yield call(request, requestUrl);
    yield put(actions.getExistingUsersSuccess(getExistingUsersResponse.data));
  } catch (error) {
    yield put(
      actions.getExistingUsersError("There was a problem retrieving your data.")
    );
  }
}

export function* addEmployee(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users`;
    yield call(request, requestUrl, {
      method: "POST",
      body: JSON.stringify(action.payload)
    });
    yield put(actions.addEmployeeSuccess());
  } catch (error) {
    let errorMessage;
    if (error.response?.status === 400) {
      errorMessage =
        "This email address is already associated with another Criteria account.";
    }
    yield put(
      actions.addEmployeeError({ status: error.response?.status, errorMessage })
    );
  }
}

export function* editUser(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/${action.payload.userAccountId}`;
    yield call(request, requestUrl, {
      method: "PUT",
      body: JSON.stringify(action.payload.formData)
    });
    yield put(actions.getUsers());
    yield put(actions.editUserSuccess());
  } catch (error) {
    yield put(actions.editUserError("There was a problem saving this user."));
  }
}

export function* getCompanyData(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/company/${action.payload}`;
    const getCompanyDataResponse = yield call(request, requestUrl);
    yield put(actions.getCompanyDataSuccess(getCompanyDataResponse));
  } catch (error) {
    yield put(actions.getCompanyDataError());
  }
}

export function* getMatches(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/testTakers/matches`;
    const getSuggestedMatchesResponse = yield call(request, requestUrl, {
      method: "POST",
      body: JSON.stringify(action.payload)
    });
    yield put(actions.getMatchesSuccess(getSuggestedMatchesResponse));
  } catch (error) {
    yield put(actions.getMatchesError());
  }
}

export function* getLinkedCandidate(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/${action.payload}/testTaker`;
    const getLinkedCandidateResponse = yield call(request, requestUrl);
    yield put(actions.getLinkedCandidateSuccess(getLinkedCandidateResponse));
  } catch (error) {
    yield put(actions.getLinkedCandidateError());
  }
}

export function* getManager(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/${action.payload}/manager`;
    const getManagerResponse = yield call(request, requestUrl);
    yield put(actions.getManagerSuccess(getManagerResponse));
  } catch (error) {
    yield put(actions.getManagerError(error));
  }
}

export function* addEmployeeSaga() {
  yield all([
    takeLatest(actions.getUsers.type, getUsers),
    takeLatest(actions.getExistingUsers.type, getExistingUsers),
    takeLatest(actions.editUser.type, editUser),
    takeLatest(actions.getCompanyData.type, getCompanyData),
    takeLatest(actions.addEmployee.type, addEmployee),
    takeLatest(actions.getMatches.type, getMatches),
    takeLatest(actions.getLinkedCandidate.type, getLinkedCandidate),
    takeLatest(actions.getManager.type, getManager)
  ]);
}
