// This file should hold functions that return config objects

export const getCookieConfigObject = (): {
  path: string;
  domain: string;
} => ({
  path: "/",
  domain:
    new URL(`${process.env.REACT_APP_URL}`).hostname === "localhost"
      ? "localhost"
      : ".criteriacorp.com",
});
