import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";

// The initial state of the AddEmployee container
export const initialState: ContainerState = {
  users: null,
  existingUsers: null,
  allowedDomains: [],
  loadingUsers: false,
  loadingExistingUsers: false,
  errorMessage: null,
  loadingCompanyData: false,
  addingEmployee: false,
  employeeFirstName: null,
  safeToRedirect: false,
  savingUser: false,
  matches: null,
  loadingMatches: false,
  matchesLoaded: false,
  currentlyLinkedLoaded: false,
  currentlyLinkedCandidate: null,
  manager: {},
  hsVersion: 0,
  emailErrorMessage: null
};

const addEmployeeSlice = createSlice({
  name: "addEmployee",
  initialState,
  reducers: {
    addEmployee(state, action: PayloadAction<any>) {
      state.addingEmployee = true;
      state.employeeFirstName = action.payload.firstName;
    },
    addEmployeeSuccess(state) {
      state.safeToRedirect = true;
      state.addingEmployee = false;
    },
    addEmployeeError(state, action: PayloadAction<any>) {
      state.addingEmployee = false;
      state.errorMessage = "There was a problem adding these users.";
      if (action.payload.status === 400) {
        state.emailErrorMessage = action.payload.errorMessage;
      }
    },
    resetState(state) {
      state.errorMessage = initialState.errorMessage;
      state.employeeFirstName = initialState.employeeFirstName;
      state.safeToRedirect = false;
      state.emailErrorMessage = initialState.emailErrorMessage;
    },
    getUsers(state) {
      state.loadingUsers = true;
    },
    getUsersSuccess(state, action: PayloadAction<any>) {
      state.loadingUsers = false;
      state.users = action.payload;
    },
    getUsersError(state, action: PayloadAction<any>) {
      state.loadingUsers = false;
      state.errorMessage = action.payload;
    },
    getExistingUsers(state) {
      state.loadingExistingUsers = true;
    },
    getExistingUsersSuccess(state, action: PayloadAction<any>) {
      state.loadingExistingUsers = false;
      state.existingUsers = action.payload;
    },
    getExistingUsersError(state, action: PayloadAction<any>) {
      state.loadingExistingUsers = false;
      state.errorMessage = action.payload;
    },
    editUser: {
      reducer(state, action: PayloadAction<any>) {
        state.savingUser = true;
      },
      prepare(userAccountId, formData) {
        return {
          payload: {
            userAccountId: userAccountId,
            formData: { ...formData }
          }
        };
      }
    },
    editUserSuccess(state) {
      state.safeToRedirect = true;
      state.savingUser = false;
    },
    editUserError(state, action: PayloadAction<any>) {
      state.errorMessage = action.payload;
    },
    getCompanyData(state, action: PayloadAction<any>) {
      state.loadingCompanyData = true;
    },
    getCompanyDataSuccess(state, action: PayloadAction<any>) {
      state.loadingCompanyData = false;
      state.allowedDomains = action.payload.allowedDomains;
      state.hsVersion = action.payload.hsVersion;
    },
    getCompanyDataError(state) {
      state.loadingCompanyData = false;
      state.errorMessage =
        "There was an issue with loading your company's data";
    },
    getMatches(state, action: PayloadAction<any>) {
      state.loadingMatches = true;
      state.matchesLoaded = false;
    },
    getMatchesSuccess(state, action: PayloadAction<any>) {
      state.loadingMatches = false;
      state.matches = action.payload;
      state.matchesLoaded = true;
    },
    getMatchesError(state) {
      state.loadingMatches = false;
      state.matchesLoaded = false;
    },
    getLinkedCandidate(state, action: PayloadAction<any>) {
      state.currentlyLinkedLoaded = false;
      state.currentlyLinkedCandidate = null;
    },
    getLinkedCandidateSuccess(state, action: PayloadAction<any>) {
      state.currentlyLinkedLoaded = true;
      state.currentlyLinkedCandidate = action.payload;
    },
    getLinkedCandidateError(state) {
      state.currentlyLinkedLoaded = false;
    },
    getManager(state, action: PayloadAction<any>) {},
    getManagerSuccess(state, action: PayloadAction<any>) {
      state.manager = action.payload;
    },
    getManagerError(state, action: PayloadAction<any>) {
      state.errorMessage = "There was an issue with loading manager data";
    }
  }
});

export const { actions, reducer, name: sliceKey } = addEmployeeSlice;
