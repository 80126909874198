import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { NavigationBar } from "./ReportComponents/NavigationBar";
import { NotableTraitCards } from "./ReportComponents/NotableTraitCards";
import { User } from "./types";
import WorkStyles from "./ReportComponents/WorkStyles";
import TemperamentOutlook from "./ReportComponents/TemperamentOutlook";
import StrengthsChallenges from "./ReportComponents/StrengthsChallenges";
import Suggestions from "./ReportComponents/Suggestions";
import StressorsMotivators from "./ReportComponents/StressorsMotivators";

interface Props {
  user: User | null;
}

export function WorkplaceInsightsReport({ user }: Props) {
  return (
    <Card className="mt-0 individual-personality-body-card">
      <Card.Body>
        <Row>
          <Col lg={4} md={4}>
            <NavigationBar />
          </Col>
          <Col lg={8} md={8}>
            <NotableTraitCards user={user} />
            <WorkStyles />
            <TemperamentOutlook />
            <StrengthsChallenges />
            <Suggestions />
            <StressorsMotivators />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
