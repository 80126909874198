import React from "react";
import { TableCell } from "@material-ui/core";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import menu from "resources/images/menu.svg";

interface Props {
  automatic: boolean;
  teamId: string | number;
}

export default function TeamsTableDropdown({ automatic, teamId }: Props) {
  const history = useHistory();

  const handleDropdown = (event, teamId) => {
    if (event === "view") {
      history.push(`/team/${teamId}`);
    }
  };

  return (
    <TableCell align="right">
      {automatic ? (
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="top"
          overlay={
            <Popover
              id="popover-basic"
              bsPrefix="automatic-popover popover-arrow"
            >
              This is an automatic team that cannot be edited or deleted.
            </Popover>
          }
        >
          <Dropdown onSelect={(event) => handleDropdown(event, teamId)}>
            <div className="no-caret">
              <Dropdown.Toggle size="sm" className="menu-button">
                <img src={menu} alt="menu" width="24" height="24" />
              </Dropdown.Toggle>
            </div>
          </Dropdown>
        </OverlayTrigger>
      ) : (
        <Dropdown onSelect={(event) => handleDropdown(event, teamId)}>
          <div className="no-caret">
            <Dropdown.Toggle size="sm" className="menu-button">
              <img src={menu} alt="menu" width="24" height="24" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="view" data-id={teamId}>
                View Team
              </Dropdown.Item>
            </Dropdown.Menu>
          </div>
        </Dropdown>
      )}
    </TableCell>
  );
}
