import React, { useEffect, useState } from "react";
import { actions } from "./slice";
import { useDispatch } from "react-redux";
import { LinkButton } from "app/components/LinkButton";
import { Row, Col, DropdownButton, Dropdown } from "react-bootstrap";
import everyoneSettingIcon from "resources/images/everyone-setting-icon.svg";
import onlyMeSettingIcon from "resources/images/only-me-settings-icon.svg";
import teamLeadSettingIcon from "resources/images/team-leads-icon.svg";
import { getSettingsIcon } from "./helper";
import { selectUserAccountId } from "app/containers/Global/selectors";
import { useSelector } from "react-redux";
import "./styles.css";

interface Props {
  text: string;
  value: number | undefined;
  permissionToUpdate: string;
}

const editLinkButtonStyle = {
  fontFamily: "Lato",
  fontSize: "14px",
  lineHeight: "1.43",
  color: "#425cc7",
  textDecoration: "underline",
};

export const SettingOption = ({ text, value, permissionToUpdate }: Props) => {
  /****************************************************************************
   * Selectors
   ****************************************************************************/
  const userAccountId = useSelector(selectUserAccountId);

  /****************************************************************************
   * State
   ****************************************************************************/
  const [editing, setEditing] = useState(false);
  const [selectedValue, setSelectedValue] = useState<number | null>(null);

  /****************************************************************************
   * Hooks                                                                    *
   ****************************************************************************/
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedValue && value) {
      setSelectedValue(value);
    }
  }, [value, selectedValue]);

  /****************************************************************************
   * Handlers                                                                 *
   ****************************************************************************/
  const handleSave = () => {
    const formData = {
      [permissionToUpdate]: selectedValue,
    };
    dispatch(actions.saveSetting(userAccountId, formData));
    setEditing(false);
  };

  const handleEdit = () => {
    setEditing(!editing);
  };

  return (
    <>
      <Row className="mt-2 mb-0">
        <Col lg={10} md={10} sm={10}>
          <p className="setting-text">{text}</p>
        </Col>
        <Col lg={2} md={2} sm={2}>
          <LinkButton
            text={editing ? `Save` : `Edit`}
            showArrow=""
            handler={!editing ? handleEdit : handleSave}
            isLoading={false}
            style={editLinkButtonStyle}
          />{" "}
        </Col>
      </Row>
      {!editing ? (
        <Row className="mb-0">
          <Col>
            {getSettingsIcon(Number(selectedValue), permissionToUpdate)}
          </Col>
        </Row>
      ) : (
        <Row className="mb-0">
          <Col lg={8} md={8} sm={12}>
            <DropdownButton
              title={getSettingsIcon(Number(selectedValue), permissionToUpdate)}
              onSelect={(value) => setSelectedValue(Number(value))}
              bsPrefix="account-setting-dropdown-button"
            >
              <Dropdown.Item eventKey={"1"}>
                <img
                  src={onlyMeSettingIcon}
                  alt="only me icon"
                  height="16px"
                  className="mr-2 justify-left"
                />
                {permissionToUpdate === "showPersonalitySummary"
                  ? "Not Visible"
                  : "Only me"}
              </Dropdown.Item>
              <Dropdown.Item eventKey={"2"}>
                <img
                  src={teamLeadSettingIcon}
                  alt="only me icon"
                  height="16px"
                  className="mr-2"
                />
                Team Lead
              </Dropdown.Item>
              <Dropdown.Item eventKey={"3"}>
                <img
                  src={everyoneSettingIcon}
                  alt="everyone icon"
                  height="16px"
                  className="mr-2"
                />
                Everyone on my teams
              </Dropdown.Item>
            </DropdownButton>
          </Col>
        </Row>
      )}
      <Row className="mt-4">
        <Col lg={12} md={12} sm={12}>
          <hr className="separator" />
        </Col>
      </Row>
    </>
  );
};
