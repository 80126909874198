import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) =>
  state.inviteConfirmation || initialState;

export const selectInviteConfirmation = createSelector(
  [selectDomain],
  (inviteConfirmationState) => inviteConfirmationState
);

export const selectAcceptingInvitation = createSelector(
  [selectDomain],
  (inviteConfirmationState) => inviteConfirmationState.acceptingInvitation
);

export const selectErrorMessage = createSelector(
  [selectDomain],
  (inviteConfirmationState) => inviteConfirmationState.errorMessage
);
