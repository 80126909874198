import { TeamMember } from "../AdminConsole/types";

export const getTeamLead = (teamMembers: TeamMember[]) => {
  if (teamMembers.length === 0) return null;
  const teamLead = teamMembers.filter(member => member.isTeamLead);
  if (teamLead.length === 0) return null;
  return `${teamLead[0]?.firstName} ${teamLead[0]?.lastName}`;
};

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";
export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export const getUsersByStatus = (status: string, users: any) => {
  if (status === "active") {
    if (users) {
      const activeUsers = Object.keys(users)
        .map(id => {
          if (users[id].tmgSuspendedByUserAccountId === 0) {
            return users[id];
          }
          return undefined;
        })
        .filter(user => user !== undefined);
      return activeUsers;
    }
    return null;
  }
  if (status === "inactive") {
    if (users) {
      const activeUsers = Object.keys(users)
        .map(id => {
          if (users[id].tmgSuspendedByUserAccountId !== 0) {
            return users[id];
          }
          return undefined;
        })
        .filter(user => user !== undefined);
      return activeUsers;
    }
    return null;
  }
};

export const addUserStatus = users => {
  if (users) {
    let usersWithStatus = Object.keys(users).map(id => {
      return Object.assign({}, users[id]);
    });

    usersWithStatus.map(user => {
      if (user.tmgSuspendedByUserAccountId !== 0) {
        user.userStatus = "deactivated";
      } else if (user.firstTeamsLogin || user.setupAccount !== 1) {
        user.userStatus = "active";
      } else if (user.tmgInvited === 1) {
        user.userStatus = "invited";
      } else {
        user.userStatus = "created";
      }
      return user;
    });
    return usersWithStatus;
  }
};

export const getUserStatusNumber = users => {
  let userStatusNumbers: number[] = [0, 0, 0, 0];
  if (users) {
    userStatusNumbers[0] = users.filter(
      user => user.userStatus === "created"
    ).length;
    userStatusNumbers[1] = users.filter(
      user => user.userStatus === "active"
    ).length;
    userStatusNumbers[2] = users.filter(
      user => user.userStatus === "deactivated"
    ).length;
    userStatusNumbers[3] = users.filter(
      user => user.userStatus === "invited"
    ).length;
  }
  return userStatusNumbers;
};

export const getUsersByFilter = (
  viewUserStatus: string,
  accessRoleStatus: string,
  users: any
) => {
  let usersByStatus;
  if (users) {
    if (viewUserStatus === null || viewUserStatus === "all") {
      usersByStatus = users;
    } else {
      usersByStatus = users.filter(user => user.userStatus === viewUserStatus);
    }
  } else {
    return null;
  }
  if (accessRoleStatus === "all" || accessRoleStatus === null) {
    return usersByStatus;
  }
  const roleStatus = accessRoleStatus === "admins" ? 1 : 2;
  if (usersByStatus) {
    const activeUsers = usersByStatus
      .map(user => {
        if (user.tmgRoleId === roleStatus) {
          return user;
        }
        return undefined;
      })
      .filter(user => user !== undefined);
    return activeUsers;
  }
  return null;
};
