import { all, call, put, takeLatest } from "redux-saga/effects";
import { request } from "utils/request";
import { actions } from "./slice";
import { TeamData } from "./types";

export function* getTeam(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/teams/${action.payload}`;
    const getTeamResponse: TeamData = yield call(request, requestUrl);
    yield put(actions.getTeamSuccess(getTeamResponse));
  } catch (error) {
    yield put(actions.getTeamError(error.response));
  }
}

export function* updateSettings(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/teams/${action.payload.teamId}`;
    const updateSettingsResponse: TeamData = yield call(request, requestUrl, {
      method: "PUT",
      body: JSON.stringify(action.payload.formData),
    });
    yield put(actions.updateSettingsSuccess(updateSettingsResponse));
  } catch (error) {
    yield put(actions.updateSettingsError(error.response));
  }
}

export function* updateTeamLead(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/teams/${action.payload.teamId}/teamLead`;
    yield call(request, requestUrl, {
      method: "PUT",
      body: JSON.stringify(action.payload.formData),
    });
    yield put(actions.updateTeamLeadSuccess());
  } catch (err) {
    let error = yield err.response.json();
    const keys = Object.keys(error);
    yield put(actions.updateTeamLeadError(error[keys[0]]));
  }
}

export function* deleteTeam(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/teams/${action.payload}`;
    yield call(request, requestUrl, {
      method: "DELETE",
    });
    window.location.href = `${process.env.REACT_APP_URL}`;
  } catch (error) {
    yield put(actions.deleteTeamError(error.response));
  }
}

export function* getAllowedDomains(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/company/${action.payload}`;
    const getAllowedDomainsResponse = yield call(request, requestUrl);
    yield put(
      actions.getAllowedDomainsSuccess(getAllowedDomainsResponse.allowedDomains)
    );
  } catch (error) {
    yield put(actions.getAllowedDomainsError(error.response));
  }
}

export function* getUsersList(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/list`;
    const getUsersListResponse = yield call(request, requestUrl);
    yield put(actions.getUsersListSuccess(getUsersListResponse));
  } catch (error) {
    yield put(
      actions.getUsersListError("There was an error in retrieving your users.")
    );
  }
}

export function* teamSettingsSaga() {
  yield all([
    takeLatest(actions.getTeam.type, getTeam),
    takeLatest(actions.updateSettings.type, updateSettings),
    takeLatest(actions.deleteTeam.type, deleteTeam),
    takeLatest(actions.updateTeamLead.type, updateTeamLead),
    takeLatest(actions.getAllowedDomains.type, getAllowedDomains),
    takeLatest(actions.getUsersList.type, getUsersList),
  ]);
}
