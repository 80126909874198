import React from "react";
import { Modal } from "react-bootstrap";

interface Props {
  show?: boolean;
  onHide: () => void;
}

export default function SampleDataModal({ show, onHide }: Props) {
  return (
    <Modal show={show} size="lg" onHide={onHide}>
      <Modal.Header closeButton className="modal-header">
        Sample Team
      </Modal.Header>
      <Modal.Body>
        This functionality is not available for Sample Teams.
      </Modal.Body>
      <Modal.Footer>
        <button
          style={{
            all: "unset",
            color: "#425cc7",
            padding: "12px",
            cursor: "pointer",
          }}
          onClick={() => onHide()}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
