import { useEffect } from "react";

const getTrackingPayload = (
  pageName: string,
  id: string | number,
  pageHasContentToShow: any,
  loggedInUserAccountId: string | null
) => {
  const typeofId = pageName.includes("team") ? "team id" : "selected user";
  return {
    [typeofId]: id,
    "is blank": !pageHasContentToShow ? "yes" : "no",
    ...(loggedInUserAccountId && {
      "selected self": id === loggedInUserAccountId ? "yes" : "no",
    }),
  };
};

export default function useSegmentTracking(
  pageName: string,
  isDataLoaded: boolean,
  pageHasContentToShow: any,
  id: string | number | null, // can I add null here?
  loggedInUserAccountId: string | null = null
) {
  useEffect(() => {
    let timeoutId;
    if (isDataLoaded && id) {
      const payload = getTrackingPayload(
        pageName,
        id,
        pageHasContentToShow,
        loggedInUserAccountId
      );
      if (payload && Object.keys(payload).length > 0) {
        window.analytics.page(`${pageName} viewed`, payload);
        timeoutId = setTimeout(() => {
          window.analytics.page(`${pageName} viewed 30 seconds`, payload);
        }, 30000);
      }
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [pageName, isDataLoaded, pageHasContentToShow, id, loggedInUserAccountId]);
}
