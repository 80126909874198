/**
 *
 * InviteLink
 *
 */

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./slice";
import {
  selectInviteLink,
  selectCompanyName,
  selectAllowableDomains,
  selectEmailSent,
  selectExpiredLink,
  selectErrorMessage,
} from "./selectors";
import { inviteLinkSaga } from "./saga";
import { Container, Card, Col, Row } from "react-bootstrap";
import teamAverageIcon from "resources/images/team-average-empty.jpg";
import expiredLinkIcon from "resources/images/expired-link-icon.png";
import useEffectOnMount from "utils/custom_hooks/useEffectOnMount";
import "./styles.css";
import { isValidEmail } from "utils/helper_functions/isValidEmail";
import InvitationForm from "./InvitationForm";
import EmailConfirmation from "./EmailConfirmation";

interface Props {}

export function InviteLink(props: Props) {
  useInjectReducer({
    key: sliceKey,
    reducer: reducer,
  });
  useInjectSaga({
    key: sliceKey,
    saga: inviteLinkSaga,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const inviteLink = useSelector(selectInviteLink);

  const dispatch = useDispatch();

  // get the value of the linkToken from the url params, to be used to check
  // the validity of the link and get the company information.
  const { code }: any = useParams();

  const companyName: string = useSelector(selectCompanyName);
  const allowableDomains: string[] = useSelector(selectAllowableDomains);
  const emailSent: boolean = useSelector(selectEmailSent);
  const expiredLink: boolean = useSelector(selectExpiredLink);
  const errorMessage: string = useSelector(selectErrorMessage);

  /****************************************************************************
   * State Variables                                                          *
   ****************************************************************************/
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [validationMessage, setValidationMessage] = useState<string>("");

  /****************************************************************************
   * Hooks                                                                    *
   ****************************************************************************/
  useEffectOnMount(() => {
    if (code) {
      dispatch(actions.getCompanyInfo(code));
    }
  });

  useEffect(() => {
    if (errorMessage) {
      setValidationMessage(errorMessage);
    }
    return () => {
      setValidationMessage("");
    };
  }, [errorMessage]);

  /****************************************************************************
   * Handlers                                                                 *
   ****************************************************************************/
  const handleSendEmail = () => {
    if (isValidEmail(emailAddress)) {
      if (
        !allowableDomains.includes(emailAddress.split("@")[1].toLowerCase())
      ) {
        setValidationMessage(
          `${emailAddress} is not included in your company domain.`
        );
      } else {
        const formData = {
          emailAddress: emailAddress,
          code: code,
        };
        dispatch(actions.sendEmailAddress(formData));
      }
    } else {
      setValidationMessage("Please enter a valid email address.");
    }
  };

  return (
    <>
      <Helmet>
        <title>Invitation Link</title>
        <meta name="description" content="Invitation Link Form" />
      </Helmet>
      <Container className="mt-3">
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Row className="text-center mt-4">
                  <Col>
                    <img
                      src={expiredLink ? expiredLinkIcon : teamAverageIcon}
                      alt="Invite Link Logo"
                      height="200px"
                      width="200px"
                    />
                  </Col>
                </Row>
                {expiredLink ? (
                  <Row className="text-center mt-3 mb-4 ">
                    <Col>
                      <p className="mb-0" id="invite-link-header">
                        This link is no longer active.
                      </p>
                    </Col>
                  </Row>
                ) : null}
                {!emailSent && !expiredLink ? (
                  <InvitationForm
                    companyName={companyName}
                    emailAddress={emailAddress}
                    setEmailAddress={setEmailAddress}
                    validationMessage={validationMessage}
                    handleSendEmail={handleSendEmail}
                  />
                ) : !expiredLink ? (
                  <EmailConfirmation handleSendEmail={handleSendEmail} />
                ) : null}
                <Row className="mt-3 text-center">
                  <Col>
                    <p className="invite-form-text">
                      Already have an account?{" "}
                      <a href={`${process.env.REACT_APP_LOGOUT_URL}`}>
                        Go to Login Page
                      </a>
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
