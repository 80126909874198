import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { NavigationBar } from "./NavigationBar";
import TraitSummaries from "./TraitSummaries";
import { PersonalityReports, MemberPersonalityReport } from "./types";

interface Props {
  teamPersonalityReports: PersonalityReports | null;
  memberPersonalityReports: MemberPersonalityReport[] | null;
  notableTraits: string[];
  viewAllWorkplaceInsights: number | undefined;
  loggedInUserIsTeamLead: boolean;
  teamId: number | undefined;
  teamName: string | undefined;
}

export function TeamSummary({
  teamPersonalityReports,
  memberPersonalityReports,
  notableTraits,
  viewAllWorkplaceInsights,
  loggedInUserIsTeamLead,
  teamId,
  teamName,
}: Props) {
  return (
    <Card className="mt-0 team-personality-body-card">
      <Card.Body>
        <Row>
          <Col lg={4} md={4} sm={4}>
            <NavigationBar notableTraits={notableTraits} />
          </Col>
          <Col lg={8} md={8} sm={8}>
            <TraitSummaries
              teamPersonalityReports={teamPersonalityReports}
              memberPersonalityReports={memberPersonalityReports}
              notableTraits={notableTraits}
              viewAllWorkplaceInsights={viewAllWorkplaceInsights}
              loggedInUserIsTeamLead={loggedInUserIsTeamLead}
              teamId={teamId}
              teamName={teamName}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
