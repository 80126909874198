import React, { useState } from "react";
import { Row, Container, Card, Col } from "react-bootstrap";
import { getDescriptions, getTips, profileCardTitles } from "./helpers";
import { ProfileCard } from "./ProfileCard";
import { PersonalityProfile } from "./types";
import { NotAvailable } from "./NotAvailable";
import { CollaborationGuideHeader } from "./CollaborationGuideHeader";
import { selectCollaborationGuidePDF } from "./selectors";
import { useSelector } from "react-redux";
import downloadIcon from "resources/images/download.svg";
import useSegmentTracking from "../../../utils/custom_hooks/useSegmentTracking";

interface Props {
  personalityProfile: PersonalityProfile | null;
  showPersonalityProfile: boolean;
  noPermission: boolean;
  noEPP: boolean;
  personalityProfileLoaded: boolean;
  user: any;
  downloadReport: (reportToDownload: string) => void;
  isSample: boolean;
}

export const CollaborationGuide = ({
  personalityProfile,
  showPersonalityProfile,
  noPermission,
  noEPP,
  personalityProfileLoaded,
  user,
  downloadReport,
  isSample
}: Props) => {
  useSegmentTracking(
    "collaboration guide", // page name
    personalityProfileLoaded, // isDataLoaded
    showPersonalityProfile, // pageHasContentToShow
    user.userAccountId
  );

  const { loadingCollaborationGuidePDF } = useSelector(
    selectCollaborationGuidePDF
  );

  const [showDownloadButton, setShowDownloadButton] = useState(false);

  return (
    <Container className="mt-3 mb-5">
      {personalityProfileLoaded ? (
        showPersonalityProfile ? (
          <>
            {showDownloadButton && (
              <Row>
                <Col className="text-right mb-3">
                  <button
                    className="download-button"
                    onClick={() => downloadReport("collaborationGuide")}
                    style={{
                      color: "#001F4E"
                    }}
                    disabled={loadingCollaborationGuidePDF}
                  >
                    <img
                      className="mr-2 mb-1"
                      src={downloadIcon}
                      alt="download report"
                    />
                    {loadingCollaborationGuidePDF ? "Loading..." : "Download"}
                  </button>
                </Col>
              </Row>
            )}

            <Card className="profile-card">
              <Card.Body>
                <Row>
                  <CollaborationGuideHeader
                    personalityProfile={personalityProfile}
                    user={user}
                  />
                  {profileCardTitles.map((cardObject, index) => {
                    const profileKey = cardObject.key;
                    const descriptionArray = personalityProfile
                      ? getDescriptions(personalityProfile[profileKey])
                      : [];
                    const tipsArray: string[] =
                      personalityProfile && personalityProfile
                        ? getTips(personalityProfile[profileKey])
                        : [];
                    return (
                      <ProfileCard
                        title={cardObject.title}
                        descriptions={descriptionArray}
                        tips={tipsArray}
                        icon={cardObject.icon}
                        key={cardObject.key}
                        lastCard={index === profileCardTitles.length - 1}
                        setShowDownloadButton={val =>
                          setShowDownloadButton(val)
                        }
                      />
                    );
                  })}
                </Row>
              </Card.Body>
            </Card>
          </>
        ) : (
          <div className="mt-4">
            <NotAvailable
              text="Collaboration Guide"
              noPermission={noPermission}
              noEPP={noEPP}
              firstName={user.firstName}
              isSample
            />
          </div>
        )
      ) : null}
    </Container>
  );
};
