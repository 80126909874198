/**
 *
 * PreviewImport
 *
 */

import React from "react";
import { Row, Col } from "react-bootstrap";
import "./styles.css";
import warning from "../../../resources/images/warning.svg";

export function PreviewImport({ previewCSVResponse }) {
  const lineErrors = () => {
    const lineErrors = Object.keys(previewCSVResponse.errors).map((key) => {
      const lineItems = Object.keys(previewCSVResponse.errors[key]).map(
        (numOfErr, index) => (
          // need to add +2 to the index to account for both the header row and rows value starting at 0
          <li style={{ textAlign: "left" }} key={index}>
            <strong>{`Row ${+key + 2}: `}</strong>
            {`${previewCSVResponse.errors[key][numOfErr]}`}
          </li>
        )
      );
      return lineItems;
    });

    return <ul style={{ padding: "10px 0 0 25px" }}>{lineErrors}</ul>;
  };

  return (
    <>
      <Row>
        <Col>
          <p style={{ marginBottom: "0px" }}>
            <strong>{previewCSVResponse.newUserCount}</strong>{" "}
            {previewCSVResponse?.newUserCount === 1
              ? "employee "
              : "employees "}
            will be <strong> created.</strong>
          </p>
          <p style={{ marginBottom: "0px" }}>
            <strong>{previewCSVResponse.updatedUserCount}</strong>{" "}
            {previewCSVResponse?.updatedUserCount === 1
              ? "employee "
              : "employees "}
            will be <strong> updated.</strong>
          </p>
          <p style={{ marginBottom: "0px" }}>
            <strong>{previewCSVResponse.deactivatedUserCount}</strong>{" "}
            {previewCSVResponse?.deactivatedUserCount === 1
              ? "employee "
              : "employees "}
            will be <strong> deactivated.</strong>
          </p>
        </Col>
      </Row>
      {previewCSVResponse.errorCounts.total > 0 && (
        <Row>
          <Col className="mb-0 warning-row h-25">
            <img
              src={warning}
              alt="download icon"
              style={{ height: "16px", marginRight: "7px" }}
            />
            <div className="requirements-list">
              <p className="import-form-text warning-text">
                <strong>{`${previewCSVResponse.rowsWithErrorsCount} ${
                  previewCSVResponse.rowsWithErrorsCount === 1 ? "row" : "rows"
                } with errors found:`}</strong>{" "}
                Rows with errors will <strong>not</strong> be imported.
              </p>
              {lineErrors()}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
}
