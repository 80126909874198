import React, { useState } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { TeamMembersData } from "../types";
import { MembersList } from "./MembersList";
import menu from "resources/images/menu.svg";
import "../styles.css";
import { useHistory } from "react-router-dom";
import { ConfirmationModal } from "app/components/ConfirmationModal";
import { isTeamLead, isTeamMember } from "utils/helper_functions/isTeamLead";
import Skeleton from "react-loading-skeleton";
import {
  selectUser,
  selectIsAdmin,
  selectUserAccountId,
} from "app/containers/Global/selectors";
import { useSelector } from "react-redux";

interface Props {
  teamName: string | undefined;
  teamMembers: TeamMembersData[] | undefined;
  handleShowInviteModal: () => void;
  handleShowSampleDataModal: () => void;
  teamId: number | null;
  handleRemoveMember: () => void;
  isLoading: boolean;
  isSample?: boolean;
  automatic?: boolean;
}

export function HeadlinePanel({
  teamName,
  teamMembers,
  handleShowInviteModal,
  teamId,
  handleRemoveMember,
  isLoading,
  isSample,
  handleShowSampleDataModal,
  automatic,
}: Props) {
  const [showLeaveTeamConfirmation, setShowLeaveTeamConfirmation] = useState(
    false
  );
  const history = useHistory();

  // determine if current logged in user is an admin.
  const loggedInUserIsAdmin = useSelector(selectIsAdmin);
  const user = useSelector(selectUser);
  const userAccountId = useSelector(selectUserAccountId);

  /****************************************************************************
   * User Variables to determine overflow menu items                          *
   ****************************************************************************/

  /* determine if the current user is the Team Lead (to determine if they have access to Team Settings) */
  const currentUserIsTeamLead = isTeamLead(teamMembers, userAccountId);

  // determine if logged in User is in the team
  const currentUserIsTeamMember = isTeamMember(teamMembers, user);

  const handleOverflow = (eventKey: string | null) => {
    switch (eventKey) {
      case "team-settings":
        if (!isSample) {
          const location = {
            pathname: `/team_settings/${teamId}`,
            state: {
              rootName: teamName,
              rootPath: `/team/${teamId}`,
            },
          };
          history.push(location);
        } else {
          handleShowSampleDataModal();
        }

        break;
      case "leave-team":
        setShowLeaveTeamConfirmation(true);
        break;
      default:
        return false;
    }
  };

  const handleRemoveMemberButton = () => {
    setShowLeaveTeamConfirmation(false);
    handleRemoveMember();
    history.push("/");
  };

  return (
    <div className="headline-panel">
      <Container>
        <Row>
          <Col>
            <Row className="ml-1">
              {isLoading ? (
                <div className="skeleton-headline-panel">
                  <Skeleton height={40} duration={2} />
                </div>
              ) : (
                <>
                  <p className="headline-panel-header-text mb-0">{teamName}</p>
                  {automatic ? (
                    <span
                      className={"automatic-team-badge-teampage"}
                      style={{ whiteSpace: "nowrap", maxWidth: "fit-content" }}
                    >
                      AUTOMATIC TEAM
                    </span>
                  ) : (
                    <Dropdown onSelect={handleOverflow}>
                      <div className="no-caret">
                        <Dropdown.Toggle size="sm" variant="outline-secondary">
                          <img
                            src={menu}
                            alt="menu"
                            className="ml-2"
                            width="24"
                            height="24"
                          />
                          {(loggedInUserIsAdmin || currentUserIsTeamMember) && (
                            <Dropdown.Menu>
                              {currentUserIsTeamLead || loggedInUserIsAdmin ? (
                                <Dropdown.Item eventKey="team-settings">
                                  Team Settings
                                </Dropdown.Item>
                              ) : null}
                              {!currentUserIsTeamLead &&
                              currentUserIsTeamMember ? (
                                <Dropdown.Item eventKey="leave-team">
                                  Leave Team
                                </Dropdown.Item>
                              ) : null}
                            </Dropdown.Menu>
                          )}
                        </Dropdown.Toggle>
                      </div>
                    </Dropdown>
                  )}
                </>
              )}
            </Row>
            {isLoading ? (
              <Row>
                <div className="first-skeleton-members-list">
                  <Skeleton height={45} width={45} duration={2} circle={true} />
                </div>
                <div className="skeleton-members-list">
                  <Skeleton height={45} width={45} duration={2} circle={true} />
                </div>
                <div className="skeleton-members-list">
                  <Skeleton height={45} width={45} duration={2} circle={true} />
                </div>
                <div className="skeleton-members-list">
                  <Skeleton height={45} width={45} duration={2} circle={true} />
                </div>
                <div className="skeleton-members-list">
                  <Skeleton height={45} width={45} duration={2} circle={true} />
                </div>
                <div className="last-skeleton-members-list">
                  <Skeleton height={45} width={70} duration={2} circle />
                </div>
                <div className="skeleton-invite-button">
                  <Skeleton height={45} width={180} />
                </div>
              </Row>
            ) : (
              <MembersList
                teamMembers={teamMembers}
                handleShowInviteModal={handleShowInviteModal}
                teamId={teamId}
                teamName={teamName}
                isLoading={isLoading}
                handleShowSampleDataModal={handleShowSampleDataModal}
                isSample={isSample}
                automatic={automatic}
              />
            )}
          </Col>
        </Row>
      </Container>
      <ConfirmationModal
        show={showLeaveTeamConfirmation}
        onHide={() => setShowLeaveTeamConfirmation(false)}
        headerText="Leave Team"
        bodyText={`Are you sure you would like to leave the ${teamName}?`}
        handleConfirm={handleRemoveMemberButton}
      />
    </div>
  );
}
