export const validateEmail = (
  emailAddress: string | null,
  allowedDomains: string[]
) => {
  if (!emailAddress) {
    return false;
  }
  const domain = emailAddress.split("@")[1].toLowerCase();
  if (!allowedDomains.includes(domain)) {
    return false;
  }
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(emailAddress).toLowerCase());
};
