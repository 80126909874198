/**
 *
 * LimitedTextAreaInput
 *
 */
import React, { Dispatch, SetStateAction } from "react";
import { Form } from "react-bootstrap";

interface Props {
  limit: number;
  formLabel: string;
  placeholder: string;
  rows: number;
  customMessage: string;
  setCustomMessage: Dispatch<SetStateAction<string>>;
}

export function LimitedTextAreaInput({
  limit,
  formLabel,
  placeholder,
  rows,
  customMessage,
  setCustomMessage
}: Props) {
  const onChangeCustomMessage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // Only allow the limit prop character limit for custom message
    const formattedCustomMessage = event.target.value.slice(0, limit);
    setCustomMessage(formattedCustomMessage);
  };

  return (
    <>
      <Form.Group controlId="customMessage">
        <Form.Label
          className="form-label-text mb-2"
          style={{ fontWeight: "normal", fontSize: "16px" }}
        >
          {formLabel}
        </Form.Label>
        <Form.Control
          as="textarea"
          type="text"
          value={customMessage}
          onChange={onChangeCustomMessage}
          placeholder={placeholder}
          rows={rows}
          maxLength={limit}
        />
      </Form.Group>
      <p className="text-right" style={{ color: "#86888b" }}>
        {customMessage.length}/{limit}
      </p>
    </>
  );
}
