import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => state.inviteLink || initialState;

export const selectInviteLink = createSelector(
  [selectDomain],
  (inviteLinkState) => inviteLinkState
);

export const selectCompanyName = createSelector(
  [selectDomain],
  (inviteLinkState) => inviteLinkState.companyName
);

export const selectAllowableDomains = createSelector(
  [selectDomain],
  (inviteLinkState) => inviteLinkState.allowableDomains
);

export const selectEmailSent = createSelector(
  [selectDomain],
  (inviteLinkState) => inviteLinkState.emailSent
);

export const selectExpiredLink = createSelector(
  [selectDomain],
  (inviteLinkState) => inviteLinkState.expiredLink
);

export const selectErrorMessage = createSelector(
  [selectDomain],
  (inviteLinkState) => inviteLinkState.errorMessage
);
