import { all, call, put, takeLatest } from "redux-saga/effects";
import { request } from "utils/request";
import { actions } from "./slice";
import { GetTeamPersonalityReportResponse } from "../TeamPage/types";
import { TeamData } from "./types";

export function* getPersonalityReports(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/teams/${action.payload}/personalityReport`;
    const getPersonalityReportResponse: GetTeamPersonalityReportResponse = yield call(
      request,
      requestUrl
    );
    yield put(
      actions.getPersonalityReportsSuccess(getPersonalityReportResponse)
    );
  } catch (error) {
    yield put(actions.getPersonalityReportsError(error.response));
  }
}

export function* getPersonalityPDF(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/teams/${action.payload}/personality`;
    const personalityReportResponse = yield call(request, requestUrl);
    yield put(actions.getPersonalityPDFSuccess(personalityReportResponse));
  } catch (error) {
    yield put(actions.getPersonalityPDFError(error.response));
  }
}

export function* getTeams(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/teams/${action.payload}`;
    const getTeamsResponse: TeamData = yield call(request, requestUrl);
    yield put(actions.getTeamsSuccess(getTeamsResponse));
  } catch (error) {
    yield put(actions.getTeamsError(error.response));
  }
}

export function* teamPersonalitySaga() {
  yield all([
    takeLatest(actions.getPersonalityReports.type, getPersonalityReports),
    takeLatest(actions.getTeams.type, getTeams),
    takeLatest(actions.getPersonalityPDF.type, getPersonalityPDF),
  ]);
}
