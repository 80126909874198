import React from "react";
import { Container, Row } from "react-bootstrap";
import { LinkButton } from "../LinkButton";
import teamIcon from "../../../resources/images/team-icon.svg";
import email from "../../../resources/images/email.svg";

interface Props {
  handleClick: () => void;
  bannerText: string;
  createdEmployeesNumber?: number;
}

export const Banner = ({
  handleClick,
  bannerText,
  createdEmployeesNumber,
}: Props) => {
  return (
    <Container className="admin-console-banner" fluid="lg">
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <p className="mr-1 mb-0">
          <img
            src={bannerText === "adminConsole" ? teamIcon : email}
            alt="admin console"
            className="mr-2"
            style={{ marginBottom: "2px" }}
          />
          {bannerText === "adminConsole"
            ? "Create, invite and manage your employees in the"
            : `Invite all created employees who have not joined Talent Insights yet.`}
        </p>
        <LinkButton
          text={
            bannerText === "adminConsole"
              ? "Admin Console"
              : `Invite ${createdEmployeesNumber} Employees`
          }
          showArrow=""
          handler={handleClick}
          style={{ textDecoration: "underline" }}
        />
      </Row>
    </Container>
  );
};
