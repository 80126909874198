import { all, call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { request } from "utils/request";
import { Team } from "./types";

export function* getTeamMembers(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/teams/${action.payload}`;
    const getTeamMembersResponse: Team = yield call(request, requestUrl);
    yield put(actions.getTeamMembersSuccess(getTeamMembersResponse));
  } catch (error) {
    yield put(
      actions.getTeamMembersError("There was an error retrieving your data.")
    );
  }
}

export function* removeTeamMember(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/teamMembers/${action.payload.teamMemberId}`;
    const getTeamMembersResponse: Team = yield call(request, requestUrl, {
      method: "DELETE",
    });
    yield put(actions.getTeamMembers(action.payload.teamId));
    yield put(actions.removeTeamMemberSuccess(getTeamMembersResponse));
  } catch (error) {
    yield put(actions.removeTeamMemberError(error.response));
  }
}

export function* getAllowedDomains(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/company/${action.payload}`;
    const getAllowedDomainsResponse = yield call(request, requestUrl);
    yield put(
      actions.getAllowedDomainsSuccess(getAllowedDomainsResponse.allowedDomains)
    );
  } catch (error) {
    yield put(actions.getAllowedDomainsError(error.response));
  }
}

export function* inviteMembers(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/teams/${action.payload.teamId}/teamMembers`;
    yield call(request, requestUrl, {
      method: "POST",
      body: JSON.stringify(action.payload.formData),
    });
    yield put(actions.getTeamMembers(action.payload.teamId));
    yield put(actions.inviteMembersSuccess());
  } catch (error) {
    yield put(actions.inviteMembersError(error.response));
  }
}

export function* resendInvitation(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/teamMembers/${action.payload}/resendInvitation`;
    yield call(request, requestUrl, {
      method: "POST",
    });
    yield put(actions.resendInviationSuccess());
  } catch (error) {
    yield put(
      actions.resendInvitationError(
        "There was an error in resending your invitation."
      )
    );
  }
}

export function* getUsersList(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/list`;
    const getUsersListResponse = yield call(request, requestUrl);
    yield put(actions.getUsersListSuccess(getUsersListResponse));
  } catch (error) {
    yield put(
      actions.getUsersListError("There was an error in retrieving your users.")
    );
  }
}

export function* teamListSaga() {
  yield all([
    takeLatest(actions.getTeamMembers.type, getTeamMembers),
    takeLatest(actions.removeTeamMember.type, removeTeamMember),
    takeLatest(actions.getAllowedDomains.type, getAllowedDomains),
    takeLatest(actions.inviteMembers.type, inviteMembers),
    takeLatest(actions.resendInvitation.type, resendInvitation),
    takeLatest(actions.getUsersList.type, getUsersList),
  ]);
}
