import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copyLinkIcon from "resources/images/copy-link-icon.svg";
import linkCopiedIcon from "resources/images/link-copied-icon.svg";

interface Props {
  show: boolean;
  setShowInviteLinkModal: any;
  link?: string;
}

export const InviteLinkModal = ({
  show,
  setShowInviteLinkModal,
  link,
}: Props) => {
  const [copied, setCopied] = useState(false);

  return (
    <Modal
      show={show}
      onHide={() => setShowInviteLinkModal(false)}
      id="invite-modal"
    >
      <Modal.Header closeButton>Invite Colleagues to Criteria</Modal.Header>
      <Modal.Body className="pl-4 pr-4 pt-3">
        <Row>
          <Col className="invite-modal-text">
            Invite your colleagues to join Criteria by sharing the invite link
            below. Only employees with a company email address will be able to
            create an account. .
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <CopyToClipboard onCopy={() => setCopied(true)} text={link}>
              <button id="copy-link-button" className="mt-5">
                <img
                  src={copied ? linkCopiedIcon : copyLinkIcon}
                  alt="copy link"
                  height="16px"
                  width="16px"
                  className="mr-1"
                />{" "}
                {copied ? "Link Copied to Clipboard" : "Copy Link"}
              </button>
            </CopyToClipboard>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="text-center">
            <p
              style={{
                color: "#86888b",
                fontSize: "14px",
              }}
            >
              {`${link?.substr(0, 48)}...`}
            </p>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
