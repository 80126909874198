import React, { useState, useEffect } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { MemberScores, TeamData } from "../types";

interface Props {
  showTooltip: boolean;
  memberScores: MemberScores[] | null;
  team: TeamData;
  handler: () => void;
}

export function ViewInsightsButton({
  showTooltip,
  memberScores,
  handler,
  team,
}: Props) {
  /****************************************************************************
   * State variables                                                          *
   ****************************************************************************/
  const [showViewInsightsButton, setShowViewInsightsButton] = useState(false);

  /****************************************************************************
   * Hooks                                                                    *
   ****************************************************************************/

  // determine if the View Insights buttons should be disabled (it is disabled
  // if there are less than 3 completed Epps among team mmembers
  useEffect(() => {
    const numberOfCompletedEpps = team?.teamMembers.reduce((total, member) => {
      if (member.completedEpp) {
        return total + 1;
      }
      return total;
    }, 0);
    if (numberOfCompletedEpps > 2) {
      setShowViewInsightsButton(true);
    }
  }, [team]);

  return (
    <span id="explore-button">
      <OverlayTrigger
        show={showTooltip}
        overlay={
          <Popover
            className="explore-button-tooltip"
            id="explore-button-tooltip"
          >
            Once three members of your team have completed the personality test,
            you will be able to view Team Personality insights.
          </Popover>
        }
      >
        <button
          onClick={handler}
          className="teal-button"
          style={{
            width: "127px",
            backgroundColor: `${!showViewInsightsButton ? "#c0eff1" : ""}`,
          }}
          disabled={!showViewInsightsButton}
        >
          View Insights
        </button>
      </OverlayTrigger>
    </span>
  );
}
