/**
 *
 * BulkImport
 *
 */

import React, { useState } from "react";
import Switch from "react-switch";
import { Row, Col, FormGroup, FormControl } from "react-bootstrap";
import "./styles.css";
import csvDownload from "../../../resources/images/csv-download.svg";
import checkmark from "../../../resources/images/checkmark.svg";
import warning from "../../../resources/images/warning.svg";
import { DownloadCSV } from "./DownloadCSV";

interface Props {
  handleUpload: (event: any) => void;
  csvUploadValidated: boolean;
  fileUploaded: boolean;
  uploadError: string;
  handleNewEmployeesAdded: () => void;
  handleEmployeesUpdated: () => void;
  handleEmployeesDeactivated: () => void;
  hsVersion: number;
}

export const BulkImport = ({
  handleUpload,
  csvUploadValidated,
  fileUploaded,
  uploadError,
  handleNewEmployeesAdded,
  handleEmployeesUpdated,
  handleEmployeesDeactivated,
  hsVersion
}: Props) => {
  /* local state variables */
  const [showRequirements, setShowRequirements] = useState(false);
  const [addNewEmployees, setAddNewEmployees] = useState(false);
  const [updateEmployees, setUpdateEmployees] = useState(false);
  const [deactivateEmployees, setDeactivateEmployees] = useState(false);

  /****************************************************************************
   * Handlers
   ****************************************************************************/

  const toggleShowRequirements = () => {
    setShowRequirements(!showRequirements);
  };

  const handleAddNewEmployees = () => {
    handleNewEmployeesAdded();
    setAddNewEmployees(!addNewEmployees);
  };

  const handleUpdateEmployees = () => {
    handleEmployeesUpdated();
    setUpdateEmployees(!updateEmployees);
  };

  const handleDeactivateEmployees = () => {
    handleEmployeesDeactivated();
    setDeactivateEmployees(!deactivateEmployees);
  };

  return (
    <>
      <Row>
        <Col className="mb-4 mt-0 template-row">
          <img
            src={csvDownload}
            alt="download icon"
            style={{ height: "16px", marginTop: "3px" }}
          />
          <p className="import-form-text">
            Ensure your employees are imported correctly by using our
          </p>
          <DownloadCSV hsVersion={hsVersion} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup className="mb-2">
            <div className="csv-upload">
              <FormControl
                id="fileUpload"
                className="csv-upload"
                type="file"
                accept=".csv"
                onChange={handleUpload}
              />
              {csvUploadValidated && fileUploaded && (
                <img
                  src={checkmark}
                  alt="checkmark"
                  style={{ marginLeft: "5px" }}
                />
              )}
            </div>
            {uploadError ? (
              <p className="csv-error">
                <img
                  src={warning}
                  alt="warning-icon"
                  style={{ margin: "0 7px 3px 0" }}
                />
                {`${
                  uploadError === "CSVError"
                    ? "The file was unable to be imported. Please choose a CSV that is under 5MB and try again."
                    : "The file must contain fields for First Name, Last Name, Email Address."
                }`}
              </p>
            ) : null}
          </FormGroup>
          <p style={{ color: "#86888b", marginTop: "10px" }}>
            File size limit: 5 MB
          </p>
        </Col>
      </Row>
      <Row className="mt-1 mb-3">
        <Col>
          <p
            onClick={toggleShowRequirements}
            className="format-requirements-text"
          >
            {showRequirements ? "Hide" : "View"} CSV Format Requirements
          </p>
          {showRequirements ? (
            <div
              className="requirements-list"
              style={{ color: "#53565a", fontSize: "14px" }}
            >
              {`CSV files should be formatted with ${
                hsVersion > 1 ? "5" : "4"
              } columns:`}
              <ul style={{ padding: "10px 0 0 25px" }}>
                <li>First Name</li>
                <li>Last Name</li>
                <li>Email Address</li>
                <li>Job Title</li>
                {hsVersion > 1 && <li>Manager Email</li>}
              </ul>
              First name, last name, and email address fields are required.
            </div>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col>
          <strong>Import Options</strong>
          <div className="import-options">
            <div className="import-options-switch">
              <Switch
                onChange={handleAddNewEmployees}
                checked={addNewEmployees}
                className="react-switch"
                uncheckedIcon={false}
                checkedIcon={false}
                onColor={"#2dccd3"}
                height={18}
                width={40}
              />
              <p style={{ margin: "0 0 0 5px" }}>Add new employees</p>
            </div>
            <div className="import-options-switch">
              <Switch
                onChange={handleUpdateEmployees}
                checked={updateEmployees}
                className="react-switch"
                uncheckedIcon={false}
                checkedIcon={false}
                onColor={"#2dccd3"}
                height={18}
                width={40}
              />
              <p style={{ margin: "0 0 0 5px" }}>Update existing employees</p>
            </div>
            <div className="import-options-switch">
              <Switch
                onChange={handleDeactivateEmployees}
                checked={deactivateEmployees}
                className="react-switch"
                uncheckedIcon={false}
                checkedIcon={false}
                onColor={"#2dccd3"}
                height={18}
                width={40}
              />
              <p style={{ margin: "0 0 0 5px" }}>
                Deactivate employees who are not found in import
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
