import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => state.createTeam || initialState;

export const selectCreateTeam = createSelector(
  [selectDomain],
  (createTeamState) => createTeamState
);

export const selectAllowedDomains = createSelector(
  [selectDomain],
  (createTeamState) => createTeamState.allowedDomains
);

export const selectUsersList = createSelector(
  [selectDomain],
  (createTeamState) => createTeamState.usersList
);

export const selectSafeToRedirect = createSelector(
  [selectDomain],
  (createTeamState) => createTeamState.safeToRedirect
);

export const selectNewTeamName = createSelector(
  [selectDomain],
  (createTeamState) => createTeamState.newTeamName
);

export const selectNewTeamId = createSelector(
  [selectDomain],
  (createTeamState) => createTeamState.newTeamId
);

export const selectCreatingNewTeam = createSelector(
  [selectDomain],
  (createTeamState) => createTeamState.creatingNewTeam
);
