import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";

// The initial state of the CreateTeam container
export const initialState: ContainerState = {
  allowedDomains: [],
  loadingAllowedDomains: false,
  creatingNewTeam: false,
  safeToRedirect: false,
  errorMessage: null,
  newTeamName: null,
  newTeamId: null,
  usersList: null,
  loadingUsers: false,
};

const createTeamSlice = createSlice({
  name: "createTeam",
  initialState,
  reducers: {
    getAllowedDomains(state, action: PayloadAction<any>) {
      state.loadingAllowedDomains = true;
    },
    getAllowedDomainsSuccess(state, action: PayloadAction<any>) {
      state.loadingAllowedDomains = false;
      state.allowedDomains = action.payload;
    },
    getAllowedDomainsError(state, action: PayloadAction<any>) {
      state.loadingAllowedDomains = false;
      state.errorMessage = action.payload;
    },
    createNewTeam(state, action: PayloadAction<any>) {
      state.creatingNewTeam = true;
      state.newTeamName = action.payload.teamName;
    },
    createNewTeamSuccess(state, action: PayloadAction<any>) {
      state.safeToRedirect = true;
      state.creatingNewTeam = false;
      state.newTeamId = action.payload.teamId;
    },
    createNewTeamError(state, action: PayloadAction<any>) {
      state.creatingNewTeam = false;
      state.errorMessage = action.payload;
    },
    resetState(state) {
      state.errorMessage = initialState.errorMessage;
      state.newTeamName = initialState.newTeamName;
      state.newTeamId = initialState.newTeamId;
    },
    getUsersList(state) {
      state.loadingUsers = true;
    },
    getUsersListSuccess(state, action: PayloadAction<any>) {
      state.loadingUsers = false;
      state.usersList = action.payload;
    },
    getUsersListError(state, action: PayloadAction<any>) {
      state.loadingUsers = false;
      state.errorMessage = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = createTeamSlice;
