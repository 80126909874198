import React from "react";
import { Row } from "react-bootstrap";
import { Modal } from "react-bootstrap";

interface Props {
  show: boolean;
  onHide: () => void;
  updateShowAutomaticTeams: () => void;
  hasAutomaticTeamsOn: number;
}

export default function AutomaticTeamsConfirmationModal({
  show,
  onHide,
  updateShowAutomaticTeams,
  hasAutomaticTeamsOn
}: Props) {
  // useEffectOnMount(() => {
  //   if (show) {
  //     if (confirmationFrom === "addEmployee") {
  //       window.analytics.track("User Created", {
  //         location: "Admin Console"
  //       });
  //     }
  //     if (confirmationFrom === "inviteAll") {
  //       window.analytics.track("Talent Insights Bulk Invite", {
  //         numberInvited: bulkInvitedNumber
  //       });
  //     }
  //   }
  // });

  return (
    <Modal show={show} onHide={onHide} style={{ padding: "10px" }}>
      <Modal.Header className="modal-header" closeButton>
        {hasAutomaticTeamsOn
          ? "Turn Off Automatic Teams"
          : "Turn On Automatic Teams"}
      </Modal.Header>
      <Modal.Body style={{ paddingLeft: "30px", paddingRight: "30px" }}>
        <Row className="mt-1 mb-1 justify-content-center">
          {hasAutomaticTeamsOn ? (
            <p className="modal-text">
              Are you sure you want to turn off Automatic Teams for your
              organization? All Automatic Teams will not be available after you
              make this change.
            </p>
          ) : (
            <p className="modal-text">
              Turning on Automatic Teams will send email notifications to any
              invited or active users with reporting relationships including
              manager or direct reports. Are you sure you want to turn on
              Automatic Teams?
            </p>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button className="link-button ml-5 pl-3 pr-3" onClick={onHide}>
          No, Cancel
        </button>
        <button
          className={`modal-button ml-5 pl-3 pr-3 ${
            hasAutomaticTeamsOn ? "deactivate-button" : ""
          }`}
          onClick={updateShowAutomaticTeams}
        >
          {`Yes, Turn ${hasAutomaticTeamsOn ? "Off" : "On"} Automatic Teams`}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
