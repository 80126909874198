import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Chart from "chart.js";
import { Row, Col, Card, Badge } from "react-bootstrap";
import { selectTraitScores } from "./selectors";

export function WorkplaceInsightsGraph({ user, loaded }) {
  const traitScores = useSelector(selectTraitScores);

  const convertedScores = [
    traitScores?.Achievement + 25,
    traitScores?.Motivation + 25,
    traitScores?.Conscientiousness + 25,
    traitScores?.Assertiveness + 25,
    traitScores?.Extroversion + 25,
    traitScores?.Cooperativeness + 25,
    traitScores?.Competitiveness + 25,
    traitScores?.Patience + 25,
    traitScores?.["Self-Confidence"] + 25,
    traitScores?.Openness + 25,
  ];

  const scoreData = [
    {
      data: [25, 25, 25, 25, 25, 25, 25, 25, 25, 25],
      fill: true,
      borderColor: "rgb(183.6, 191.25, 201.45)",
      backgroundColor: "rgba(204, 209, 219, 1)",
      borderWidth: 1,
      pointBorderWidth: 0,
      pointHitRadius: 0,
      label: "",
    },
    {
      data: convertedScores,
      fill: true,
      backgroundColor: "rgba(0, 31, 78, 0.2)",
      borderColor: "rgb(0, 31, 78)",
      pointBackgroundColor: "rgb(0, 31, 78)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgb(0, 31, 78)",
      label: `${user?.firstName}`,
    },
    {
      data: [75, 75, 75, 75, 75, 75, 75, 75, 75, 75],
      fill: true,
      backgroundColor: "rgba(255, 255, 255, 0)",
      borderColor: "rgb(100, 100, 100)",
      borderDash: [3, 3],
      borderDashOffset: 3,
      borderWidth: 2,
      pointBorderWidth: 0,
      pointHitRadius: 0,
      pointRadius: 0,
      label: "General Population",
    },
  ];
  Chart.defaults.global.defaultFontColor = "#001f4e";

  useEffect(() => {
    if (loaded && scoreData) {
      const ctx = document.getElementById("WIRChart");
      if (ctx) {
        // eslint-disable-next-line no-unused-vars
        const teamChart = new Chart(ctx, {
          type: "radar",
          data: {
            labels: [
              "Achievement",
              "Motivation",
              "Conscientiousness",
              "Assertiveness",
              "Extroversion",
              "Cooperativeness",
              "Competitiveness",
              "Patience",
              "Self-Confidence",
              "Openness",
            ],
            datasets: scoreData,
          },
          options: {
            animation: false,
            legend: {
              display: false,
              position: "bottom",
              align: "center",
              labels: {
                filter: function (legendItem, data) {
                  if (legendItem.text === "") return false;
                  else return true;
                },
              },
            },
            scale: {
              ticks: {
                beginAtZero: true,
                min: 0,
                max: 125,
                stepSize: 25,
                display: false,
              },
              pointLabels: {
                fontColor: [
                  "#425CC7",
                  "#425CC7",
                  "#425CC7",
                  "#ffa300",
                  "#ffa300",
                  "#ffa300",
                  "#FF5C39",
                  "#FF5C39",
                  "#2dccd3",
                  "#2dccd3",
                ],
                fontStyle: "bold",
                fontSize: window.innerWidth < 500 ? 9 : 13,
              },
            },
            layout: {
              padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              },
            },
            tooltips: {
              enabled: false,
            },
          },
        });
      }
    }
  }, [loaded, scoreData]);
  return (
    <Card className="mt-0 individual-personality-body-card">
      {loaded ? (
        <Card.Body>
          <p className="headline-text">{`${user?.firstName}'s Personality Summary`}</p>

          <Row id="individual-wir">
            <Col lg={12} md={12} sm={12} xs={12}>
              <Badge pill id="workHabitsPill">
                <span className="badge-title">Work Habits</span>
              </Badge>
              <Badge pill id="attitudesOutlookPill">
                <span className="badge-title">Attitudes &amp; Outlook</span>
              </Badge>
              <canvas id="WIRChart" />
              <Badge pill id="interactionStylePill">
                <span className="badge-title">Interaction Style</span>
              </Badge>
              <Badge pill id="temperamentPill">
                <span className="badge-title">Temperament</span>
              </Badge>
              <Row className="my-3">
                <Col className="text-center">
                  <div className="insightsChartLegendObject">&nbsp;</div>
                  <div className="insightsChartLegendLabel mr-2">
                    {user?.firstName}
                  </div>
                  <div className="insightsChartLegendObject insightsChartLegendObject-general">
                    &nbsp;
                  </div>
                  <div className="insightsChartLegendLabel mr-4">
                    General Population
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      ) : null}
    </Card>
  );
}
