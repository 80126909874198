import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";

// The initial state of the InviteConfirmation container
export const initialState: ContainerState = {
  acceptingInvitation: false,
  errorMessage: null,
  loadingTeams: false,
};

const inviteConfirmationSlice = createSlice({
  name: "inviteConfirmation",
  initialState,
  reducers: {
    acceptInvitation(state, action: PayloadAction<any>) {
      state.acceptingInvitation = true;
    },
    acceptInvitationSuccess(state, action: PayloadAction<any>) {
      state.acceptingInvitation = false;
    },
    acceptInvitationError(state, action: PayloadAction<any>) {
      state.acceptingInvitation = false;
      state.errorMessage = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = inviteConfirmationSlice;
