import { call, put, takeLatest, all } from "redux-saga/effects";
import { actions } from "./slice";
import { request } from "utils/request";

export function* getCompanyInfo(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/checkInviteLink`;
    const formData = {
      code: action.payload,
    };
    const getCompanyInfoResponse = yield call(request, requestUrl, {
      method: "POST",
      body: JSON.stringify(formData),
    });
    yield put(actions.getCompanyInfoSuccess(getCompanyInfoResponse));
  } catch (error) {
    yield put(actions.getCompanyInfoError(error.response.status));
  }
}
export function* sendEmailAddress(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/inviteLink`;
    const sendEmailAddressResponse = yield call(request, requestUrl, {
      method: "POST",
      body: JSON.stringify(action.payload),
    });
    yield put(actions.sendEmailAddressSuccess(sendEmailAddressResponse));
  } catch (error) {
    let errorMessage;
    if (error.response.status === 400) {
      errorMessage =
        "This email address is already in use. Click “Go to Login Page” below to login.";
    }
    yield put(actions.sendEmailAddressError(errorMessage));
  }
}

export function* inviteLinkSaga() {
  yield all([
    takeLatest(actions.getCompanyInfo.type, getCompanyInfo),
    takeLatest(actions.sendEmailAddress.type, sendEmailAddress),
  ]);
}
