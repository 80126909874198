import Cookies from "js-cookie";

export const redirectUserFromAccountSetup = (
  authenticated,
  teamName,
  teamId,
  alreadyAccepted
) => {
  // if the user is not authenticated, redirect to login screen
  if (authenticated !== undefined && !authenticated) {
    localStorage.setItem("teamName", teamName);
    localStorage.setItem("tid", teamId);
    Cookies.set("rdt-ti", 1, {
      path: "/",
      domain:
        new URL(`${process.env.REACT_APP_URL}`).hostname === "localhost"
          ? "localhost"
          : ".criteriacorp.com"
    });
    window.location.href = `${process.env.REACT_APP_LOGOUT_URL}${
      alreadyAccepted ? "?login=true" : ""
    }`;
    return;
    // if user is authenticated, redirect to dashboard
  } else if (authenticated) {
    window.location.href = `${process.env.REACT_APP_URL}?team=${teamName}&tid=${teamId}`;
    return;
  }
  return false;
};
