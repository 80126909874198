/**
 *
 * ImportCSV
 *
 */

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Button, ProgressBar } from "react-bootstrap";
import Loader from "react-loader-advanced";
import { ThreeBounce } from "better-react-spinkit";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./slice";
import { importCSVSaga } from "./saga";
import {
  selectLoadingPreview,
  selectPreviewCSVResponse,
  selectProcessCSVResponse,
  selectSafeToRedirect,
  selectProcessingCSV,
} from "./selectors";
import { selectUser } from "app/containers/Global/selectors";
import { selectHsVersion } from "../AdminConsole/selectors";
import "./styles.css";
import { useHistory, Redirect } from "react-router-dom";
import { LinkButton } from "../../components/LinkButton";
import { BulkImport } from "./BulkImport";
import { PreviewImport } from "./PreviewImport";
import csv from "csvtojson";

export function ImportCSV() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: importCSVSaga });

  const [csvUploadValidated, setCSVUploadValidated] = useState(false);
  const [currentPage, setCurrentPage] = useState("import");
  const [fileUploaded, setFileUploaded] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [users, setUsers] = useState<any[]>([]);
  const [uploadError, setUploadError] = useState("");
  const [missingFirstName, setMissingFirstName] = useState(false);
  const [missingLastName, setMissingLastName] = useState(false);
  const [missingEmail, setMissingEmail] = useState(false);
  const [overSized, setOverSized] = useState(false);
  const [newEmployeesAdded, setNewEmployeesAdded] = useState(false);
  const [employeesUpdated, setEmployeesUpdated] = useState(false);
  const [employeesDeactivated, setEmployeesDeactivated] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [timePerInterval, setTimePerInterval] = useState(0);
  const [processingSmallCSV, setProcessingSmallCSV] = useState(false);
  const user = useSelector(selectUser);
  const previewCSVResponse = useSelector(selectPreviewCSVResponse);
  const processCSVResponse = useSelector(selectProcessCSVResponse);
  const safeToRedirect = useSelector(selectSafeToRedirect);
  const loadingPreview = useSelector(selectLoadingPreview);
  const processingCSV = useSelector(selectProcessingCSV);
  const hsVersion = useSelector(selectHsVersion);

  const history = useHistory();

  const dispatch = useDispatch();

  /****************************************************************************
   * Hooks
   ****************************************************************************/

  useEffect(() => {
    if (loadingPreview) {
      setCurrentPage("loading");
    }
    if (currentPage === "loading" && !loadingPreview) {
      setCurrentPage("preview");
    }
  }, [currentPage, loadingPreview]);

  useEffect(() => {
    if (user && user.tmgRoleId !== 1) history.push("/");
    if (hsVersion <= 1) history.push("/admin");
  }, [dispatch, history, hsVersion, user]);

  /****************************************************************************
   *  Handlers
   ****************************************************************************/

  const handleBack = () => {
    dispatch(actions.resetState());
    if (currentPage !== "import") {
      resetData();
    } else {
      history.push("/admin");
    }
  };

  const resetData = () => {
    setCurrentPage("import");
    setFileUploaded(false);
    setUsers([]);
    setCSVUploadValidated(false);
    setLoadingProgress(0);
    setNewEmployeesAdded(false);
    setEmployeesUpdated(false);
    setEmployeesDeactivated(false);
  };

  // formating user data for request
  const getUsersData = () => {
    const usersData = {
      rows: {},
      deactivateUsers: employeesDeactivated ? 1 : 0,
      addUsers: newEmployeesAdded ? 1 : 0,
      updateUsers: employeesUpdated ? 1 : 0,
    };
    users?.slice(1, users.length).forEach((user, i) => {
      usersData.rows[i] = user;
    });
    return usersData;
  };

  // Add new user to users in state while processing each row
  const handleAddUser = (headers, csv) => {
    const newUsersObject: any = {};
    if (!headers.includes("jobTitle")) {
      newUsersObject.jobTitle = "";
    }
    headers.forEach((key, i) => {
      newUsersObject[key] = csv[i];
    });
    const usersCopy = users;
    usersCopy.push(newUsersObject);
    setUsers(usersCopy);
  };

  // Validating csv upload, checking header and setting new users in state
  const handleUpload = (event) => {
    setFileUploaded(true);
    setUploading(true);
    setUsers([]);
    let headers = [];

    const validateHeader = (csv) => {
      const headerKeys = csv
        .map((key) => {
          return key.toLowerCase().replace(/\s+/g, "");
        })
        .map((key) => {
          if (key.includes("first")) {
            return "firstName";
          } else if (key.includes("last")) {
            return "lastName";
            // check to make sure there is an email field on the csv that
            // isn't manager email to be used as the employee email
          } else if (key.includes("email") && !key.includes("manager")) {
            return "emailAddress";
          } else if (key.includes("job")) {
            return "jobTitle";
          } else if (key.includes("manager")) {
            return "manager";
          } else {
            return true;
          }
        });
      if (!headerKeys.includes("firstName")) {
        setMissingFirstName(true);
      }
      if (!headerKeys.includes("lastName")) {
        setMissingLastName(true);
      }
      if (!headerKeys.includes("emailAddress")) {
        setMissingEmail(true);
      }
      if (
        headerKeys.includes("firstName") &&
        headerKeys.includes("lastName") &&
        headerKeys.includes("emailAddress")
      ) {
        setCSVUploadValidated(true);
        setUploadError("");
        headers = headerKeys;
        return true;
      } else {
        setUploadError("headerError");
        setCSVUploadValidated(false);
        return false;
      }
    };

    const uploadFileIsCSV =
      event.target.files.length > 0 &&
      /.+(.csv)$/.test(event.target.files[0].name);

    if (uploadFileIsCSV && event.target.files[0].size < 5 * 1024 * 1024) {
      const reader = new window.FileReader();
      let headerValidated = false;
      reader.onload = () => {
        csv({ noheader: true, output: "csv" })
          // @ts-ignore
          .fromString(reader.result)
          .subscribe((csvRow) => {
            if (headers.length < 1) {
              headerValidated = validateHeader(csvRow);
            }
            if (headerValidated) {
              handleAddUser(headers, csvRow);
            }
          })
          .on("done", () => {
            setUploading(false);
          });
      };
      reader.readAsText(event.target.files[0]);
    } else {
      setUploading(false);
      setCSVUploadValidated(false);
      setOverSized(true);
      setUploadError("CSVError");
    }
  };

  const handleNewEmployeesAdded = () => {
    setNewEmployeesAdded(!newEmployeesAdded);
  };

  const handleEmployeesUpdated = () => {
    setEmployeesUpdated(!employeesUpdated);
  };

  const handleEmployeesDeactivated = () => {
    setEmployeesDeactivated(!employeesDeactivated);
  };

  const sendPreviewImportData = () => {
    if (fileUploaded) {
      window.analytics.track("Talent Insights CSV Uploaded", {
        success: csvUploadValidated,
        failureReason: uploadError
          ? {
              missingFirstName: missingFirstName,
              missingLastName: missingLastName,
              missingEmail: missingEmail,
              overSized: overSized,
            }
          : false,
        addEmployees: newEmployeesAdded,
        updateEmployees: employeesUpdated,
        deactivateEmployees: employeesDeactivated,
      });
    }
  };

  const handlePreviewImport = () => {
    sendPreviewImportData();
    if (csvUploadValidated) {
      const usersData = getUsersData();
      dispatch(actions.previewCSV(usersData));
      setCurrentPage("loading");
    }
  };

  const handleProcessCSV = () => {
    const candidateData = {
      usersToAdd: previewCSVResponse?.usersToAdd,
      usersToUpdate: previewCSVResponse?.usersToUpdate,
      usersToDeactivate: previewCSVResponse?.usersToDeactivate,
      inviteCreatedEmployees: 0,
    };
    determineTimePerInterval(previewCSVResponse);
    dispatch(actions.processCSV(candidateData));
    setCurrentPage("currentlyProcessing");
  };

  // Determining time per interval based on the number of rows
  // needed to import. Used in progress bar
  const determineTimePerInterval = (previewCSVResponse) => {
    let rows =
      previewCSVResponse.usersToAdd.length +
      previewCSVResponse.usersToUpdate.length +
      previewCSVResponse.usersToDeactivate.length;
    if (rows > 10000) {
      const rowsPerSecond = 700;
      const totalSeconds = rows / rowsPerSecond;
      const totalMilSeconds = totalSeconds * 1000;
      const numOfIntervals = 100;
      const millisecondsPerInterval = Math.round(
        totalMilSeconds / numOfIntervals
      );
      setTimePerInterval(millisecondsPerInterval);
    } else {
      setProcessingSmallCSV(true);
    }
  };

  // Updated progress bar based on interval detemrined in determineTimePerInterval
  useEffect(() => {
    let counter = 0;
    if (processingCSV) {
      const interval = setInterval(() => {
        if (counter < 99) {
          counter++;
          setLoadingProgress(counter);
        } else {
          clearInterval(interval);
        }
      }, timePerInterval);
    }
  }, [processingCSV, timePerInterval]);

  useEffect(() => {
    if (processCSVResponse) {
      window.analytics.track("Talent Insight CSV Submitted", {
        numberCreated: processCSVResponse.newUserCount,
        numberUpdated: processCSVResponse.updatedUserCount,
        numberRemoved: processCSVResponse.deactivatedUserCount,
      });
    }
  }, [processCSVResponse]);

  const linkButtonStyle = {
    fontFamily: "Lato",
    fontSize: "14px",
    lineHeight: "32px",
    color: "#425cc7",
    margin: "24px 0 0 0",
  };

  return (
    <>
      <Helmet>
        <title>Import CSV</title>
        <meta name="description" content="Import CSV" />
      </Helmet>
      <Container fluid="lg">
        <LinkButton
          text={
            currentPage !== "import"
              ? "Back to CSV Import"
              : "Back to Employees"
          }
          showArrow="left"
          handler={handleBack}
          style={linkButtonStyle}
        />
      </Container>
      <Container
        fluid="lg"
        className="import-csv-container"
        style={{
          minHeight:
            currentPage === "currentlyProcessing" ||
            (previewCSVResponse && !previewCSVResponse.errorCount)
              ? "0px"
              : "300px",
        }}
      >
        <Row>
          <Col className="mb-4">
            <span className="import-csv-header">
              {currentPage === "import"
                ? "Import CSV"
                : currentPage === "loading"
                ? "Importing CSV..."
                : currentPage === "currentlyProcessing"
                ? "Processing CSV Import..."
                : "Preview CSV Import"}
            </span>
          </Col>
        </Row>
        <Loader
          show={loadingPreview || (processingSmallCSV && processingCSV)}
          message={<ThreeBounce color="#1F469A" size={50} />}
          contentBlur={30}
          messageStyle={{ height: "300px" }}
          backgroundStyle={{
            backgroundColor: "rgba( 0 0 0 0)",
          }}
          style={{
            minHeight:
              currentPage === "currentlyProcessing" ||
              (previewCSVResponse && !previewCSVResponse.errorCount)
                ? "0px"
                : "300px",
          }}
        >
          {currentPage === "import" && (
            <BulkImport
              handleUpload={handleUpload}
              csvUploadValidated={csvUploadValidated}
              fileUploaded={fileUploaded}
              uploadError={uploadError}
              handleNewEmployeesAdded={handleNewEmployeesAdded}
              handleEmployeesUpdated={handleEmployeesUpdated}
              handleEmployeesDeactivated={handleEmployeesDeactivated}
              hsVersion={hsVersion}
            />
          )}
          {currentPage === "preview" && previewCSVResponse && (
            <PreviewImport previewCSVResponse={previewCSVResponse} />
          )}
          {processingCSV && currentPage === "currentlyProcessing" && (
            <ProgressBar
              now={loadingProgress}
              label={`${loadingProgress}% completed`}
              max={100}
              className="progress"
            />
          )}
        </Loader>
        {currentPage !== "currentlyProcessing" ? (
          <Col className="mb-2 mt-5 text-right">
            <Button
              onClick={handleBack}
              style={{
                color: "#425cc7",
                backgroundColor: "white",
                border: "none",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Cancel
            </Button>
            <Button
              className="modal-button ml-2 pl-3 pr-3"
              type="submit"
              id="createNewUser"
              onClick={
                currentPage === "import"
                  ? handlePreviewImport
                  : handleProcessCSV
              }
              disabled={
                currentPage === "loading" ||
                uploading ||
                currentPage === "currentlyProcessing" ||
                (!newEmployeesAdded &&
                  !employeesUpdated &&
                  !employeesDeactivated)
              }
            >
              {currentPage === "import" ? "Preview Import" : "Finish Import"}
            </Button>
          </Col>
        ) : null}
      </Container>
      {safeToRedirect && processCSVResponse ? (
        <Redirect
          to={{
            pathname: "/admin",
            state: {
              from: "importCSV",
              userFirstName: null,
              userLastName: null,
              invitedToInsights: null,
              edited: null,
              newEmployeeCount: processCSVResponse.newUserCount,
              updatedEmployeeCount: processCSVResponse.updatedUserCount,
              deactivatedEmployeeCount: processCSVResponse.deactivatedUserCount,
            },
          }}
        />
      ) : null}
    </>
  );
}
