import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";
import { PendingInvitationsData } from "./types";

const selectDomain = (state: RootState) => state.dashboard || initialState;

export const selectDashboard = createSelector(
  [selectDomain],
  dashboardState => dashboardState
);

export const selectUser = createSelector(
  [selectDomain],
  dashboardState => dashboardState.user
);

export const selectUsers = createSelector(
  [selectDomain],
  adminConsoleState => adminConsoleState.users
);

export const selectTeams = createSelector(
  [selectDomain],
  dashboardState => dashboardState.teams
);

export const selectPendingTests = createSelector(
  [selectDomain],
  dashboardState => dashboardState.pendingTests
);

export const selectCompanyInfo = createSelector(
  [selectDomain],
  dashboardState => dashboardState.companyInfo
);

export const selectCollaborationGuideUsers = createSelector(
  [selectDomain],
  dashboardState => dashboardState.collaborationGuideUsers
);

export const selectDashboardLoading = createSelector(
  [selectDomain],
  dashboardState => {
    return (
      dashboardState.loadingTeams ||
      dashboardState.loadingNotifications ||
      dashboardState.loadingTestsAndInvitations ||
      dashboardState.loadingCompanyInfo ||
      dashboardState.loadingCollaborationGuideUsers
    );
  }
);

export const selectInvitationAcceptedData = createSelector(
  [selectDomain],
  dashboardState => {
    return {
      showInvitationAcceptedModal: dashboardState.showInvitationAcceptedModal,
      invitationAcceptedTeamName: dashboardState.invitationAcceptedTeamName,
      invitationAcceptedTeamId: dashboardState.invitationAcceptedTeamId
    };
  }
);

export const selectHasSSOHardEnabled = createSelector(
  [selectDomain],
  dashboardState => dashboardState.hasSSOHardEnabled
);

export const selectAccountSettings = createSelector(
  [selectDomain],
  dashboardState => {
    return {
      loadingUserSettings: dashboardState.loadingUserSettings,
      userSettings: dashboardState.userSettings,
      savingSetting: dashboardState.savingSetting
    };
  }
);

export const selectVisitedAdminConsole = createSelector(
  [selectDomain],
  dashboardState => dashboardState.visitedAdminConsole
);

// sort by recency of all actions (pending team invites and pending manager guide views)
export const selectSortedPendingActions = createSelector(
  [selectDomain],
  dashboardState => {
    if (
      dashboardState.pendingInvitations &&
      dashboardState.user?.directReports &&
      dashboardState.companyInfo?.hsVersion
    ) {
      const pendingViewManagerGuide =
        dashboardState.companyInfo?.hsVersion > 1
          ? dashboardState.user.directReports.filter(
              directReport =>
                directReport.managerGuideAvailable !== 0 &&
                directReport.viewedManagerGuide === 0
            )
          : [];

      const pendingActions: Array<DirectReport | PendingInvitationsData> = [
        ...dashboardState.pendingInvitations,
        ...pendingViewManagerGuide
      ];

      const sortedPendingActions = pendingActions.sort((a: any, b: any) => {
        // if the object has teamId, meaning it's a team invitation object. get the date string from the field `dateInvited`
        // if the object has userAccountId, meaning it's a direct report object. get the date string from the field `managerGuideAvailable`
        const aDate = a.hasOwnProperty("teamId")
          ? a.dateInvited
          : a.hasOwnProperty("userAccountId")
          ? a.managerGuideAvailable
          : "";
        const bDate = b.hasOwnProperty("teamId")
          ? b.dateInvited
          : b.hasOwnProperty("userAccountId")
          ? b.managerGuideAvailable
          : "";

        return new Date(aDate.replace(/-/g, "/")) <
          new Date(bDate.replace(/-/g, "/"))
          ? 1
          : -1;
      });

      return sortedPendingActions;
    } else {
      return null;
    }
  }
);
