/**
 *
 * InviteConfirmation
 *
 */

import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import queryString from "query-string";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./slice";
import { selectInviteConfirmation } from "./selectors";
import { inviteConfirmationSaga } from "./saga";
import useEffectOnMount from "utils/custom_hooks/useEffectOnMount";
import { useHistory, useLocation } from "react-router-dom";
import { Modal, Row, Col } from "react-bootstrap";
import addUser from "resources/images/add-user.jpg";
import rightArrow from "resources/images/arrow-right-white.svg";
import "./styles.css";
import { LinkButton } from "app/components/LinkButton";

interface Props {}

export function InviteConfirmation(props: Props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: inviteConfirmationSaga });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const inviteConfirmation = useSelector(selectInviteConfirmation);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  /*
   * State Variables
   */
  const [eventId, setEventId] = useState<any>(null);
  const [teamId, setTeamId] = useState<any>(null);
  const [teamName, setTeamName] = useState(null);

  useEffectOnMount(() => {
    /*
     * we need to get the token from the query string, decode it to get the team name,and then
     * pass it through to the api in order to accept the invitation for the user
     */

    // we have to cast this to a String because query-string library has some weird typing
    const token: string = String(queryString.parse(location.search).token);
    const teamId: string = String(queryString.parse(location.search).teamId);
    if (token && teamId) {
      // we have to do this in two steps like this because of query-string library's weird typing, again.
      const decodedToken: any = jwtDecode(token);
      const teamName = decodedToken.teamName;
      setTeamName(teamName);

      // set up form data to pass the token back to the API to accept the invitation.
      const formData = {
        token: token,
        teamId: teamId
      };
      dispatch(actions.acceptInvitation(formData));
    }

    /*
     * we need to check for the eventId in the query string. if it is there, that means there is a
     * pending test, and we need to configure the confirmation modal accordingly.
     */
    const queryStringObj = queryString.parse(location.search);
    const hasEventId = Object.keys(queryStringObj).includes("eventId");
    const hasTeamId = Object.keys(queryStringObj).includes("teamId");

    if (hasEventId && queryStringObj) {
      setEventId(queryStringObj.eventId);
    }

    if (hasTeamId) {
      setTeamId(queryStringObj.teamId);
    }
  });

  /****************************************************************************
   * Handlers                                                                 *
   ****************************************************************************/

  const handleStartTestButton = () => {
    window.open(
      `${process.env.REACT_APP_ODA_URL}/verify/index/${eventId}`,
      "_blank"
    );
  };

  const handleDashboardButton = () => {
    history.push("/dashboard");
  };

  const handleTeamButton = () => {
    history.push(`/team/${teamId}`);
  };

  return (
    <>
      <Helmet>
        <title>Accept Invitation</title>
        <meta name="description" content="Invitation Confirmation" />
      </Helmet>
      <div>
        <Modal show={true} centered onHide={() => {}}>
          <Modal.Body style={{ padding: "40px" }}>
            <Row>
              <Col lg={6} md={5} sm={12} xs={12} className="text-center">
                <img
                  src={addUser}
                  alt="confirmation icon"
                  className="confirmation-image"
                />
              </Col>
              <Col lg={6} md={7} sm={12} xs={12}>
                <p className="confirmation-header-text">
                  You've joined {teamName}!
                </p>
                {eventId ? (
                  <p className="confirmation-body-text">
                    Learn more about yourself and your teammates by taking a
                    personality assessment and comparing your Workplace
                    Insights.
                  </p>
                ) : (
                  <p className="confirmation-body-text">
                    Learn more about yourself and your teammates by visiting
                    your Team page.
                  </p>
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="confirmation-modal-footer">
            {eventId ? (
              <button
                className="confirmation-modal-teal-button"
                onClick={handleStartTestButton}
              >
                Start Personality Test{" "}
                <img src={rightArrow} alt="right arrow" className="ml-2" />
              </button>
            ) : (
              <>
                {localStorage.getItem("tmg-tkn") ? (
                  <Col className="text-left">
                    <LinkButton
                      text="Back to My Dashboard"
                      showArrow=""
                      handler={handleDashboardButton}
                      isLoading={false}
                    />
                  </Col>
                ) : null}

                {teamId ? (
                  <Col className="text-right">
                    <button
                      className="teal-button"
                      style={{ width: "100%" }}
                      onClick={handleTeamButton}
                    >
                      View Team
                      <img
                        src={rightArrow}
                        alt="right arrow"
                        className="link-button-arrow"
                      />
                    </button>
                  </Col>
                ) : null}
              </>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
