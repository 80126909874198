import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => state.global || initialState;

export const selectUser = createSelector(
  [selectDomain],
  globalState => globalState?.user
);

export const selectUserAccountId = createSelector(
  [selectDomain],
  globalState => globalState?.user?.userAccountId || null
);

export const selectUserInitials = createSelector([selectDomain], globalState =>
  globalState?.user
    ? `${globalState.user?.firstName
        .charAt(0)
        .toUpperCase()}${globalState.user?.lastName.charAt(0).toUpperCase()}`
    : null
);

export const selectUserName = createSelector([selectDomain], globalState =>
  globalState?.user
    ? `${globalState.user.firstName} ${globalState.user.lastName}`
    : null
);

export const selectCompanyAccountId = createSelector(
  [selectDomain],
  globalState => globalState?.user?.companyAccountId || null
);

export const selectIsAdmin = createSelector(
  [selectDomain],
  globalState => globalState?.user?.tmgRoleId === 1 || false
);

export const selectDirectReports = createSelector(
  [selectDomain],
  globalState => globalState?.user?.directReports || null
);
