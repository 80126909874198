import React, { useEffect, useState } from "react";
import useEffectOnMount from "utils/custom_hooks/useEffectOnMount";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from "@material-ui/core";
import TablePagination from "@mui/material/TablePagination";
import { actions } from "../slice";
import { selectInviteSentSuccessfully } from "../selectors";
import {
  getComparator,
  stableSort,
  getUsersByFilter
} from "../helper-functions";
import menu from "resources/images/menu.svg";
import { useHistory } from "react-router-dom";
import { ConfirmationModal } from "app/components/ConfirmationModal";
import { Users } from "../types";

interface Props {
  searchValue: string;
  viewUserStatus: string;
  accessRoleStatus: string;
  users: Users[] | undefined;
  hsVersion: number;
}

interface Data {
  employeeName: string;
  lastName: string;
  jobTitle: string;
  emailAddress: string;
  teams: any;
  teamCount: number;
  accessRole: number;
  teamMemberId: string;
  setupAccount: number;
  firstTeamsLogin: string | null;
  tmgSuspendedByUserAccountId: number;
  tmgInvited: number | null;
  userStatus: string;
  managerName: string | null;
  managerId: number | null;
  directReports: any[];
  directReportsCount: number;
}

interface HeadCell {
  id: keyof Data;
  label: string;
}

const headCells: HeadCell[] = [
  { id: "lastName", label: "Employee Name" },
  { id: "emailAddress", label: "Email" },
  { id: "teamCount", label: "Teams" },
  { id: "managerName", label: "Manager" },
  { id: "directReports", label: "Direct Reports" },
  { id: "accessRole", label: "Status" }
];

const essentialHeadCells: HeadCell[] = [
  { id: "lastName", label: "Employee Name" },
  { id: "emailAddress", label: "Email" },
  { id: "teamCount", label: "Teams" },
  { id: "accessRole", label: "Status" }
];

function createData(
  employeeName: string,
  lastName: string,
  jobTitle: string,
  emailAddress: string,
  teams: any,
  teamCount: number,
  accessRole: number,
  teamMemberId: string,
  setupAccount: number,
  firstTeamsLogin: string | null,
  tmgSuspendedByUserAccountId: number,
  tmgInvited: number | null,
  userStatus: string,
  managerName: string | null,
  managerId: number | null,
  directReports: any[],
  directReportsCount: number
): Data {
  return {
    employeeName,
    lastName,
    jobTitle,
    emailAddress,
    teams,
    teamCount,
    accessRole,
    teamMemberId,
    setupAccount,
    firstTeamsLogin,
    tmgSuspendedByUserAccountId,
    tmgInvited,
    userStatus,
    managerName,
    managerId,
    directReports,
    directReportsCount
  };
}

type Order = "asc" | "desc";

const UsersTable = ({
  searchValue,
  viewUserStatus,
  accessRoleStatus,
  users,
  hsVersion
}: Props) => {
  // state variables
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("lastName");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [showInvitationResentModal, setShowInvitationResentModal] = useState(
    false
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const inviteSentSuccessfully = useSelector(selectInviteSentSuccessfully);

  const usersToShow = getUsersByFilter(viewUserStatus, accessRoleStatus, users);

  useEffectOnMount(() => {
    dispatch(actions.getUsers());
  });

  useEffect(() => {
    dispatch(actions.getUsers());
  }, [inviteSentSuccessfully, dispatch]);

  useEffect(() => {
    if (!showChangeStatusModal) setSelectedUser(null);
  }, [showChangeStatusModal]);

  useEffect(() => {
    setPage(0);
  }, [viewUserStatus, searchValue, accessRoleStatus]);

  // create the array of rows to be displayed in the table
  let rows;
  if (usersToShow) {
    rows = usersToShow?.map(user => {
      const employeeName = `${user.firstName} ${user.lastName}`;
      const managerName =
        user.manager && (user.manager.firstName || user.manager.lastName)
          ? `${user.manager.firstName} ${user.manager.lastName}`
          : "";
      return createData(
        employeeName,
        user.lastName.toLowerCase(),
        user.jobTitle,
        user.emailAddress,
        user.teams,
        user.teamCount,
        user.tmgRoleId,
        user.userAccountId,
        user.setupAccount,
        user.firstTeamsLogin,
        user.tmgSuspendedByUserAccountId,
        user.tmgInvited,
        user.userStatus,
        managerName,
        user.manager?.userAccountId,
        user.directReports,
        user.directReports?.length
      );
    });
  }

  const filteredRows = rows?.filter(row => {
    if (
      String(row.employeeName)
        .toLowerCase()
        .includes(searchValue.toLowerCase()) ||
      String(row.emailAddress).toLowerCase().includes(searchValue.toLowerCase())
    )
      return true;
    return false;
  });

  /****************************************************************************
   * Sorting and Pagination Functions                                                        *
   ****************************************************************************/
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    handleRequestSort(event, property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // Dropdown Handler
  const handleDropdown = (event, teamMemberId) => {
    const currentUsers = Object.keys(usersToShow).map(id => usersToShow[id]);
    switch (event) {
      case "invite":
        const userToInvite = currentUsers?.filter(
          currentUser => currentUser.userAccountId === teamMemberId
        );
        if (userToInvite) {
          setSelectedUser(userToInvite[0]);
          let userData = {
            users: [userToInvite[0].userAccountId],
            inviteAll: 0
          };
          dispatch(actions.inviteUsers(userData));
        }
        setShowInvitationResentModal(true);
        break;
      case "view":
        history.push(`/profile/${teamMemberId}`);
        break;
      case "deactivate":
        const userToDelete = currentUsers?.filter(
          currentUser => currentUser.userAccountId === teamMemberId
        );
        if (userToDelete) {
          setSelectedUser(userToDelete[0]);
          setShowChangeStatusModal(true);
        }
        break;
      case "reactivate":
        const formData = {
          tmgSuspendedByUserAccountId: 0
        };
        dispatch(actions.editUser(teamMemberId, formData));
        break;
      case "edit":
        const userToEdit = currentUsers?.filter(
          currentUser => currentUser.userAccountId === teamMemberId
        );
        if (userToEdit) {
          setSelectedUser(userToEdit[0]);
          history.push(`/add_employee?editId=${userToEdit[0].userAccountId}`, {
            from: "admin"
          });
        }
        break;
      default:
        return false;
    }
  };

  const handleDeactivateUser = () => {
    if (selectedUser) {
      dispatch(actions.deactivateUser(selectedUser.userAccountId));
      setShowChangeStatusModal(false);
    }
  };

  const resetInvitesSentSuccessfully = () => {
    dispatch(actions.resetInvitesSentSuccessfully());
    setShowInvitationResentModal(false);
  };

  const teamsList = teams => {
    const teamsCopy = [...teams];
    const allTeams = teamsCopy
      .sort((a, b) =>
        a.teamName
          .trim()
          .toLowerCase()
          .localeCompare(b.teamName.trim().toLowerCase())
      )
      .map((team, index) => {
        return (
          <p
            key={index}
            className="popover-text"
            style={{ color: "black", textDecoration: "none" }}
          >
            {team.teamName}
          </p>
        );
      });

    return (
      <ul style={{ padding: "10px 0 0 17px", fontSize: "14px" }}>{allTeams}</ul>
    );
  };

  const directReportsList = directReports => {
    const directReportsCopy = [...directReports];
    const allDirectReports = directReportsCopy
      .sort((a, b) =>
        a.firstName
          .trim()
          .toLowerCase()
          .localeCompare(b.firstName.trim().toLowerCase())
      )
      .map(report => {
        return (
          <p
            key={report.userAccountId}
            className="popover-text"
            style={{ color: "black", textDecoration: "none" }}
          >
            {report.firstName} {report.lastName}
          </p>
        );
      });

    return (
      <ul style={{ padding: "10px 0 0 17px", fontSize: "14px" }}>
        {allDirectReports}
      </ul>
    );
  };

  const renderTableHead = cells => {
    return (
      <TableHead className="table-header">
        <TableRow>
          {cells.map(headCell => (
            <TableCell
              key={headCell.id}
              align="left"
              className={`header-labels ${
                headCell.label === "Teams"
                  ? "teams-label-padding"
                  : headCell.label === "Direct Reports"
                  ? "direct-reports-padding"
                  : null
              }`}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                className="table-sort-label"
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell key={"teamId"} align="left"></TableCell>
        </TableRow>
      </TableHead>
    );
  };

  return (
    <>
      <TableContainer className="table-container">
        <Table
          aria-labelledby="Teams Table"
          size="medium"
          aria-label="teams table"
          stickyHeader={true}
        >
          {hsVersion > 1 ? (
            <colgroup>
              <col style={{ width: "23%" }} />
              <col style={{ width: "23%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "18%" }} />
              <col style={{ width: "11%" }} />
              <col style={{ width: "18%" }} />
            </colgroup>
          ) : (
            <colgroup>
              <col style={{ width: "20%" }} />
              <col style={{ width: "25%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "10%" }} />
            </colgroup>
          )}
          <>{renderTableHead(hsVersion > 1 ? headCells : essentialHeadCells)}</>
          <TableBody>
            {rows
              ? stableSort(filteredRows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index} style={{ height: "75px" }}>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        className="link-as-text table-text"
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <a
                            href={`/profile/${row.teamMemberId}`}
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              color: "black"
                            }}
                          >
                            {row.employeeName}
                          </a>
                          {row.accessRole === 1 && (
                            <p className={"badge"} style={{ width: "75px" }}>
                              Admin
                            </p>
                          )}
                          {row.jobTitle && (
                            <p
                              style={{
                                fontSize: "12px",
                                color: "#53565a",
                                height: "20px",
                                marginBottom: "0px"
                              }}
                            >
                              {row.jobTitle}
                            </p>
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="left" className="table-text">
                        <div
                          style={{
                            overflow: "hidden",
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          {row.emailAddress}
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ maxWidth: "75px" }}
                        className="table-text"
                      >
                        {row.teamCount > 0 ? (
                          <div style={{ maxWidth: "fit-content" }}>
                            <OverlayTrigger
                              placement="right-start"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Popover
                                  id="teams-popover"
                                  className="teams-popover"
                                >
                                  {teamsList(row.teams)}
                                </Popover>
                              }
                              trigger={["hover", "focus"]}
                            >
                              <p
                                style={{
                                  color: "#425cc7",
                                  textDecoration: "underline",
                                  marginBottom: "0"
                                }}
                              >
                                {row.teamCount}
                              </p>
                            </OverlayTrigger>
                          </div>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      {hsVersion > 1 && (
                        <>
                          <TableCell align="left" className="table-text">
                            <div
                              style={{
                                overflow: "hidden",
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              {row.managerName ? (
                                <a
                                  href={`/profile/${row.managerId}`}
                                  style={{
                                    color: "black",
                                    textDecoration: "none"
                                  }}
                                >
                                  {row.managerName}
                                </a>
                              ) : (
                                <div style={{ justifyContent: "center" }}>
                                  -
                                </div>
                              )}
                            </div>
                          </TableCell>
                          <TableCell
                            align="left"
                            className="table-text padding-left-10"
                            style={{
                              maxWidth: "75px"
                            }}
                          >
                            {row.directReportsCount > 0 ? (
                              <div style={{ maxWidth: "fit-content" }}>
                                <OverlayTrigger
                                  placement="right-start"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Popover
                                      id="teams-popover"
                                      className="teams-popover"
                                    >
                                      {directReportsList(row.directReports)}
                                    </Popover>
                                  }
                                  trigger={["hover", "focus"]}
                                >
                                  <p
                                    style={{
                                      color: "#425cc7",
                                      textDecoration: "underline",
                                      marginBottom: "0"
                                    }}
                                  >
                                    {row.directReportsCount}
                                  </p>
                                </OverlayTrigger>
                              </div>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                        </>
                      )}
                      <TableCell align="left" className="table-text">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center"
                          }}
                        >
                          <div
                            className={`status-indicator ${row.userStatus}-user`}
                          ></div>
                          {String(row.userStatus).charAt(0).toUpperCase() +
                            String(row.userStatus).substring(1)}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <Dropdown
                          onSelect={event =>
                            handleDropdown(event, row.teamMemberId)
                          }
                        >
                          <div className="no-caret">
                            <Dropdown.Toggle size="sm" className="menu-button">
                              <img
                                src={menu}
                                alt="menu"
                                width="24"
                                height="24"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              className={`${
                                usersToShow && usersToShow.length > 2
                                  ? ""
                                  : "user-dropdown-menu"
                              }`}
                            >
                              <Dropdown.Item
                                eventKey="edit"
                                data-id={row.teamMemberId}
                              >
                                Edit Employee
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="view"
                                data-id={row.teamMemberId}
                              >
                                View User
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey={
                                  row.userStatus === "deactivated"
                                    ? "reactivate"
                                    : "deactivate"
                                }
                                data-id={row.teamMemberId}
                              >
                                {row.userStatus === "deactivated"
                                  ? "Activate Employee"
                                  : "Deactivate Employee"}
                              </Dropdown.Item>
                              {row.userStatus === "created" && (
                                <Dropdown.Item
                                  eventKey="invite"
                                  data-id={row.teamMemberId}
                                >
                                  Invite
                                </Dropdown.Item>
                              )}
                              {row.userStatus === "invited" && (
                                <Dropdown.Item
                                  eventKey="invite"
                                  data-id={row.teamMemberId}
                                >
                                  Resend Invitation
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </div>
                        </Dropdown>
                      </TableCell>
                    </TableRow>
                  ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={filteredRows ? filteredRows.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className="table-pagination"
      />

      {/* **********************************************************************
       * Deactivate User Confirmation Modal
       ***********************************************************************/}
      <ConfirmationModal
        show={showChangeStatusModal}
        onHide={() => setShowChangeStatusModal(false)}
        headerText="Deactivate User"
        bodyText={`This user will no longer have access to their Talent Insights profile.`}
        handleConfirm={handleDeactivateUser}
      />

      {/* **********************************************************************
       * Invitation Resent Confirmation Modal
       ***********************************************************************/}
      <ConfirmationModal
        show={inviteSentSuccessfully && showInvitationResentModal}
        onHide={resetInvitesSentSuccessfully}
        headerText="Invitation Sent"
        bodyText={`An invitation to join Talent Insights was sent to ${selectedUser?.emailAddress}.`}
        handleConfirm={resetInvitesSentSuccessfully}
        showClose={true}
      />
    </>
  );
};

export default UsersTable;
