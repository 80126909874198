/**
 *
 * SampleDataBanner
 *
 */
import React from "react";

interface Props {}

export function SampleDataBanner(props: Props) {
  return (
    <div className="sample-data-banner">
      <p style={{ marginBottom: 0 }}>
        <span style={{ paddingRight: "5px", marginBottom: 10 }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            style={{ position: "relative", top: "-.1em" }}
          >
            <g fill="none" fill-rule="evenodd">
              <g fill="#425cc7">
                <g>
                  <g>
                    <g>
                      <path
                        d="M8 0c1.44 0 2.774.36 4 1.08 1.226.721 2.199 1.694 2.92 2.92C15.64 5.226 16 6.56 16 8s-.36 2.774-1.08 4c-.721 1.226-1.694 2.199-2.92 2.92-1.226.72-2.56 1.08-4 1.08s-2.774-.36-4-1.08c-1.226-.721-2.199-1.694-2.92-2.92C.36 10.774 0 9.44 0 8s.36-2.774 1.08-4C1.802 2.774 2.775 1.801 4 1.08 5.226.36 6.56 0 8 0zm0 1.548c-1.161 0-2.237.29-3.226.871C3.784 3 3 3.785 2.42 4.774c-.58.99-.87 2.065-.87 3.226s.29 2.237.87 3.226C3 12.216 3.785 13 4.774 13.58c.99.58 2.065.87 3.226.87s2.237-.29 3.226-.87c.99-.581 1.774-1.366 2.355-2.355.58-.99.87-2.065.87-3.226s-.29-2.237-.87-3.226C13 3.784 12.215 3 11.226 2.42c-.99-.58-2.065-.87-3.226-.87zm.656 5.283c.097 0 .183.037.255.11.073.073.11.158.11.255v3.864h.364c.098 0 .183.037.256.11.073.073.109.158.109.255v.875c0 .097-.036.182-.11.255-.072.073-.157.11-.255.11h-2.77c-.098 0-.183-.037-.256-.11-.073-.073-.109-.158-.109-.255v-.875c0-.097.036-.182.11-.255.072-.073.157-.11.255-.11h.364V8.435h-.364c-.098 0-.183-.036-.256-.109-.073-.073-.109-.158-.109-.255v-.875c0-.097.036-.182.11-.255.072-.073.157-.11.255-.11h2.041zM8 3.331c.365 0 .674.128.93.383.255.255.382.565.382.93 0 .364-.127.674-.382.93-.256.255-.565.382-.93.382-.365 0-.674-.127-.93-.383-.255-.255-.383-.565-.383-.93 0-.364.128-.674.383-.929.256-.255.565-.383.93-.383z"
                        transform="translate(-697 -337) translate(128 242) translate(24 25) translate(545 69.969)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </span>
        <span style={{ fontWeight: "bold" }}>Sample Team: </span>Explore team
        and individual insights
      </p>
    </div>
  );
}
