import React from "react";
import { Row } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import iconCircleCheck from "../../../resources/images/icon-circle-check-green.svg";
import useEffectOnMount from "utils/custom_hooks/useEffectOnMount";

interface Props {
  show: boolean;
  onHide: () => void;
  createdEmployeesNumber: number | undefined;
  invitedToInsights: boolean | null;
  employeeName: string | null;
  employeeEdited: boolean | null;
  newEmployeeCount: number | null;
  updatedEmployeeCount: number | null;
  deactivatedEmployeeCount: number | null;
  confirmationFrom: string;
  bulkInvitedNumber: number;
}

export default function ConfirmationModal({
  show,
  onHide,
  createdEmployeesNumber,
  invitedToInsights,
  employeeName,
  employeeEdited,
  newEmployeeCount,
  updatedEmployeeCount,
  deactivatedEmployeeCount,
  confirmationFrom,
  bulkInvitedNumber
}: Props) {
  useEffectOnMount(() => {
    if (show) {
      if (confirmationFrom === "addEmployee") {
        window.analytics.track("User Created", {
          location: "Admin Console"
        });
      }
      if (confirmationFrom === "inviteAll") {
        window.analytics.track("Talent Insights Bulk Invite", {
          numberInvited: bulkInvitedNumber
        });
      }
    }
  });

  let modalHeader = "";
  let imgAlt = "";

  switch (confirmationFrom) {
    case "editEmployee":
      modalHeader = "Employee Updated";
      imgAlt = "users successfully edited";
      break;
    case "addEmployee":
      modalHeader = "Invitation Sent";
      imgAlt = "user successfully invited";
      break;
    case "inviteAll":
      modalHeader = "Invitations Sent";
      imgAlt = "users successfully invited";
      break;
    case "importCSV":
      modalHeader = "CSV Import Complete";
      imgAlt = "csv successfully imported";
      break;
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      id="admin-console-confirmation-modal"
      className="admin-console-confirmation-modal"
    >
      <Modal.Header className="modal-header" closeButton>
        {modalHeader}
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Row className="mt-3 mb-3 justify-content-center">
          <img src={iconCircleCheck} alt={imgAlt} height="32px" width="32px" />
        </Row>
        {confirmationFrom === "inviteAll" && (
          <Row className="mt-3 mb-3 justify-content-center">
            <p className="modal-text">
              {`${
                bulkInvitedNumber !== 1 ? "Invitations have" : "Invitation has"
              } been sent successfully to `}
              <strong>{`${bulkInvitedNumber} ${
                bulkInvitedNumber !== 1 ? "employees" : "employee"
              }`}</strong>
            </p>
          </Row>
        )}
        {confirmationFrom === "addEmployee" && (
          <Row className="mt-3 mb-3 justify-content-center">
            {invitedToInsights ? (
              <p>
                <strong>{`${employeeName}`}</strong> has been{" "}
                <strong>added</strong> and <strong>invited</strong> to Talent
                Insights
              </p>
            ) : (
              <>
                <p>
                  <strong>{`${employeeName}`}</strong> has been{" "}
                  <strong>added</strong> to Talent Insights
                </p>
                <p style={{ color: "#86888b" }}>
                  They will not have access to Talent Insights until they are
                  invited to the platform
                </p>
              </>
            )}
          </Row>
        )}
        {confirmationFrom === "editEmployee" && (
          <Row className="mt-3 mb-3 justify-content-center">
            <p>
              <strong>{`${employeeName}`}</strong> has been successfully
              updated.
            </p>
          </Row>
        )}
        {confirmationFrom === "importCSV" && (
          <Row className="mt-3 mb-3 justify-content-center d-flex flex-column csv-confirmation">
            <p className="modal-text">Import has been successfully completed</p>
            <p className="mb-0">
              <strong>{`${newEmployeeCount} `}</strong>
              {newEmployeeCount !== 1 ? "employees have " : "employee has "}
              been <strong> created</strong>
            </p>
            <p className="mb-0">
              <strong>{`${updatedEmployeeCount}`}</strong>
              {updatedEmployeeCount !== 1
                ? " employees have "
                : " employee has "}
              been <strong> updated</strong>
            </p>
            <p className="modal-text">
              <strong>{`${deactivatedEmployeeCount} `}</strong>
              {deactivatedEmployeeCount !== 1
                ? " employees have "
                : " employee has "}
              been <strong> deactivated</strong>
            </p>
            <div
              style={{
                color: "#86888b",
                maxWidth: "350px",
                textAlign: "center"
              }}
            >
              <span>Created employees will</span>
              <span style={{ textDecoration: "underline" }}>
                <strong> not </strong>
              </span>
              <span>
                have access to Talent Insights until they are invited to the
                platform.
              </span>
            </div>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="modal-button ml-5 pl-3 pr-3" onClick={onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
