/**
 *
 * ManagerDropdown
 *
 */
import React, { useState, useRef, useEffect } from "react";
import { Col, Form, InputGroup, Card, Row } from "react-bootstrap";
import { selectManager } from "./selectors";
import { useSelector } from "react-redux";
import searchIcon from "resources/images/search-icon.svg";
import "./styles.css";

export function ManagerDropdown({ users, editId, handleReportsTo }) {
  const [managerSearch, setManagerSearch] = useState<string | null>(null);
  const [searchedEmployees, setSearchedEmployees] = useState<any[]>([]);
  const [reportsTo, setReportsTo] = useState<any>(null);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [managerCleared, setManagerCleared] = useState<boolean>(false);

  const manager = useSelector(selectManager);
  const reportDropdown = useRef(null);

  // get current manager from redux if manager hasn't
  // been cleared and we're editing a current user
  useEffect(() => {
    if (
      !managerCleared &&
      editId &&
      manager &&
      Object.keys(manager).length !== 0
    ) {
      setReportsTo(manager);
      handleReportsTo(manager);
    }
  }, [editId, handleReportsTo, manager, managerCleared]);

  // listen for click outside of dropdown to hide dropdown
  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener("click", event => {
        if (reportDropdown.current) {
          setDropdownVisible(false);
        }
      });
    }
  }, [dropdownVisible]);

  const handleSetManager = (from, id) => {
    setDropdownVisible(false);
    const manager = searchedEmployees.find(
      employee => employee.userAccountId === parseInt(id)
    );
    setReportsTo(manager);
    handleReportsTo(manager);
  };

  // sort searched employees by first name, last name and email address
  // organize them in alphabetical order starting with first name then last name then email address.
  useEffect(() => {
    if (users && managerSearch) {
      const lowerCaseSearch = managerSearch?.toLocaleLowerCase();
      const employees = Object.keys(users)
        .filter(userId => {
          const employeeInfo = `${users[userId].firstName} ${users[userId].lastName} ${users[userId].emailAddress}`;
          return (
            employeeInfo.toLocaleLowerCase().includes(lowerCaseSearch) &&
            userId !== editId &&
            !users[userId].tmgSuspendedbyUserAccountId
          );
        })
        .sort((a, b) => {
          if (users[a].firstName === users[b].firstName) {
            if (users[a].lastName === users[b].lastName) {
              return users[a].emailAddress
                .trim()
                .toLowerCase()
                .localeCompare(users[b].emailAddress.trim().toLowerCase());
            }
            return users[a].lastName
              .trim()
              .toLowerCase()
              .localeCompare(users[b].lastName.trim().toLowerCase());
          } else {
            return users[a].firstName
              .trim()
              .toLowerCase()
              .localeCompare(users[b].firstName.trim().toLowerCase());
          }
        })
        .map(userId => users[userId]);
      setSearchedEmployees(employees);
    }
  }, [editId, managerSearch, users]);

  const handleBold = string => {
    if (
      managerSearch &&
      string.toLowerCase().includes(managerSearch.toLowerCase())
    ) {
      const search = managerSearch.toLowerCase();
      const searchIndex = string.toLowerCase().indexOf(search);
      if (searchIndex > -1) {
        const boldedString =
          string.substring(0, searchIndex) +
          `<b>${string.substring(
            searchIndex,
            searchIndex + search.length
          )}</b>` +
          string.substring(searchIndex + search.length);
        return boldedString;
      }
    } else {
      return string;
    }
  };

  const handleCardStyle = (
    firstName,
    lastName,
    emailAddress,
    userAccountId
  ) => {
    return (
      <>
        <div
          id={userAccountId}
          data-employee={userAccountId}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <p
            style={{ margin: "0 3px 0 0" }}
            id={firstName}
            data-employee={userAccountId}
            dangerouslySetInnerHTML={{
              __html: handleBold(firstName)
            }}
          />{" "}
          <p
            style={{ marginBottom: "0px" }}
            id={lastName}
            data-employee={userAccountId}
            dangerouslySetInnerHTML={{
              __html: handleBold(lastName)
            }}
          />
        </div>
        <p
          style={{ marginBottom: "0px", color: "#53565a" }}
          id={emailAddress}
          data-employee={userAccountId}
          dangerouslySetInnerHTML={{
            __html: handleBold(emailAddress)
          }}
        />
      </>
    );
  };

  const teamLeadsDropdown = () => {
    let teamLeads;
    if (users) {
      teamLeads = searchedEmployees
        .filter(user => user.tmgSuspendedByUserAccountId === 0)
        .map(employee => {
          const { firstName, lastName, emailAddress, userAccountId } = employee;
          return (
            <Card
              id={`${userAccountId}`}
              data-employee={userAccountId}
              className="mt-2 mb-1 manager-dropdown-item"
              onClick={e =>
                handleSetManager("card", e.target.getAttribute("data-employee"))
              }
            >
              {handleCardStyle(
                firstName,
                lastName,
                emailAddress,
                userAccountId
              )}
            </Card>
          );
        });

      return (
        <>
          <Col lg={12} md={12} sm={12}>
            <div className="manager-dropdown" ref={reportDropdown}>
              {teamLeads}
            </div>
          </Col>
        </>
      );
    }
  };

  const handleReportChange = e => {
    if (reportsTo) {
      //When there is a manager selected in the dropdown search bar,
      // if a user starts typing in the bar without clearing the manager
      // first it will switch back to an empty search bar
      setReportsTo(null);
      setManagerSearch(null);
      handleReportsTo(null);
    } else {
      setDropdownVisible(true);
      setManagerSearch(e.target.value);
    }
  };

  const handleReportsDisplay = () => {
    if (reportsTo) {
      return `${reportsTo.firstName} ${reportsTo.lastName} | ${reportsTo.emailAddress}`;
    } else if (managerSearch) {
      return managerSearch;
    } else {
      return "";
    }
  };

  const handleClear = () => {
    setReportsTo(null);
    setManagerSearch(null);
    handleReportsTo(null);
    setManagerCleared(true);
  };

  return (
    <>
      <Row style={{ margin: "0px" }}>
        <Col lg={12} md={12} sm={12}>
          <Form.Label className="form-label-text">Reports To</Form.Label>
          <InputGroup style={{ width: "100%" }}>
            {!reportsTo && (
              <InputGroup.Prepend>
                <InputGroup.Text style={{ backgroundColor: "white" }}>
                  <span>
                    <img
                      src={searchIcon}
                      alt="search"
                      style={{ height: "16px" }}
                    />
                  </span>
                </InputGroup.Text>
              </InputGroup.Prepend>
            )}
            <Form.Control
              type="text"
              onChange={e => handleReportChange(e)}
              value={handleReportsDisplay()}
              placeholder={"Search by manager name or email"}
              className={`manager-input ${
                reportsTo ? "manager-input-report" : ""
              }`}
            />
            {reportsTo && (
              <InputGroup.Append onClick={() => handleClear()}>
                <InputGroup.Text
                  style={{
                    backgroundColor: "white",
                    paddingTop: "2px"
                  }}
                >
                  <span
                    style={{
                      paddingBottom: "2px"
                    }}
                  >
                    x
                  </span>
                </InputGroup.Text>
              </InputGroup.Append>
            )}
          </InputGroup>
          {dropdownVisible &&
            searchedEmployees.length > 0 &&
            teamLeadsDropdown()}
        </Col>
        <Col>
          <p className="sub-text">
            Managers will automatically have access to their direct report's
            personality profile.
          </p>
        </Col>
      </Row>
    </>
  );
}
