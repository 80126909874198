import React from "react";
import { Row, Col } from "react-bootstrap";
import { LinkButton } from "app/components/LinkButton";
import { useHistory } from "react-router-dom";

interface Props {
  userAccountId: number;
  firstName: string;
  lastName: string;
}

export function PendingManagerGuideBox({
  userAccountId,
  firstName,
  lastName
}: Props) {
  const history = useHistory();
  const viewMyProfileHandler = () => {
    history.push(`/profile/${userAccountId}`);
  };

  return (
    <>
      <Row className="pending-box">
        <Col lg={8} md={7} sm={8} className="text-left">
          {`Manager Guide for ${firstName} ${lastName} is available.`}
        </Col>
        <Col lg={4} md={5} sm={4} className="text-sm-left text-md-right">
          <LinkButton
            text={`View`}
            showArrow=""
            handler={viewMyProfileHandler}
            isLoading={false}
          />
        </Col>
      </Row>
    </>
  );
}
