import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import Switch from "react-switch";

interface Props {
  handleAllowUsersToCreateTeams: (checked: boolean) => void;
  handleAllowUsersShareInviteLink: (checked: boolean) => void;
  handleAllowLinkResults: (checked: boolean) => void;
  tmgAllowUsersCreateTeam: number;
  allowUsersShareInviteLink: number;
  allowLinkResults: number;
  hasSSOHardEnabled: boolean;
  handleAutomaticTeamsChange: (checked: boolean) => void;
  hasAutomaticTeamsOn: number;
  hsVersion: number;
}

export default function CompanySettings({
  handleAllowUsersToCreateTeams,
  handleAllowUsersShareInviteLink,
  handleAllowLinkResults,
  tmgAllowUsersCreateTeam,
  allowUsersShareInviteLink,
  allowLinkResults,
  hasSSOHardEnabled,
  handleAutomaticTeamsChange,
  hasAutomaticTeamsOn,
  hsVersion
}: Props) {
  return (
    <Container className="table-container" style={{ padding: "0" }}>
      <Row className="d-flex m-4" style={{ height: "54px" }}>
        <Col lg={8} md={8} sm={8} xs={10}>
          <Row className="settings-title form-text">
            Allow users to invite users
          </Row>
          <Row className="setting-desc">
            Allow any user in your organization to invite new users using a
            company invite link.
          </Row>
        </Col>
        <Col lg={1} md={1} sm={1} xs={2} className="offset-md-3">
          <Switch
            onChange={handleAllowUsersShareInviteLink}
            checked={allowUsersShareInviteLink === 1}
            className="react-switch mt-4"
            uncheckedIcon={false}
            checkedIcon={false}
            onColor={"#2dccd3"}
            height={18}
            width={40}
            disabled={hasSSOHardEnabled}
          />
        </Col>
      </Row>
      <hr className="separator" />
      <Row className="d-flex m-4" style={{ height: "54px" }}>
        <Col lg={8} md={8} sm={8} xs={10}>
          <Row className="settings-title form-text">
            Allow users to create teams
          </Row>
          <Row className="setting-desc">
            Allow any user in your organization to create teams and invite team
            members to Criteria.
          </Row>
        </Col>
        <Col lg={1} md={1} sm={1} xs={2} className="offset-md-3">
          <Switch
            onChange={handleAllowUsersToCreateTeams}
            checked={tmgAllowUsersCreateTeam === 1}
            className="react-switch mt-4"
            uncheckedIcon={false}
            checkedIcon={false}
            onColor={"#2dccd3"}
            height={18}
            width={40}
          />
        </Col>
      </Row>
      <hr className="separator" />
      <Row className="d-flex m-4" style={{ height: "74px" }}>
        <Col lg={10} md={10} sm={10} xs={12}>
          <Row className="settings-title form-text">
            Allow users to link previous results
          </Row>
          <Row className="setting-desc">
            Allow users to link results that they’ve previously completed with
            your organization to their employee profile. This can save time if a
            user previously took a test as a candidate for a position at your
            company.
          </Row>
        </Col>
        <Col lg={1} md={1} sm={1} xs={2} className="offset-md-1">
          <Switch
            onChange={handleAllowLinkResults}
            checked={allowLinkResults === 1}
            className="react-switch mt-4"
            uncheckedIcon={false}
            checkedIcon={false}
            onColor={"#2dccd3"}
            height={18}
            width={40}
            disabled={hasSSOHardEnabled}
          />
        </Col>
      </Row>
      {hsVersion > 1 ? (
        <React.Fragment>
          <hr className="separator" />
          <Row className="d-flex m-4" style={{ height: "74px" }}>
            <Col lg={10} md={10} sm={10} xs={12}>
              <Row className="settings-title form-text">Automatic Teams</Row>
              <Row className="setting-desc">
                Automatic Teams enable users to receive onboarding insights
                whenever a new reporting relationship is established.
              </Row>
            </Col>
            <Col lg={1} md={1} sm={1} xs={2} className="offset-md-1">
              <Switch
                onChange={handleAutomaticTeamsChange}
                checked={hasAutomaticTeamsOn === 1}
                className="react-switch mt-4"
                uncheckedIcon={false}
                checkedIcon={false}
                onColor={"#2dccd3"}
                height={18}
                width={40}
              />
            </Col>
          </Row>
        </React.Fragment>
      ) : null}
    </Container>
  );
}
