import React from "react";
import { Row, Col, OverlayTrigger, Popover } from "react-bootstrap";
import { MemberScores, TeamData } from "../types";
import { useHistory } from "react-router-dom";
import notableTraitIcon from "resources/images/notable-trait.svg";
import circleInfoIcon from "resources/images/circle-info.svg";
import { ViewInsightsButton } from "./ViewInsightsButton";

interface Props {
  showTooltip: boolean;
  memberScores: MemberScores[] | null;
  teamId: string | null;
  totalTeamMembers: number;
  numberOfDisplayedMembers: number;
  team: TeamData;
}

export function TeamPersonalityHeader({
  showTooltip,
  memberScores,
  teamId,
  totalTeamMembers,
  numberOfDisplayedMembers,
  team,
}: Props) {
  let history = useHistory();

  /* Explore Button Handler */
  const viewInsightsButtonHandler = () => {
    history.push(`/team_personality/${teamId}`);
  };

  return (
    <>
      <Row className="mb-3">
        <Col lg={6} md={6} sm={6} xs={6} className="text-left">
          <p className="personality-header">Team Personality Summary</p>
        </Col>
        <Col lg={6} md={6} sm={6} xs={6} className="text-right">
          <ViewInsightsButton
            showTooltip={showTooltip}
            memberScores={memberScores}
            handler={viewInsightsButtonHandler}
            team={team}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          {memberScores ? (
            <p className="personality-text">
              This section shows how your team members work according to 10
              different behavioral traits. Notable traits are marked with a
              <img src={notableTraitIcon} alt="notable trait" />
              and most strongly characterize your team.{" "}
              <span className="count-text">
                Showing {numberOfDisplayedMembers} of {totalTeamMembers}{" "}
                members.{" "}
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  overlay={
                    <Popover
                      className="explore-button-tooltip"
                      id="explore-button-tooltip"
                    >
                      Team members who have joined the team, completed their
                      personality test, and chosen to be visible on Team Reports
                      will appear on the continuums.
                    </Popover>
                  }
                >
                  <img
                    src={circleInfoIcon}
                    alt="information"
                    height="16px"
                    width="16px"
                    className="ml-1"
                  />
                </OverlayTrigger>
              </span>
            </p>
          ) : (
            <p>
              See how your team members compare according to 10 behavioral
              traits.{" "}
            </p>
          )}
        </Col>
      </Row>
    </>
  );
}
