import React from "react";
import { Col, Row } from "react-bootstrap";

interface Props {
  companyName: string;
  emailAddress: string;
  setEmailAddress: (value: string) => void;
  validationMessage: string;
  handleSendEmail: () => void;
}

export default function InvitationForm({
  companyName,
  emailAddress,
  setEmailAddress,
  validationMessage,
  handleSendEmail,
}: Props) {
  return (
    <>
      <Row className="text-center mt-3 mb-4 ">
        <Col>
          <p className="mb-0" id="invite-link-header">
            Join {companyName}
          </p>
          <p className="mt-0 invite-form-text">on Criteria</p>
        </Col>
      </Row>
      <Row className="text-center mt-3">
        <Col>
          <p className="invite-form-text">
            Enter your work email to get started:
          </p>
        </Col>
      </Row>
      <Row className="text-center">
        <Col
          lg={{
            span: 6,
            offset: 3,
          }}
          md={{
            span: 8,
            offset: 2,
          }}
          sm={{
            span: 8,
            offset: 2,
          }}
          xs={12}
        >
          <input
            id="email-input"
            placeholder="example@email.com"
            value={emailAddress}
            onChange={(event) => setEmailAddress(event.currentTarget.value)}
            style={{
              border: validationMessage
                ? "solid 1px #d50057"
                : "border: solid 1px #b1b3b3",
            }}
          />
          {validationMessage.length > 0 ? (
            <p className="mt-0 ml-1 text-left" id="error-text">
              {validationMessage}
            </p>
          ) : null}
        </Col>
      </Row>
      <Row className="text-center mt-3">
        <Col
          lg={{
            span: 6,
            offset: 3,
          }}
          md={{
            span: 8,
            offset: 2,
          }}
          sm={{
            span: 8,
            offset: 2,
          }}
          xs={12}
        >
          <button
            id="continue-button"
            disabled={emailAddress.length === 0}
            onClick={handleSendEmail}
          >
            Continue
          </button>
        </Col>
      </Row>
      <Row className="text-center mt-3 invite-form-text">
        <Col
          lg={{
            span: 6,
            offset: 3,
          }}
          md={{
            span: 6,
            offset: 3,
          }}
          sm={{
            span: 8,
            offset: 2,
          }}
          xs={12}
        >
          By continuing, you’re agreeing to our{" "}
          <a
            href="https://www.ondemandassessment.com/home/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use
          </a>{" "}
          and the{" "}
          <a
            href="https://www.ondemandassessment.com/home/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          .
        </Col>
      </Row>
    </>
  );
}
