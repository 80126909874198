/**
 *
 * AdminConsole
 *
 */

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Col,
  Container,
  Form,
  Row,
  ToggleButton,
  ToggleButtonGroup,
  InputGroup,
  Dropdown
} from "react-bootstrap";
import { Tabs, Tab } from "@material-ui/core";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./slice";
import { actions as addEmployeeActions } from "../AddEmployee/slice";
import {
  selectTmgAllowUsersCreateTeam,
  selectAllowedDomains,
  selectExistingUsers,
  selectAllowUsersShareInviteLink,
  selectHasSSOHardEnabled,
  selectInviteLink,
  selectNewLinkGenerated,
  selectAllowLinkResults,
  selectUsers,
  selectSearchTerm,
  selectAccessRole,
  selectUserStatus,
  selectHsVersion,
  selectTeams,
  selectHasAutomaticTeamsOn
} from "./selectors";
import { actions as importCSVActions } from "../ImportCSV/slice";
import { adminConsoleSaga } from "./saga";
import "./styles.css";
import TabPanel from "./TabPanel";
import useEffectOnMount from "utils/custom_hooks/useEffectOnMount";
import TeamsTable from "./Tables/TeamsTable";
import plusIcon from "resources/images/plus.svg";
import searchIcon from "resources/images/search-icon.svg";
import addUser from "resources/images/add-user.svg";
import { useHistory } from "react-router-dom";
import UsersTable from "./Tables/UsersTable";
import InviteUsersModal from "./InviteUsersModal";
import CompanySettings from "./CompanySettings";
import {
  selectUser,
  selectCompanyAccountId
} from "app/containers/Global/selectors";
import ConfirmationModal from "./ConfirmationModal";
import AutomaticTeamsConfirmationModal from "./AutomaticTeamsConfirmationModal";
import InviteAllUsersModal from "./InviteAllUsersModal";
import { NewTeamModal } from "../Dashboard/Modals/NewTeamModal";
import { Banner } from "../../components/Banner";
import { addUserStatus, getUserStatusNumber } from "./helper-functions";
import useSegmentTracking from "../../../utils/custom_hooks/useSegmentTracking";
import { selectUserAccountId as selectLoggedInUserAccountId } from "app/containers/Global/selectors";
import { actions as createTeamActions } from "../CreateTeam/slice";

interface FormData {
  emails: string[];
  subTestIds: number[];
}

export function AdminConsole() {
  useInjectReducer({
    key: sliceKey,
    reducer: reducer
  });
  useInjectSaga({
    key: sliceKey,
    saga: adminConsoleSaga
  });

  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const originalTmgAllowUsersCreateTeam: number = useSelector(
    selectTmgAllowUsersCreateTeam
  );
  const originalAllowUsersShareInviteLink: number = useSelector(
    selectAllowUsersShareInviteLink
  );
  const originalHasAutomaticTeamsOn: number = useSelector(
    selectHasAutomaticTeamsOn
  );
  const originalAllowLinkResults: number = useSelector(selectAllowLinkResults);
  const hasSSOHardEnabled: boolean = useSelector(selectHasSSOHardEnabled);
  const allowedDomains: string[] = useSelector(selectAllowedDomains);
  const existingUsers: any[] = useSelector(selectExistingUsers);
  const inviteLink: string = useSelector(selectInviteLink);
  const newLinkGenerated: boolean = useSelector(selectNewLinkGenerated);
  const user = useSelector(selectUser);
  const companyAccountId = useSelector(selectCompanyAccountId);
  const users = addUserStatus(useSelector(selectUsers));
  const searchTerm = useSelector(selectSearchTerm);
  const accessRole = useSelector(selectAccessRole);
  const userStatus = useSelector(selectUserStatus);
  const hsVersion = useSelector(selectHsVersion);
  const teams = useSelector(selectTeams);
  const loggedInUserAccountId: string = String(
    useSelector(selectLoggedInUserAccountId)
  );

  // initialize the useHistory hook`
  const history = useHistory();

  const [value, setValue] = useState(0);
  const [showAddUsersModal, setShowAddUsersModal] = useState(false);
  const [tmgAllowUsersCreateTeam, setTmgAllowUsersCreateTeam] = useState(
    originalTmgAllowUsersCreateTeam
  );
  const [allowUsersShareInviteLink, setAllowUsersShareInviteLink] = useState(
    originalAllowUsersShareInviteLink
  );
  const [allowLinkResults, setAllowLinkResults] = useState(
    originalAllowLinkResults
  );
  const [invitedEmployeeName, setInvitedEmployeeName] = useState<string | null>(
    null
  );
  const [employeeInvitedToInsights, setEmployeeInvitedToInsights] = useState(
    null
  );
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationFrom, setConfirmationFrom] = useState("");
  const [employeeEdited, setEmployeeEdited] = useState(false);
  const [showInviteAllUsersModal, setShowInviteAllUsersModal] = useState(false);
  const [newEmployees, setNewEmployees] = useState(0);
  const [updatedEmployees, setUpdatedEmployees] = useState(0);
  const [deactivatedEmployees, setDeactivatedEmployees] = useState(0);
  const [
    createdEmployeesNumber,
    activeEmployeesNumber,
    deactivatedEmployeesNumber,
    invitedEmployeesNumber
  ] = getUserStatusNumber(users);
  const [bulkInvitedNumber, setBulkInvitedNumber] = useState(
    createdEmployeesNumber
  );
  const [teamType, setTeamType] = useState("all");
  const [hasAutomaticTeamsOn, setHasAutomaticTeamsOn] = useState(
    originalHasAutomaticTeamsOn
  );
  const [
    showAutomaticTeamsConfirmationModal,
    setShowAutomaticTeamsConfirmationModal
  ] = useState(false);
  const [showNewTeamModal, setShowNewTeamModal] = useState(false);
  const [newTeamName, setNewTeamName] = useState(null);
  const [newTeamId, setNewTeamId] = useState(null);
  const [isOnTeam, setIsOnTeam] = useState(false);

  /****************************************************************************
   * Hooks                                                                    *
   ****************************************************************************/
  const dispatch = useDispatch();

  // get the teams data for initial display of page
  useEffectOnMount(() => {
    dispatch(actions.getTeams());
    if (location?.state) {
      const {
        from,
        userFirstName,
        userLastName,
        invitedToInsights,
        edited,
        newEmployeeCount,
        updatedEmployeeCount,
        deactivatedEmployeeCount,
        teamName,
        teamId,
        isOnTeam
      } = location.state as any;

      if (teamName && teamId) {
        /*
         * we have to reset the state in the CreateTeam modal manually because we were
         * redirected here from that component, and therefore it is still retained
         */
        dispatch(createTeamActions.resetState());

        /* set the state variables that will display the new team modal */
        setNewTeamName(teamName);
        setNewTeamId(teamId);
        setIsOnTeam(isOnTeam);
        setShowNewTeamModal(true);
      }

      if (from === "addEmployee") {
        dispatch(addEmployeeActions.resetState());
        setInvitedEmployeeName(`${userFirstName} ${userLastName}`);
        setEmployeeInvitedToInsights(invitedToInsights);
        setEmployeeEdited(edited);
        setConfirmationFrom(edited ? "editEmployee" : "addEmployee");
        setShowConfirmationModal(true);
      }

      if (from === "importCSV") {
        dispatch(importCSVActions.resetState());
        setNewEmployees(newEmployeeCount);
        setUpdatedEmployees(updatedEmployeeCount);
        setDeactivatedEmployees(deactivatedEmployeeCount);
        setConfirmationFrom("importCSV");
        setShowConfirmationModal(true);
      }
    }
  });

  useEffect(() => {
    if (user) {
      if (user.tmgRoleId !== 1) history.push("/");
      if (user.companyAccountId) {
        const companyAccountId: number = user.companyAccountId;
        dispatch(actions.getCompanyData(companyAccountId));
        dispatch(actions.updateUserTracking(user.userAccountId));
      }
    }
  }, [dispatch, history, user]);

  useEffect(() => {
    if (!users) {
      dispatch(actions.getUsers());
    }
  }, [users, dispatch]);

  useEffect(() => {
    setTmgAllowUsersCreateTeam(originalTmgAllowUsersCreateTeam);
  }, [originalTmgAllowUsersCreateTeam]);

  useEffect(() => {
    setAllowUsersShareInviteLink(originalAllowUsersShareInviteLink);
  }, [originalAllowUsersShareInviteLink]);

  useEffect(() => {
    setAllowLinkResults(originalAllowLinkResults);
  }, [originalAllowLinkResults]);

  useEffect(() => {
    setHasAutomaticTeamsOn(originalHasAutomaticTeamsOn);
  }, [originalHasAutomaticTeamsOn]);

  useEffect(() => {
    if (value === 1 || value === 2) {
      dispatch(actions.updateSearchTerm(""));
    }
  }, [dispatch, value]);

  useEffect(() => {
    if (!showConfirmationModal) {
      setBulkInvitedNumber(createdEmployeesNumber);
    }
  }, [createdEmployeesNumber, showConfirmationModal]);

  useSegmentTracking(
    `Admin Console ${
      value === 0 ? "Users Page" : value === 1 ? "Teams Page" : "Settings"
    }`, // page name
    user !== null,
    users !== null || teams !== null,
    null,
    loggedInUserAccountId
  );

  /****************************************************************************
   * Handlers                                                                 *
   ****************************************************************************/

  const handleTabsChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleSetAccessRoleStatus = event => {
    if (event) {
      dispatch(actions.updateAccessRole(event));
    }
  };

  const handleSetTeamType = event => {
    if (event) {
      setTeamType(event);
      // dispatch(actions.updateAccessRole(event));
    }
  };

  const formatAccessRoleStatus = status => {
    return status.charAt(0).toUpperCase() + status.substring().slice(1);
  };

  const handleCreateTeamClick = () => {
    history.push("/create", { from: "admin" });
  };

  // Handler for allowing users to create teams
  const handleAllowUsersToCreateTeams = (checked: boolean) => {
    const numberValue = checked ? 1 : 0;
    setTmgAllowUsersCreateTeam(numberValue);
    const formData = {
      tmgAllowUsersCreateTeam: numberValue
    };
    if (companyAccountId) {
      dispatch(actions.saveSettings(formData, companyAccountId));
    }
  };

  // Handler for allowing users to share invite links
  const handleAllowUsersShareInviteLink = (checked: boolean) => {
    const numberValue = checked ? 1 : 0;
    setAllowUsersShareInviteLink(numberValue);
    const formData = {
      allowUsersShareInviteLink: numberValue
    };
    if (companyAccountId) {
      dispatch(actions.saveSettings(formData, companyAccountId));
    }
  };

  // Handler for allowing users to link results
  const handleAllowLinkResults = (checked: boolean) => {
    const numberValue = checked ? 1 : 0;
    setAllowLinkResults(numberValue);
    const formData = {
      allowLinkResults: numberValue
    };
    if (companyAccountId) {
      dispatch(actions.saveSettings(formData, companyAccountId));
    }
  };

  // Handler for enabling Automatic Teams
  const handleAutomaticTeamsChange = (checked: boolean) => {
    setShowAutomaticTeamsConfirmationModal(true);
  };

  const updateShowAutomaticTeams = () => {
    const numberValue = hasAutomaticTeamsOn ? 0 : 1;
    setShowAutomaticTeamsConfirmationModal(false);
    setHasAutomaticTeamsOn(numberValue);
    const formData = {
      hasAutomaticTeamsOn: numberValue
    };
    if (companyAccountId) {
      dispatch(actions.saveSettings(formData, companyAccountId));
    }
  };

  const handleAddUsers = (formData: FormData) => {
    setShowAddUsersModal(false);
    dispatch(actions.addUsers(formData));
  };

  const resetNewLinkGenerated = () => {
    dispatch(actions.updateNewLinkGenerated(false));
  };

  const deactivateLink = () => {
    if (companyAccountId) {
      dispatch(actions.deactivateLink(companyAccountId));
    }
  };

  const handleInviteAllUsersClick = () => {
    setShowInviteAllUsersModal(true);
  };

  const handleAddAllUsers = () => {
    const formData = {
      users: [],
      inviteAll: 1
    };
    dispatch(actions.inviteUsers(formData));
    setShowInviteAllUsersModal(false);
    setBulkInvitedNumber(createdEmployeesNumber);
    setConfirmationFrom("inviteAll");
    setShowConfirmationModal(true);
  };

  const handleAddEmployees = (event: string | null) => {
    switch (event) {
      case "manually":
        handleCreateAddEmployee();
        break;
      case "csv":
        history.push("/import_csv", { from: "admin" });
        break;
      case "link":
        setShowAddUsersModal(true);
        break;
      default:
        break;
    }
  };

  const handleCreateAddEmployee = () => {
    history.push("/add_employee", { from: "admin" });
  };

  const hideConfirmationModal = () => {
    window.history.replaceState("", "", "");
    setShowConfirmationModal(false);
  };

  const handleUserStatus = event => {
    dispatch(actions.updateUserStatus(event));
  };

  const closeNewTeamModal = () => {
    /* we need to manually reset the location state for the window */
    window.history.replaceState(null, "");
    setShowNewTeamModal(false);
  };

  return (
    <>
      <Helmet>
        <title>Admin Console</title>
        <meta name="description" content="Admin console" />
      </Helmet>
      <Row
        style={{
          backgroundColor: "white",
          paddingTop: "30px",
          borderBottom: "1px solid #ddddde"
        }}
      >
        <Container>
          <Row>
            <Col>
              <p className="header-text">Admin Console</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Tabs
                value={value}
                indicatorColor="primary"
                onChange={handleTabsChange}
                aria-label="admin options"
                className="tabs-override"
              >
                <Tab
                  label="Employees"
                  disableRipple={true}
                  style={{ width: "108px" }}
                />
                <Tab label="Teams" disableRipple={true} />
                <Tab label="Settings" disableRipple={true} />
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Row>
      <Container>
        {value === 0 && (
          <>
            <Row>
              <Col xs={12} md={12} lg={7}>
                <ToggleButtonGroup
                  name="status"
                  className="users-toggle mt-4"
                  value={userStatus}
                  onChange={(event: string) => handleUserStatus(event)}
                >
                  <ToggleButton
                    value={"all"}
                    id="active"
                    className="users-button"
                    style={{
                      height: "35px",
                      borderColor: "transparent",
                      borderRadius: "30px"
                    }}
                  >
                    {`All${users ? `  (${Object.keys(users)?.length})` : ""}`}
                  </ToggleButton>
                  <ToggleButton
                    value={"active"}
                    id="inactive"
                    className="users-button"
                    style={{
                      height: "35px",
                      borderColor: "transparent",
                      borderRadius: "30px"
                    }}
                  >
                    {`Active${
                      activeEmployeesNumber > 0
                        ? ` (${activeEmployeesNumber})`
                        : ""
                    }`}
                  </ToggleButton>
                  <ToggleButton
                    value={"invited"}
                    id="inactive"
                    className="users-button"
                    style={{
                      height: "35px",
                      borderColor: "transparent",
                      borderRadius: "30px"
                    }}
                  >
                    {`Invited${
                      invitedEmployeesNumber > 0
                        ? ` (${invitedEmployeesNumber})`
                        : ""
                    }`}
                  </ToggleButton>
                  <ToggleButton
                    value={"created"}
                    id="created"
                    className="users-button"
                    style={{
                      height: "35px",
                      borderColor: "transparent",
                      borderRadius: "30px"
                    }}
                  >
                    {`Created${
                      createdEmployeesNumber > 0
                        ? ` (${createdEmployeesNumber})`
                        : ""
                    }`}
                  </ToggleButton>
                  <ToggleButton
                    value={"deactivated"}
                    id="deactivated"
                    className="users-button"
                    style={{
                      height: "35px",
                      borderColor: "transparent",
                      borderRadius: "30px"
                    }}
                  >
                    {`Deactivated${
                      deactivatedEmployeesNumber > 0
                        ? ` (${deactivatedEmployeesNumber})`
                        : ""
                    }`}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Col>
              <Col
                className="mt-4 access-role-col users-toggle-font"
                lg={5}
                xs={12}
              >
                <div
                  style={{
                    margin: "5px 10px 0 0",
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  <p
                    style={{
                      marginBottom: "0px",
                      padding: "4px 4px 0 0",
                      minWidth: "90px"
                    }}
                  >
                    Access Role:
                  </p>
                  <Dropdown
                    onSelect={event => handleSetAccessRoleStatus(event)}
                  >
                    <Dropdown.Toggle size="sm" className="access-dropdown">
                      {formatAccessRoleStatus(accessRole)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="all" data-id={"all"}>
                        All
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="users" data-id={"users"}>
                        Users
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="admins" data-id={"admins"}>
                        Admins
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <Dropdown onSelect={handleAddEmployees}>
                  <Dropdown.Toggle className="add-employees-button d-flex">
                    <img
                      src={addUser}
                      className="add-employee-image"
                      alt="add-employee-icon"
                    />
                    <p className="mt-1 mr-3">Add Employees</p>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="manually">
                      Add Employees Manually
                    </Dropdown.Item>
                    {hsVersion > 1 && (
                      <Dropdown.Item eventKey="csv">
                        Add Employees via CSV
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item eventKey="link">
                      Add Employees via Invite Link
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <Row>
              {createdEmployeesNumber && createdEmployeesNumber > 0 ? (
                <Banner
                  handleClick={handleInviteAllUsersClick}
                  bannerText={"inviteEmployee"}
                  createdEmployeesNumber={createdEmployeesNumber}
                />
              ) : null}
            </Row>
            <Row className="mt-3">
              <Col lg={12} md={12} sm={12} className="text-right">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text style={{ backgroundColor: "white" }}>
                      <span>
                        <img
                          src={searchIcon}
                          alt="search"
                          style={{ height: "16px" }}
                        />
                      </span>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    onChange={e =>
                      dispatch(actions.updateSearchTerm(e.target.value))
                    }
                    value={searchTerm}
                    placeholder="Search by employee name or email"
                    style={{
                      height: "40px",
                      borderLeft: "none",
                      paddingLeft: "0px"
                    }}
                  />
                </InputGroup>
              </Col>
            </Row>
          </>
        )}
        {value === 1 && (
          <Row className="mt-4">
            <Col lg={6} md={6} sm={6} className="text-right">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text style={{ backgroundColor: "white" }}>
                    <span>
                      <img
                        src={searchIcon}
                        alt="search"
                        style={{ height: "16px" }}
                      />
                    </span>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  onChange={e =>
                    dispatch(actions.updateSearchTerm(e.target.value))
                  }
                  placeholder="Search by team name"
                  style={{
                    height: "40px",
                    borderLeft: "none",
                    paddingLeft: "0px"
                  }}
                />
              </InputGroup>
            </Col>
            <Col
              lg={5}
              md={5}
              sm={5}
              className="offset-md-1"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {hsVersion > 1 && (
                <div
                  style={{
                    margin: "5px 10px 0 0",
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  <p
                    style={{
                      marginBottom: "0px",
                      padding: "4px 4px 0 0",
                      minWidth: "90px"
                    }}
                  >
                    Team Type:
                  </p>
                  <Dropdown onSelect={event => handleSetTeamType(event)}>
                    <Dropdown.Toggle size="sm" className="access-dropdown">
                      {formatAccessRoleStatus(teamType)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="all" data-id={"all"}>
                        {`All   `}
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="automatic" data-id={"automatic"}>
                        Automatic
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
              <button
                className="teal-button"
                onClick={handleCreateTeamClick}
                style={{
                  width: "146px"
                }}
              >
                <img src={plusIcon} className="mr-2" alt="create team" />
                Create Team
              </button>
            </Col>
          </Row>
        )}
        <Container className="admin-container">
          <Row
            className="mt-3"
            style={{
              maxHeight: "100vh",
              overflowY: "auto"
            }}
          >
            <Col>
              <TabPanel value={value} index={0}>
                <UsersTable
                  searchValue={searchTerm}
                  viewUserStatus={userStatus}
                  accessRoleStatus={accessRole}
                  users={users}
                  hsVersion={hsVersion}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <TeamsTable searchValue={searchTerm} teamType={teamType} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <CompanySettings
                  handleAllowUsersToCreateTeams={handleAllowUsersToCreateTeams}
                  handleAllowUsersShareInviteLink={
                    handleAllowUsersShareInviteLink
                  }
                  handleAllowLinkResults={handleAllowLinkResults}
                  tmgAllowUsersCreateTeam={tmgAllowUsersCreateTeam}
                  allowUsersShareInviteLink={allowUsersShareInviteLink}
                  allowLinkResults={allowLinkResults}
                  hasSSOHardEnabled={hasSSOHardEnabled}
                  handleAutomaticTeamsChange={handleAutomaticTeamsChange}
                  hasAutomaticTeamsOn={hasAutomaticTeamsOn}
                  hsVersion={hsVersion}
                />
              </TabPanel>
            </Col>
          </Row>
        </Container>
      </Container>
      <InviteUsersModal
        show={showAddUsersModal}
        onHide={() => setShowAddUsersModal(false)}
        tmgAllowUsersCreateTeam={tmgAllowUsersCreateTeam}
        allowedDomains={allowedDomains}
        handleAddUsers={handleAddUsers}
        existingUsers={existingUsers}
        inviteLink={inviteLink}
        deactivateLink={deactivateLink}
        newLinkGenerated={newLinkGenerated}
        resetNewLinkGenerated={resetNewLinkGenerated}
        allowUsersShareInviteLink={allowUsersShareInviteLink}
        hasSSOHardEnabled={hasSSOHardEnabled}
      />
      <InviteAllUsersModal
        show={showInviteAllUsersModal}
        onHide={() => setShowInviteAllUsersModal(false)}
        createdEmployeesNumber={createdEmployeesNumber}
        handleAddAllUsers={handleAddAllUsers}
      />
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={hideConfirmationModal}
        createdEmployeesNumber={createdEmployeesNumber}
        invitedToInsights={employeeInvitedToInsights}
        employeeName={invitedEmployeeName}
        employeeEdited={employeeEdited}
        newEmployeeCount={newEmployees}
        updatedEmployeeCount={updatedEmployees}
        deactivatedEmployeeCount={deactivatedEmployees}
        confirmationFrom={confirmationFrom}
        bulkInvitedNumber={bulkInvitedNumber}
      />
      <AutomaticTeamsConfirmationModal
        show={showAutomaticTeamsConfirmationModal}
        onHide={() => {
          setShowAutomaticTeamsConfirmationModal(false);
        }}
        updateShowAutomaticTeams={updateShowAutomaticTeams}
        hasAutomaticTeamsOn={hasAutomaticTeamsOn}
      />
      <NewTeamModal
        showNewTeamModal={showNewTeamModal}
        teamName={newTeamName}
        teamId={newTeamId}
        isOnTeam={isOnTeam}
        onHide={closeNewTeamModal}
        fromDashboard={false}
      />
    </>
  );
}
