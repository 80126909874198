import React from "react";
import { Modal, Button } from "react-bootstrap";
import { LinkButton } from "app/components/LinkButton";

interface Props {
  show: boolean;
  onHide: () => void;
  createdEmployeesNumber: number | undefined;
  handleAddAllUsers: () => void;
}

export default function InviteUsersModal({
  show,
  onHide,
  createdEmployeesNumber,
  handleAddAllUsers,
}: Props) {
  /* Style constants */
  const linkButtonStyle = {
    fontFamily: "Lato",
    fontSize: "14px",
    lineHeight: "1.43",
    color: "#425cc7",
    paddingTop: "2px",
  };

  const handleSubmit = () => {
    handleAddAllUsers();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      id="invite-modal"
      className="invite-all-modal"
    >
      <Modal.Header closeButton className="modal-header">
        Invite Employees
      </Modal.Header>
      <Modal.Body>
        {createdEmployeesNumber === 1 ? (
          <p style={{ paddingTop: "10px" }}>
            Invitation will be sent to{" "}
            <strong>{`${createdEmployeesNumber}`}</strong> employee who has not
            joined Talent Insights yet.
          </p>
        ) : (
          <p style={{ paddingTop: "10px" }}>
            Invitations will be sent to{" "}
            <strong>{`${createdEmployeesNumber}`}</strong> employees who have
            not joined Talent Insights yet.
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <LinkButton
          text="Cancel"
          showArrow=""
          handler={onHide}
          isLoading={false}
          style={linkButtonStyle}
        />
        <Button className="invite-created-button" onClick={handleSubmit}>
          {`Invite ${createdEmployeesNumber} ${
            createdEmployeesNumber === 1 ? "Employee" : "Employees"
          }`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
