import React from "react";
import { Nav } from "react-bootstrap";
import achievementIcon from "resources/images/trait_icons/trait-icons-achievement.svg";
import assertivenessIcon from "resources/images/trait_icons/trait-icons-assertiveness.svg";
import competitivenessIcon from "resources/images/trait_icons/trait-icons-competitiveness.svg";
import conscientiousnessIcon from "resources/images/trait_icons/trait-icons-conscientiousness.svg";
import cooperativenessIcon from "resources/images/trait_icons/trait-icons-cooperativeness.svg";
import extroversionIcon from "resources/images/trait_icons/trait-icons-extroversion.svg";
import motivationIcon from "resources/images/trait_icons/trait-icons-motivation.svg";
import opennessIcon from "resources/images/trait_icons/trait-icons-openness.svg";
import patienceIcon from "resources/images/trait_icons/trait-icons-patience.svg";
import selfConfidenceIcon from "resources/images/trait_icons/trait-icons-self-confidence.svg";
import notableTraitIcon from "resources/images/notable-trait.svg";
import {
  PersonalityReports,
  MemberPersonalityReport,
  TraitData,
} from "./types";
import { TraitSummaryCard } from "./TraitSummaryCard";
// import averageTeamScoreIcon from "resources/images/average-team-score-icon.svg";

export const getNavItems = (notableTraits: string[]) => {
  const traitData = getTraitsData();

  const sortedTraitData = getSortedTraitData(traitData, notableTraits);

  const navItemsArray = sortedTraitData.map((trait) => {
    return (
      <Nav.Link
        key={`${trait.name}`}
        eventKey={`${trait.name}`}
        href={`#${trait.name}`}
        className="trait-nav"
      >
        <img
          src={trait.icon}
          alt={`${trait.name}`}
          className="mr-3 nav-image"
        />
        <span className="nav-text">{trait.name}</span>
        {notableTraits.includes(trait.abbreviation) ? (
          <span style={{ marginLeft: "auto" }}>
            <img src={notableTraitIcon} alt="notable trait" />
          </span>
        ) : null}
      </Nav.Link>
    );
  });
  return navItemsArray;
};

export const getTraitCards = (
  teamPersonalityReports: PersonalityReports | null,
  memberPersonalityReports: MemberPersonalityReport[] | null,
  notableTraits: string[],
  viewAllWorkplaceInsights: number | undefined,
  loggedInUserIsTeamLead: boolean,
  teamId: number | undefined,
  teamName: string | undefined
) => {
  const traitData = getTraitsData();

  const sortedTraitData = getSortedTraitData(traitData, notableTraits);

  // in order for the nav bar to initially display with the first option highlighted, we
  // cannot return null for the below map, therefore we need to disable that eslint rule.
  // eslint-disable-next-line array-callback-return
  const traitCardsArray = sortedTraitData.map((trait) => {
    if (teamPersonalityReports !== null) {
      return (
        <TraitSummaryCard
          trait={trait}
          teamPersonalityReports={teamPersonalityReports}
          memberPersonalityReports={memberPersonalityReports}
          notableTraits={notableTraits}
          key={trait.name}
          viewAllWorkplaceInsights={viewAllWorkplaceInsights}
          loggedInUserIsTeamLead={loggedInUserIsTeamLead}
          teamId={teamId}
          teamName={teamName}
        />
      );
    }
  });

  return traitCardsArray;
};

export const getSortedTraitData = (
  traitData: TraitData[],
  notableTraits: string[]
) => {
  /*
   * in order to sort the traitData object, we have to first create an array of just the
   * notable traits, and then one of the non-notable traits, and finally, merge them together.
   */
  const notableTraitData = traitData.filter((trait) =>
    notableTraits.includes(trait.abbreviation)
  );
  const nonNotableTraitData = traitData.filter(
    (trait) => !notableTraits.includes(trait.abbreviation)
  );

  notableTraitData.sort(sortCallBack);
  nonNotableTraitData.sort(sortCallBack);
  const sortedTraitData = [...notableTraitData, ...nonNotableTraitData];

  return sortedTraitData;
};

export const getSuggestions = (
  trait: string,
  teamPersonalityReports: PersonalityReports | null
) => {
  if (teamPersonalityReports) {
    const suggestionTitles = teamPersonalityReports[trait].suggestions
      ? Object.keys(teamPersonalityReports[trait].suggestions)
      : null;
    const suggestions = suggestionTitles?.map((suggestion, index) => {
      return (
        <div key={`${trait}-${index}`}>
          <p className="trait-suggestion-title">{suggestion}</p>
          <p>{teamPersonalityReports[trait].suggestions[suggestion]}</p>
        </div>
      );
    });
    return suggestions;
  }
  return false;
};

export const getTraitsData = () => {
  return [
    {
      name: "Self-Confidence",
      icon: selfConfidenceIcon,
      abbreviation: "SCN",
      definition:
        "The Self-Confidence scale is an indicator of the level of confidence and self-assurance an individual brings to his or her work.",
    },
    {
      name: "Extroversion",
      icon: extroversionIcon,
      abbreviation: "EXT",
      definition:
        "The Extroversion scale indicates the degree to which a person sees himself or herself as socially outgoing.",
    },
    {
      name: "Competitiveness",
      icon: competitivenessIcon,
      abbreviation: "CMP",
      definition:
        "The Competitiveness scale reflects the need to win, to perform better than others, or to surpass standards of achievement or performance.",
    },
    {
      name: "Motivation",
      icon: motivationIcon,
      abbreviation: "MOT",
      definition:
        "The Motivation scale is intended to represent a person's inner drive, commitment to achieve, and the strength of inner emotions, needs, and values.",
    },
    {
      name: "Cooperativeness",
      icon: cooperativenessIcon,
      abbreviation: "COP",
      definition:
        "The Cooperativeness scale indicates a person's level of comfort in working closely with others and in taking the lead from others.",
    },
    {
      name: "Openness",
      icon: opennessIcon,
      abbreviation: "OPEN",
      definition:
        "The Openness scale measures the extent to which an individual is imaginative and creative, as opposed to down to earth and conventional.",
    },
    {
      name: "Achievement",
      icon: achievementIcon,
      abbreviation: "ACH",
      definition:
        "The Achievement scale reflects an individual's ability to follow through and complete tasks and to achieve specific goals.",
    },
    {
      name: "Assertiveness",
      icon: assertivenessIcon,
      abbreviation: "AST",
      definition:
        "The Assertiveness scale provides a gauge of an individual's directness in expressing himself or herself and in dealing with others.",
    },
    {
      name: "Patience",
      icon: patienceIcon,
      abbreviation: "PAT",
      definition:
        "The Patience scale indicates a person's ability to effectively cope with frustration encountered in completing tasks or in conflict-laden situations.",
    },
    {
      name: "Conscientiousness",
      icon: conscientiousnessIcon,
      abbreviation: "CON",
      definition:
        "The Conscientiousness scale is an indicator of a person's tendencies with respect to being deliberate, self-disciplined, organized and dependable.",
    },
  ];
};

export const sortCallBack = (a, b) => {
  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
  const nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};
