import React from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectStrengths, selectWeaknesses } from "../selectors";

export default function StrengthsChallenges() {
  const strengths = useSelector(selectStrengths);
  const weaknesses = useSelector(selectWeaknesses);

  return (
    <div className="trait-summary-card">
      {/* eslint-disable-next-line */}
      <a className="anchor" id="StrengthPotentialChallenges"></a>
      <Row>
        <Col className="d-flex">
          <p className="trait-name mb-4">Strengths & Potential Challenges</p>
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={6} sm={6}>
          <p className="individual-personality-strength-header">Strengths</p>
          <ul>
            {strengths?.map((strength, index) => (
              <li key={`${strength}-${index}`}>{strength}</li>
            ))}
          </ul>
        </Col>
        <Col lg={6} md={6} sm={6}>
          <p className="individual-personality-strength-header">
            Potential Challenges
          </p>
          <ul>
            {weaknesses?.map((weakness, index) => (
              <li key={`${weakness}-${index}`}>{weakness}</li>
            ))}
          </ul>
        </Col>
      </Row>
    </div>
  );
}
