import React from "react";
import { Card } from "react-bootstrap";
import { TeamMembers } from "./types";
import { getTeamMembersList } from "./helper-functions";
import { isTeamLead } from "utils/helper_functions/isTeamLead";
import { selectUserAccountId } from "app/containers/Global/selectors";
import { useSelector } from "react-redux";

interface Props {
  teamMembers: TeamMembers[] | undefined;
  showRemoveMemberConfirmation: (teamMemberId: number | undefined) => void;
  showResendInviteConfirmation: (teamMemberId: number | undefined) => void;
  teamName: string | undefined;
  teamId: string;
  automatic: boolean;
}

export function TeamMemberList({
  teamMembers,
  showRemoveMemberConfirmation,
  showResendInviteConfirmation,
  teamName,
  teamId,
  automatic,
}: Props) {
  const userAccountId = useSelector(selectUserAccountId);

  /* determine if the logged in user is the team lead, in order to show the overflow menus */
  const currentMemberIsTeamLead: boolean = teamMembers
    ? isTeamLead(teamMembers, userAccountId)
    : false;

  const teamMembersList = teamMembers
    ? getTeamMembersList(
        teamMembers,
        showRemoveMemberConfirmation,
        currentMemberIsTeamLead,
        showResendInviteConfirmation,
        teamName,
        teamId,
        automatic
      )
    : null;

  return (
    <Card>
      <Card.Body>{teamMembersList}</Card.Body>
    </Card>
  );
}
