import React, { useRef, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, OverlayTrigger, Popover } from "react-bootstrap";
import { useWindowResize } from "beautiful-react-hooks";
import {
  TraitData,
  PersonalityReports,
  TraitsLabelAndColor,
  MemberPersonalityReport,
  TeamData,
} from "./types";
import { selectTeam } from "./selectors";
import { SpectrumBars } from "app/components/SpectrumBars";
import { getSuggestions } from "./helper-functions";
import { getAverageTeamMember } from "../TeamPage/helper-functions";
import { getTraitNameAndIcon } from "utils/helper_functions/getTraitNameAndIcon";
import { getAvatars } from "utils/helper_functions/getAvatars";
import { LabelRow } from "app/components/LabelRow";
import anonymousUserIcon from "resources/images/user-anonymous.svg";
import notableTraitIcon from "resources/images/notable-trait.svg";
import { Avatar } from "app/components/Avatar";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { getIndicators } from "../TeamPage/helper-functions";
import {
  selectUserAccountId,
  selectIsAdmin,
} from "app/containers/Global/selectors";

interface Props {
  trait: TraitData;
  teamPersonalityReports: PersonalityReports;
  memberPersonalityReports: MemberPersonalityReport[] | null;
  notableTraits: string[];
  viewAllWorkplaceInsights: number | undefined;
  loggedInUserIsTeamLead: boolean;
  teamId: number | undefined;
  teamName: string | undefined;
}

export function TraitSummaryCard({
  trait,
  teamPersonalityReports,
  memberPersonalityReports,
  notableTraits,
  viewAllWorkplaceInsights,
  loggedInUserIsTeamLead,
  teamId,
  teamName,
}: Props) {
  /* State Variables */
  const [width, setWidth] = useState<number | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedMember, setSelectedMember] = useState<any>(null);

  /****************************************************************************
   * Selectors                                                               *
   ****************************************************************************/
  const team: TeamData | null = useSelector(selectTeam);

  /*
   * set variable to whether the logged in user (the one view the page) is a
   * team lead. for use when determining whether to show the Avatars or not.
   */
  const loggedInUserAccountId = useSelector(selectUserAccountId);
  const loggedInUserIsAdmin = useSelector(selectIsAdmin);

  // convert memberScoresArray to an array so that the getAvatars function
  // can work with it.
  const memberScoresArray = memberPersonalityReports
    ? Object.values(memberPersonalityReports)
    : [];

  /*
   * define the ref we use to get the width of the spectrum line element
   * so we can set the avatar positions correctly
   */
  const rowRef: any = useRef(null);
  const history = useHistory();

  /*
   * the layout effect hook will determine the width of the element that contains
   * the spectrum line.  it is responsive, so it'll get called anytime there is a
   * change to the element size
   */
  useLayoutEffect(() => {
    if (rowRef.current) {
      setWidth(Math.round(rowRef?.current?.getBoundingClientRect().width) - 65);
    }
  }, [rowRef]);

  /* Handlers */
  function handleAvatarClick(teamMemberId: string | number) {
    let selectedMember: any;
    memberScoresArray?.map((member) => {
      if (member.teamMemberId === teamMemberId) {
        selectedMember = member;
      }
    });
    if (teamMemberId !== "teamAverage") {
      setSelectedMember(selectedMember);
      const location = {
        pathname: `/profile/${selectedMember.userAccountId}`,
        state: {
          rootName: teamName,
          rootPath: `/team/${teamId}`,
          previousName: "Team Personality",
          previousPath: `/team_personality/${teamId}`,
        },
      };
      history.push(location);
    }
  }

  // method to set the new width of the window if the browser width is changed.
  useWindowResize(() => {
    if (rowRef.current) {
      setWidth(Math.round(rowRef?.current?.getBoundingClientRect().width) - 65);
    }
  });

  const indicatorLocations = getIndicators(
    memberScoresArray,
    width,
    trait.abbreviation,
    true
  );

  /*
   * method used to determine if hovered over avatar collides with any other, and if
   * so, display the avatars correctly
   */
  const getToolTipDisplay = (dataTip: string) => {
    const trait = dataTip?.substring(0, dataTip.indexOf("-"));
    const teamMemberId = dataTip?.substring(dataTip.indexOf("-") + 1);
    let allScores = memberScoresArray?.map((member) => member);
    const averageTeamMember = getAverageTeamMember();
    if (trait) {
      averageTeamMember[trait] = {
        score: teamPersonalityReports[trait]?.teamAverage,
      };
    }
    allScores?.push(averageTeamMember);
    const chosenMember = allScores?.filter((member) => {
      // eslint-disable-next-line eqeqeq
      if (member.teamMemberId == teamMemberId) return member;
    });
    const baselineTraitScore =
      teamMemberId === "teamAverage"
        ? averageTeamMember[trait].score
        : chosenMember && chosenMember.length > 0 && trait
        ? chosenMember[0][trait].score
        : null;
    const collidingAvatars: MemberPersonalityReport[] | null = [];
    allScores?.map((member) => {
      if (
        width &&
        (width / 100) * member[trait]?.score >
          (width / 100) * baselineTraitScore - 32 &&
        (width / 100) * member[trait]?.score <
          (width / 100) * baselineTraitScore + 32
      ) {
        collidingAvatars.push(member);
      }
    });

    const CollidingAvatarsArray = collidingAvatars
      .slice()
      .sort((a, b) => a[trait].score - b[trait].score)
      .map((member) => {
        const isLoggedInMember = loggedInUserAccountId === member.userAccountId;
        return (
          <span
            style={{ marginRight: "3px" }}
            onClick={() => handleAvatarClick(member.teamMemberId)}
            data-id={member.teamMemberId}
            data-trait={trait}
            key={member.teamMemberId}
          >
            {isLoggedInMember || loggedInUserIsAdmin || member.firstName ? (
              <Avatar
                height={32}
                width={32}
                image={member.profilePicture}
                userName={
                  member.firstName
                    ? `${member.firstName} ${member.lastName}`
                    : member.emailAddress
                }
                initials={
                  member.firstName
                    ? `${member.firstName.charAt(0)} ${member.lastName.charAt(
                        0
                      )}`
                    : member.emailAddress.slice(0, 2)
                }
                fontSize={12}
                key={member.teamMemberId}
                backgroundColor={`#${member.avatarColor}`}
                dataFor={`avatar-${trait}`}
                dataTip={`${trait}-${member.teamMemberId}`}
              />
            ) : (
              <Avatar
                height={32}
                width={32}
                image={anonymousUserIcon}
                userName={""}
                initials={""}
                isTeamLead={0}
                fontSize={12}
                shouldOverlap={true}
                key={member.teamMemberId}
                backgroundColor={`#${member.avatarColor}`}
                dataFor={`avatar-${trait}`}
                dataTip={`${trait}-${member.teamMemberId}`}
                doNotShowTooltip={true}
                doNotShowBorder={true}
              />
            )}
          </span>
        );
      });

    // react-tooltip requires null in getContent if we don't want to display a tooltip
    if (CollidingAvatarsArray.length <= 1) return null;

    return (
      <div>
        <Row>
          {CollidingAvatarsArray.length > 1 ? CollidingAvatarsArray : null}
        </Row>
      </div>
    );
  };
  /* end getTooltipDisplay method */

  /* get the suggestions for this specific trait, with their headlines and body */
  const suggestionsArray = getSuggestions(
    trait.abbreviation,
    teamPersonalityReports
  );

  /* get the trait color for the Spectrum Bar and the icon for trait strength */
  const traitLabelsAndColor: TraitsLabelAndColor = getTraitNameAndIcon(
    trait.abbreviation,
    teamPersonalityReports
  );

  /* get the members Avatars and postions to display along the trait spectrum */
  const AvatarArray = getAvatars(
    memberScoresArray,
    width,
    trait.abbreviation,
    false,
    handleAvatarClick,
    true,
    // viewAllWorkplaceInsights,
    // loggedInUserIsTeamLead,
    loggedInUserIsAdmin,
    true,
    teamPersonalityReports[trait.abbreviation].teamAverage,
    true,
    loggedInUserAccountId
  );

  // popover for notable traits icon and text
  const notablePopover = (
    <Popover id="notable-popover" className="notable-popover">
      Notable traits are defined as those that are furthest from the global
      average.
    </Popover>
  );

  return (
    <div className="trait-summary-card">
      {/* eslint-disable-next-line */}
      <a className="anchor" id={`${trait.name}`}></a>
      <Row>
        <Col className="d-flex">
          <p className="trait-name">{trait.name}</p>
          {notableTraits.includes(trait.abbreviation) ? (
            <OverlayTrigger overlay={notablePopover}>
              <span className="notable-icon">
                <img src={notableTraitIcon} alt="notable trait" />
                <span className="notable-text">Notable Trait</span>
              </span>
            </OverlayTrigger>
          ) : null}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <p className="trait-text">{trait.definition}</p>
        </Col>
      </Row>
      {teamPersonalityReports ? (
        <Row>
          <Col>
            <p className="trait-text">
              {teamPersonalityReports[trait.abbreviation].summary}
            </p>
          </Col>
        </Row>
      ) : null}
      <Row className="mb-4 mt-3" ref={rowRef}>
        <Col>
          <Row>
            <Col className="d-flex mr-4 ml-1">
              {AvatarArray}
              <ReactTooltip
                id={`avatar-${trait.abbreviation}`}
                getContent={(dataTip) => getToolTipDisplay(dataTip)}
                backgroundColor="#FFF"
                borderColor="#e4e4e4"
                border={true}
                clickable
                effect="solid"
                delayHide={500}
                key={trait.abbreviation}
              />
              <SpectrumBars
                traitColor={`#CCD2DC`}
                indicatorLocations={indicatorLocations}
              />
            </Col>
          </Row>
          <Row>
            <LabelRow
              lowLabel={traitLabelsAndColor.lowLabel}
              highLabel={traitLabelsAndColor.highLabel}
              fromSummaryPage={false}
            />
          </Row>
        </Col>
      </Row>
      {loggedInUserIsTeamLead || loggedInUserIsAdmin || team?.isSample ? (
        <>
          <Row>
            <Col>
              <p className="trait-italics">What this means for ...</p>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <div>{suggestionsArray}</div>
            </Col>
          </Row>
        </>
      ) : null}
    </div>
  );
}
