import { Avatar } from "app/components/Avatar";
import { BreadcrumbNavigation } from "app/components/BreadcrumbNavigation";
import React from "react";
import { Media } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectUserAccountId as selectLoggedInUserAccountId } from "app/containers/Global/selectors";
// import { User } from "./types";

interface Props {
  user: User | null;
  handleUploadClick: () => void;
  // userAccountId: number;
  location: any;
}

export function HeadlinePanel({ user, handleUploadClick, location }: Props) {
  // get the userAccountId of the logged in user to compare it to the userAccountId prop that
  // is passed in.  If it's the same, we can show the upload image icon.  If not, we can't.
  const loggedInUserAccountId = useSelector(selectLoggedInUserAccountId);

  return (
    <>
      {user ? (
        <>
          <div className="mt-3 mb-0 ml-0">
            <BreadcrumbNavigation location={location} user={user} />
          </div>
          <Media className="results-headline-panel">
            <div className="align-self-start mr-3">
              <Avatar
                height={84}
                width={84}
                fontSize={34}
                userName={`${user?.firstName} ${user?.lastName}`}
                emailAddress={user?.emailAddress}
                initials={
                  user?.firstName
                    ? `${user.firstName
                        .charAt(0)
                        .toUpperCase()} ${user.lastName
                        .charAt(0)
                        .toUpperCase()}`
                    : `${user?.emailAddress.slice(0, 2).toUpperCase()}`
                }
                image={user?.profilePicture}
                backgroundColor={`#${user?.avatarColor}`}
                isTeamLead={0}
                showPhotoUpload={
                  Number(user?.userAccountId) === loggedInUserAccountId
                }
                uploadPhotoHandler={handleUploadClick}
              />
            </div>
            <Media.Body>
              <p className="headline-panel-header-text mt-3 mb-0">
                {user.firstName} {user.lastName}
              </p>
              <p className="email-text">{user.emailAddress}</p>
            </Media.Body>
          </Media>
        </>
      ) : null}
    </>
  );
}
