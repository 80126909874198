/**
 *
 * TeamPage
 *
 */

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./slice";
import {
  selectTeam,
  selectTeamPage,
  selectMemberScores,
  selectTeamScores,
  selectLoadingPersonalityScores,
  selectMembersPersonalityReports,
  selectAllowedDomains,
  selectMembersInvited,
  selectUsersList,
  selectIsLoading,
  selectInvitingMembers,
  selectPersonalityReportsLoaded
} from "./selectors";
import { teamPageSaga } from "./saga";
import useEffectOnMount from "utils/custom_hooks/useEffectOnMount";
import { HeadlinePanel } from "./HeadlinePanel/HeadlinePanel";
import { TeamPersonalityCard } from "./TeamPersonalityCard/TeamPersonalityCard";
import { TeamPersonalityComparisonCard } from "./TeamPersonalityComparisonCard/TeamPersonalityComparisonCard";
import { InviteCandidatesModal } from "app/components/InviteCandidatesModal";
import { InviteFormData } from "./types";
import StartAssessmentBanner from "./StartAssessmentBanner";
import { hasPendingTest } from "./helper-functions";
import { SampleDataBanner } from "app/components/SampleDataBanner";
import SampleDataModal from "./SampleDataModal";
import {
  selectUser,
  selectUserAccountId
} from "app/containers/Global/selectors";
import useSegmentTracking from "../../../utils/custom_hooks/useSegmentTracking";

interface Props {}

export function TeamPage() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: teamPageSaga });

  /****************************************************************************
   * Selectors                                                                 *
   *****************************************************************************/
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const teamPage = useSelector(selectTeamPage);
  const team = useSelector(selectTeam);
  const memberScores = useSelector(selectMemberScores);
  const teamScores = useSelector(selectTeamScores);
  const loadingPersonalityScores = useSelector(selectLoadingPersonalityScores);
  const membersPersonalityReports = useSelector(
    selectMembersPersonalityReports
  );
  const allowedDomains = useSelector(selectAllowedDomains);
  const membersInvited = useSelector(selectMembersInvited);
  const usersList = useSelector(selectUsersList);
  const isLoading = useSelector(selectIsLoading);
  const invitingMembers = useSelector(selectInvitingMembers);
  const user = useSelector(selectUser);
  const userAccountId = useSelector(selectUserAccountId);
  const personalityReportsLoaded = useSelector(selectPersonalityReportsLoaded);

  /****************************************************************************
   * State Variables                                                          *
   ****************************************************************************/
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showSampleDataModal, setShowSampleDataModal] = useState(false);
  // const [showSampleDataBanner, setShowSampleDataBanner] = useState(false);

  /****************************************************************************
   * Hooks                                                                    *
   ****************************************************************************/
  const dispatch = useDispatch();

  /* get the value of teamId from the url params, to be used to get the team-related data */
  const { teamId }: any = useParams();

  const currentUserPendingEventId = hasPendingTest(userAccountId, team);

  useEffectOnMount(() => {
    dispatch(actions.getTeams(teamId));

    /* get the personality scores for all of the teamMembers of the team */
    dispatch(actions.getPersonalityScores(teamId));

    /* we have to make this api call every time we mount so that the radar graph will display */
    dispatch(actions.getPersonalityReports(teamId));

    dispatch(actions.getUsersList());
  });

  useEffect(() => {
    /* we need to get the allowed domains in case the InviteCandidatesModal is opened */
    const companyAccountId = user?.companyAccountId;
    if (companyAccountId) {
      dispatch(actions.getAllowedDomains(companyAccountId));
    }
  }, [user, dispatch]);

  useSegmentTracking(
    "team dashboard", // page name
    personalityReportsLoaded && !loadingPersonalityScores, // isDataLoaded
    teamScores && memberScores, // pageHasContentToShow
    teamId
  );

  /* Clean up */
  useEffect(() => {
    return () => {
      dispatch(actions.cleanUp());
    };
  }, [dispatch]);

  /****************************************************************************
   * Handler                                                                  *
   ****************************************************************************/
  const handleShowInviteModal = () => {
    dispatch(actions.resetMembersInvited());
    setShowInviteModal(true);
  };

  const handleInviteMembers = (formData: InviteFormData) => {
    dispatch(actions.inviteMembers(formData, teamId));
  };

  const handleRemoveMember = () => {
    /*
     * to get the teamMemberId, we need to pull the userAccountId from the teaming token.
     * for some reason, TS requires we do this in 3 steps, otherwise it throws an error
     */
    const userAccountId = user?.userAccountId;
    const teamMemberId = team?.teamMembers.filter(member => {
      if (member.userAccountId === userAccountId) {
        return member;
      }
    })[0].teamMemberId;
    dispatch(actions.removeMember(teamMemberId));
  };
  const handleShowSampleDataModal = () => {
    setShowSampleDataModal(!showSampleDataModal);
  };

  return (
    <>
      <Helmet>
        <title>{team ? team.teamName : ""}</title>
        <meta name="description" content="Talent Insights - Team Page" />
      </Helmet>
      {team?.isSample === 1 && <SampleDataBanner />}
      <SampleDataModal
        show={showSampleDataModal}
        onHide={() => handleShowSampleDataModal()}
      />
      <div>
        <HeadlinePanel
          teamName={team?.teamName}
          teamMembers={team?.teamMembers}
          handleShowInviteModal={handleShowInviteModal}
          teamId={teamId}
          handleRemoveMember={handleRemoveMember}
          isLoading={isLoading}
          isSample={team?.isSample === 1}
          handleShowSampleDataModal={handleShowSampleDataModal}
          automatic={Boolean(team?.automatic)}
        />
      </div>
      {currentUserPendingEventId ? (
        <div className="mt-3">
          <StartAssessmentBanner eventId={currentUserPendingEventId} />
        </div>
      ) : null}
      <div className="mt-3">
        <TeamPersonalityCard
          teamMembers={team?.teamMembers}
          memberScores={memberScores}
          teamScores={teamScores}
          loadingPersonalityScores={loadingPersonalityScores}
          memberPersonalityReports={membersPersonalityReports}
          teamId={teamId}
          team={team}
        />
      </div>

      <div className="mt-3">
        <TeamPersonalityComparisonCard
          memberScores={memberScores}
          teamScores={teamScores}
          isLoading={isLoading}
        />
      </div>
      <div>
        <InviteCandidatesModal
          show={showInviteModal}
          allowedDomains={allowedDomains}
          handleCancel={() => setShowInviteModal(false)}
          handleInviteMembers={handleInviteMembers}
          membersInvited={membersInvited}
          usersList={usersList}
          teamMembers={team?.teamMembers}
          invitingMembers={invitingMembers}
          teamName={team?.teamName}
        />
      </div>
    </>
  );
}
