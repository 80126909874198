import { useEffect } from "react";
import { useDispatch } from "react-redux";

// Download report as a PDF
// reference: https://medium.com/octopus-labs-london/downloading-a-base-64-pdf-from-an-api-request-in-javascript-6b4c603515eb
export default function useDownloadPDF(
  pdfData: any,
  reportType: string,
  resetPDF: () => void
) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (pdfData) {
      const data =
        reportType === "individualPersonality" ? pdfData.report : pdfData.data;
      const linkSource = `data:application/pdf;base64,${
        reportType === "individualPersonality" ? data : data[0]
      }`;
      const downloadLink = document.createElement("a");

      let fileName;
      switch (reportType) {
        case "individualPersonality":
          fileName = pdfData.name;
          break;
        case "teamPersonality":
          fileName = "Team Personality Report - " + data.teamName;
          break;
        case "collaborationGuide":
          let joinedName = "";
          data.users?.forEach(user => {
            joinedName += user.firstName + " and ";
          });
          joinedName = joinedName.substr(0, joinedName.length - 5);
          fileName = "Collaboration Guide - " + joinedName;
          break;
        case "managerGuide":
          fileName = data.title;
          break;
        default:
          break;
      }

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      downloadLink.remove();
    }
    return () => {
      dispatch(resetPDF());
    };
  }, [pdfData, reportType, resetPDF, dispatch]);
}
