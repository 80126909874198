import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import emptyIcon from "resources/images/team-personality-empty.jpg";
import { useSelector } from "react-redux";
import { selectTeamMembers } from "../selectors";

export default function EmptyTraits() {
  const teamMembers = useSelector(selectTeamMembers);

  return (
    <Container fluid="lg" className="pb-5">
      <Row className="text-center">
        <Col lg={{ span: 6, offset: 3 }}>
          <img
            src={emptyIcon}
            alt="No Scores"
            style={{ height: "200px", width: "200px" }}
          />
          {teamMembers && teamMembers.length <= 1 ? (
            <p className="personality-empty-state-text">
              Start inviting team members to learn more about you can work
              better together!
            </p>
          ) : (
            <p className="personality-empty-state-text">
              Once your team members have taken their personality assessment,
              their results will appear here.
            </p>
          )}
        </Col>
      </Row>
    </Container>
  );
}
