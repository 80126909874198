import React from "react";
import { NotableTraitCard } from "app/components/NotableTraitCard";
import { NotableTraits } from "../types/global-types";

export const getNotableTraitCards = (
  notableTraits: NotableTraits[] | null | undefined
) => {
  const notableTraitCards = notableTraits?.map((trait) => {
    return <NotableTraitCard {...trait} key={trait.title} />;
  });
  return notableTraitCards;
};
