import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";

// The initial state of the AccountSetup container
export const initialState: ContainerState = {
  creatingAccount: false,
  invitationAccepted: false,
  expiredLink: false,
  errorMessage: null,
  userAccountId: null,
  loadingTeams: false,
  alreadyAccepted: false,
};

const accountSetupSlice = createSlice({
  name: "accountSetup",
  initialState,
  reducers: {
    createAccount(state, action: PayloadAction<any>) {
      state.creatingAccount = true;
    },
    createAccountSuccess(state, action: PayloadAction<any>) {
      state.creatingAccount = false;
      state.userAccountId = action.payload;
    },
    createAccountError(state, action: PayloadAction<any>) {
      state.creatingAccount = false;
      state.errorMessage = action.payload;
    },
    acceptInvitation(state, action: PayloadAction<any>) {
      state.invitationAccepted = false;
    },
    acceptInvitationSuccess(state) {
      state.invitationAccepted = true;
    },
    acceptInvitationError(state, action: PayloadAction<any>) {
      state.invitationAccepted = true;
      if (action.payload === 400) {
        state.expiredLink = true;
      }
      if (action.payload === 403) {
        state.alreadyAccepted = true;
      }
    },
  },
});

export const { actions, reducer, name: sliceKey } = accountSetupSlice;
