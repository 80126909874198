import React, { useState, useLayoutEffect, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Col, Card, Container, Row } from "react-bootstrap";
import { Avatar } from "app/components/Avatar";
import { useWindowResize } from "beautiful-react-hooks";
import { LinkButton } from "app/components/LinkButton";
import SampleData from "resources/images/sample-collaboration-guide.pdf";

interface Props {
  collaborationGuideUsers: User[] | null;
  handleCollabGuideViewAllClick: () => void;
  teams: any | null;
}
export const CollaborationGuide = ({
  collaborationGuideUsers,
  handleCollabGuideViewAllClick,
  teams,
}: Props) => {
  /****************************************************************************
   * State Variable                                                           *
   ****************************************************************************/
  const [numberOfCollabUsers, setNumberOfCollabUsers] = useState(
    collaborationGuideUsers?.length
  );

  const [windowWidth, setWindowWidth] = useState(0);

  /****************************************************************************
   * Hooks                                                                    *
   ****************************************************************************/
  useEffect(() => {
    setNumberOfCollabUsers(collaborationGuideUsers?.length);
  }, [collaborationGuideUsers]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    setWindowWidth(window.innerWidth);
  });

  useWindowResize(() => {
    setWindowWidth(window.innerWidth);
  });

  const history = useHistory();

  /****************************************************************************
   * Handlers                                                                 *
   ****************************************************************************/
  const handleCandidateClick = (userAccountId: number | undefined) => {
    const selectedMember = collaborationGuideUsers?.filter(
      (member) => member.userAccountId === userAccountId
    )[0];

    const location = {
      pathname: `/profile/${selectedMember?.userAccountId}`,
      state: {
        rootName: "Dashboard",
        rootPath: `/dashboard`,
      },
    };
    history.push(location);
  };

  // sampleCollabGuide: holds an array of avatars to show incase the user doesn't have members on their collaboration guide
  // sampleCollabGuideUrls: holds an array of all URL's from all of sample data members.
  const sampleCollabGuide: any = [];
  let sampleCollabGuideUrls: string[] = [];

  // Loops through each member and find sample data to display in collaboration guide on the dashboard,
  // if the user doesn't have any members part of their collaboration guide
  if (teams) {
    teams
      .slice()
      .sort((a, b) => a.teamName.trim().localeCompare(b.teamName.trim()))
      .forEach((team) => {
        if (team.isSample === 1) {
          team.teamMembers.forEach((memb) => {
            sampleCollabGuideUrls.push(memb.profilePicture);
          });
        }
      });
  }

  // Loops through all urls and only shows maxUsersToShow
  for (let index = 0; index < 2; index++) {
    sampleCollabGuide.push(
      sampleCollabGuideUrls
        // .slice(index * maxUsersToShow, maxUsersToShow + index * maxUsersToShow)
        .map((user, idx) => {
          return (
            <Row key={idx}>
              <Col className="text-center">
                <Avatar
                  key={idx}
                  height={isMobile ? 54 : 80}
                  width={isMobile ? 54 : 80}
                  fontSize={20}
                  image={user}
                  userName={null}
                  isLoading={false}
                  isTeamLead={0}
                  opacity={50}
                  doNotShowTooltip={true}
                />
              </Col>
            </Row>
          );
        })
    );
  }

  return (
    <Container className="mb-3 mt-5" fluid="lg">
      <Row className="flex-nowrap">
        <Col
          lg={10}
          md={10}
          sm={10}
          xs={8}
          className="d-flex align-items-start"
        >
          <p className="dashboard-section-text">Collaboration Guides</p>
        </Col>
        <Col
          lg={2}
          md={2}
          sm={2}
          xs={4}
          className="mt-1 text-right d-flex align-items-start justify-content-end"
        >
          <LinkButton
            text="View All"
            showArrow="none"
            handler={handleCollabGuideViewAllClick}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Card className="dashboard-collaboration-guide d-flex">
            {!collaborationGuideUsers ||
            collaborationGuideUsers.length === 0 ? (
              <div className="text-center">
                <Row
                  style={{
                    paddingTop: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  // ref={rowRef}
                >
                  {sampleCollabGuide[0]}
                </Row>
                {windowWidth <= 500 && (
                  <Row
                    style={{
                      paddingTop: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    // ref={rowRef}
                  >
                    {sampleCollabGuide[1]}
                  </Row>
                )}
                <br />
                <p
                  style={{
                    fontFamily: "Lato",
                    fontSize: "14px",
                    color: "#86888b",
                  }}
                >
                  Your Collaboration Guides will appear here once your teammates
                  complete their personality test.{" "}
                  {windowWidth > 500 && (
                    <a
                      style={{ color: "#425cc7" }}
                      href={SampleData}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Sample Collaboration Guide
                    </a>
                  )}
                </p>
                {windowWidth <= 500 && (
                  <p>
                    <a
                      style={{ color: "#425cc7" }}
                      href={SampleData}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Sample Collaboration Guide
                    </a>
                  </p>
                )}
              </div>
            ) : (
              <Row
                style={{
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent:
                    numberOfCollabUsers && numberOfCollabUsers >= 8
                      ? "space-between"
                      : "flex-start",
                  flexWrap: windowWidth <= 768 || isMobile ? "wrap" : "nowrap",
                  flexBasis: "25%",
                }}
              >
                {collaborationGuideUsers
                  .slice(0, 8)
                  .sort((a, b) => a.firstName.localeCompare(b.firstName))
                  .map((user) => {
                    const userName = user.firstName
                      ? `${user.firstName} ${user.lastName}`
                      : `${user.emailAddress}`;

                    return (
                      <Row
                        key={user?.userAccountId}
                        style={{
                          flex:
                            windowWidth <= 768 || isMobile
                              ? "1 0 calc(25% - 10px)"
                              : "",
                          margin:
                            numberOfCollabUsers && numberOfCollabUsers >= 8
                              ? windowWidth <= 768 || isMobile
                                ? "0px -5px"
                                : ""
                              : "0px",
                        }}
                      >
                        <Col
                          onClick={(event: any) =>
                            handleCandidateClick(user?.userAccountId)
                          }
                          className="text-center d-flex flex-column align-items-center"
                        >
                          <Avatar
                            key={user?.userAccountId}
                            height={isMobile ? 54 : 80}
                            width={isMobile ? 54 : 80}
                            fontSize={20}
                            userName={`${user?.firstName} ${user?.lastName}`}
                            emailAddress={user?.emailAddress}
                            image={user?.profilePicture}
                            initials={
                              user?.firstName
                                ? `${user?.firstName.charAt(
                                    0
                                  )}${user?.lastName.charAt(0)}`
                                : `${user?.emailAddress.slice(0, 2)}`
                            }
                            isLoading={false}
                            backgroundColor={`#${user?.avatarColor}`}
                            isTeamLead={0}
                            showInGrayscale={false}
                          />
                          <p className="mt-2 d-flex flex-column">
                            {(windowWidth <= 375 || isMobile) &&
                            userName.indexOf(" ") >= 0 ? (
                              <>
                                <span>{userName.split(" ")[0]}</span>{" "}
                                <span>{userName.split(" ")[1]}</span>
                              </>
                            ) : (
                              `${userName.substring(0, 12)}${
                                userName.length > 12 ? `...` : ``
                              }`
                            )}
                          </p>
                        </Col>
                      </Row>
                    );
                  })}
              </Row>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
