/**
 *
 * TeamSettings
 *
 */

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  InputGroup,
  Breadcrumb,
} from "react-bootstrap";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./slice";
import {
  selectAllowedDomains,
  selectTeamLeadChangeSuccessful,
  selectTeamMembers,
  selectTeamName,
  selectTeamSettings,
  selectUsersList,
} from "./selectors";
import { teamSettingsSaga } from "./saga";
import { LinkButton } from "app/components/LinkButton";
import { useParams } from "react-router-dom";
import { TeamMembersData } from "./types";
import "./styles.css";
import { ConfirmationModal } from "app/components/ConfirmationModal";
import { TeamLeadModal } from "./TeamLeadModal";
import useEffectOnMount from "utils/custom_hooks/useEffectOnMount";
import { selectUser, selectUserName } from "app/containers/Global/selectors";

interface Props {}

export function TeamSettings(props: Props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: teamSettingsSaga });

  /****************************************************************************
   * Selectors                                                               *
   ****************************************************************************/
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const teamSettings = useSelector(selectTeamSettings);
  const originalTeamName: string | null = useSelector(selectTeamName);
  const teamMembers: TeamMembersData[] | null = useSelector(selectTeamMembers);
  const teamLeadChangeSuccessful: boolean = useSelector(
    selectTeamLeadChangeSuccessful
  );
  const allowedDomains = useSelector(selectAllowedDomains);
  const usersList = useSelector(selectUsersList);
  const user = useSelector(selectUser);
  const userName = useSelector(selectUserName);

  /****************************************************************************
   * State Variables                                                          *
   ****************************************************************************/
  const [currentlySavedTeamName, setCurrentlySavedTeamName] = useState(
    originalTeamName
  );
  const [teamName, setTeamName] = useState(originalTeamName);
  const [teamNameDisabled, setTeamNameDisabled] = useState(true);
  const [teamLead, setTeamLead] = useState<TeamMembersData | null>(null);
  const [showTeamLeadModal, setShowTeamLeadModal] = useState(false);
  const [showDeleteTeamConfirmation, setShowDeleteTeamConfirmation] = useState(
    false
  );

  /****************************************************************************
   * Hooks                                                                    *
   ****************************************************************************/
  const dispatch = useDispatch();
  const { teamId }: any = useParams();

  useEffectOnMount(() => {
    /* we need to get the allowed domanins in case the Team Lead Modal is opened */
    const companyAccountId = user?.companyAccountId;
    dispatch(actions.getUsersList());
    if (companyAccountId) {
      dispatch(actions.getAllowedDomains(companyAccountId));
    }
  });

  useEffect(() => {
    if (teamId) {
      dispatch(actions.getTeam(teamId));
    }
  }, [teamId, dispatch]);

  useEffect(() => {
    if (!teamName && originalTeamName) {
      setTeamName(originalTeamName);
    }
  }, [teamName, originalTeamName]);

  useEffect(() => {
    const teamLead: TeamMembersData[] | undefined = teamMembers?.filter(
      (member) => member.isTeamLead
    );
    if (teamLead) setTeamLead(teamLead[0]);
  }, [teamMembers]);

  // useEffect(() => {
  //   setViewAllWorkplaceInsights(originalViewAllWorkplaceInsights);
  // }, [originalViewAllWorkplaceInsights]);

  /****************************************************************************
   * Link Button Style Constants                                              *
   ****************************************************************************/

  const editLinkButtonStyle = {
    fontFamily: "Lato",
    fontSize: "14px",
    lineHeight: "1.43",
    color: "#425cc7",
    textDecoration: "underline",
  };

  const cancelLinkButtonStyle = {
    fontFamily: "Lato",
    fontSize: "14px",
    lineHeight: "1.43",
    color: "#53565a",
    textDecoration: "none",
    position: "absolute",
    right: "10px",
    top: "7px",
    zIndex: "100",
  };

  const deleteLinkButtonStyle = {
    fontFamily: "Lato",
    fontSize: "14px",
    lineHeight: "1.43",
    color: "#ca003d",
    textDecoration: "underline",
  };

  /****************************************************************************
   * Handlers                                                                 *
   ****************************************************************************/

  const enableTeamNameEdit = () => {
    setTeamNameDisabled(false);
  };

  const cancelEditTeamName = () => {
    setTeamName(currentlySavedTeamName);
    setTeamNameDisabled(true);
  };

  const handleSaveTeamName = () => {
    setTeamNameDisabled(true);
    setCurrentlySavedTeamName(teamName);
    const formData = {
      teamName: teamName,
    };
    dispatch(actions.updateSettings(formData, teamId));
  };

  const handleSendInvitation = (formData) => {
    dispatch(actions.updateTeamLead(formData, teamId));
  };

  const handleDelete = () => {
    window.ChurnZero.push([
      "trackEvent",
      "Team Deleted",
      `${userName} deleted ${teamName}`,
    ]);
    dispatch(actions.deleteTeam(teamId));
  };

  const resetTeamLeadSuccessful = () => {
    dispatch(actions.getTeam(teamId));
    dispatch(actions.resetTeamLeadSuccessful());
    dispatch(actions.resetTeamLeadError());
  };

  const closeTeamLeadModal = () => {
    dispatch(actions.resetTeamLeadError());
    setShowTeamLeadModal(false);
  };

  return (
    <>
      <Helmet>
        <title>Team Settings</title>
        <meta name="description" content="Team Settings Form" />
      </Helmet>
      <>
        <Helmet>
          <title>Edit Team Settings - Talent Insights</title>
          <meta name="description" content="Talent Insights - Edit Settings" />
        </Helmet>
        <Container fluid="lg">
          <Row className="my-3">
            <Col>
              <Breadcrumb className="breadcrumb">
                <Breadcrumb.Item
                  href={`${process.env.REACT_APP_URL}/team/${teamId}`}
                >
                  {teamName}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Team Settings</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <p className="section-header">Team Settings</p>
                  <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <span className="settings-subheading">Team Profile</span>
                      <hr className="separator mt-2" />
                      <Form.Row className="mb-3">
                        <Col lg={10} md={10} sm={10}>
                          <Form.Label
                            className="edit-form-label"
                            htmlFor="team-name"
                          >
                            Team Name
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="text"
                              name="team-name"
                              value={teamName || ""}
                              onChange={(event) =>
                                setTeamName(event.target.value)
                              }
                              disabled={teamNameDisabled}
                              className="edit-form-input"
                            />
                            {!teamNameDisabled ? (
                              <LinkButton
                                text="Cancel"
                                showArrow=""
                                handler={cancelEditTeamName}
                                isLoading={false}
                                style={cancelLinkButtonStyle}
                              />
                            ) : null}
                          </InputGroup>
                        </Col>
                        <Col lg={2} md={2} sm={2} className="d-flex mt-4">
                          <LinkButton
                            text={teamNameDisabled ? "Edit" : "Save"}
                            showArrow=""
                            handler={
                              teamNameDisabled
                                ? enableTeamNameEdit
                                : handleSaveTeamName
                            }
                            isLoading={false}
                            style={editLinkButtonStyle}
                          />
                        </Col>
                      </Form.Row>
                      <Form.Row className="mb-3">
                        <Col lg={10} md={10} sm={10}>
                          <Form.Label
                            className="edit-form-label"
                            htmlFor="team-lead"
                          >
                            Team Lead
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="text"
                              name="team-lead"
                              value={
                                teamLead?.firstName
                                  ? `${teamLead?.firstName} ${teamLead?.lastName}`
                                  : `${teamLead?.emailAddress}`
                              }
                              disabled
                              className="edit-form-input"
                            />
                          </InputGroup>
                        </Col>
                        <Col lg={2} md={2} sm={2} className="d-flex mt-4">
                          <LinkButton
                            text={"Edit"}
                            showArrow=""
                            handler={() => setShowTeamLeadModal(true)}
                            isLoading={false}
                            style={editLinkButtonStyle}
                          />
                        </Col>
                      </Form.Row>
                      <Row className="mt-4">
                        <Col>
                          <p>
                            <LinkButton
                              text="Delete Team"
                              showArrow=""
                              handler={() =>
                                setShowDeleteTeamConfirmation(true)
                              }
                              isLoading={false}
                              style={deleteLinkButtonStyle}
                            />
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <ConfirmationModal
            show={showDeleteTeamConfirmation}
            onHide={() => setShowDeleteTeamConfirmation(false)}
            headerText="Delete Team"
            bodyText={`Are you sure you would like to delete the ${teamName}?  This cannot be undone.`}
            handleConfirm={handleDelete}
          />
          <TeamLeadModal
            show={showTeamLeadModal}
            onHide={closeTeamLeadModal}
            handleConfirm={handleSendInvitation}
            teamMembers={teamMembers}
            teamLeadChangeSuccessful={teamLeadChangeSuccessful}
            resetTeamLeadSuccessful={resetTeamLeadSuccessful}
            allowedDomains={allowedDomains}
            usersList={usersList}
          />
        </Container>
      </>
    </>
  );
}
