import React, { useEffect, useState } from "react";
import { Col, Form, FormControl, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import rightArrow from "resources/images/arrow-right-white.svg";
import ReactPasswordStrength from "react-password-strength";
import { FormData } from "./types";
import "./styles.css";

interface Props {
  firstName: string | null;
  lastName: string | null;
  onSubmit: (formData: FormData) => void;
}

interface PasswordData {
  password: string;
  isValid: boolean;
}

export function AccountSetupForm({ firstName, lastName, onSubmit }: Props) {
  /****************************************************************************
   * State Variables                                                          *
   ****************************************************************************/
  const [password, setPassword] = useState("");
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [showPasswordInvalid, setShowPasswordInvalid] = useState(false);
  const [showMinimumPasswordError, setShowMinimumPasswordError] = useState(
    false
  );
  const [agreeToOdaTerms, setAgreeToOdaTerms] = useState(0);

  // set up react-hook-forms variables
  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (firstName) {
      setValue("firstName", firstName);
    }
    if (lastName) {
      setValue("lastName", lastName);
    }
  }, [firstName, lastName, setValue]);

  const isPasswordMinimumCharacters =
    password.length !== 0 && password.length >= 8;

  const handlePasswordInput = (data: PasswordData) => {
    setPassword(data.password);
    setPasswordIsValid(data.isValid);
    if (data.isValid && showPasswordInvalid) setShowPasswordInvalid(false);
    if (isPasswordMinimumCharacters && showMinimumPasswordError)
      setShowMinimumPasswordError(false);
  };

  const handleAgreeToTermsChange = () => {
    if (agreeToOdaTerms) {
      setAgreeToOdaTerms(0);
    } else {
      setAgreeToOdaTerms(1);
    }
  };

  const handleSubmitButton = (data: FormData) => {
    if (passwordIsValid) {
      const formData: FormData = {
        firstName: data.firstName,
        lastName: data.lastName,
        password: password,
        agreeToOdaTerms: agreeToOdaTerms,
      };
      onSubmit(formData);
    } else if (!isPasswordMinimumCharacters) {
      setShowMinimumPasswordError(true);
    } else {
      setShowPasswordInvalid(true);
    }
  };

  return (
    <Row>
      <Col lg={{ offset: 3, span: 6 }}>
        <Form noValidate onSubmit={handleSubmit(handleSubmitButton)}>
          <Form.Group controlId="firstName">
            <Form.Label className="account-setup-label">First Name</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              ref={register({ required: "Please enter a First Name." })}
              isInvalid={errors.firstName}
              className="account-setup-label"
            />
            <FormControl.Feedback type="invalid">
              Please enter your first name.
            </FormControl.Feedback>
          </Form.Group>
          <Form.Group controlId="lastName">
            <Form.Label className="account-setup-label">Last Name</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              ref={register({ required: "Please enter a Last Name." })}
              isInvalid={errors.lastName}
              className="account-setup-label"
            />
            <FormControl.Feedback type="invalid">
              Please enter your last name.
            </FormControl.Feedback>
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label className="account-setup-label">Password</Form.Label>
            <ReactPasswordStrength
              minLength={8}
              inputProps={{ id: "newPassword" }}
              minScore={3}
              scoreWords={["weak", "okay", "good", "strong", "stronger"]}
              changeCallback={handlePasswordInput}
              style={{
                height: "34px",
                marginBottom: "5px",
                width: "100%",
              }}
            />
            {showMinimumPasswordError ? (
              <p className="manual-override-invalid-feedback">
                Minimum password length is 8 characters.
              </p>
            ) : null}
            {showPasswordInvalid ? (
              <p className="manual-override-invalid-feedback">
                Please choose a stronger password. Try using uncommon words,
                non-standard uPPercasing, creative spelllling, personal slang
                words, and non-obvious numbers and symbols.
              </p>
            ) : null}
          </Form.Group>
          <div className="d-flex align-items-center align-self-center mb-4">
            <input
              type="checkbox"
              name="consent-checkbox"
              value={agreeToOdaTerms}
              onChange={handleAgreeToTermsChange}
              className="mr-2 mb-1"
            />
            <label htmlFor="consent-checkbox">
              I have read and agree to both the{" "}
              <a
                href="https://www.ondemandassessment.com/home/terms"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-1"
              >
                Terms of Use
              </a>
              {` `}
              and the {` `}
              <a
                href="https://www.ondemandassessment.com/home/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </label>
          </div>

          <div className="text-center">
            <button
              className="account-setup-submit-button"
              type="submit"
              style={{
                backgroundColor:
                  password.length === 0 || agreeToOdaTerms === 0
                    ? "#c0eff1"
                    : "#2dccd3",
              }}
              disabled={
                password.length === 0 ||
                showMinimumPasswordError ||
                showPasswordInvalid ||
                agreeToOdaTerms === 0
              }
            >
              View Your Dashboard
              <img src={rightArrow} alt="right arrow" className="ml-2" />
            </button>
          </div>
        </Form>
      </Col>
    </Row>
  );
}
