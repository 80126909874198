import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getTraitDisplay } from "../helper-functions";
import { selectTemperament } from "../selectors";

interface Props {}

export default function TemperamentOutlook() {
  const temperament: any = useSelector(selectTemperament);
  const temperamentArray = temperament ? getTraitDisplay(temperament) : null;
  return (
    <div className="trait-summary-card">
      {/* eslint-disable-next-line */}
      <a className="anchor" id="TemperamentAttitudesOutlook"></a>
      <Row>
        <Col className="d-flex">
          <p className="trait-name mb-4">Temperament, Attitudes & Outlook</p>
        </Col>
      </Row>
      <Row>{temperamentArray}</Row>
    </div>
  );
}
