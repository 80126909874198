/**
 *
 * AccountSettings
 *
 */

import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./slice";
import { selectAccountSettings } from "./selectors";
import { selectUserAccountId } from "app/containers/Global/selectors";
import { accountSettingsSaga } from "./saga";
import "./styles.css";
import { SettingOption } from "./SettingOption";

interface Props {}

export function AccountSettings(props: Props) {
  useInjectReducer({
    key: sliceKey,
    reducer: reducer,
  });
  useInjectSaga({
    key: sliceKey,
    saga: accountSettingsSaga,
  });

  const dispatch = useDispatch();

  /****************************************************************************
   * Selectors
   ****************************************************************************/
  const accountSettings = useSelector(selectAccountSettings);
  const userAccountId = useSelector(selectUserAccountId);

  /****************************************************************************
   * Hooks
   ****************************************************************************/
  useEffect(() => {
    dispatch(actions.getUserSettings(userAccountId));
  }, [dispatch, userAccountId]);

  return (
    <>
      <Helmet>
        <title>Account Settings</title>
        <meta name="description" content="Account Settings" />
      </Helmet>
      <Container className="account-settings-container">
        <Row>
          <Col>
            <p className="header-text">Account Settings</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="subheader-text mt-3">Custom Teams</p>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <hr className="separator" />
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <SettingOption
              text="Who can see your Collaboration Guide"
              value={accountSettings?.userSettings?.showPersonalitySummary}
              permissionToUpdate="showPersonalitySummary"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <SettingOption
              text="Who can see your Workplace Insights"
              value={accountSettings?.userSettings?.showWorkplaceInsights}
              permissionToUpdate="showWorkplaceInsights"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <SettingOption
              text="Who can see you on Team Reports"
              value={accountSettings?.userSettings?.showOnTeamReport}
              permissionToUpdate="showOnTeamReport"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="subheader-text mt-3">Automatic Teams</p>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <hr className="separator" />
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <Row className="mt-2 mb-0 ml-1">
              <p className="setting-text">
                Who can see your Collaboration Guide
              </p>
              <Row className="mt-2 mb-0 ml-1">
                <p style={{ color: "#86888b", marginBottom: "0px" }}>
                  By default, your manager's other direct reports have access to
                  your Collaboration Guides.
                </p>
              </Row>
            </Row>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg={12} md={12} sm={12}>
            <hr className="separator" />
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <Row className="mt-2 mb-0 ml-1">
              <p className="setting-text">
                Who can see your Workplace Insights
              </p>
              <Row className="mt-2 mb-0 ml-1">
                <p style={{ color: "#86888b", marginBottom: "0px" }}>
                  By default, you and your manager have access to your
                  personality results.
                </p>
              </Row>
            </Row>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg={12} md={12} sm={12}>
            <hr className="separator" />
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <Row className="mt-2 mb-0 ml-1">
              <p className="setting-text">Who can see you on Team Reports</p>
              <Row className="mt-2 mb-0 ml-1">
                <p style={{ color: "#86888b", marginBottom: "0px" }}>
                  By default, you and your manager can see you on Team Reports.
                </p>
              </Row>
            </Row>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg={12} md={12} sm={12}>
            <hr className="separator" />
          </Col>
        </Row>
      </Container>
    </>
  );
}
