/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/**
 *
 * AddUserInput
 *
 */
import React, { useState, useEffect } from "react";
import { Chip, Popper, TextField } from "@material-ui/core";
import { Autocomplete, createFilterOptions, Alert } from "@material-ui/lab";
import { isValidEmail } from "utils/helper_functions/isValidEmail";
import { Avatar } from "../Avatar/Loadable";
import { Row, Col, Media } from "react-bootstrap";

interface Props {
  usersList?: Users[] | null;
  allowedDomains: string[];
  emailAddresses: string[];
  setEmailAddresses: any;
  tempInputValue?: string;
  setTempInputValue?: any;
  validationErrors: any;
  setValidationErrors: any;
  tempInputValidationError?: string;
  existingUsers?: any;
  existingTeamMembers?: any;
  emptyEmailError?: string | null;
  setEmptyEmailError?: (value: string | null) => void;
  displayPopper?: boolean;
}

interface Users {
  emailAddress: string;
  firstName: string;
  lastName: string;
}

interface Option {
  label: string;
  value: string;
}

export function AddUserInput({
  usersList,
  allowedDomains,
  emailAddresses,
  setEmailAddresses,
  validationErrors,
  setValidationErrors,
  existingUsers,
  existingTeamMembers,
  emptyEmailError,
  setEmptyEmailError,
  tempInputValue,
  setTempInputValue,
  tempInputValidationError,
  displayPopper = true
}: Props) {
  /* State Variables */
  const [options, setOptions] = useState([{ label: "", value: "" }]);
  const [originalOptions, setOriginalOptions] = useState<Option[]>([]);
  const [value, setValue] = useState<any>([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [existingUserEmails, setExistingUserEmails] = useState<string[]>([]);
  const [existingMemberEmails, setExistingMemberEmails] = useState<string[]>(
    []
  );

  /****************************************************************************
   * Filter Options                                                           *
   ****************************************************************************/

  // we need to set this value so that we can filter by both label and value of the options
  // object.  this way, if the option is an accepted user with a name, we can filter by both
  // email and name.
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: any) => {
      return `${option?.label} ${option?.value}`;
    }
  });

  /***************************************************************************
   * Hooks                                                                   *
   ***************************************************************************/

  // when first coming to the component, we need to create a list of options that
  // will be displayed in the dropdown. this list needs to be composed as an array
  // of options in the shape {label: _, value: _}.
  useEffect(() => {
    let optionList: Option[] =
      usersList
        ?.filter(user => {
          return !emailAddresses.includes(user.emailAddress);
        })
        .map(user => {
          const option: Option = {
            label: user.firstName
              ? `${user.firstName} ${user.lastName}`
              : user.emailAddress,
            value: user.emailAddress
          };
          return option;
        })
        .sort((a: Option, b: Option) => {
          return a.label.localeCompare(b.label);
        }) ?? [];
    if (optionList) {
      // we want to have an "active" options list of the options that are currently
      // eligible to be displayed, and an original one that we can compare against
      // when options are selected (see the useEffect immediately below this).
      setOptions(optionList);
      setOriginalOptions(optionList);
    }
    // adding options to the dependency array is required by eslint, but that
    // leds to this function being called inifinitely.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersList]);

  // this useEffect will remove any selected values from, or return them to, the options list
  // when a value is selected or removed from the dropdown list or input.
  useEffect(() => {
    // create arrays to hold the upcoming values. this allows us to not have a nested
    // map, in case the list of options is large.
    const filteredOptions: Option[] = [];
    const existingValues: string[] = [];

    // create an array of selected email addresses that we no longer want to include in
    // options array.
    value.map(entry => {
      existingValues.push(entry.value);
    });

    // map over the original list of options to determine if we need to remove, or add (in
    // the case that an option is removed from the input), the option from the current list
    // of options.
    originalOptions.map(option => {
      if (
        !existingValues.includes(option?.value) &&
        !existingMemberEmails.includes(option?.value)
      ) {
        filteredOptions.push(option);
      }
    });

    setOptions(filteredOptions);
  }, [value, originalOptions, existingMemberEmails]);

  // if the existingTeamMembers prop has been passed in, we need to remove those members from the
  // users options list so that they won't display in the dropdown.
  useEffect(() => {
    if (existingMemberEmails.length > 0) {
      // create arrays to hold the upcoming values. this allows us to not have a nested
      // map, in case the list of options is large.
      const filteredOptions: Option[] = [];

      // map over the original list of options to determine if we need to remove, or add (in
      // the case that an option is removed from the input), the option from the current list
      // of options.
      originalOptions.map(option => {
        if (!existingMemberEmails.includes(option?.value)) {
          filteredOptions.push(option);
        }
      });

      setOptions(filteredOptions);
    }
  }, [existingMemberEmails, originalOptions]);

  // in order to validate the entered objects based on whether or not the entered
  // email is already a Criteria user, we need to create an array of existing user
  // email addresses that we can then validate against.
  useEffect(() => {
    if (existingUsers) {
      const existingEmailAddresses = existingUsers.map(user => {
        return user.emailAddress;
      });
      setExistingUserEmails(existingEmailAddresses);
    }
  }, [existingUsers]);

  // in order to validate the entered objects based on whether or not the entered
  // email is already a team member, we need to create an array of existing team member
  // email addresses that we can then validate against.
  useEffect(() => {
    if (existingTeamMembers) {
      const existingMemberArray = existingTeamMembers.map(member => {
        return member.emailAddress;
      });
      setExistingMemberEmails(existingMemberArray);
    }
  }, [existingTeamMembers]);

  /****************************************************************************
   * Handlers
   ****************************************************************************/
  const handleEnterPress = key => {
    // if the user is typing in an email address and presses the "Enter" button
    // we want to validate the current entry and then add it to the email addresses.
    if (key === "Enter") {
      let emailAddressArray: string[] = emailAddresses;
      let valueArray: Option[] = value;
      let tempValidationErrors = validationErrors;
      const entryObject: any = {
        label: tempInputValue,
        value: tempInputValue
      };
      if (isValidEmail(entryObject.value)) {
        if (
          !allowedDomains.includes(
            entryObject.value.split("@")[1].toLowerCase()
          )
        ) {
          const newErrorObject: any = {
            [entryObject.value]: "is not included in your company domain."
          };
          tempValidationErrors = {
            ...tempValidationErrors,
            ...newErrorObject
          };
          setValidationErrors(tempValidationErrors);
        }
        if (existingUserEmails.includes(entryObject.value)) {
          const newErrorObject: any = {
            [entryObject.value]: "is already a Criteria user."
          };
          tempValidationErrors = {
            ...tempValidationErrors,
            ...newErrorObject
          };
          setValidationErrors(tempValidationErrors);
        }
        if (existingMemberEmails.includes(entryObject.value)) {
          const newErrorObject: any = {
            [entryObject.value]: "is already a team member."
          };
          tempValidationErrors = {
            ...tempValidationErrors,
            ...newErrorObject
          };
          setValidationErrors(tempValidationErrors);
        }
        emailAddressArray.push(entryObject.value);
        valueArray.push(entryObject);
        setValue(valueArray);
        setEmailAddresses(emailAddressArray);
        setTempInputValue("");
        setOpenPopup(false);
      } else {
        setOpenPopup(false);
        const newErrorObject: any = {
          [entryObject.value]: "is not an email address."
        };
        tempValidationErrors = {
          ...tempValidationErrors,
          ...newErrorObject
        };
        emailAddressArray.push(entryObject.value);
        valueArray.push(entryObject);
        setValidationErrors(tempValidationErrors);
      }
    }
  };

  /******************************************************************************
   * Custom Popper - used to determine if the popper should be displayed or not *
   ******************************************************************************/
  const CustomPopper = props => (
    <Popper
      {...props}
      placement="bottom-start"
      style={{
        display: displayPopper ? "" : "none"
      }}
    />
  );

  return (
    <div className="mb-0">
      <Autocomplete
        id="email-autocomplete-members"
        multiple
        inputValue={inputValue}
        value={value}
        options={options}
        getOptionLabel={option => `${option?.label.toString()}`}
        filterOptions={filterOptions}
        freeSolo
        PopperComponent={CustomPopper}
        /**********************************************************************
         * Autocomplete OnChange Method                                       *
         **********************************************************************/

        onChange={(event: any, selectedValue: Option[], reason: string) => {
          setOpenPopup(false);
          // if the Add User input is validating for empty values, and there is currently an
          // error message being displayed, we need to reset it so no error is showing.
          if (setEmptyEmailError) {
            setEmptyEmailError(null);
          }
          const currentSelection = selectedValue[selectedValue.length - 1];

          /********************************************************************
           * If an option is selected from dropdown                           *
           ********************************************************************/

          if (reason === "select-option") {
            setValue(selectedValue);
            setEmailAddresses([...emailAddresses, currentSelection.value]);
            setOpenPopup(false);
          }

          /********************************************************************
           * If an option is removed from the input individually              *
           ********************************************************************/

          if (reason === "remove-option") {
            const removedValue = event.currentTarget.parentElement.innerText;
            if (value.length === 0) {
              setEmailAddresses([]);
            } else {
              setTempInputValue("");
              const newEmailAddressArray: string[] = [];
              // if the selected value to remove is just a name (label), then we have to compare
              // it to the list of options and find the email address associated with that label,
              // since that is what we need to send to the API to add the member.
              const newSelectedValue: any = [];
              selectedValue.map(entry => {
                if (entry.value !== removedValue) {
                  newEmailAddressArray.push(entry.value);

                  // if the same invalid email address is entered, we need to ensure that if it's removed
                  // at least once, all instances of that email address are removed as well.
                  const newSelectedValueObject = {
                    label: entry.label,
                    value: entry.value
                  };
                  newSelectedValue.push(newSelectedValueObject);
                }
              });
              setValue(newSelectedValue);
              setEmailAddresses(newEmailAddressArray);
            }
            if (validationErrors) {
              if (Object.keys(validationErrors).length === 1) {
                setValidationErrors({});
              } else {
                Object.keys(validationErrors).map(key => {
                  if (key) {
                    const {
                      [removedValue]: removed,
                      ...rest
                    } = validationErrors;

                    setValidationErrors(rest);
                  }
                });
              }
            }
          }

          /********************************************************************
           * If the input is entirely cleared                                 *
           ********************************************************************/

          if (reason === "clear") {
            setEmailAddresses([]);
            setTempInputValue("");
            setValue([]);
            setValidationErrors({});
            setOptions(originalOptions);
            setOpenPopup(false);
          }
        }}
        /****************************************************************************
         * if a user types in the email address, we need to control the input here  *
         ****************************************************************************/

        onInputChange={(event, newInputValue, reason) => {
          setOpenPopup(false);
          const currentEntry: string[] = newInputValue.split(/[\s,;]+/);
          const filteredCurrentEntry = currentEntry.filter(
            entry => entry !== ""
          );
          const delimiters: string[] = [" ", ",", ";"];

          // if the Add User input is validating for empty values, and there is currently an
          // error message being displayed, we need to reset it so no error is showing.
          if (setEmptyEmailError) {
            setEmptyEmailError(null);
          }

          // we have to store the "in progress" typed value in state in case the user does not
          // type in a delimiter before saving the email addresses (i.e. tries to create the team).
          // this way, we can run the validations and add the value as a user in the onSubmit method.
          setTempInputValue(newInputValue);

          if (
            filteredCurrentEntry.length === 1 &&
            delimiters.includes(newInputValue.slice(-1))
          ) {
            setInputValue("");
            setOpenPopup(false);
            let emailAddressArray: string[] = emailAddresses;
            let valueArray: Option[] = value;
            let tempValidationErrors = validationErrors;
            const entryObject: any = {
              label: filteredCurrentEntry[0],
              value: filteredCurrentEntry[0]
            };
            if (isValidEmail(entryObject.value)) {
              if (
                !allowedDomains.includes(
                  entryObject.value.split("@")[1].toLowerCase()
                )
              ) {
                const newErrorObject: any = {
                  [entryObject.value]: "is not included in your company domain."
                };
                tempValidationErrors = {
                  ...tempValidationErrors,
                  ...newErrorObject
                };
                setValidationErrors(tempValidationErrors);
              }
              if (existingUserEmails.includes(entryObject.value)) {
                const newErrorObject: any = {
                  [entryObject.value]: "is already a Criteria user."
                };
                tempValidationErrors = {
                  ...tempValidationErrors,
                  ...newErrorObject
                };
                setValidationErrors(tempValidationErrors);
              }
              emailAddressArray.push(entryObject.value);
              valueArray.push(entryObject);
              setValue(valueArray);
              setEmailAddresses(emailAddressArray);
              setTempInputValue("");
              setOpenPopup(false);
            } else {
              setOpenPopup(false);
              const newErrorObject: any = {
                [entryObject.value]: "is not an email address."
              };
              tempValidationErrors = {
                ...tempValidationErrors,
                ...newErrorObject
              };
              setValidationErrors(tempValidationErrors);
              emailAddressArray.push(entryObject.value);
              valueArray.push(entryObject);
            }
          } else if (filteredCurrentEntry.length > 1) {
            let emailAddressArray: string[] = emailAddresses;
            let valueArray: Option[] = value;
            let tempValidationErrors = validationErrors;
            filteredCurrentEntry.map(entry => {
              const entryObject: any = {
                label: entry,
                value: entry
              };

              if (isValidEmail(entry)) {
                if (
                  !allowedDomains.includes(entry.split("@")[1].toLowerCase())
                ) {
                  const newErrorObject: any = {
                    [entry]: "is not included in your company domain."
                  };
                  tempValidationErrors = {
                    ...tempValidationErrors,
                    ...newErrorObject
                  };
                  setValidationErrors(tempValidationErrors);
                }
                if (existingUserEmails.includes(entry)) {
                  const newErrorObject: any = {
                    [entry]: "is already a Criteria user."
                  };
                  tempValidationErrors = {
                    ...tempValidationErrors,
                    ...newErrorObject
                  };
                  setValidationErrors(tempValidationErrors);
                }
                emailAddressArray.push(entryObject.value);
                valueArray.push(entryObject);
                setValue(valueArray);
                setEmailAddresses(emailAddressArray);
                setOpenPopup(false);
              }
            });
          } else {
            setInputValue(newInputValue);
          }
          if (validationErrors) {
            Object.keys(validationErrors).map(key => {
              if (key) {
                if (key.length === value.length && key.includes(value)) {
                  const { [key]: _, ...newValidationErrors } = validationErrors;
                  setValidationErrors(newValidationErrors);
                }
              }
            });
          }
        }}
        /**********************************************************************
         * we determine how the selected inputs are rendered here             *
         **********************************************************************/
        renderTags={(value: string[], getTagProps) =>
          value.map((option: any, index: number) => {
            const optionToUse =
              typeof option === "object" ? option?.label : option;
            return (
              <Chip
                variant="outlined"
                label={optionToUse}
                {...getTagProps({ index })}
                color={
                  Object.keys(validationErrors).includes(optionToUse)
                    ? "secondary"
                    : "default"
                }
                size="small"
              />
            );
          })
        }
        renderOption={option => {
          const selectedUser = usersList?.find(
            user => user?.emailAddress === option?.value
          );
          if (selectedUser) {
            return (
              <Media>
                <div className="align-self-start mr-3 mt-1">
                  <Avatar
                    height={40}
                    width={40}
                    fontSize={20}
                    userName={
                      selectedUser.firstName
                        ? `${selectedUser?.firstName} ${selectedUser?.lastName}`
                        : ""
                    }
                    initials={
                      selectedUser?.firstName
                        ? `${selectedUser?.firstName
                            .charAt(0)
                            .toUpperCase()} ${selectedUser?.lastName
                            .charAt(0)
                            .toUpperCase()}`
                        : null
                    }
                    emailAddress={selectedUser?.emailAddress}
                    backgroundColor={`#2dccd3`}
                  />
                </div>
                <Media.Body>
                  <p className="dropdown-text mb-0 mt-2">
                    {selectedUser?.firstName} {selectedUser?.lastName}
                  </p>
                  <p
                    className={`${
                      selectedUser?.firstName
                        ? "dropdown-text-muted  mt-0"
                        : "dropdown-text mt-0"
                    }`}
                  >
                    {selectedUser?.emailAddress}
                  </p>
                </Media.Body>
              </Media>
            );
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            error={
              emptyEmailError !== null &&
              emptyEmailError !== "" &&
              emptyEmailError !== undefined
            }
            placeholder={`${
              value && value.length > 0
                ? ""
                : displayPopper
                ? "Enter a name or email address"
                : "Enter an email address"
            }`}
            fullWidth
            margin="normal"
            variant="outlined"
            multiline
            onKeyDown={event => handleEnterPress(event.key)}
          />
        )}
      />
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          {tempInputValidationError ? (
            <Alert severity="error" className="mt-2">
              {tempInputValidationError}
            </Alert>
          ) : null}
          {Object.keys(validationErrors).map(key => (
            <Alert
              severity="error"
              className="mt-2"
            >{`${key} ${validationErrors[key]}`}</Alert>
          ))}
        </Col>
      </Row>
    </div>
  );
}
