import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";

// The initial state of the TeamSettings container
export const initialState: ContainerState = {
  loadingTeam: false,
  teamName: null,
  teamMembers: null,
  viewAllWorkplaceInsights: 1,
  viewTeamPersonalitySummary: 0,
  savingEdits: false,
  teamLeadChangeSuccessful: false,
  teamLeadErrorMessage: null,
  errorMessage: null,
  allowedDomains: [],
  loadingAllowedDomains: false,
  usersList: null,
  loadingUsers: false,
};

const teamSettingsSlice = createSlice({
  name: "teamSettings",
  initialState,
  reducers: {
    getTeam(state, action: PayloadAction<any>) {
      state.loadingTeam = true;
    },
    getTeamSuccess(state, action: PayloadAction<any>) {
      state.loadingTeam = false;
      state.teamName = action.payload.teamName;
      state.teamMembers = action.payload.teamMembers;
      // state.viewAllWorkplaceInsights = action.payload.viewAllWorkplaceInsights;
      // state.viewTeamPersonalitySummary =
      //   action.payload.viewTeamPersonalitySummary;
    },
    getTeamError(state, action: PayloadAction<any>) {
      state.loadingTeam = false;
      state.errorMessage = action.payload.message;
    },
    updateSettings: {
      reducer(state, action: PayloadAction<any>) {
        state.savingEdits = true;
      },
      prepare(formData, teamId) {
        return {
          payload: {
            formData: formData,
            teamId: teamId,
          },
        };
      },
    },
    updateSettingsSuccess(state, action: PayloadAction<any>) {
      state.savingEdits = false;
    },
    updateSettingsError(state, action: PayloadAction<any>) {
      state.savingEdits = false;
      state.errorMessage = action.payload.message;
    },
    deleteTeam(state, action: PayloadAction<any>) {
      state.savingEdits = true;
    },
    deleteTeamError(state, action: PayloadAction<any>) {
      state.savingEdits = false;
      state.errorMessage = action.payload.message;
    },
    updateTeamLead: {
      reducer(state, action: PayloadAction<any>) {
        state.teamLeadChangeSuccessful = false;
      },
      prepare(formData, teamId) {
        return {
          payload: {
            formData: formData,
            teamId: teamId,
          },
        };
      },
    },
    updateTeamLeadSuccess(state) {
      state.teamLeadChangeSuccessful = true;
    },
    updateTeamLeadError(state, action: PayloadAction<any>) {
      state.teamLeadChangeSuccessful = false;
      state.teamLeadErrorMessage = action.payload;
    },
    resetTeamLeadSuccessful(state) {
      state.teamLeadChangeSuccessful = false;
    },
    getAllowedDomains(state, action: PayloadAction<any>) {
      state.loadingAllowedDomains = true;
    },
    getAllowedDomainsSuccess(state, action: PayloadAction<any>) {
      state.allowedDomains = action.payload;
    },
    getAllowedDomainsError(state, action: PayloadAction<any>) {
      state.errorMessage = action.payload;
    },
    resetTeamLeadError(state) {
      state.teamLeadErrorMessage = initialState.teamLeadErrorMessage;
    },
    getUsersList(state) {
      state.loadingUsers = true;
    },
    getUsersListSuccess(state, action: PayloadAction<any>) {
      state.loadingUsers = false;
      state.usersList = action.payload;
    },
    getUsersListError(state, action: PayloadAction<any>) {
      state.loadingUsers = false;
      state.errorMessage = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = teamSettingsSlice;
