import React from "react";
import { Modal } from "react-bootstrap";
import { getCollaborationGuideUsersList } from "./helper-functions";
// import { User } from "./types";

interface Props {
  show: boolean;
  setShowCollabGuideModal: (boolean) => void;
  collaborationGuideUsers: User[] | null;
}

export const CollaborationGuideModal = ({
  show,
  setShowCollabGuideModal,
  collaborationGuideUsers,
}: Props) => {
  const collabUsersList = getCollaborationGuideUsersList(
    collaborationGuideUsers
  );
  return (
    <Modal show={show} onHide={() => setShowCollabGuideModal(false)}>
      <Modal.Header closeButton>Collaboration Guide</Modal.Header>
      <Modal.Body>{collabUsersList}</Modal.Body>
    </Modal>
  );
};
