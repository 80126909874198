/**
 *
 * Dashboard
 *
 */

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import { useHistory, useLocation } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./slice";
import {
  selectDashboard,
  selectPendingTests,
  selectDashboardLoading,
  selectTeams,
  selectInvitationAcceptedData,
  selectCompanyInfo,
  selectCollaborationGuideUsers,
  selectHasSSOHardEnabled,
  selectUsers,
  selectVisitedAdminConsole,
  selectSortedPendingActions
} from "./selectors";
import { selectUser } from "app/containers/Global/selectors";
import { dashboardSaga } from "./saga";
import useEffectOnMount from "utils/custom_hooks/useEffectOnMount";
import { HeadlinePanel } from "app/containers/Dashboard/HeadlinePanel/HeadlinePanel";
import {
  PendingTestsData,
  AcceptedInvitationsModalData,
  TeamData,
  CompanyInfo
} from "./types";
import { Teams } from "./Teams/Teams";
import "./styles.css";
import { actions as createTeamActions } from "../CreateTeam/slice";
import { NewTeamModal } from "./Modals/NewTeamModal";
import { AcceptedInvitationModal } from "./Modals/AcceptedInvitationModal";
import { InviteLinkModal } from "./Modals/InviteLinkModal";
import { PendingTests } from "./PendingTests";
import { CollaborationGuide } from "./CollaborationGuide";
import { CollaborationGuideModal } from "./CollaborationGuideModal";
import { Banner } from "../../components/Banner";
import { checkCreatedEmployees } from "./helper-functions";

interface Props {}

export function Dashboard(props: Props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: dashboardSaga });

  /*
   * getting access to the location prop (we have to use the custom hook because)
   * of the PrivateRoutes we use in the Router
   */
  const location = useLocation();

  /****************************************************************************
   * State Variables                                                          *
   ****************************************************************************/
  const [showNewTeamModal, setShowNewTeamModal] = useState(false);
  const [newTeamName, setNewTeamName] = useState(null);
  const [showInviteLinkModal, setShowInviteLinkModal] = useState(false);
  const [showCollabGuideModal, setShowCollabGuideModal] = useState(false);
  const [isOnTeam, setIsOnTeam] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newTeamId, setNewTeamId] = useState(null);
  const [createdEmployees, setCreatedEmployees] = useState(false);

  /****************************************************************************
   * Selector Variables                                                       *
   ****************************************************************************/
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dashboard = useSelector(selectDashboard);
  const isLoading = useSelector(selectDashboardLoading);
  const user = useSelector(selectUser);

  const teams: TeamData[] | null = useSelector(selectTeams);
  const companyInfo: CompanyInfo | null = useSelector(selectCompanyInfo);
  const collaborationGuideUsers: User[] | null = useSelector(
    selectCollaborationGuideUsers
  );
  const pendingTests: PendingTestsData[] | null = useSelector(
    selectPendingTests
  );

  const invitationAcceptedData: AcceptedInvitationsModalData = useSelector(
    selectInvitationAcceptedData
  );
  const hasSSOHardEnabled: boolean = useSelector(selectHasSSOHardEnabled);
  const users = useSelector(selectUsers);
  const visitedAdminConsole = useSelector(selectVisitedAdminConsole);
  const sortedPendingActions = useSelector(selectSortedPendingActions);

  /****************************************************************************
   * State variables                                                          *
   ****************************************************************************/
  // these are the same variable names as what we store in redux when a user
  // accepts an invitation via the dashboard, but here they are set from the url
  // query string, for when a user accepts a team invitation via email.
  const [invitationAcceptedTeamId, setinvitationAcceptedTeamId] = useState<
    number | null
  >(null);
  const [invitationAcceptedTeamName, setInvitationAcceptedTeamName] = useState<
    string | null
  >(null);
  const [
    showInvitationAcceptedModal,
    setShowInvitationAcceptedModal
  ] = useState(false);

  /****************************************************************************
   * Hooks                                                                    *
   ****************************************************************************/

  const dispatch = useDispatch();

  useEffectOnMount(() => {
    const rawToken: string | null = localStorage.getItem("tmg-tkn");
    if (rawToken) {
      const decodedToken: any = jwtDecode(rawToken);
      dispatch(actions.getUser(decodedToken.sub));
      dispatch(actions.getUsers());
      dispatch(actions.getTeams(decodedToken.sub));
      dispatch(actions.getUserSettings(decodedToken.sub));
      dispatch(actions.getTeamsAndInvitations());
      dispatch(actions.getNotifications());
      dispatch(actions.getCollaborationGuideUsers());
      dispatch(actions.getUserTracking(decodedToken.sub));
    } else {
      window.location.href = `${process.env.REACT_APP_LOGOUT_URL}`;
    }
    /*
     * if we have both a teamName and teamId in the location state prop, that means
     * we have been redirected to the Dashboard from the CreateTeam component and we
     * need to display the newTeam modal
     */
    if (location?.state) {
      const { teamName, teamId, isOnTeam } = location.state as any;
      /*
       * we have to reset the state in the CreateTeam modal manually because we were
       * redirected here from that component, and therefore it is still retained
       */
      dispatch(createTeamActions.resetState());

      /* set the state variables that will display the new team modal */
      setNewTeamName(teamName);
      setNewTeamId(teamId);
      setIsOnTeam(isOnTeam);
      setShowNewTeamModal(true);
    }
    if (
      queryString.parse(location.search).team &&
      queryString.parse(location.search).tid
    ) {
      const teamName = String(queryString.parse(location.search).team);
      const teamId = Number(queryString.parse(location.search).tid);
      setInvitationAcceptedTeamName(teamName);
      setinvitationAcceptedTeamId(teamId);
      if (teamName && teamId) {
        setShowInvitationAcceptedModal(true);
      }
    } else if (
      localStorage.getItem("teamName") &&
      localStorage.getItem("tid")
    ) {
      const teamName = localStorage.getItem("teamName");
      const teamId = Number(localStorage.getItem("tid"));
      window.analytics.track("talent insights login", {
        "referral source type": "url",
        url: `${process.env.REACT_APP_URL}/team/${teamId}`
      });
      setInvitationAcceptedTeamName(teamName);
      setinvitationAcceptedTeamId(teamId);
      setShowInvitationAcceptedModal(true);
    }
    return () => {
      setShowInvitationAcceptedModal(false);
    };
  });

  useEffect(() => {
    if (user) {
      dispatch(actions.getCompanyInfo(user.companyAccountId));
    }
  }, [dispatch, user]);

  /****************************************************************************
   * Handlers                                                                 *
   ****************************************************************************/

  const closeNewTeamModal = () => {
    /* we need to manually reset the location state for the window */
    window.history.replaceState(null, "");
    setShowNewTeamModal(false);
  };

  const acceptInvitation = (
    teamMemberId: number,
    teamId: number,
    teamName: string
  ) => {
    const payload = {
      teamMemberId: teamMemberId,
      userAccountId: user?.userAccountId,
      teamId: teamId,
      teamName: teamName
    };
    dispatch(actions.acceptInvitation(payload));
  };

  const closeAcceptedInvitationModal = () => {
    localStorage.removeItem("teamName");
    localStorage.removeItem("tid");
    dispatch(actions.resetAcceptInvitationModalData());
    setShowInvitationAcceptedModal(false);
  };

  const handleCollabGuideViewAllClick = () => {
    setShowCollabGuideModal(true);
  };

  const history = useHistory();
  const handleAdminConsoleClick = () => {
    history.push(`/admin`);
  };

  useEffect(() => {
    if (users) {
      setCreatedEmployees(checkCreatedEmployees(users));
    }
  }, [users]);

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
        <meta name="description" content="Talent Insights - Dashboard" />
      </Helmet>
      <div>
        <HeadlinePanel
          user={user}
          pendingTests={pendingTests}
          pendingActions={sortedPendingActions}
          acceptInvitation={acceptInvitation}
          isLoading={isLoading}
          setShowInviteLinkModal={setShowInviteLinkModal}
          hasSSOHardEnabled={hasSSOHardEnabled}
          allowUsersShareInviteLink={companyInfo?.allowUsersShareInviteLink}
        />
      </div>
      {((users && createdEmployees) || visitedAdminConsole === 0) &&
        user?.tmgRoleId === 1 && (
          <Banner
            handleClick={handleAdminConsoleClick}
            bannerText={"adminConsole"}
          />
        )}
      {pendingTests && pendingTests.length > 0 && !isLoading ? (
        <div>
          <PendingTests pendingTests={pendingTests} />
        </div>
      ) : null}
      <div>
        <CollaborationGuide
          teams={teams}
          collaborationGuideUsers={collaborationGuideUsers}
          handleCollabGuideViewAllClick={handleCollabGuideViewAllClick}
        />
      </div>
      <div>
        <Teams
          teams={teams}
          isLoading={isLoading}
          tmgAllowUsersCreateTeam={companyInfo?.tmgAllowUsersCreateTeam}
        />
      </div>
      <NewTeamModal
        showNewTeamModal={showNewTeamModal}
        teamName={newTeamName}
        teamId={newTeamId}
        isOnTeam={isOnTeam}
        onHide={closeNewTeamModal}
        fromDashboard
      />
      {invitationAcceptedData.invitationAcceptedTeamName ? (
        <AcceptedInvitationModal
          {...invitationAcceptedData}
          onHide={closeAcceptedInvitationModal}
        />
      ) : invitationAcceptedTeamName ? (
        <AcceptedInvitationModal
          invitationAcceptedTeamName={invitationAcceptedTeamName}
          invitationAcceptedTeamId={invitationAcceptedTeamId}
          showInvitationAcceptedModal={showInvitationAcceptedModal}
          onHide={closeAcceptedInvitationModal}
        />
      ) : null}
      <InviteLinkModal
        show={showInviteLinkModal}
        setShowInviteLinkModal={setShowInviteLinkModal}
        link={companyInfo?.inviteLink}
      />
      <CollaborationGuideModal
        show={showCollabGuideModal}
        setShowCollabGuideModal={setShowCollabGuideModal}
        collaborationGuideUsers={collaborationGuideUsers}
      />
    </>
  );
}
