import React from "react";
import { Row } from "react-bootstrap";
import NoResultsIcon from "resources/images/results-not-available.png";
import File from "resources/images/file.svg";
import CollaborationGuideSampleData from "resources/images/sample-collaboration-guide.pdf";
import ManagerGuideSampleData from "resources/images/sample-manager-guide.pdf";

interface Props {
  text: string;
  noPermission: boolean;
  noEPP: boolean;
  firstName: string | undefined;
  isSample?: boolean;
  isManagerGuide?: boolean;
}

export const NotAvailable = ({
  text,
  noPermission,
  noEPP,
  firstName,
  isSample,
  isManagerGuide
}: Props) => {
  return (
    <div className="d-flex not-available-container">
      <div className="m-auto">
        <Row className="d-flex mt-0">
          <img
            className="m-auto"
            src={NoResultsIcon}
            alt="no results"
            height="80px"
            width="80px"
          />
        </Row>

        <Row className="d-flex mt-4">
          {isSample && !noPermission && (
            <div
              style={{
                display: "flex",
                justifyContent: "Center",
                alignItems: "center",
                flexDirection: "column",
                padding: "20px"
              }}
            >
              <p
                className="p-auto not-available-text"
                style={{
                  maxWidth: "808px",
                  height: "fit-content",
                  padding: "0px 20px"
                }}
              >
                {isManagerGuide
                  ? "Manager Guides provide an overview of how to communicate, support, and motivate your direct reports.  Within the guide there are also tips and conversation starters to help you better manage your direct reports."
                  : "Collaboration Guides provide an overview of how you and your teammates compare in communication style, work style, and thinking style. Within the guide there are also tips to help you work more effectively together."}
              </p>
              <a
                href={
                  isManagerGuide
                    ? `${ManagerGuideSampleData}`
                    : `${CollaborationGuideSampleData}`
                }
                style={{ display: "flex", alignItems: "center" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={File} alt="file" />
                <p
                  style={{
                    color: "#425cc7",
                    margin: "11px 6px",
                    fontWeight: "bold",
                    display: "inline"
                  }}
                >
                  {isManagerGuide
                    ? "View Sample Manager Guide"
                    : "View Sample Collaboration Guide"}
                </p>
              </a>
            </div>
          )}
          {noEPP && !isSample ? (
            <p className="m-auto not-available-text">{`${firstName} has not yet completed their personality test.`}</p>
          ) : null}
          {/*DEV NOTE: Will not address permissions for Manager Guide*/}
          {!isManagerGuide && noPermission ? (
            <p className="m-auto not-available-text">{`${firstName} has chosen not to share their ${text}.`}</p>
          ) : null}
        </Row>
      </div>
    </div>
  );
};
