import { useRef, useEffect } from "react";

export function useEventListener(eventName: string, handler, element) {
  // Create a ref that stores handler
  const savedHandler: any = useRef();

  /*
   * we want to update the ref.current value if the handler changes. this will allow
   * us to always be using the most current handler without passing the hander in
   * to the deps array and causing additional re-renders.
   */
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      /* make sure element supports addEventListener */
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;
      /* create event listener that calls handler function stored in ref */
      const eventListener = (event) => savedHandler?.current(event);

      /* add event listener */
      element.addEventListener(eventName, eventListener);

      /* remove event listener on cleanup */
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    /* if event name or element change, we want to re-run this */
    [eventName, element]
  );
}
