/**
 *
 * CandidateCard
 *
 */

import React from "react";
import { Card, Col } from "react-bootstrap";
import "./styles.css";
import personalityTag from "../../../resources/images/personality-tag.svg";

export function CandidateCard({
  candidateInfo,
  formattedDate,
  handleLinkCandidate,
  handleUnlinkCandidate,
  linked,
  index,
}) {
  return (
    <Card className="candidate-card">
      <Col sm={10} className="candidate-info-col">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            lineHeight: "28px",
          }}
        >
          <p className="candidate-card-title">
            {`${candidateInfo.testTakerFirstName} ${candidateInfo.testTakerLastName}`}
          </p>
          {candidateInfo.eppAvailable === 1 && (
            <img
              src={personalityTag}
              alt="personalityTag"
              style={{ padding: "0 0 5px 7px" }}
            />
          )}
        </div>
        <span className="candidate-card-email">
          {candidateInfo.testTakerEmail}
        </span>
        {candidateInfo.jobTitle && (
          <span className="candidate-card-job-title">
            {" "}
            {`· ${candidateInfo.jobTitle}`}
          </span>
        )}
        <div style={{ display: "flex", flexDirection: "row" }}>
          {formattedDate !== "Invalid Date" && (
            <p className="candidate-card-date">{`Applied ${formattedDate} · `}</p>
          )}
          <span className="candidate-card-date">
            {" "}
            {` ${candidateInfo.stageName}`}
          </span>
        </div>
      </Col>
      <Col sm={2} className="link-button-col">
        <button
          className={`candidate-link-button ${
            linked && "candidate-unlink-button"
          }`}
          onClick={linked ? handleUnlinkCandidate : handleLinkCandidate}
          value={index}
        >
          {linked ? "Unlink" : "Link"}
        </button>
      </Col>
    </Card>
  );
}
