import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import rightArrow from "resources/images/arrow-right-white.svg";

interface Props {
  eventId: string;
}

export default function StartAssessmentBanner({ eventId }: Props) {
  const handleStartAssessement = () => {
    window.open(
      `${process.env.REACT_APP_ODA_URL}/verify/index/${eventId}`,
      "_blank"
    );
  };

  return (
    <Container>
      <Row>
        <Col>
          <div className="start-test-container d-flex justify-content-between mt-3">
            <p className="personality-text mt-1">
              Haven‘t completed your Personality Assessment? Start now to see
              your Workplace Insights!
            </p>
            <button
              className="start-test-button"
              onClick={handleStartAssessement}
            >
              Start Personality Test{" "}
              <img src={rightArrow} alt="start test" className="ml-2" />
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
