import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";

// The initial state of the TeamPage container
export const initialState: ContainerState = {
  team: null,
  memberScores: null,
  teamScores: null,
  errorMessage: null,
  teamPersonalityReports: null,
  memberPersonalityReports: null,
  allowedDomains: [],
  membersInvited: false,
  invitingMembers: false,
  loadingTeams: false,
  loadingPersonalityScores: false,
  loadingPersonalityReports: false,
  loadingAllowedDomains: false,
  removingUserFromTeam: false,
  loadingUsers: false,
  usersList: null,
  personalityReportsLoaded: false,
};

const teamPageSlice = createSlice({
  name: "teamPage",
  initialState,
  reducers: {
    getTeams(state, action: PayloadAction<any>) {
      state.loadingTeams = true;
    },
    getTeamsSuccess(state, action: PayloadAction<any>) {
      state.loadingTeams = false;
      state.team = action.payload;
    },
    getTeamsError(state, action: PayloadAction<any>) {
      state.loadingTeams = false;
      state.errorMessage = action.payload.message;
    },
    getPersonalityScores(state, action: PayloadAction<any>) {
      state.loadingPersonalityScores = true;
    },
    getPersonalityScoresSuccess(state, action: PayloadAction<any>) {
      state.memberScores = action.payload.teamMembers;
      state.teamScores = action.payload.teamScores;
      state.loadingPersonalityScores = false;
    },
    getPersonalityScoresError(state, action: PayloadAction<any>) {
      state.errorMessage = action.payload;
      state.loadingPersonalityScores = false;
    },
    getPersonalityReports(state, action: PayloadAction<any>) {
      state.loadingPersonalityReports = true;
    },
    getPersonalityReportsSuccess(state, action: PayloadAction<any>) {
      state.teamPersonalityReports = action.payload?.team;
      state.memberPersonalityReports = action.payload?.teamMembers;
      state.loadingPersonalityReports = false;
      state.personalityReportsLoaded = true;
    },
    getPersonalityReportsError(state, action: PayloadAction<any>) {
      state.errorMessage = action.payload;
      state.loadingPersonalityReports = false;
    },
    getAllowedDomains(state, action: PayloadAction<any>) {
      state.loadingAllowedDomains = true;
    },
    getAllowedDomainsSuccess(state, action: PayloadAction<any>) {
      state.loadingAllowedDomains = false;
      state.allowedDomains = action.payload;
    },
    getAllowedDomainsError(state, action: PayloadAction<any>) {
      state.loadingAllowedDomains = false;
      state.errorMessage = action.payload;
    },
    inviteMembers: {
      reducer(state, action: PayloadAction<any>) {
        state.membersInvited = false;
        state.invitingMembers = true;
      },
      prepare(formData, teamId) {
        return {
          payload: {
            formData: formData,
            teamId: teamId,
          },
        };
      },
    },
    inviteMembersSuccess(state) {
      state.membersInvited = true;
      state.invitingMembers = false;
    },
    inviteMembersError(state, action: PayloadAction<any>) {
      state.errorMessage = action.payload;
      state.invitingMembers = false;
    },
    resetMembersInvited(state) {
      state.membersInvited = false;
    },
    removeMember(state, action: PayloadAction<any>) {
      state.removingUserFromTeam = true;
    },
    removeMemberSuccess(state) {
      state.removingUserFromTeam = false;
    },
    removeMemberError(state, action: PayloadAction<any>) {
      state.removingUserFromTeam = false;
      state.errorMessage = action.payload.message;
    },
    cleanUp(state) {
      state.team = initialState.team;
      state.memberScores = initialState.memberScores;
      state.teamScores = initialState.teamScores;
      state.errorMessage = initialState.errorMessage;
      state.teamPersonalityReports = initialState.teamPersonalityReports;
      state.memberPersonalityReports = initialState.memberPersonalityReports;
      state.allowedDomains = initialState.allowedDomains;
      state.membersInvited = initialState.membersInvited;
      state.loadingTeams = initialState.loadingTeams;
      state.loadingPersonalityScores = initialState.loadingPersonalityScores;
      state.loadingPersonalityReports = initialState.loadingPersonalityReports;
      state.loadingAllowedDomains = initialState.loadingAllowedDomains;
      state.removingUserFromTeam = initialState.removingUserFromTeam;
    },
    getUsersList(state) {
      state.loadingUsers = true;
    },
    getUsersListSuccess(state, action: PayloadAction<any>) {
      state.loadingUsers = false;
      state.usersList = action.payload;
    },
    getUsersListError(state, action: PayloadAction<any>) {
      state.loadingUsers = false;
      state.errorMessage = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = teamPageSlice;
