import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    background-color: #f7f7f7;
    font-size: 14px;
  }

  body {
    font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  .nav-bar {
    height: 60px;
    background-color: #001f4e;
    display: flex;
    padding: 0px 16px;
    align-items: center;
  }

  .nav-header {
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 500;
    color: #ffffff!important;
    margin-left: 8px;
  }

  .nav-link-item {
    text-decoration: none;
    margin-left: 32px;
    padding-bottom: 18px;
    font-family: Lato;
    font-weight: bold;
    line-height: 1.5;
    color: #ffffff!important;
  }

  .nav-link.active {
    border-bottom: 3px solid #2dccd3;
  }

  .nav-link {
    padding: 1rem 1rem
  }

  .headline-panel-header-text {
    font-family: Lato;
    font-size: 24px;
    color: #001f4e;
  }

  .member-count {
    height: 20px;
    font-family: Lato;
    font-size: 14px;
    line-height: 1.43;
    color: #828283;
  }

  .horizontal-avatar-row {
    padding: 17px 0 0 12px;
    display: inline-flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }


  .teal-button {
    width: 97px;
    height: 40px;
    border-radius: 4px;
    background-color: #2dccd3;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: #ffffff;
    border: none;
  }

  .teal-button:hover {
    background-color: #42d1d7;
  }

  .teal-button:active {
    background-color: #1f8e93;
  }

  .teal-button:focus {
    border: 1px solid #fff;
    box-shadow: 0 0 0 .2rem #2dccd3;
  }

  .arrow-button {
    width: 145px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #ffffff;
    background-color: #FFF;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.71;
    color: #425cc7;
    text-align: center;
    margin-top: 34px;
  }

  .inverse-arrow-button {
    width: 80%;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #425cc7;
    background-color: #425cc7;
    color: #FFF;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.71;
    text-align: center;
  }

  .form-label-text {
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: #000000;
  }

  .modal-header {
    font-family: Lato;
    font-size: 20px;
    font-weight: bold;
    color: #001f4e;
  }

  .modal-text {
    font-family: Lato;
    font-size: 16px;
    line-height: 1.5;
  }

  .icon-button {
    padding: 0;
    text-decoration: none;
    border: 0;
    outline: 0;
    background-color: transparent;
  }

  .section-header {
    font-family: Lato;
    font-size: 20px;
    font-weight: bold;
    color: #032468;
  }

  .separator {
    border: 1px 0 0 0 solid #b1b3b3;
    margin-top: 0;
  }

  .edit-form-label {
    font-size: 14px;
    font-weight: 600;
  }

  .edit-form-option {
    color: #828283;
  }

  .notable-popover {
    width: 175px;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 2px;
    font-family: Lato;
    font-size: 12px;
    background-color: #f7f7f7;
    color: #4d4d4f;
    border: none;
    text-align: center;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .breadcrumb {
    font-size: 12px;
    font-family: Lato;
    font-weight: bold;
    padding-left: 0px;
  }

  .dropdown-item.active {
    color: #000000;
    background-color: #FFF;
  }

  .download-button:disabled{
    cursor:wait;
  }

  .sample-data-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 42px;
    background-color: #d9def4;
    color: #425cc7;
  }
`;
