import { all, call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { request } from "utils/request";
import { actions } from "./slice";
import { WorkplaceInsightsResponse } from "./types";

export function* getUser(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/${action.payload}`;
    const getUserResponse = yield call(request, requestUrl);
    yield put(actions.getUserSuccess(getUserResponse));
  } catch (error) {
    yield put(actions.getUserError(error.response));
  }
}

export function* getWorkplaceInsights(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/${action.payload.userAccountId}/workplaceInsights/format/${action.payload.format}`;
    const workplaceInsightsResponse: WorkplaceInsightsResponse = yield call(
      request,
      requestUrl
    );
    yield put(
      actions.getWorkplaceInsightsSuccess(
        workplaceInsightsResponse,
        action.payload.format
      )
    );
  } catch (error) {
    yield put(actions.getWorkplaceInsightsError(error.response.status));
  }
}

export function* individualPersonalitySaga() {
  yield all([
    takeLatest(actions.getWorkplaceInsights.type, getWorkplaceInsights),
    takeLatest(actions.getUser.type, getUser),
  ]);
}
