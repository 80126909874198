import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import emptyIcon from "resources/images/team-average-empty.jpg";

export default function NoTeamScores() {
  return (
    <Container fluid="lg" className="pb-5">
      <Row className="text-center">
        <Col lg={{ span: 6, offset: 3 }}>
          <img
            src={emptyIcon}
            alt="No Scores"
            style={{ height: "200px", width: "200px" }}
          />
          <p className="personality-empty-state-text">
            Once your team members have taken their personality assessment,
            their results will appear here.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
