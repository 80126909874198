import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { TeamScores, MemberScores } from "../types";
import { TeamWorkplaceInsights } from "./TeamWorkplaceInsights";
import { Header } from "./Header";
import NoTeamScores from "./NoTeamScores";
import { useSelector } from "react-redux";
import { selectLoadingPersonalityScores } from "../selectors";
import Skeleton from "react-loading-skeleton";

interface Props {
  teamScores: TeamScores;
  memberScores: MemberScores;
  isLoading: boolean;
}

export function TeamPersonalityComparisonCard({
  teamScores,
  memberScores,
  isLoading,
}) {
  const loadingPersonalityScores = useSelector(selectLoadingPersonalityScores);

  const [scoreData, setScoreData] = useState<any>(null);

  useEffect(() => {
    const teamAverage = [
      teamScores?.ACH.teamAverage + 25,
      teamScores?.MOT.teamAverage + 25,
      teamScores?.CON.teamAverage + 25,
      teamScores?.AST.teamAverage + 25,
      teamScores?.EXT.teamAverage + 25,
      teamScores?.COP.teamAverage + 25,
      teamScores?.CMP.teamAverage + 25,
      teamScores?.PAT.teamAverage + 25,
      teamScores?.SCN.teamAverage + 25,
      teamScores?.OPEN.teamAverage + 25,
    ];

    const scoreData = [
      {
        data: [25, 25, 25, 25, 25, 25, 25, 25, 25, 25],
        fill: true,
        borderColor: "rgb(183.6, 191.25, 201.45)",
        backgroundColor: "rgba(204, 209, 219, 1)",
        borderWidth: 1,
        pointBorderWidth: 0,
        pointHitRadius: 0,
        label: "",
      },
      {
        data: teamAverage,
        fill: true,
        backgroundColor: "rgba(0, 31, 78, 0.2)",
        borderColor: "rgb(0, 31, 78)",
        pointBackgroundColor: "rgb(0, 31, 78)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(0, 31, 78)",
        label: "Team Average",
      },
      {
        data: [75, 75, 75, 75, 75, 75, 75, 75, 75, 75],
        fill: true,
        backgroundColor: "rgba(255, 255, 255, 0)",
        borderColor: "rgb(100, 100, 100)",
        borderDash: [3, 3],
        borderDashOffset: 3,
        borderWidth: 2,
        pointBorderWidth: 0,
        pointHitRadius: 0,
        pointRadius: 0,
        label: "General Population",
      },
    ];
    setScoreData(scoreData);
  }, [loadingPersonalityScores, teamScores]);

  /* SAVE THIS FOR BETA (Adds multiple score graphs to the radar) */

  // const randomColorArray = [
  //   "rgba(17, 157, 67, 0.6)",
  //   "rgba(28, 83, 251, 0.1)",
  //   "rgba(30, 154, 188, 0.3)",
  //   "rgba(162, 109, 193, 0.6)",
  //   "rgba(127, 204, 137, 0.3)",
  //   "rgba(228, 71, 81, 0.3)",
  //   "rgba(98, 181, 156, 0.6)",
  //   "rgba(19, 163, 164, 0.6)",
  //   "rgba(123, 127, 119, 0.8)",
  //   "rgba(177, 249, 24, 0.3)",
  // ];

  // memberScores?.map((member) => {
  //   const randomIndex = Math.floor(Math.random() * 9);
  //   const memberName = member.firstName
  //     ? `${member.firstName} ${member.lastName}`
  //     : "Stephen the Great";
  //   const scoreObject: any = {
  //     data: [
  //       member?.ACH,
  //       member?.MOT,
  //       member?.CON,
  //       member?.AST,
  //       member?.EXT,
  //       member?.COP,
  //       member?.CMP,
  //       member?.PAT,
  //       member?.SCN,
  //       member?.OPEN,
  //     ],
  //     fill: true,
  //     backgroundColor: randomColorArray[randomIndex],
  //     borderColor: "rgb(0, 31, 78)",
  //     // pointBackgroundColor: "rgb(0, 31, 78)",
  //     // pointBorderColor: "#fff",
  //     pointHoverBackgroundColor: "#fff",
  //     pointHoverBorderColor: "rgb(0, 31, 78)",
  //     label: memberName,
  //   };
  //   scoreData.push(scoreObject);
  // });

  return (
    <Container fluid="lg" style={{ paddingBottom: "60px" }}>
      <Row>
        <Col>
          <Card>
            <Card.Body className="personality-card-body">
              <Header isLoading={isLoading} />
              {isLoading ? (
                <>
                  <div className="skeleton-insights-report-icon text-center mb-2">
                    <Skeleton
                      height={200}
                      width={200}
                      duration={2}
                      circle={true}
                    />
                  </div>
                  <div className="text-center mb-2">
                    <Skeleton height={20} width={400} />
                  </div>
                  <div className="text-center mb-5">
                    <Skeleton height={20} width={250} />
                  </div>
                </>
              ) : null}
              {teamScores && scoreData ? (
                <TeamWorkplaceInsights
                  scoreData={scoreData}
                  numberOfMembers={memberScores?.length}
                />
              ) : !isLoading ? (
                <NoTeamScores />
              ) : null}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
